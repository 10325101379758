export const coordinates = [
	{
		name: "Victoria",
		lineColor: "#118dd6",
		features: [
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.114891424443535,
							51.46261000896967
						],
						[
							-0.1154612,
							51.4627201
						],
						[
							-0.1167776,
							51.4629816
						],
						[
							-0.121132,
							51.4638465
						],
						[
							-0.1215893,
							51.463931
						],
						[
							-0.1223392,
							51.4641384
						],
						[
							-0.1234773,
							51.4645792
						],
						[
							-0.1240446,
							51.4648628
						],
						[
							-0.1245532,
							51.4652346
						],
						[
							-0.1248932,
							51.4655457
						],
						[
							-0.1253925,
							51.4661992
						],
						[
							-0.125583834528522,
							51.4668713
						],
						[
							-0.125705747622817,
							51.467440956209565
						],
						[
							-0.12569490833993,
							51.4680261059226
						],
						[
							-0.125441616679861,
							51.46867845640065
						],
						[
							-0.125167034528522,
							51.46910374651369
						],
						[
							-0.124835465471478,
							51.46953358315122
						],
						[
							-0.124173078332637,
							51.47028024714855
						],
						[
							-0.123502384024243,
							51.47102451037071
						],
						[
							-0.122940511388372,
							51.47164230662258
						],
						[
							-0.12251845435888,
							51.47216887834858
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.122491308860511,
							51.47219667371571
						],
						[
							-0.12234150500788,
							51.472397323454985
						],
						[
							-0.122118449749374,
							51.472697475558256
						],
						[
							-0.121944811610457,
							51.472936182181606
						],
						[
							-0.121534421434226,
							51.47345527030711
						],
						[
							-0.1207362,
							51.4744846
						],
						[
							-0.120325913094296,
							51.47509674151831
						],
						[
							-0.1200921,
							51.4756212
						],
						[
							-0.1199342,
							51.4762813
						],
						[
							-0.1199064,
							51.4767518
						],
						[
							-0.1199648,
							51.477317
						],
						[
							-0.1207897,
							51.480801
						],
						[
							-0.121377,
							51.4826903
						],
						[
							-0.1216691,
							51.4834535
						],
						[
							-0.1218633,
							51.4838768
						],
						[
							-0.122212,
							51.4843442
						],
						[
							-0.1225796,
							51.4846758
						],
						[
							-0.123062566163799,
							51.48496161937574
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.12309728461939,
							51.48498050339025
						],
						[
							-0.1249426,
							51.4858991
						],
						[
							-0.1285958,
							51.4877197
						],
						[
							-0.129483,
							51.4880992
						],
						[
							-0.1302772,
							51.4883418
						],
						[
							-0.1311313,
							51.4885657
						],
						[
							-0.1320079,
							51.4887686
						],
						[
							-0.132934600537786,
							51.488888171103945
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.132974026146632,
							51.488891797666966
						],
						[
							-0.1342926,
							51.4889648
						],
						[
							-0.1362384,
							51.4890837
						],
						[
							-0.1372958,
							51.4891508
						],
						[
							-0.1382233,
							51.4892211
						],
						[
							-0.1391708,
							51.4893583
						],
						[
							-0.1399732,
							51.4895339
						],
						[
							-0.1409728,
							51.4897824
						],
						[
							-0.1418598,
							51.4900703
						],
						[
							-0.1425437,
							51.4904159
						],
						[
							-0.1432358,
							51.4909463
						],
						[
							-0.1436998,
							51.4916093
						],
						[
							-0.1440071,
							51.492305
						],
						[
							-0.1441288,
							51.4929111
						],
						[
							-0.1441467,
							51.4936013
						],
						[
							-0.1440767,
							51.4943076
						],
						[
							-0.1438867,
							51.4949693
						],
						[
							-0.1436992,
							51.4953883
						],
						[
							-0.1434869,
							51.4957931
						],
						[
							-0.143237692644883,
							51.496340504190286
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.143218903997589,
							51.4963752712379
						],
						[
							-0.143105968353388,
							51.49655402455304
						],
						[
							-0.142937110037606,
							51.496762373074
						],
						[
							-0.1427808,
							51.4969751
						],
						[
							-0.1426333,
							51.4971604
						],
						[
							-0.1425728,
							51.4972324
						],
						[
							-0.1421676,
							51.4978087
						],
						[
							-0.141483,
							51.4988127
						],
						[
							-0.1410038,
							51.4996495
						],
						[
							-0.1406149,
							51.5005709
						],
						[
							-0.1403881,
							51.5012993
						],
						[
							-0.1403343,
							51.5017901
						],
						[
							-0.1403882,
							51.5022439
						],
						[
							-0.1405476,
							51.5028081
						],
						[
							-0.1407581,
							51.5032585
						],
						[
							-0.1411515,
							51.5038925
						],
						[
							-0.1415664,
							51.5045151
						],
						[
							-0.141847,
							51.504916
						],
						[
							-0.1423836,
							51.5056178
						],
						[
							-0.143106346532779,
							51.50654175265661
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.143130345178118,
							51.50657361709414
						],
						[
							-0.1436139,
							51.5072406
						],
						[
							-0.1439061,
							51.5079871
						],
						[
							-0.1439667,
							51.50879
						],
						[
							-0.1438755,
							51.5093366
						],
						[
							-0.1436604,
							51.5099587
						],
						[
							-0.1434348,
							51.5105877
						],
						[
							-0.1423061,
							51.512684
						],
						[
							-0.1421627,
							51.513036
						],
						[
							-0.1420014,
							51.5135783
						],
						[
							-0.141955422141667,
							51.5143572
						],
						[
							-0.142077649934992,
							51.515288267639015
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.14208784881023,
							51.51532614437964
						],
						[
							-0.142054235911188,
							51.51562798261417
						],
						[
							-0.142105664302674,
							51.516157446071865
						],
						[
							-0.142284890491266,
							51.51674050105406
						],
						[
							-0.142470695245633,
							51.51725178158723
						],
						[
							-0.142526434528521,
							51.51768975406234
						],
						[
							-0.1425335,
							51.5186845
						],
						[
							-0.1424223,
							51.5199544
						],
						[
							-0.1422987,
							51.520649
						],
						[
							-0.1421492,
							51.5211998
						],
						[
							-0.1419204,
							51.5217836
						],
						[
							-0.1415236,
							51.5223722
						],
						[
							-0.1408861,
							51.5230568
						],
						[
							-0.140248540665555,
							51.523555261288315
						],
						[
							-0.139373773805555,
							51.52402226113356
						],
						[
							-0.138117223234919,
							51.52464472294273
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.138086379841895,
							51.52466828680391
						],
						[
							-0.137338559334445,
							51.525510195961274
						],
						[
							-0.136936870477778,
							51.5262123
						],
						[
							-0.136757922576667,
							51.52644215390719
						],
						[
							-0.136567559914446,
							51.526698646957726
						],
						[
							-0.136459193344446,
							51.52690246859902
						],
						[
							-0.136327589726666,
							51.52712883856229
						],
						[
							-0.135948478293334,
							51.52749835339247
						],
						[
							-0.135569608395557,
							51.527826549008886
						],
						[
							-0.135062049061113,
							51.52811464321334
						],
						[
							-0.134303990434612,
							51.52842487477233
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.134266543425979,
							51.52843805128134
						],
						[
							-0.133348307525556,
							51.528710557252154
						],
						[
							-0.132494373805555,
							51.52890516439852
						],
						[
							-0.13199413314,
							51.52899192152589
						],
						[
							-0.131428814761111,
							51.52904702307086
						],
						[
							-0.130902782386099,
							51.52911554843426
						],
						[
							-0.130892403617778,
							51.529118844239726
						],
						[
							-0.130476129777232,
							51.52917534331942
						],
						[
							-0.1299311,
							51.529293231859896
						],
						[
							-0.1291599,
							51.5294367
						],
						[
							-0.12842,
							51.5296029
						],
						[
							-0.127719,
							51.5297392
						],
						[
							-0.1268072,
							51.5298876
						],
						[
							-0.1255839,
							51.5300589
						],
						[
							-0.1245337,
							51.5302433
						],
						[
							-0.1237215,
							51.530405
						],
						[
							-0.122962818197787,
							51.530599863895205
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.122924420413687,
							51.530609979888276
						],
						[
							-0.1224641,
							51.5307343
						],
						[
							-0.1220478,
							51.5308426
						],
						[
							-0.1216069,
							51.5309643
						],
						[
							-0.1208822,
							51.5311881
						],
						[
							-0.1197395,
							51.5316565
						],
						[
							-0.1055657,
							51.5382972
						],
						[
							-0.1048295,
							51.538769
						],
						[
							-0.104296,
							51.5391798
						],
						[
							-0.1037894,
							51.539671
						],
						[
							-0.1034767,
							51.5401221
						],
						[
							-0.103167,
							51.5407735
						],
						[
							-0.1029853,
							51.54163
						],
						[
							-0.1029069,
							51.5427158
						],
						[
							-0.1030187,
							51.544101
						],
						[
							-0.1031766,
							51.5450042
						],
						[
							-0.1033721,
							51.5458422
						],
						[
							-0.10375,
							51.547
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.10375,
							51.547
						],
						[
							-0.1039103,
							51.5475064
						],
						[
							-0.1040999,
							51.5481328
						],
						[
							-0.1058556,
							51.5526714
						],
						[
							-0.1065,
							51.5543697
						],
						[
							-0.1068,
							51.557
						],
						[
							-0.106342620069555,
							51.56461937920347
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.106339418444566,
							51.56465828977886
						],
						[
							-0.10638457738852,
							51.56514243177542
						],
						[
							-0.106512004830771,
							51.5655988
						],
						[
							-0.106476331635091,
							51.56605639747626
						],
						[
							-0.106125,
							51.5673585
						],
						[
							-0.1057608,
							51.5681561
						],
						[
							-0.1054655,
							51.5685306
						],
						[
							-0.1051112,
							51.5688242
						],
						[
							-0.1046358,
							51.5690993
						],
						[
							-0.1040512,
							51.5693329
						],
						[
							-0.103358,
							51.5695198
						],
						[
							-0.0988524,
							51.570218
						],
						[
							-0.0969937,
							51.5705918
						],
						[
							-0.0956396,
							51.5709279
						],
						[
							-0.0889499,
							51.573236
						],
						[
							-0.0879755,
							51.5736177
						],
						[
							-0.0871665,
							51.5740288
						],
						[
							-0.0864696,
							51.5745243
						],
						[
							-0.0858377,
							51.5750234
						],
						[
							-0.0852117,
							51.5755556
						],
						[
							-0.0825543,
							51.5781944
						],
						[
							-0.0815917,
							51.57891
						],
						[
							-0.0807738,
							51.5793669
						],
						[
							-0.0796348,
							51.5799756
						],
						[
							-0.0767917,
							51.5814729
						],
						[
							-0.075131005767103,
							51.582437860548126
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.075095905346547,
							51.58245599491418
						],
						[
							-0.0745028,
							51.5827261
						],
						[
							-0.0727884,
							51.5834588
						],
						[
							-0.070914,
							51.5842585
						],
						[
							-0.0706037,
							51.5844321
						],
						[
							-0.0693999,
							51.5850755
						],
						[
							-0.0680058,
							51.5858409
						],
						[
							-0.0674166,
							51.5861285
						],
						[
							-0.066762,
							51.5864052
						],
						[
							-0.0656446,
							51.5868071
						],
						[
							-0.0647223,
							51.5871137
						],
						[
							-0.0638813,
							51.5873726
						],
						[
							-0.06312,
							51.5875765
						],
						[
							-0.0624316,
							51.5877779
						],
						[
							-0.0603318,
							51.588316
						],
						[
							-0.060107302526599,
							51.588374864950964
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.060068896793658,
							51.58838493075702
						],
						[
							-0.0597144,
							51.5884778
						],
						[
							-0.0592533,
							51.5885793
						],
						[
							-0.058933,
							51.5886161
						],
						[
							-0.0585074,
							51.5886246
						],
						[
							-0.0580889,
							51.5886081
						],
						[
							-0.0570676,
							51.588448
						],
						[
							-0.0564067,
							51.5882823
						],
						[
							-0.0548067,
							51.5877621
						],
						[
							-0.0543361,
							51.5876159
						],
						[
							-0.0531491,
							51.5872233
						],
						[
							-0.0514956,
							51.5867389
						],
						[
							-0.0508873,
							51.5866215
						],
						[
							-0.0500664,
							51.5865445
						],
						[
							-0.0489161,
							51.5865541
						],
						[
							-0.0463885,
							51.5867559
						],
						[
							-0.0448944,
							51.5868807
						],
						[
							-0.0435775,
							51.5869541
						],
						[
							-0.0407,
							51.587
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.0407,
							51.587
						],
						[
							-0.037,
							51.5869
						],
						[
							-0.034,
							51.5865
						],
						[
							-0.0201,
							51.5839
						]
					]
				}
			}
		]
	},
	{
		name: "Piccadilly",
		lineColor: "#012775",
		features: [
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.18582568760043,
							51.494940382355814
						],
						[
							-0.186280163141244,
							51.495012929648965
						],
						[
							-0.186726450854992,
							51.49502909721007
						],
						[
							-0.187202605598494,
							51.49502041073965
						],
						[
							-0.187759402799247,
							51.494989789141755
						],
						[
							-0.188223669829002,
							51.49491558119778
						],
						[
							-0.188550960341997,
							51.494821689401526
						],
						[
							-0.18899752348324,
							51.49467511662514
						],
						[
							-0.189497210107724,
							51.49445298089089
						],
						[
							-0.18983216205198,
							51.494265746127084
						],
						[
							-0.1930872,
							51.4921905
						],
						[
							-0.194151482191761,
							51.49149259712507
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.194184662369276,
							51.49147069406985
						],
						[
							-0.195188837122854,
							51.49080340565126
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.205998941080947,
							51.49069090506463
						],
						[
							-0.2074438,
							51.4902549
						],
						[
							-0.2078464,
							51.4901629
						],
						[
							-0.2082606,
							51.4900866
						],
						[
							-0.2085221,
							51.4900525
						],
						[
							-0.2091062,
							51.4899868
						],
						[
							-0.2098047,
							51.4899461
						],
						[
							-0.2103489,
							51.4899393
						],
						[
							-0.2108993,
							51.4899592
						],
						[
							-0.2118351,
							51.4900404
						],
						[
							-0.2128789,
							51.4901581
						],
						[
							-0.213729163952768,
							51.49025760209264
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.213768619004947,
							51.49026104275777
						],
						[
							-0.2139257,
							51.4902701
						],
						[
							-0.214924,
							51.4902975
						],
						[
							-0.2159456,
							51.4902636
						],
						[
							-0.2162493,
							51.4902515
						],
						[
							-0.2165021,
							51.4902428
						],
						[
							-0.2168793,
							51.490229
						],
						[
							-0.2185298,
							51.4901441
						],
						[
							-0.2189483,
							51.4901259
						],
						[
							-0.2189585,
							51.4901262
						],
						[
							-0.2193616,
							51.4901339
						],
						[
							-0.2196329,
							51.4901583
						],
						[
							-0.219945,
							51.4901908
						],
						[
							-0.22016,
							51.4902203
						],
						[
							-0.2204008,
							51.4902698
						],
						[
							-0.2208901,
							51.4903946
						],
						[
							-0.2211269,
							51.4904645
						],
						[
							-0.2213124,
							51.4905354
						],
						[
							-0.2215474,
							51.4906423
						],
						[
							-0.2218926,
							51.4908297
						],
						[
							-0.2220668,
							51.4909422
						],
						[
							-0.2223151,
							51.4911396
						],
						[
							-0.2226305,
							51.4914381
						],
						[
							-0.223316770590784,
							51.492108693364266
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.223345022074079,
							51.49213631397084
						],
						[
							-0.2240276,
							51.492804
						],
						[
							-0.2242771,
							51.4929535
						],
						[
							-0.2245047,
							51.4930769
						],
						[
							-0.2246881,
							51.4931378
						],
						[
							-0.2254199,
							51.4933526
						],
						[
							-0.2259567,
							51.493499
						],
						[
							-0.2265229,
							51.4936093
						],
						[
							-0.2267587,
							51.4936452
						],
						[
							-0.2269015,
							51.4936582
						],
						[
							-0.2270346,
							51.4936639
						],
						[
							-0.2271687,
							51.4936619
						],
						[
							-0.2273599,
							51.4936504
						],
						[
							-0.2276267,
							51.49363
						],
						[
							-0.2282859,
							51.4936007
						],
						[
							-0.2288195,
							51.4935998
						],
						[
							-0.2295265,
							51.4936217
						],
						[
							-0.2297079,
							51.4936338
						],
						[
							-0.230126,
							51.4936636
						],
						[
							-0.2303899,
							51.4936874
						],
						[
							-0.2314464,
							51.4937513
						],
						[
							-0.2319306,
							51.4937752
						],
						[
							-0.232409,
							51.4938106
						],
						[
							-0.2328612,
							51.4938636
						],
						[
							-0.233072,
							51.4938892
						],
						[
							-0.2334854,
							51.4939399
						],
						[
							-0.2339106,
							51.4939824
						],
						[
							-0.2341364,
							51.4939993
						],
						[
							-0.2345997,
							51.4940341
						],
						[
							-0.2348132,
							51.4940496
						],
						[
							-0.235777713635565,
							51.49411535115813
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.235817247982396,
							51.49411829117653
						],
						[
							-0.2364106,
							51.4941661
						],
						[
							-0.2368651,
							51.4942196
						],
						[
							-0.2371197,
							51.4942547
						],
						[
							-0.2379999,
							51.4944046
						],
						[
							-0.2388779,
							51.4945448
						],
						[
							-0.2392382,
							51.4946058
						],
						[
							-0.2396067,
							51.4946596
						],
						[
							-0.2398008,
							51.4946851
						],
						[
							-0.2408432,
							51.494794
						],
						[
							-0.2417947,
							51.4948755
						],
						[
							-0.2423268,
							51.4949083
						],
						[
							-0.2428446,
							51.4949209
						],
						[
							-0.2433219,
							51.4949238
						],
						[
							-0.2437908,
							51.4949128
						],
						[
							-0.2442318,
							51.4948942
						],
						[
							-0.2446638,
							51.4948899
						],
						[
							-0.2448129,
							51.4948915
						],
						[
							-0.2449609,
							51.4948963
						],
						[
							-0.2453404,
							51.4949045
						],
						[
							-0.245814913657105,
							51.49493685102214
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.245854434881942,
							51.49493987388956
						],
						[
							-0.246693,
							51.495011
						],
						[
							-0.246928,
							51.4950253
						],
						[
							-0.2475219,
							51.4950467
						],
						[
							-0.2481929,
							51.4950795
						],
						[
							-0.2489134,
							51.4950964
						],
						[
							-0.2495748,
							51.4951083
						],
						[
							-0.2508836,
							51.4951462
						],
						[
							-0.2514875,
							51.4951479
						],
						[
							-0.2521085,
							51.4951469
						],
						[
							-0.2532777,
							51.4951452
						],
						[
							-0.2539147,
							51.4951486
						],
						[
							-0.254510126217157,
							51.49514366552859
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.254550010572405,
							51.49514293537636
						],
						[
							-0.255186,
							51.4951249
						],
						[
							-0.2554399,
							51.4951156
						],
						[
							-0.2558112,
							51.4951013
						],
						[
							-0.2570672,
							51.495065
						],
						[
							-0.2581752,
							51.4950497
						],
						[
							-0.2589677,
							51.4950587
						],
						[
							-0.2591277,
							51.4950616
						],
						[
							-0.2594342,
							51.4950655
						],
						[
							-0.259682,
							51.4950651
						],
						[
							-0.2601306,
							51.4950609
						],
						[
							-0.2603179,
							51.4950478
						],
						[
							-0.2609633,
							51.4949989
						],
						[
							-0.2613798,
							51.4949591
						],
						[
							-0.2627467,
							51.4948152
						],
						[
							-0.2638152,
							51.4946884
						],
						[
							-0.2652324,
							51.4945391
						],
						[
							-0.2656482,
							51.4944963
						],
						[
							-0.265651715521404,
							51.49449610418332
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.268140938009075,
							51.49458294827107
						],
						[
							-0.2686107,
							51.4946687
						],
						[
							-0.2694157,
							51.4947987
						],
						[
							-0.2704093,
							51.4951295
						],
						[
							-0.2710732,
							51.4954353
						],
						[
							-0.2716862,
							51.495799
						],
						[
							-0.2721974,
							51.4962403
						],
						[
							-0.2726914,
							51.4966705
						],
						[
							-0.273218,
							51.4970345
						],
						[
							-0.2737031,
							51.4973187
						],
						[
							-0.2740089,
							51.4974666
						],
						[
							-0.2744508,
							51.4976694
						],
						[
							-0.2751001,
							51.4979332
						],
						[
							-0.2758177,
							51.4982336
						],
						[
							-0.2763412,
							51.498465
						],
						[
							-0.2768515,
							51.4987259
						],
						[
							-0.2771555,
							51.498911
						],
						[
							-0.2773992,
							51.499081
						],
						[
							-0.2776654,
							51.4992817
						],
						[
							-0.2778959,
							51.4994896
						],
						[
							-0.2780104,
							51.4996031
						],
						[
							-0.2781226,
							51.4997258
						],
						[
							-0.2783114,
							51.4999789
						],
						[
							-0.2783996,
							51.5001064
						],
						[
							-0.2785218,
							51.5002998
						],
						[
							-0.2786322,
							51.5005011
						],
						[
							-0.2787294,
							51.5007201
						],
						[
							-0.2788323,
							51.5010959
						],
						[
							-0.2790101,
							51.5014276
						],
						[
							-0.2792693,
							51.5018755
						],
						[
							-0.2794301,
							51.5020873
						],
						[
							-0.2795691,
							51.5022661
						],
						[
							-0.2797643,
							51.5024777
						],
						[
							-0.279903324400723,
							51.50262903955433
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.279932073985372,
							51.50265616205955
						],
						[
							-0.2801097,
							51.5028013
						],
						[
							-0.2804136,
							51.5030168
						],
						[
							-0.2806575,
							51.5031665
						],
						[
							-0.280662120097887,
							51.50316883796229
						],
						[
							-0.280671858810906,
							51.50317376615741
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.17257892682836,
							51.49402315763971
						],
						[
							-0.172170102799247,
							51.4940132
						],
						[
							-0.1719546,
							51.4940623
						],
						[
							-0.1718237,
							51.4941113
						],
						[
							-0.1716725,
							51.4941883
						],
						[
							-0.171519,
							51.4942851
						],
						[
							-0.1713937,
							51.4944012
						],
						[
							-0.1712039,
							51.4946241
						],
						[
							-0.1711399,
							51.4946935
						],
						[
							-0.1710528,
							51.494768
						],
						[
							-0.1708893,
							51.4948563
						],
						[
							-0.1707314,
							51.4949159
						],
						[
							-0.1705201,
							51.4949756
						],
						[
							-0.1702864,
							51.4950316
						],
						[
							-0.1701156,
							51.4950863
						],
						[
							-0.1699378,
							51.4951663
						],
						[
							-0.1697991,
							51.4952497
						],
						[
							-0.1696698,
							51.4953516
						],
						[
							-0.1695443,
							51.4954622
						],
						[
							-0.1694525,
							51.4955764
						],
						[
							-0.1693732,
							51.4956815
						],
						[
							-0.1693085,
							51.4958046
						],
						[
							-0.1692624,
							51.4959351
						],
						[
							-0.1692238,
							51.4960646
						],
						[
							-0.1691794,
							51.4961844
						],
						[
							-0.1691108,
							51.4963221
						],
						[
							-0.1690274,
							51.4964475
						],
						[
							-0.1689227,
							51.4965603
						],
						[
							-0.1688033,
							51.4966674
						],
						[
							-0.1686752,
							51.4967639
						],
						[
							-0.1685081,
							51.4968681
						],
						[
							-0.165372,
							51.4985935
						],
						[
							-0.1636398,
							51.4995607
						],
						[
							-0.1633382,
							51.4997429
						],
						[
							-0.1628749,
							51.5000464
						],
						[
							-0.1625564,
							51.500277
						],
						[
							-0.1620852,
							51.5007019
						],
						[
							-0.1618575,
							51.5008903
						],
						[
							-0.1616761,
							51.5010269
						],
						[
							-0.1614484,
							51.5011841
						],
						[
							-0.1612739,
							51.5012876
						],
						[
							-0.1610742,
							51.5013922
						],
						[
							-0.1608649,
							51.5014871
						],
						[
							-0.160609755733053,
							51.50159782122232
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.160572684081739,
							51.50161208683018
						],
						[
							-0.1603209,
							51.5016971
						],
						[
							-0.1600466,
							51.5017763
						],
						[
							-0.1597195,
							51.5018661
						],
						[
							-0.1594331,
							51.5019394
						],
						[
							-0.1591074,
							51.5020284
						],
						[
							-0.1588365,
							51.5020955
						],
						[
							-0.1585546,
							51.5021493
						],
						[
							-0.1583054,
							51.5021793
						],
						[
							-0.1580265,
							51.5022063
						],
						[
							-0.1576562,
							51.5022311
						],
						[
							-0.1569536,
							51.5022699
						],
						[
							-0.156617,
							51.5022912
						],
						[
							-0.1562535,
							51.502335
						],
						[
							-0.1558109,
							51.5024023
						],
						[
							-0.1553229,
							51.5024835
						],
						[
							-0.1549261,
							51.502548
						],
						[
							-0.152576862912845,
							51.5029573089652
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.152537928629574,
							51.50296403763581
						],
						[
							-0.1506048,
							51.5032954
						],
						[
							-0.1501273,
							51.5033749
						],
						[
							-0.1497384,
							51.5034566
						],
						[
							-0.1493187,
							51.5035704
						],
						[
							-0.1489472,
							51.5036944
						],
						[
							-0.1485395,
							51.5038416
						],
						[
							-0.1481063,
							51.5040194
						],
						[
							-0.1476554,
							51.5042341
						],
						[
							-0.1456027,
							51.5052888
						],
						[
							-0.143922,
							51.5061306
						],
						[
							-0.1434097,
							51.5064019
						],
						[
							-0.1431360340115,
							51.506548116780586
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.143101195217054,
							51.50656676461679
						],
						[
							-0.1428352,
							51.5067094
						],
						[
							-0.1418986,
							51.5071603
						],
						[
							-0.1416649,
							51.5072761
						],
						[
							-0.1411754,
							51.5074963
						],
						[
							-0.1397525,
							51.5080578
						],
						[
							-0.1392567,
							51.5082226
						],
						[
							-0.1379751,
							51.5086004
						],
						[
							-0.135798,
							51.5093201
						],
						[
							-0.1346705,
							51.5096667
						],
						[
							-0.133461174211341,
							51.50995421755078
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.133422641405379,
							51.509963481049354
						],
						[
							-0.1328725,
							51.5100972
						],
						[
							-0.1319325,
							51.5102873
						],
						[
							-0.130663,
							51.5106001
						],
						[
							-0.1299411,
							51.5107832
						],
						[
							-0.12850868953559,
							51.511216941216496
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.128470920915368,
							51.51122956232421
						],
						[
							-0.1260181,
							51.5121278
						],
						[
							-0.125262,
							51.5124774
						],
						[
							-0.124213440813241,
							51.51305052185215
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.124179320726951,
							51.51307058023254
						],
						[
							-0.123154,
							51.5137171
						],
						[
							-0.122707,
							51.5140058
						],
						[
							-0.1221909,
							51.5143206
						],
						[
							-0.1220049,
							51.5144953
						],
						[
							-0.1217382,
							51.5148755
						],
						[
							-0.1215985,
							51.5150096
						],
						[
							-0.1203569,
							51.5158825
						],
						[
							-0.1200047,
							51.5160821
						],
						[
							-0.1198567,
							51.5161884
						],
						[
							-0.1197552,
							51.5162899
						],
						[
							-0.1196987,
							51.5164133
						],
						[
							-0.1196893,
							51.5165335
						],
						[
							-0.1197108,
							51.5166837
						],
						[
							-0.1197582,
							51.5168205
						],
						[
							-0.120167915505495,
							51.51759756294829
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.120185913299529,
							51.51763273295577
						],
						[
							-0.120370429592414,
							51.518004438307294
						],
						[
							-0.1205416,
							51.5182355
						],
						[
							-0.1207026,
							51.5184938
						],
						[
							-0.1209214,
							51.5187717
						],
						[
							-0.1210842,
							51.5189777
						],
						[
							-0.1213034,
							51.5191982
						],
						[
							-0.1225539,
							51.5201934
						],
						[
							-0.1237669,
							51.5212198
						],
						[
							-0.1239121,
							51.5213654
						],
						[
							-0.124046,
							51.5215253
						],
						[
							-0.1241556,
							51.5216771
						],
						[
							-0.1242349,
							51.5218397
						],
						[
							-0.1242736,
							51.5220062
						],
						[
							-0.1242735,
							51.5222283
						],
						[
							-0.124204910608605,
							51.52312553925691
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.124206137228663,
							51.52316486646558
						],
						[
							-0.1242324,
							51.5233526
						],
						[
							-0.1242994,
							51.5235409
						],
						[
							-0.1243996,
							51.5237325
						],
						[
							-0.1253179,
							51.5253411
						],
						[
							-0.1253707,
							51.52548
						],
						[
							-0.1253969,
							51.5256527
						],
						[
							-0.125402,
							51.5257947
						],
						[
							-0.1253879,
							51.5259257
						],
						[
							-0.1253373,
							51.526138
						],
						[
							-0.125264,
							51.5263241
						],
						[
							-0.125164,
							51.5265223
						],
						[
							-0.1238458,
							51.5290409
						],
						[
							-0.1236738,
							51.5293544
						],
						[
							-0.1232607,
							51.5300273
						],
						[
							-0.12295311216556,
							51.530587476519656
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.122935960862881,
							51.53062307783602
						],
						[
							-0.122878525904444,
							51.5307605
						],
						[
							-0.1226649,
							51.5315357
						],
						[
							-0.1226145,
							51.5318597
						],
						[
							-0.1225421,
							51.5332029
						],
						[
							-0.1225283,
							51.5383675
						],
						[
							-0.1224582,
							51.5392521
						],
						[
							-0.1224145,
							51.5405876
						],
						[
							-0.1221516,
							51.5415952
						],
						[
							-0.1218298,
							51.5425427
						],
						[
							-0.1210627,
							51.5441074
						],
						[
							-0.1201132,
							51.5459022
						],
						[
							-0.118378717378139,
							51.54835719822646
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.118355406882393,
							51.548389642927305
						],
						[
							-0.1177561,
							51.54921
						],
						[
							-0.1163828,
							51.5504802
						],
						[
							-0.113025247504986,
							51.55271926489702
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.112993027542221,
							51.55274256358714
						],
						[
							-0.1118446,
							51.5536412
						],
						[
							-0.1100542,
							51.5554466
						],
						[
							-0.1087324,
							51.5568928
						],
						[
							-0.108110529444314,
							51.5578077226999
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.108094489200021,
							51.55784342212538
						],
						[
							-0.1078741,
							51.5587178
						],
						[
							-0.1074985,
							51.5597991
						],
						[
							-0.1067313,
							51.5621967
						],
						[
							-0.1065896,
							51.5628233
						],
						[
							-0.1064448,
							51.5636107
						],
						[
							-0.106339632404046,
							51.564618906003744
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.106335527753778,
							51.564658255891004
						],
						[
							-0.1062394,
							51.5655798
						],
						[
							-0.106123,
							51.5662183
						],
						[
							-0.1058597,
							51.5670874
						],
						[
							-0.1055147,
							51.5676336
						],
						[
							-0.1050861,
							51.5680937
						],
						[
							-0.1046414,
							51.5683895
						],
						[
							-0.1040942,
							51.5686262
						],
						[
							-0.1034291,
							51.5687966
						],
						[
							-0.1027499,
							51.568889
						],
						[
							-0.1018519,
							51.5689383
						],
						[
							-0.1003284,
							51.5689583
						],
						[
							-0.0995286,
							51.5689977
						],
						[
							-0.0988913,
							51.5690717
						],
						[
							-0.0983082,
							51.5691871
						],
						[
							-0.0978393,
							51.5693681
						],
						[
							-0.0973994,
							51.5696005
						],
						[
							-0.0969563,
							51.5698673
						],
						[
							-0.0963841,
							51.5703213
						],
						[
							-0.096200763040656,
							51.570477909757
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.096170630615261,
							51.570503649421475
						],
						[
							-0.0955342,
							51.5710473
						],
						[
							-0.0953341,
							51.5713227
						],
						[
							-0.0952024,
							51.5716201
						],
						[
							-0.095148,
							51.5719132
						],
						[
							-0.095163,
							51.5722959
						],
						[
							-0.0952805,
							51.5726987
						],
						[
							-0.0954787,
							51.5731025
						],
						[
							-0.0956726,
							51.5734064
						],
						[
							-0.095914,
							51.5736984
						],
						[
							-0.0967831,
							51.5748381
						],
						[
							-0.0981875,
							51.5765843
						],
						[
							-0.0984621,
							51.576954
						],
						[
							-0.0986552,
							51.5773304
						],
						[
							-0.0987411,
							51.5776501
						],
						[
							-0.0996048,
							51.582893
						],
						[
							-0.0998515,
							51.5839787
						],
						[
							-0.1001213,
							51.585138
						],
						[
							-0.1006374,
							51.586429
						],
						[
							-0.1012992,
							51.5875383
						],
						[
							-0.1018182,
							51.5883926
						],
						[
							-0.1022711,
							51.5890821
						],
						[
							-0.10241,
							51.5893525
						],
						[
							-0.102838555482209,
							51.5903300777632
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.102855009303854,
							51.590366034463905
						],
						[
							-0.1034045,
							51.5915177
						],
						[
							-0.1036077,
							51.5918254
						],
						[
							-0.1039279,
							51.5922268
						],
						[
							-0.1059423,
							51.5940657
						],
						[
							-0.1063927,
							51.5944566
						],
						[
							-0.1076287,
							51.5955705
						],
						[
							-0.1083147,
							51.5961685
						],
						[
							-0.1089172,
							51.5966854
						],
						[
							-0.109740402316586,
							51.597405861638485
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.109771028496654,
							51.59743110754829
						],
						[
							-0.1110052,
							51.598389
						],
						[
							-0.1117022,
							51.5988678
						],
						[
							-0.112278,
							51.5991906
						],
						[
							-0.1131759,
							51.5995986
						],
						[
							-0.1145267,
							51.6002727
						],
						[
							-0.1157237,
							51.6007501
						],
						[
							-0.1187083,
							51.6019424
						],
						[
							-0.1191129,
							51.6021516
						],
						[
							-0.1197219,
							51.6025403
						],
						[
							-0.1204096,
							51.6031614
						],
						[
							-0.1209711,
							51.6038871
						],
						[
							-0.1216171,
							51.6050112
						],
						[
							-0.1220226,
							51.6055805
						],
						[
							-0.1223803,
							51.6060093
						],
						[
							-0.122825,
							51.6064079
						],
						[
							-0.1232094,
							51.6066706
						],
						[
							-0.124116292923111,
							51.607058021990284
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.124153025735047,
							51.607072952607865
						],
						[
							-0.1257147,
							51.6076759
						],
						[
							-0.1270107,
							51.6082584
						],
						[
							-0.1283376,
							51.608792
						],
						[
							-0.1292069,
							51.6090728
						],
						[
							-0.1302228,
							51.6094039
						],
						[
							-0.1308132,
							51.6096607
						],
						[
							-0.1314425,
							51.6100141
						],
						[
							-0.1318466,
							51.61033
						],
						[
							-0.1320613,
							51.6105379
						],
						[
							-0.1322331,
							51.6107409
						],
						[
							-0.1323824,
							51.6109327
						],
						[
							-0.132484,
							51.6110778
						],
						[
							-0.1326033,
							51.6112746
						],
						[
							-0.1326856,
							51.611421
						],
						[
							-0.1327735,
							51.6115969
						],
						[
							-0.1328502,
							51.6118109
						],
						[
							-0.1329005,
							51.6120189
						],
						[
							-0.132934,
							51.6122112
						],
						[
							-0.1329493,
							51.6124044
						],
						[
							-0.1329577,
							51.6126072
						],
						[
							-0.1329563,
							51.6131331
						],
						[
							-0.1329563,
							51.6132994
						],
						[
							-0.1329577,
							51.6139926
						],
						[
							-0.1329619,
							51.6143582
						],
						[
							-0.1329633,
							51.6144717
						],
						[
							-0.1330428,
							51.6150678
						],
						[
							-0.1330719,
							51.6152429
						],
						[
							-0.1331084,
							51.615462
						],
						[
							-0.1331809,
							51.6158873
						],
						[
							-0.133265880810694,
							51.61645606235417
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.133272014976937,
							51.61649509079768
						],
						[
							-0.1333344,
							51.6168733
						],
						[
							-0.133386,
							51.6171115
						],
						[
							-0.1334656,
							51.6173688
						],
						[
							-0.1335744,
							51.6176261
						],
						[
							-0.1336762,
							51.617834
						],
						[
							-0.1337909,
							51.6180154
						],
						[
							-0.1338214,
							51.6180636
						],
						[
							-0.1338786,
							51.618165
						],
						[
							-0.1339804,
							51.6183001
						],
						[
							-0.134159,
							51.6185124
						],
						[
							-0.134325,
							51.6187021
						],
						[
							-0.1345664,
							51.618968
						],
						[
							-0.1346948,
							51.6191179
						],
						[
							-0.1349152,
							51.6194012
						],
						[
							-0.1350338,
							51.6195848
						],
						[
							-0.1351134,
							51.6197356
						],
						[
							-0.1352013,
							51.6199314
						],
						[
							-0.1352878,
							51.6201609
						],
						[
							-0.135331,
							51.6203429
						],
						[
							-0.135391,
							51.6206885
						],
						[
							-0.135472,
							51.6212758
						],
						[
							-0.1355417,
							51.621741
						],
						[
							-0.135684,
							51.6226965
						],
						[
							-0.1357692,
							51.6232465
						],
						[
							-0.1358082,
							51.6235367
						],
						[
							-0.1358292,
							51.6237403
						],
						[
							-0.1358445,
							51.6239023
						],
						[
							-0.1358445,
							51.6241301
						],
						[
							-0.1358319,
							51.6244488
						],
						[
							-0.1357915,
							51.6247477
						],
						[
							-0.1357343,
							51.6250387
						],
						[
							-0.1356589,
							51.6253003
						],
						[
							-0.1355878,
							51.6255004
						],
						[
							-0.1354943,
							51.6257542
						],
						[
							-0.1353952,
							51.6259577
						],
						[
							-0.1353129,
							51.6261249
						],
						[
							-0.1351762,
							51.6263605
						],
						[
							-0.1350562,
							51.6265329
						],
						[
							-0.1349058,
							51.6267571
						],
						[
							-0.1348484,
							51.6268306
						],
						[
							-0.1346273,
							51.6271057
						],
						[
							-0.1344432,
							51.627309
						],
						[
							-0.1341945,
							51.6275472
						],
						[
							-0.1333752,
							51.628252
						],
						[
							-0.1328328,
							51.6286813
						],
						[
							-0.1319757,
							51.6292561
						],
						[
							-0.1310947,
							51.6298717
						],
						[
							-0.1305905,
							51.630198
						],
						[
							-0.1300862,
							51.6305793
						],
						[
							-0.1296034,
							51.6309656
						],
						[
							-0.1289812,
							51.631465
						],
						[
							-0.127826736065239,
							51.63232170002028
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.127794327499009,
							51.632344978567616
						],
						[
							-0.1264062,
							51.6333097
						],
						[
							-0.1262766,
							51.6334043
						],
						[
							-0.1259729,
							51.6336415
						],
						[
							-0.1258138,
							51.6337896
						],
						[
							-0.1256213,
							51.6339918
						],
						[
							-0.1253327,
							51.6343581
						],
						[
							-0.1251828,
							51.6346027
						],
						[
							-0.1250626,
							51.6348795
						],
						[
							-0.1249552,
							51.6352343
						],
						[
							-0.1249219,
							51.6354606
						],
						[
							-0.124909,
							51.6356857
						],
						[
							-0.1249108,
							51.6361634
						],
						[
							-0.1249411,
							51.6364778
						],
						[
							-0.1249848,
							51.6367915
						],
						[
							-0.1250586,
							51.6371048
						],
						[
							-0.1252739,
							51.637752
						],
						[
							-0.1255725,
							51.638551
						],
						[
							-0.1258148,
							51.6392053
						],
						[
							-0.1260603,
							51.6398723
						],
						[
							-0.1263603,
							51.6406985
						],
						[
							-0.1267819,
							51.6418887
						],
						[
							-0.1272357,
							51.6431545
						],
						[
							-0.1275383,
							51.643885
						],
						[
							-0.1279038,
							51.6448796
						],
						[
							-0.1282199,
							51.6453913
						],
						[
							-0.1286019,
							51.6457939
						],
						[
							-0.129206,
							51.6462783
						],
						[
							-0.1295796,
							51.6464848
						],
						[
							-0.1299911,
							51.6466994
						],
						[
							-0.131467189765354,
							51.64736853566567
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.13150323649276,
							51.647384812799956
						],
						[
							-0.1317093,
							51.6474775
						],
						[
							-0.1319044,
							51.6475598
						],
						[
							-0.1325461,
							51.6478834
						],
						[
							-0.1326158,
							51.6479178
						],
						[
							-0.1329002,
							51.6480364
						],
						[
							-0.1333914,
							51.6482606
						],
						[
							-0.1338723,
							51.6484797
						],
						[
							-0.1344382,
							51.6487074
						],
						[
							-0.1351829,
							51.6489901
						],
						[
							-0.1355722,
							51.6491079
						],
						[
							-0.136101,
							51.6492583
						],
						[
							-0.1367192,
							51.6494126
						],
						[
							-0.1374642,
							51.6495607
						],
						[
							-0.1387578,
							51.6498029
						],
						[
							-0.1392041,
							51.6498721
						],
						[
							-0.1394794,
							51.6499058
						],
						[
							-0.1400436,
							51.6499581
						],
						[
							-0.1407455,
							51.6500113
						],
						[
							-0.1414773,
							51.6500464
						],
						[
							-0.142227,
							51.650084
						],
						[
							-0.1429779,
							51.6501428
						],
						[
							-0.1438438,
							51.650257
						],
						[
							-0.1446878,
							51.6503879
						],
						[
							-0.1457494,
							51.6506051
						],
						[
							-0.1459298,
							51.6506417
						],
						[
							-0.1464975,
							51.6507549
						],
						[
							-0.1468155,
							51.6508209
						],
						[
							-0.1470948,
							51.6509111
						],
						[
							-0.1472301,
							51.6509469
						],
						[
							-0.1477268,
							51.6510864
						],
						[
							-0.1482001,
							51.6512389
						],
						[
							-0.1490347,
							51.6515022
						],
						[
							-0.149331288227367,
							51.65159890129466
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.17257892682836,
							51.49402315763969
						],
						[
							-0.172880240952617,
							51.494036241746215
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.172919981948695,
							51.49403787703262
						],
						[
							-0.17421781897401,
							51.49408833090708
						],
						[
							-0.174655205598494,
							51.49408461162325
						],
						[
							-0.174917230170998,
							51.4940709923595
						],
						[
							-0.175129445256497,
							51.494061605816746
						],
						[
							-0.175420060341997,
							51.49404260582991
						],
						[
							-0.176014487713748,
							51.49399305965017
						],
						[
							-0.176594,
							51.493937
						],
						[
							-0.1792016,
							51.493723432842216
						],
						[
							-0.17970254245725,
							51.493739185103514
						],
						[
							-0.1802151,
							51.49379035817749
						],
						[
							-0.182791312286239,
							51.494305480526364
						],
						[
							-0.183452400063918,
							51.49443458575443
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.183491161152979,
							51.494442407930244
						],
						[
							-0.184239872628249,
							51.49459838746867
						],
						[
							-0.184845278226743,
							51.49472670620243
						],
						[
							-0.185816587713748,
							51.494938929753665
						],
						[
							-0.18582568760043,
							51.494940382355814
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.3702738,
							51.5750268
						],
						[
							-0.3698724,
							51.5749616
						],
						[
							-0.3696757,
							51.5749184
						],
						[
							-0.3692651,
							51.5748331
						],
						[
							-0.3689489,
							51.5747498
						],
						[
							-0.368623,
							51.5746552
						],
						[
							-0.3682139,
							51.5745366
						],
						[
							-0.3677496,
							51.5743885
						],
						[
							-0.3674591,
							51.5742932
						],
						[
							-0.3671715,
							51.5741899
						],
						[
							-0.3667218,
							51.5740382
						],
						[
							-0.3662994,
							51.5738809
						],
						[
							-0.3658699,
							51.5737111
						],
						[
							-0.3653685,
							51.5735002
						],
						[
							-0.3648983,
							51.573284
						],
						[
							-0.3645635,
							51.5731221
						],
						[
							-0.3642096,
							51.572935
						],
						[
							-0.3638812,
							51.5727608
						],
						[
							-0.3635665,
							51.5725864
						],
						[
							-0.3629677,
							51.5722593
						],
						[
							-0.3623703,
							51.5719168
						],
						[
							-0.3612098,
							51.5712593
						],
						[
							-0.3608434,
							51.5710452
						],
						[
							-0.3604584,
							51.5708276
						],
						[
							-0.3597149,
							51.5703807
						],
						[
							-0.3592301,
							51.5700621
						],
						[
							-0.3588399,
							51.5698005
						],
						[
							-0.3585846,
							51.5696131
						],
						[
							-0.3583275,
							51.5694274
						],
						[
							-0.3580518,
							51.5692245
						],
						[
							-0.3576416,
							51.5688963
						],
						[
							-0.3573701,
							51.568672
						],
						[
							-0.3571145,
							51.5684467
						],
						[
							-0.3568668,
							51.5682228
						],
						[
							-0.3567409,
							51.568109
						],
						[
							-0.3565643,
							51.567943
						],
						[
							-0.3561255,
							51.567511
						],
						[
							-0.3558025,
							51.567187
						],
						[
							-0.3555976,
							51.5669807
						],
						[
							-0.3553425,
							51.5667425
						],
						[
							-0.3550407,
							51.5664926
						],
						[
							-0.3547901,
							51.5662911
						],
						[
							-0.3546185,
							51.5661644
						],
						[
							-0.3542111,
							51.5658853
						],
						[
							-0.3536391,
							51.5655402
						],
						[
							-0.3532739,
							51.5653277
						],
						[
							-0.3527081,
							51.5650075
						],
						[
							-0.3525138,
							51.5648817
						],
						[
							-0.352226005494077,
							51.56467380633717
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.352193639387552,
							51.564650324567516
						],
						[
							-0.3519194,
							51.5644505
						],
						[
							-0.3515837,
							51.5641922
						],
						[
							-0.3512588,
							51.5639097
						],
						[
							-0.350903,
							51.5635614
						],
						[
							-0.3504392,
							51.5630308
						],
						[
							-0.3503039,
							51.5628755
						],
						[
							-0.3501993,
							51.5627537
						],
						[
							-0.349414,
							51.5618456
						],
						[
							-0.3489296,
							51.5612608
						],
						[
							-0.3486735,
							51.5609736
						],
						[
							-0.3479967,
							51.5602388
						],
						[
							-0.3476554,
							51.5599424
						],
						[
							-0.3473736,
							51.5596977
						],
						[
							-0.3466572,
							51.5591796
						],
						[
							-0.3462386,
							51.558928
						],
						[
							-0.345867,
							51.5587046
						],
						[
							-0.345473,
							51.558496
						],
						[
							-0.3448778,
							51.5582193
						],
						[
							-0.3442309,
							51.5579594
						],
						[
							-0.3439801,
							51.5578757
						],
						[
							-0.3434521,
							51.5577367
						],
						[
							-0.343174,
							51.5576716
						],
						[
							-0.3424651,
							51.5575776
						],
						[
							-0.3420028,
							51.5575411
						],
						[
							-0.340405,
							51.5574612
						],
						[
							-0.339519,
							51.5574158
						],
						[
							-0.3389777,
							51.5573801
						],
						[
							-0.3383796,
							51.5573175
						],
						[
							-0.3379826,
							51.5572668
						],
						[
							-0.336701307267463,
							51.55699231263346
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.336662619936615,
							51.55698402476661
						],
						[
							-0.3342179,
							51.5564603
						],
						[
							-0.3322571,
							51.5560591
						],
						[
							-0.3317652,
							51.5559805
						],
						[
							-0.3312578,
							51.5559237
						],
						[
							-0.3304376,
							51.55586
						],
						[
							-0.3297955,
							51.5557919
						],
						[
							-0.3292548,
							51.5557188
						],
						[
							-0.3287568,
							51.5556361
						],
						[
							-0.3283092,
							51.5555437
						],
						[
							-0.3278032,
							51.5554118
						],
						[
							-0.3272398,
							51.555221
						],
						[
							-0.3267869,
							51.5550499
						],
						[
							-0.3263113,
							51.5548663
						],
						[
							-0.3256884,
							51.5546195
						],
						[
							-0.3250158,
							51.554365
						],
						[
							-0.3244064,
							51.5541509
						],
						[
							-0.3238141,
							51.5539321
						],
						[
							-0.3228683,
							51.5535858
						],
						[
							-0.3219068,
							51.5532286
						],
						[
							-0.3214795,
							51.5530638
						],
						[
							-0.3204969,
							51.5526959
						],
						[
							-0.3197168,
							51.5524027
						],
						[
							-0.3189348,
							51.5521025
						],
						[
							-0.3181537,
							51.5518067
						],
						[
							-0.3175249,
							51.5515673
						],
						[
							-0.3165624,
							51.5511769
						],
						[
							-0.315821659015107,
							51.550855772336234
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.315785504007082,
							51.55083970771463
						],
						[
							-0.3147917,
							51.5503873
						],
						[
							-0.3143593,
							51.5501849
						],
						[
							-0.313666,
							51.5498615
						],
						[
							-0.3128809,
							51.5494999
						],
						[
							-0.3120998,
							51.5491377
						],
						[
							-0.3115153,
							51.5488663
						],
						[
							-0.3107483,
							51.548511
						],
						[
							-0.3100318,
							51.5481782
						],
						[
							-0.3092316,
							51.547816
						],
						[
							-0.3087549,
							51.5475878
						],
						[
							-0.3080857,
							51.5472243
						],
						[
							-0.30774,
							51.5470219
						],
						[
							-0.3074145,
							51.5468119
						],
						[
							-0.3071615,
							51.5466296
						],
						[
							-0.3068501,
							51.546392
						],
						[
							-0.3065911,
							51.5461789
						],
						[
							-0.3062495,
							51.5458568
						],
						[
							-0.3058967,
							51.5454839
						],
						[
							-0.3057456,
							51.5453134
						],
						[
							-0.3054301,
							51.5449091
						],
						[
							-0.3052437,
							51.5446321
						],
						[
							-0.3050028,
							51.5442504
						],
						[
							-0.3049494,
							51.5441376
						],
						[
							-0.3048527,
							51.543954
						],
						[
							-0.3045886,
							51.5434895
						],
						[
							-0.3043729,
							51.5431485
						],
						[
							-0.3041976,
							51.5429279
						],
						[
							-0.3040152,
							51.5427317
						],
						[
							-0.303861,
							51.5425869
						],
						[
							-0.3035909,
							51.5423469
						],
						[
							-0.3034155,
							51.5422084
						],
						[
							-0.3029227,
							51.5419125
						],
						[
							-0.3027665,
							51.5418285
						],
						[
							-0.3024017,
							51.5416587
						],
						[
							-0.3022223,
							51.5415891
						],
						[
							-0.3020499,
							51.5415233
						],
						[
							-0.30154,
							51.5413315
						],
						[
							-0.3008637,
							51.5410795
						],
						[
							-0.2999366,
							51.5407373
						],
						[
							-0.299473383514202,
							51.54056183920993
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.299436004635201,
							51.540548176570354
						],
						[
							-0.2993359,
							51.5405129
						],
						[
							-0.2989398,
							51.540375
						],
						[
							-0.2986939,
							51.5402841
						],
						[
							-0.2983412,
							51.540143
						],
						[
							-0.2979381,
							51.5399625
						],
						[
							-0.2976387,
							51.5398014
						],
						[
							-0.2972931,
							51.5395745
						],
						[
							-0.297095,
							51.5393856
						],
						[
							-0.2968327,
							51.5391654
						],
						[
							-0.2965012,
							51.5388301
						],
						[
							-0.2957865,
							51.5379973
						],
						[
							-0.2956211,
							51.5378098
						],
						[
							-0.2953033,
							51.5374408
						],
						[
							-0.2951108,
							51.5372148
						],
						[
							-0.2949022,
							51.5369807
						],
						[
							-0.2941334,
							51.5361156
						],
						[
							-0.2937148,
							51.5356161
						],
						[
							-0.293462,
							51.5353143
						],
						[
							-0.2932895,
							51.5351152
						],
						[
							-0.2931359,
							51.5349369
						],
						[
							-0.2928276,
							51.5345868
						],
						[
							-0.2925763,
							51.5343127
						],
						[
							-0.2917426,
							51.5334067
						],
						[
							-0.2914072,
							51.5330844
						],
						[
							-0.2910714,
							51.5327692
						],
						[
							-0.2907354,
							51.5325082
						],
						[
							-0.290009,
							51.5319488
						],
						[
							-0.2894929,
							51.5315624
						],
						[
							-0.2893454,
							51.5314567
						],
						[
							-0.2887859,
							51.5310721
						],
						[
							-0.2873326,
							51.5301669
						],
						[
							-0.286594,
							51.5296914
						],
						[
							-0.2860069,
							51.529246
						],
						[
							-0.2856894,
							51.5289646
						],
						[
							-0.2853347,
							51.5286221
						],
						[
							-0.2851425,
							51.5284188
						],
						[
							-0.2850125,
							51.5282669
						],
						[
							-0.2848788,
							51.5281097
						],
						[
							-0.2846445,
							51.5277966
						],
						[
							-0.2844389,
							51.5274936
						],
						[
							-0.2840826,
							51.5268176
						],
						[
							-0.283963146973305,
							51.52654097193818
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.283949175825759,
							51.52650376997391
						],
						[
							-0.2838575,
							51.5262189
						],
						[
							-0.2837603,
							51.5258735
						],
						[
							-0.2837067,
							51.5255304
						],
						[
							-0.2836385,
							51.5250751
						],
						[
							-0.2835925,
							51.5246225
						],
						[
							-0.2835571,
							51.5236729
						],
						[
							-0.28359,
							51.5231475
						],
						[
							-0.2836442,
							51.5226232
						],
						[
							-0.2837207,
							51.5222666
						],
						[
							-0.2838419,
							51.5219317
						],
						[
							-0.283994,
							51.5215655
						],
						[
							-0.2841956,
							51.5212172
						],
						[
							-0.2843702,
							51.5209513
						],
						[
							-0.2845849,
							51.5206905
						],
						[
							-0.2848504,
							51.5203857
						],
						[
							-0.2850107,
							51.5202106
						],
						[
							-0.285638,
							51.5196804
						],
						[
							-0.2861574,
							51.5193377
						],
						[
							-0.2867504,
							51.5189859
						],
						[
							-0.2877963,
							51.5183103
						],
						[
							-0.2880614,
							51.5181049
						],
						[
							-0.2883023,
							51.5179086
						],
						[
							-0.288521029288434,
							51.517707965333365
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.288549414747412,
							51.517680478702474
						],
						[
							-0.2887429,
							51.5174827
						],
						[
							-0.2890156,
							51.5171772
						],
						[
							-0.2892427,
							51.5168886
						],
						[
							-0.2893337,
							51.5167562
						],
						[
							-0.2893901,
							51.5166447
						],
						[
							-0.2894348,
							51.5165335
						],
						[
							-0.2894673,
							51.5163649
						],
						[
							-0.2894587,
							51.5161402
						],
						[
							-0.289395,
							51.5159262
						],
						[
							-0.2893243,
							51.5157778
						],
						[
							-0.2892012,
							51.5156283
						],
						[
							-0.2890652,
							51.5155009
						],
						[
							-0.2888741,
							51.5153215
						],
						[
							-0.28861288683959,
							51.515112569305934
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.28861288683959,
							51.515112569305934
						],
						[
							-0.2886118,
							51.5151117
						],
						[
							-0.2881572,
							51.5146794
						],
						[
							-0.2880626,
							51.5145858
						],
						[
							-0.2879285,
							51.5144209
						],
						[
							-0.2877954,
							51.5142016
						],
						[
							-0.2876908,
							51.5139351
						],
						[
							-0.2876642,
							51.51376
						],
						[
							-0.2876453,
							51.5135388
						],
						[
							-0.2876574,
							51.5132555
						],
						[
							-0.2878684,
							51.5125048
						],
						[
							-0.287987,
							51.5119386
						],
						[
							-0.288068,
							51.5110173
						],
						[
							-0.2881893,
							51.5105034
						],
						[
							-0.2881798,
							51.5102267
						],
						[
							-0.28818111256059,
							51.50992668615095
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.288177228862952,
							51.50988732896631
						],
						[
							-0.2880755,
							51.5093911
						],
						[
							-0.2879358,
							51.5089512
						],
						[
							-0.2878625,
							51.5087203
						],
						[
							-0.2877945,
							51.5085228
						],
						[
							-0.2877135,
							51.5082644
						],
						[
							-0.2876118,
							51.5080266
						],
						[
							-0.2874563,
							51.5077279
						],
						[
							-0.2873574,
							51.5075656
						],
						[
							-0.2872494,
							51.5074161
						],
						[
							-0.2871328,
							51.5072568
						],
						[
							-0.2870097,
							51.5070982
						],
						[
							-0.2868636,
							51.5069329
						],
						[
							-0.2866978,
							51.506766
						],
						[
							-0.2864206,
							51.5065234
						],
						[
							-0.2861449,
							51.5062931
						],
						[
							-0.2858652,
							51.5061041
						],
						[
							-0.2856112,
							51.5059453
						],
						[
							-0.2850843,
							51.505657
						],
						[
							-0.2847809,
							51.5055002
						],
						[
							-0.2844605,
							51.5053428
						],
						[
							-0.283818,
							51.5050051
						],
						[
							-0.2836338,
							51.504908
						],
						[
							-0.2834669,
							51.5048093
						],
						[
							-0.2831245,
							51.5045993
						],
						[
							-0.2824737,
							51.5042107
						],
						[
							-0.2821417,
							51.504015
						],
						[
							-0.2817902,
							51.5038213
						],
						[
							-0.2815264,
							51.5036824
						],
						[
							-0.2812484,
							51.5035386
						],
						[
							-0.280671858810906,
							51.50317376615741
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.478392812086747,
							51.54644142683732
						],
						[
							-0.4772954,
							51.5468704
						],
						[
							-0.476757,
							51.5470562
						],
						[
							-0.4762277,
							51.5472222
						],
						[
							-0.4753784,
							51.5474929
						],
						[
							-0.474746,
							51.5476853
						],
						[
							-0.4742031,
							51.5478173
						],
						[
							-0.4735676,
							51.5479314
						],
						[
							-0.4731142,
							51.5479965
						],
						[
							-0.473093,
							51.5479987
						],
						[
							-0.4726122,
							51.5480493
						],
						[
							-0.4718721,
							51.5480672
						],
						[
							-0.470947,
							51.5480644
						],
						[
							-0.4700021,
							51.5480719
						],
						[
							-0.4694774,
							51.5480974
						],
						[
							-0.4689708,
							51.5481502
						],
						[
							-0.4684931,
							51.548221
						],
						[
							-0.4683883,
							51.54824
						],
						[
							-0.4676878,
							51.5483671
						],
						[
							-0.4670144,
							51.5485114
						],
						[
							-0.466332,
							51.5486736
						],
						[
							-0.4657147,
							51.5488698
						],
						[
							-0.4654326,
							51.5489471
						],
						[
							-0.4649685,
							51.5490877
						],
						[
							-0.4644362,
							51.5492565
						],
						[
							-0.463546,
							51.5495337
						],
						[
							-0.4627649,
							51.5497714
						],
						[
							-0.4617715,
							51.5500854
						],
						[
							-0.4610709,
							51.5503052
						],
						[
							-0.4607903,
							51.5503938
						],
						[
							-0.4601761,
							51.5505853
						],
						[
							-0.4597181,
							51.5507352
						],
						[
							-0.4595998,
							51.5507691
						],
						[
							-0.4593101,
							51.5508644
						],
						[
							-0.4586125,
							51.5510879
						],
						[
							-0.4577253,
							51.5513576
						],
						[
							-0.4571247,
							51.5515528
						],
						[
							-0.4564362,
							51.5517612
						],
						[
							-0.4558447,
							51.5519451
						],
						[
							-0.4550773,
							51.5521894
						],
						[
							-0.4543175,
							51.5524336
						],
						[
							-0.4533893,
							51.5527203
						],
						[
							-0.4524278,
							51.5530239
						],
						[
							-0.4517014,
							51.5532521
						],
						[
							-0.4509628,
							51.5534794
						],
						[
							-0.450217429637587,
							51.55371937337283
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.450179371256143,
							51.55373163226993
						],
						[
							-0.4493643,
							51.5539943
						],
						[
							-0.448606,
							51.55428
						],
						[
							-0.4481328,
							51.5544912
						],
						[
							-0.4477021,
							51.5546789
						],
						[
							-0.4473336,
							51.5548722
						],
						[
							-0.4469286,
							51.5550957
						],
						[
							-0.4466829,
							51.5552569
						],
						[
							-0.4463523,
							51.5554747
						],
						[
							-0.4459535,
							51.5557614
						],
						[
							-0.4456532,
							51.556
						],
						[
							-0.4453317,
							51.5562923
						],
						[
							-0.4450602,
							51.5565459
						],
						[
							-0.4449009,
							51.5566949
						],
						[
							-0.4446825,
							51.5569372
						],
						[
							-0.4445324,
							51.5571418
						],
						[
							-0.4442837,
							51.5574992
						],
						[
							-0.4440577,
							51.5578651
						],
						[
							-0.4437059,
							51.5584911
						],
						[
							-0.4435299,
							51.5589249
						],
						[
							-0.4434526,
							51.5592011
						],
						[
							-0.4434025,
							51.5594595
						],
						[
							-0.4432933,
							51.5598206
						],
						[
							-0.443169,
							51.5601874
						],
						[
							-0.4430719,
							51.5604485
						],
						[
							-0.4429218,
							51.5607634
						],
						[
							-0.4427546,
							51.5609799
						],
						[
							-0.4426305,
							51.5611969
						],
						[
							-0.4424569,
							51.5614509
						],
						[
							-0.4422944,
							51.5616776
						],
						[
							-0.44206858763742,
							51.561956951592485
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.4420444053484,
							51.56198861144818
						],
						[
							-0.4418223,
							51.5622965
						],
						[
							-0.4415265,
							51.5625831
						],
						[
							-0.4413278,
							51.5627651
						],
						[
							-0.4410351,
							51.5630196
						],
						[
							-0.4407819,
							51.5632167
						],
						[
							-0.4404952,
							51.5634316
						],
						[
							-0.4400615,
							51.5637248
						],
						[
							-0.4396714,
							51.5639545
						],
						[
							-0.4392093,
							51.5642119
						],
						[
							-0.4385923,
							51.5645077
						],
						[
							-0.4379067,
							51.5647992
						],
						[
							-0.4372074,
							51.5650677
						],
						[
							-0.4364834,
							51.5653583
						],
						[
							-0.4359075,
							51.5655944
						],
						[
							-0.4352521,
							51.5658689
						],
						[
							-0.4345432,
							51.5661672
						],
						[
							-0.4342344,
							51.566297
						],
						[
							-0.4334422,
							51.56663
						],
						[
							-0.4326414,
							51.5669914
						],
						[
							-0.4317378,
							51.5673886
						],
						[
							-0.430981,
							51.5676997
						],
						[
							-0.4301596,
							51.5680474
						],
						[
							-0.429363,
							51.5684028
						],
						[
							-0.4286102,
							51.5687284
						],
						[
							-0.427985,
							51.5690079
						],
						[
							-0.4274036,
							51.5692602
						],
						[
							-0.4267386,
							51.5695474
						],
						[
							-0.4259639,
							51.5698798
						],
						[
							-0.4254497,
							51.570092
						],
						[
							-0.4250562,
							51.5702599
						],
						[
							-0.4243761,
							51.5705104
						],
						[
							-0.4238647,
							51.5706971
						],
						[
							-0.4233985,
							51.5708684
						],
						[
							-0.422994,
							51.5710116
						],
						[
							-0.4226101,
							51.5711377
						],
						[
							-0.421978,
							51.5712954
						],
						[
							-0.421647379633623,
							51.57136847957721
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.421608732624377,
							51.57137702202463
						],
						[
							-0.420918,
							51.5715297
						],
						[
							-0.4202887,
							51.571672
						],
						[
							-0.419865,
							51.5717539
						],
						[
							-0.4194235,
							51.571834
						],
						[
							-0.4188311,
							51.5719226
						],
						[
							-0.4177987,
							51.5721024
						],
						[
							-0.4164686,
							51.5723947
						],
						[
							-0.4150605,
							51.5727092
						],
						[
							-0.413895,
							51.5729691
						],
						[
							-0.4131587,
							51.5731361
						],
						[
							-0.412879,
							51.5732001
						],
						[
							-0.412407430780487,
							51.573303174714304
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.41236875515192,
							51.57331153646644
						],
						[
							-0.4109374,
							51.5736176
						],
						[
							-0.4092564,
							51.5740003
						],
						[
							-0.4076507,
							51.5743633
						],
						[
							-0.4063221,
							51.5746777
						],
						[
							-0.4050455,
							51.5749504
						],
						[
							-0.4034399,
							51.5753117
						],
						[
							-0.4023211,
							51.5755555
						],
						[
							-0.4008635,
							51.5758793
						],
						[
							-0.3999407,
							51.5760812
						],
						[
							-0.3993333,
							51.5762108
						],
						[
							-0.3986066,
							51.5763471
						],
						[
							-0.3978346,
							51.5764809
						],
						[
							-0.397094484442436,
							51.57660454489765
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.397055498718836,
							51.57661094878681
						],
						[
							-0.3965046,
							51.5766999
						],
						[
							-0.3958163,
							51.5767945
						],
						[
							-0.3952006,
							51.5768686
						],
						[
							-0.3943683,
							51.5769589
						],
						[
							-0.3936718,
							51.5770254
						],
						[
							-0.3930671,
							51.5770757
						],
						[
							-0.3924652,
							51.5771157
						],
						[
							-0.3919866,
							51.5771413
						],
						[
							-0.3912544,
							51.5771873
						],
						[
							-0.3907361,
							51.5772043
						],
						[
							-0.3903029,
							51.5772231
						],
						[
							-0.3894802,
							51.5772393
						],
						[
							-0.3889934,
							51.5772469
						],
						[
							-0.3881241,
							51.5772418
						],
						[
							-0.3874221,
							51.5772325
						],
						[
							-0.3868462,
							51.5772163
						],
						[
							-0.3863018,
							51.5772035
						],
						[
							-0.3856834,
							51.5771694
						],
						[
							-0.3851583,
							51.5771404
						],
						[
							-0.3846468,
							51.5771063
						],
						[
							-0.3838198,
							51.5770419
						],
						[
							-0.3833981,
							51.5770036
						],
						[
							-0.3825295,
							51.576916
						],
						[
							-0.3818024,
							51.5768268
						],
						[
							-0.3810752,
							51.576731
						],
						[
							-0.3803955,
							51.5766358
						],
						[
							-0.3795482,
							51.5765249
						],
						[
							-0.3787143,
							51.5764074
						],
						[
							-0.3780653,
							51.5763074
						],
						[
							-0.3774594,
							51.5762107
						],
						[
							-0.3767661,
							51.5760971
						],
						[
							-0.3761181,
							51.5760063
						],
						[
							-0.3756477,
							51.5759383
						],
						[
							-0.3750284,
							51.5758425
						],
						[
							-0.3744412,
							51.5757462
						],
						[
							-0.3738487,
							51.5756504
						],
						[
							-0.3733929,
							51.5755671
						],
						[
							-0.3729895,
							51.5754884
						],
						[
							-0.3728404,
							51.5754593
						],
						[
							-0.3719208,
							51.5753004
						],
						[
							-0.37130085917451,
							51.57519161463775
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.371261972812131,
							51.57518458340654
						],
						[
							-0.3708565,
							51.5751091
						],
						[
							-0.3702738,
							51.5750268
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.425484161377068,
							51.46637035135414
						],
						[
							-0.426107232985072,
							51.46620532876219
						],
						[
							-0.426513852667121,
							51.466129851419566
						],
						[
							-0.4296335,
							51.4657586
						],
						[
							-0.4314317,
							51.4655267
						],
						[
							-0.4325368,
							51.4653733
						],
						[
							-0.4337384,
							51.4651687
						],
						[
							-0.434822,
							51.4649341
						],
						[
							-0.4359485,
							51.4646531
						],
						[
							-0.437,
							51.4643205
						],
						[
							-0.4378451,
							51.46399
						],
						[
							-0.438668,
							51.4636104
						],
						[
							-0.4393439,
							51.4631896
						],
						[
							-0.4400504,
							51.4626936
						],
						[
							-0.440898,
							51.4620302
						],
						[
							-0.4446815,
							51.4591867
						],
						[
							-0.4448802,
							51.4590448
						],
						[
							-0.445418233084965,
							51.458670389530695
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.445450976728351,
							51.458647629729015
						],
						[
							-0.4464529,
							51.457952
						],
						[
							-0.4478361,
							51.4571001
						],
						[
							-0.4495205,
							51.4564306
						],
						[
							-0.4519307,
							51.4560258
						],
						[
							-0.4552358,
							51.4559987
						],
						[
							-0.4565162,
							51.4561826
						],
						[
							-0.4582538,
							51.456799
						],
						[
							-0.4600616,
							51.4577711
						],
						[
							-0.4689628,
							51.463385
						],
						[
							-0.4700397,
							51.4640591
						],
						[
							-0.4709554,
							51.4647897
						],
						[
							-0.4716458,
							51.4655944
						],
						[
							-0.4719854,
							51.4662615
						],
						[
							-0.4721474,
							51.4671487
						],
						[
							-0.4721061,
							51.4680617
						],
						[
							-0.4718765,
							51.4688554
						],
						[
							-0.4715054,
							51.4695788
						],
						[
							-0.4712097,
							51.4701553
						],
						[
							-0.4707044,
							51.4708834
						],
						[
							-0.4702876,
							51.4714104
						],
						[
							-0.4699131,
							51.4717726
						],
						[
							-0.4694938,
							51.4721031
						],
						[
							-0.4689177,
							51.4724383
						],
						[
							-0.4680674,
							51.4727413
						],
						[
							-0.4671898,
							51.4729722
						],
						[
							-0.4657425,
							51.4731957
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.465742499999987,
							51.473195700000026
						],
						[
							-0.463900252793783,
							51.47330804691378
						],
						[
							-0.462663896751105,
							51.47332807577672
						],
						[
							-0.460462956660524,
							51.473247541886835
						],
						[
							-0.458543461885913,
							51.473070178661736
						],
						[
							-0.4570843,
							51.4728994
						],
						[
							-0.4558044,
							51.4727079
						],
						[
							-0.455020529758967,
							51.47250966311051
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.454982759863699,
							51.4724976756562
						],
						[
							-0.4544509,
							51.4722933
						],
						[
							-0.4535552,
							51.4718857
						],
						[
							-0.4526694,
							51.4714894
						],
						[
							-0.4521956,
							51.47128
						],
						[
							-0.4517386,
							51.4710813
						],
						[
							-0.4512444,
							51.4708664
						],
						[
							-0.4470409,
							51.4690384
						],
						[
							-0.444584,
							51.4680749
						],
						[
							-0.4434575,
							51.467694
						],
						[
							-0.441911,
							51.4672392
						],
						[
							-0.4411783,
							51.4670473
						],
						[
							-0.440085,
							51.4668385
						],
						[
							-0.4389145,
							51.4667112
						],
						[
							-0.4368106,
							51.4666239
						],
						[
							-0.4341176,
							51.4664879
						],
						[
							-0.4308786,
							51.4663574
						],
						[
							-0.4289856,
							51.4662923
						],
						[
							-0.4276797,
							51.4662352
						],
						[
							-0.4268993,
							51.4662227
						],
						[
							-0.4262415,
							51.4662512
						],
						[
							-0.4258351,
							51.4662982
						],
						[
							-0.425484161377073,
							51.46637035135414
						],
						[
							-0.425206384131235,
							51.46644075406473
						],
						[
							-0.424845836864028,
							51.466518506684125
						],
						[
							-0.4242154,
							51.4665998
						],
						[
							-0.4238784,
							51.4666401
						],
						[
							-0.4234715,
							51.4666794
						],
						[
							-0.423136139005258,
							51.46671832076897
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.423096916473641,
							51.466723229511146
						],
						[
							-0.4228536,
							51.4667559
						],
						[
							-0.4225506,
							51.4668055
						],
						[
							-0.4223287,
							51.4668533
						],
						[
							-0.4220959,
							51.4669126
						],
						[
							-0.4218768,
							51.4669721
						],
						[
							-0.421684,
							51.4670297
						],
						[
							-0.4141132,
							51.4693731
						],
						[
							-0.4113988,
							51.470297
						],
						[
							-0.4112282,
							51.4703563
						],
						[
							-0.4066826,
							51.4719052
						],
						[
							-0.4031542,
							51.4730442
						],
						[
							-0.3996942,
							51.4741612
						],
						[
							-0.3983077,
							51.4745918
						],
						[
							-0.3976594,
							51.4747831
						],
						[
							-0.3970659,
							51.4749289
						],
						[
							-0.396455,
							51.4750466
						],
						[
							-0.3959498,
							51.4751065
						],
						[
							-0.395465,
							51.47515
						],
						[
							-0.3949386,
							51.4751596
						],
						[
							-0.3943592,
							51.475136
						],
						[
							-0.3935603,
							51.4750663
						],
						[
							-0.3929404,
							51.4749677
						],
						[
							-0.3923612,
							51.4748512
						],
						[
							-0.3916096,
							51.4746609
						],
						[
							-0.3879471,
							51.4736534
						],
						[
							-0.3875841,
							51.4735767
						],
						[
							-0.3872923,
							51.473529
						],
						[
							-0.3869426,
							51.4735092
						],
						[
							-0.3867467,
							51.4735102
						],
						[
							-0.386466940570581,
							51.47352439340229
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.386427391276406,
							51.473527239046874
						],
						[
							-0.3862049,
							51.473548
						],
						[
							-0.3860336,
							51.4735714
						],
						[
							-0.3856248,
							51.4736308
						],
						[
							-0.3845659,
							51.4737888
						],
						[
							-0.383904,
							51.4739052
						],
						[
							-0.3832016,
							51.473995
						],
						[
							-0.3825922,
							51.4740377
						],
						[
							-0.3820884,
							51.4740416
						],
						[
							-0.3817211,
							51.474035
						],
						[
							-0.3813825,
							51.474015
						],
						[
							-0.3808683,
							51.4739631
						],
						[
							-0.3803107,
							51.4738991
						],
						[
							-0.3796684,
							51.4738193
						],
						[
							-0.3792864,
							51.4737522
						],
						[
							-0.3788337,
							51.4736533
						],
						[
							-0.3784321,
							51.4735465
						],
						[
							-0.3781292,
							51.4734533
						],
						[
							-0.3776374,
							51.4732798
						],
						[
							-0.3771952,
							51.4730985
						],
						[
							-0.376711,
							51.4728976
						],
						[
							-0.3761989,
							51.4726867
						],
						[
							-0.3756203,
							51.4724453
						],
						[
							-0.3748801,
							51.4721245
						],
						[
							-0.37451,
							51.4719999
						],
						[
							-0.3739034,
							51.4717811
						],
						[
							-0.3732926,
							51.4716133
						],
						[
							-0.3728672,
							51.4715153
						],
						[
							-0.3722837,
							51.4713941
						],
						[
							-0.3717093,
							51.4713083
						],
						[
							-0.3710461,
							51.4712281
						],
						[
							-0.3704892,
							51.471171
						],
						[
							-0.3700491,
							51.4711553
						],
						[
							-0.369446,
							51.4711562
						],
						[
							-0.3690752,
							51.4711666
						],
						[
							-0.3683189,
							51.4711867
						],
						[
							-0.3679495,
							51.4712028
						],
						[
							-0.3674101,
							51.4712263
						],
						[
							-0.3672918,
							51.4712329
						],
						[
							-0.3669539,
							51.4712542
						],
						[
							-0.3667566,
							51.4712734
						],
						[
							-0.366432802926253,
							51.47130422435025
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.366393232482373,
							51.471306936460856
						],
						[
							-0.3657813,
							51.4713327
						],
						[
							-0.3653342,
							51.4713349
						],
						[
							-0.3650103,
							51.4713318
						],
						[
							-0.364466,
							51.4713283
						],
						[
							-0.3641096,
							51.4713312
						],
						[
							-0.3639063,
							51.4713479
						],
						[
							-0.3637992,
							51.471351
						],
						[
							-0.3634634,
							51.4713688
						],
						[
							-0.3628554,
							51.4714373
						],
						[
							-0.3622929,
							51.4715148
						],
						[
							-0.3617115,
							51.4716595
						],
						[
							-0.36093,
							51.4718513
						],
						[
							-0.360427,
							51.4719907
						],
						[
							-0.3597071,
							51.4721903
						],
						[
							-0.3589934,
							51.4723934
						],
						[
							-0.3582427,
							51.4726065
						],
						[
							-0.3577628,
							51.4727451
						],
						[
							-0.3573059,
							51.4728859
						],
						[
							-0.3570044,
							51.4729783
						],
						[
							-0.3568651,
							51.4730223
						],
						[
							-0.3565251,
							51.4731426
						],
						[
							-0.3563314799708,
							51.47322096131371
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.356294900690757,
							51.4732361932546
						],
						[
							-0.3559906,
							51.4733665
						],
						[
							-0.3557037,
							51.4735217
						],
						[
							-0.3554015,
							51.4737012
						],
						[
							-0.3549845,
							51.4739867
						],
						[
							-0.3546725,
							51.4742555
						],
						[
							-0.3543569,
							51.4745758
						],
						[
							-0.3540575,
							51.4749184
						],
						[
							-0.3538322,
							51.4752618
						],
						[
							-0.353665,
							51.4755393
						],
						[
							-0.353516,
							51.4758866
						],
						[
							-0.3534926,
							51.4759993
						],
						[
							-0.3534387,
							51.4762461
						],
						[
							-0.3533788,
							51.4767019
						],
						[
							-0.3534019,
							51.477809
						],
						[
							-0.3534598,
							51.4788611
						],
						[
							-0.3533349,
							51.4794941
						],
						[
							-0.3531269,
							51.4800499
						],
						[
							-0.3527522,
							51.4806411
						],
						[
							-0.352310061793626,
							51.481109725748695
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.352281493276379,
							51.48113702932132
						],
						[
							-0.3516832,
							51.4816525
						],
						[
							-0.3509424,
							51.4821081
						],
						[
							-0.3501528,
							51.4824797
						],
						[
							-0.3493186,
							51.4827714
						],
						[
							-0.3485471,
							51.4829721
						],
						[
							-0.3473949,
							51.4831932
						],
						[
							-0.3455806,
							51.4835025
						],
						[
							-0.3447471,
							51.4836835
						],
						[
							-0.3438433,
							51.4839314
						],
						[
							-0.3429267,
							51.4842715
						],
						[
							-0.3421968,
							51.4846741
						],
						[
							-0.3418143,
							51.4849291
						],
						[
							-0.3402272,
							51.4861713
						],
						[
							-0.3380977,
							51.4878545
						],
						[
							-0.3360359,
							51.4894788
						],
						[
							-0.3353373,
							51.4899354
						],
						[
							-0.3345778,
							51.4903551
						],
						[
							-0.3316699,
							51.4917915
						],
						[
							-0.3306767,
							51.4922862
						],
						[
							-0.3296346,
							51.4928042
						],
						[
							-0.3280102,
							51.4935996
						],
						[
							-0.3274924,
							51.4939378
						],
						[
							-0.3271343,
							51.4941947
						],
						[
							-0.324750072947252,
							51.49573751109132
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.324717079590947,
							51.49575978080381
						],
						[
							-0.3237025,
							51.4964735
						],
						[
							-0.3230843,
							51.4968436
						],
						[
							-0.3211018,
							51.4977028
						],
						[
							-0.3206059,
							51.4978953
						],
						[
							-0.319795,
							51.4981687
						],
						[
							-0.3186267,
							51.4985067
						],
						[
							-0.3169383,
							51.4988896
						],
						[
							-0.314618408451905,
							51.49934807850359
						],
						[
							-0.31439194245725,
							51.49938411659139
						],
						[
							-0.314105836858756,
							51.499438844069914
						],
						[
							-0.313181819293444,
							51.49960019418915
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.313143003888494,
							51.499607673427946
						],
						[
							-0.3128755,
							51.499664078673824
						],
						[
							-0.312034872628249,
							51.499846178475075
						],
						[
							-0.3114477,
							51.4999887506206
						],
						[
							-0.309733472628249,
							51.500348261200934
						],
						[
							-0.30860985754275,
							51.5006012776632
						],
						[
							-0.307870648055744,
							51.50079270526585
						],
						[
							-0.307120059785995,
							51.50102327671577
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.307082166931522,
							51.50103570896612
						],
						[
							-0.306847030170998,
							51.50111783531493
						],
						[
							-0.303625917884746,
							51.50235418977171
						],
						[
							-0.302631845256497,
							51.502715105180094
						],
						[
							-0.301725697200753,
							51.502951847005725
						],
						[
							-0.300765600757738,
							51.50312643176693
						],
						[
							-0.30020605832603,
							51.5032142305115
						],
						[
							-0.2933021,
							51.5041755
						],
						[
							-0.2922734,
							51.5043993
						],
						[
							-0.2913596,
							51.5046459
						],
						[
							-0.2901994,
							51.5050942
						],
						[
							-0.289345,
							51.5054412
						],
						[
							-0.2887202,
							51.5056269
						],
						[
							-0.2881292,
							51.5057316
						],
						[
							-0.2874817,
							51.5057776
						],
						[
							-0.2868981,
							51.5057753
						],
						[
							-0.286335,
							51.5057177
						],
						[
							-0.2857911,
							51.5056176
						],
						[
							-0.2851749,
							51.5054414
						],
						[
							-0.284559,
							51.5051843
						],
						[
							-0.2843516,
							51.50508
						],
						[
							-0.2841376,
							51.5049664
						],
						[
							-0.2837015,
							51.5047302
						],
						[
							-0.2832344,
							51.5044857
						],
						[
							-0.282503,
							51.5041004
						],
						[
							-0.280671858810906,
							51.50317376615741
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.490084481829978,
							51.4725488480293
						],
						[
							-0.4896313,
							51.4725751
						],
						[
							-0.4893809,
							51.4725866
						],
						[
							-0.487744,
							51.4725978
						],
						[
							-0.485389,
							51.4726064
						],
						[
							-0.4846449,
							51.4726101
						],
						[
							-0.4837357,
							51.4726662
						],
						[
							-0.4827135,
							51.4727045
						],
						[
							-0.4818722,
							51.4727002
						],
						[
							-0.4807629,
							51.4727009
						],
						[
							-0.4803198,
							51.4726899
						],
						[
							-0.4768898,
							51.4725459
						],
						[
							-0.4754843,
							51.4725626
						],
						[
							-0.4738428,
							51.4725826
						],
						[
							-0.472212,
							51.4726254
						],
						[
							-0.4657425,
							51.47319570000001
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.265651715521404,
							51.49449610418332
						],
						[
							-0.2661078,
							51.4944707
						],
						[
							-0.2663654,
							51.4944623
						],
						[
							-0.2665843,
							51.4944613
						],
						[
							-0.2667681,
							51.494464
						],
						[
							-0.2671513,
							51.4944763
						],
						[
							-0.2673507,
							51.4944906
						],
						[
							-0.2676086,
							51.4945157
						],
						[
							-0.26810188582407,
							51.49457696400272
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.195188837122854,
							51.49080340565126
						],
						[
							-0.1951902,
							51.4908025
						],
						[
							-0.195892,
							51.4903721
						],
						[
							-0.1963949,
							51.4901511
						],
						[
							-0.1968078,
							51.4900405
						],
						[
							-0.1972888,
							51.4899539
						],
						[
							-0.1978192,
							51.4899065
						],
						[
							-0.1983329,
							51.4898959
						],
						[
							-0.1988874,
							51.489922
						],
						[
							-0.1995307,
							51.4900253
						],
						[
							-0.200090313988162,
							51.49018516289642
						],
						[
							-0.200831327417429,
							51.490526591014834
						],
						[
							-0.200835347021121,
							51.49052861203458
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.200835347021121,
							51.49052861203458
						],
						[
							-0.2015561,
							51.490891
						],
						[
							-0.2018883,
							51.4910349
						],
						[
							-0.2021946,
							51.4911317
						],
						[
							-0.2025274,
							51.4912211
						],
						[
							-0.2028842,
							51.4912783
						],
						[
							-0.2032499,
							51.4912963
						],
						[
							-0.2035495,
							51.4912835
						],
						[
							-0.2038509,
							51.4912553
						],
						[
							-0.2041615,
							51.4912152
						],
						[
							-0.2044462,
							51.491153
						],
						[
							-0.205141,
							51.4909498
						],
						[
							-0.205960765659018,
							51.49070242499719
						]
					]
				}
			}
		]
	},
	{
		name: "District",
		lineColor: "#0e6729",
		features: [
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.18582568760043,
							51.494940382355814
						],
						[
							-0.186280163141244,
							51.495012929648965
						],
						[
							-0.186726450854992,
							51.49502909721007
						],
						[
							-0.187202605598494,
							51.49502041073965
						],
						[
							-0.187759402799247,
							51.494989789141755
						],
						[
							-0.188223669829002,
							51.49491558119778
						],
						[
							-0.188550960341997,
							51.494821689401526
						],
						[
							-0.18899752348324,
							51.49467511662514
						],
						[
							-0.189497210107724,
							51.49445298089089
						],
						[
							-0.18983216205198,
							51.494265746127084
						],
						[
							-0.1930872,
							51.4921905
						],
						[
							-0.194151482191761,
							51.49149259712507
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.194184662369276,
							51.49147069406985
						],
						[
							-0.195188837122854,
							51.49080340565126
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.205998941080947,
							51.49069090506463
						],
						[
							-0.2074438,
							51.4902549
						],
						[
							-0.2078464,
							51.4901629
						],
						[
							-0.2082606,
							51.4900866
						],
						[
							-0.2085221,
							51.4900525
						],
						[
							-0.2091062,
							51.4899868
						],
						[
							-0.2098047,
							51.4899461
						],
						[
							-0.2103489,
							51.4899393
						],
						[
							-0.2108993,
							51.4899592
						],
						[
							-0.2118351,
							51.4900404
						],
						[
							-0.2128789,
							51.4901581
						],
						[
							-0.213729163952768,
							51.49025760209264
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.213768619004947,
							51.49026104275777
						],
						[
							-0.2139257,
							51.4902701
						],
						[
							-0.214924,
							51.4902975
						],
						[
							-0.2159456,
							51.4902636
						],
						[
							-0.2162493,
							51.4902515
						],
						[
							-0.2165021,
							51.4902428
						],
						[
							-0.2168793,
							51.490229
						],
						[
							-0.2185298,
							51.4901441
						],
						[
							-0.2189483,
							51.4901259
						],
						[
							-0.2189585,
							51.4901262
						],
						[
							-0.2193616,
							51.4901339
						],
						[
							-0.2196329,
							51.4901583
						],
						[
							-0.219945,
							51.4901908
						],
						[
							-0.22016,
							51.4902203
						],
						[
							-0.2204008,
							51.4902698
						],
						[
							-0.2208901,
							51.4903946
						],
						[
							-0.2211269,
							51.4904645
						],
						[
							-0.2213124,
							51.4905354
						],
						[
							-0.2215474,
							51.4906423
						],
						[
							-0.2218926,
							51.4908297
						],
						[
							-0.2220668,
							51.4909422
						],
						[
							-0.2223151,
							51.4911396
						],
						[
							-0.2226305,
							51.4914381
						],
						[
							-0.223316770590784,
							51.492108693364266
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.223345022074079,
							51.49213631397084
						],
						[
							-0.2240276,
							51.492804
						],
						[
							-0.2242771,
							51.4929535
						],
						[
							-0.2245047,
							51.4930769
						],
						[
							-0.2246881,
							51.4931378
						],
						[
							-0.2254199,
							51.4933526
						],
						[
							-0.2259567,
							51.493499
						],
						[
							-0.2265229,
							51.4936093
						],
						[
							-0.2267587,
							51.4936452
						],
						[
							-0.2269015,
							51.4936582
						],
						[
							-0.2270346,
							51.4936639
						],
						[
							-0.2271687,
							51.4936619
						],
						[
							-0.2273599,
							51.4936504
						],
						[
							-0.2276267,
							51.49363
						],
						[
							-0.2282859,
							51.4936007
						],
						[
							-0.2288195,
							51.4935998
						],
						[
							-0.2295265,
							51.4936217
						],
						[
							-0.2297079,
							51.4936338
						],
						[
							-0.230126,
							51.4936636
						],
						[
							-0.2303899,
							51.4936874
						],
						[
							-0.2314464,
							51.4937513
						],
						[
							-0.2319306,
							51.4937752
						],
						[
							-0.232409,
							51.4938106
						],
						[
							-0.2328612,
							51.4938636
						],
						[
							-0.233072,
							51.4938892
						],
						[
							-0.2334854,
							51.4939399
						],
						[
							-0.2339106,
							51.4939824
						],
						[
							-0.2341364,
							51.4939993
						],
						[
							-0.2345997,
							51.4940341
						],
						[
							-0.2348132,
							51.4940496
						],
						[
							-0.235777713635565,
							51.49411535115813
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.235817247982396,
							51.49411829117653
						],
						[
							-0.2364106,
							51.4941661
						],
						[
							-0.2368651,
							51.4942196
						],
						[
							-0.2371197,
							51.4942547
						],
						[
							-0.2379999,
							51.4944046
						],
						[
							-0.2388779,
							51.4945448
						],
						[
							-0.2392382,
							51.4946058
						],
						[
							-0.2396067,
							51.4946596
						],
						[
							-0.2398008,
							51.4946851
						],
						[
							-0.2408432,
							51.494794
						],
						[
							-0.2417947,
							51.4948755
						],
						[
							-0.2423268,
							51.4949083
						],
						[
							-0.2428446,
							51.4949209
						],
						[
							-0.2433219,
							51.4949238
						],
						[
							-0.2437908,
							51.4949128
						],
						[
							-0.2442318,
							51.4948942
						],
						[
							-0.2446638,
							51.4948899
						],
						[
							-0.2448129,
							51.4948915
						],
						[
							-0.2449609,
							51.4948963
						],
						[
							-0.2453404,
							51.4949045
						],
						[
							-0.245814913657105,
							51.49493685102214
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.245854434881942,
							51.49493987388956
						],
						[
							-0.246693,
							51.495011
						],
						[
							-0.246928,
							51.4950253
						],
						[
							-0.2475219,
							51.4950467
						],
						[
							-0.2481929,
							51.4950795
						],
						[
							-0.2489134,
							51.4950964
						],
						[
							-0.2495748,
							51.4951083
						],
						[
							-0.2508836,
							51.4951462
						],
						[
							-0.2514875,
							51.4951479
						],
						[
							-0.2521085,
							51.4951469
						],
						[
							-0.2532777,
							51.4951452
						],
						[
							-0.2539147,
							51.4951486
						],
						[
							-0.254510126217157,
							51.49514366552859
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.254550010572405,
							51.49514293537636
						],
						[
							-0.255186,
							51.4951249
						],
						[
							-0.2554399,
							51.4951156
						],
						[
							-0.2558112,
							51.4951013
						],
						[
							-0.2570672,
							51.495065
						],
						[
							-0.2581752,
							51.4950497
						],
						[
							-0.2589677,
							51.4950587
						],
						[
							-0.2591277,
							51.4950616
						],
						[
							-0.2594342,
							51.4950655
						],
						[
							-0.259682,
							51.4950651
						],
						[
							-0.2601306,
							51.4950609
						],
						[
							-0.2603179,
							51.4950478
						],
						[
							-0.2609633,
							51.4949989
						],
						[
							-0.2613798,
							51.4949591
						],
						[
							-0.2627467,
							51.4948152
						],
						[
							-0.2638152,
							51.4946884
						],
						[
							-0.2652324,
							51.4945391
						],
						[
							-0.2656482,
							51.4944963
						],
						[
							-0.265651715521404,
							51.49449610418332
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.268140938009075,
							51.49458294827107
						],
						[
							-0.2686107,
							51.4946687
						],
						[
							-0.2694157,
							51.4947987
						],
						[
							-0.2704093,
							51.4951295
						],
						[
							-0.2710732,
							51.4954353
						],
						[
							-0.2716862,
							51.495799
						],
						[
							-0.2721974,
							51.4962403
						],
						[
							-0.2726914,
							51.4966705
						],
						[
							-0.273218,
							51.4970345
						],
						[
							-0.2737031,
							51.4973187
						],
						[
							-0.2740089,
							51.4974666
						],
						[
							-0.2744508,
							51.4976694
						],
						[
							-0.2751001,
							51.4979332
						],
						[
							-0.2758177,
							51.4982336
						],
						[
							-0.2763412,
							51.498465
						],
						[
							-0.2768515,
							51.4987259
						],
						[
							-0.2771555,
							51.498911
						],
						[
							-0.2773992,
							51.499081
						],
						[
							-0.2776654,
							51.4992817
						],
						[
							-0.2778959,
							51.4994896
						],
						[
							-0.2780104,
							51.4996031
						],
						[
							-0.2781226,
							51.4997258
						],
						[
							-0.2783114,
							51.4999789
						],
						[
							-0.2783996,
							51.5001064
						],
						[
							-0.2785218,
							51.5002998
						],
						[
							-0.2786322,
							51.5005011
						],
						[
							-0.2787294,
							51.5007201
						],
						[
							-0.2788323,
							51.5010959
						],
						[
							-0.2790101,
							51.5014276
						],
						[
							-0.2792693,
							51.5018755
						],
						[
							-0.2794301,
							51.5020873
						],
						[
							-0.2795691,
							51.5022661
						],
						[
							-0.2797643,
							51.5024777
						],
						[
							-0.279903324400723,
							51.50262903955433
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.279932073985372,
							51.50265616205955
						],
						[
							-0.2801097,
							51.5028013
						],
						[
							-0.2804136,
							51.5030168
						],
						[
							-0.2806575,
							51.5031665
						],
						[
							-0.280662120097887,
							51.50316883796229
						],
						[
							-0.280671858810906,
							51.50317376615741
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.17257892682836,
							51.49402315763969
						],
						[
							-0.172880240952617,
							51.494036241746215
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.172919981948695,
							51.49403787703262
						],
						[
							-0.17421781897401,
							51.49408833090708
						],
						[
							-0.174655205598494,
							51.49408461162325
						],
						[
							-0.174917230170998,
							51.4940709923595
						],
						[
							-0.175129445256497,
							51.494061605816746
						],
						[
							-0.175420060341997,
							51.49404260582991
						],
						[
							-0.176014487713748,
							51.49399305965017
						],
						[
							-0.176594,
							51.493937
						],
						[
							-0.1792016,
							51.493723432842216
						],
						[
							-0.17970254245725,
							51.493739185103514
						],
						[
							-0.1802151,
							51.49379035817749
						],
						[
							-0.182791312286239,
							51.494305480526364
						],
						[
							-0.183452400063918,
							51.49443458575443
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.183491161152979,
							51.494442407930244
						],
						[
							-0.184239872628249,
							51.49459838746867
						],
						[
							-0.184845278226743,
							51.49472670620243
						],
						[
							-0.185816587713748,
							51.494938929753665
						],
						[
							-0.18582568760043,
							51.494940382355814
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.28861288683959,
							51.515112569305934
						],
						[
							-0.2886118,
							51.5151117
						],
						[
							-0.2881572,
							51.5146794
						],
						[
							-0.2880626,
							51.5145858
						],
						[
							-0.2879285,
							51.5144209
						],
						[
							-0.2877954,
							51.5142016
						],
						[
							-0.2876908,
							51.5139351
						],
						[
							-0.2876642,
							51.51376
						],
						[
							-0.2876453,
							51.5135388
						],
						[
							-0.2876574,
							51.5132555
						],
						[
							-0.2878684,
							51.5125048
						],
						[
							-0.287987,
							51.5119386
						],
						[
							-0.288068,
							51.5110173
						],
						[
							-0.2881893,
							51.5105034
						],
						[
							-0.2881798,
							51.5102267
						],
						[
							-0.28818111256059,
							51.50992668615095
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.288177228862952,
							51.50988732896631
						],
						[
							-0.2880755,
							51.5093911
						],
						[
							-0.2879358,
							51.5089512
						],
						[
							-0.2878625,
							51.5087203
						],
						[
							-0.2877945,
							51.5085228
						],
						[
							-0.2877135,
							51.5082644
						],
						[
							-0.2876118,
							51.5080266
						],
						[
							-0.2874563,
							51.5077279
						],
						[
							-0.2873574,
							51.5075656
						],
						[
							-0.2872494,
							51.5074161
						],
						[
							-0.2871328,
							51.5072568
						],
						[
							-0.2870097,
							51.5070982
						],
						[
							-0.2868636,
							51.5069329
						],
						[
							-0.2866978,
							51.506766
						],
						[
							-0.2864206,
							51.5065234
						],
						[
							-0.2861449,
							51.5062931
						],
						[
							-0.2858652,
							51.5061041
						],
						[
							-0.2856112,
							51.5059453
						],
						[
							-0.2850843,
							51.505657
						],
						[
							-0.2847809,
							51.5055002
						],
						[
							-0.2844605,
							51.5053428
						],
						[
							-0.283818,
							51.5050051
						],
						[
							-0.2836338,
							51.504908
						],
						[
							-0.2834669,
							51.5048093
						],
						[
							-0.2831245,
							51.5045993
						],
						[
							-0.2824737,
							51.5042107
						],
						[
							-0.2821417,
							51.504015
						],
						[
							-0.2817902,
							51.5038213
						],
						[
							-0.2815264,
							51.5036824
						],
						[
							-0.2812484,
							51.5035386
						],
						[
							-0.280671858810906,
							51.50317376615741
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.296195090588108,
							51.51503768722618
						],
						[
							-0.295424594793164,
							51.515143493788436
						],
						[
							-0.294856589962394,
							51.51522188815716
						],
						[
							-0.2945816,
							51.5152711
						],
						[
							-0.2942998,
							51.5153198
						],
						[
							-0.293846,
							51.5154355
						],
						[
							-0.2932761,
							51.5155939
						],
						[
							-0.2926958,
							51.5157575
						],
						[
							-0.2921265,
							51.5158499
						],
						[
							-0.2916013,
							51.5158876
						],
						[
							-0.2910491,
							51.5158799
						],
						[
							-0.2905539,
							51.5158476
						],
						[
							-0.289954,
							51.5157497
						],
						[
							-0.2895407,
							51.5156213
						],
						[
							-0.2893129,
							51.5155287
						],
						[
							-0.2891252,
							51.5154317
						],
						[
							-0.2889186,
							51.5153068
						],
						[
							-0.2886118,
							51.5151117
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.301164655682419,
							51.463332914315274
						],
						[
							-0.2995944,
							51.4634613
						],
						[
							-0.2992322,
							51.4634566
						],
						[
							-0.2985611,
							51.4634699
						],
						[
							-0.2977911,
							51.4635666
						],
						[
							-0.2960077,
							51.4638946
						],
						[
							-0.2949053,
							51.464078
						],
						[
							-0.2931077,
							51.4643715
						],
						[
							-0.2927417,
							51.4644353
						],
						[
							-0.2924684,
							51.4644897
						],
						[
							-0.2922351,
							51.4645432
						],
						[
							-0.2919508,
							51.4646159
						],
						[
							-0.2917023,
							51.4646926
						],
						[
							-0.291528,
							51.4647569
						],
						[
							-0.2913295,
							51.464838
						],
						[
							-0.2911726,
							51.4649098
						],
						[
							-0.2910197,
							51.4649867
						],
						[
							-0.2908923,
							51.4650552
						],
						[
							-0.2907635,
							51.4651296
						],
						[
							-0.2906526,
							51.4652007
						],
						[
							-0.2905439,
							51.4652734
						],
						[
							-0.2904299,
							51.4653561
						],
						[
							-0.2903267,
							51.465433
						],
						[
							-0.2902221,
							51.4655148
						],
						[
							-0.2901067,
							51.4656109
						],
						[
							-0.2899874,
							51.4657112
						],
						[
							-0.2898747,
							51.4658089
						],
						[
							-0.2897607,
							51.4659142
						],
						[
							-0.2896132,
							51.4660546
						],
						[
							-0.2894228,
							51.46624
						],
						[
							-0.2892457,
							51.4664188
						],
						[
							-0.2890996,
							51.4665759
						],
						[
							-0.2889038,
							51.466794
						],
						[
							-0.2886942,
							51.4670378
						],
						[
							-0.2885145,
							51.4672625
						],
						[
							-0.2884295,
							51.4673707
						],
						[
							-0.288072,
							51.4678703
						],
						[
							-0.2879068,
							51.4681308
						],
						[
							-0.287643,
							51.4686025
						],
						[
							-0.2873835,
							51.469128
						],
						[
							-0.2871801,
							51.4696214
						],
						[
							-0.2870731,
							51.4700616
						],
						[
							-0.2864976,
							51.472012
						],
						[
							-0.2860523,
							51.4735695
						],
						[
							-0.2854955,
							51.4755203
						],
						[
							-0.2852819,
							51.4763157
						],
						[
							-0.2852045,
							51.4765471
						],
						[
							-0.2851036,
							51.4768472
						],
						[
							-0.28502036398589,
							51.47705883101488
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.285005847916101,
							51.47709577504947
						],
						[
							-0.284909,
							51.4773425
						],
						[
							-0.2847666,
							51.4776536
						],
						[
							-0.2846669,
							51.4778554
						],
						[
							-0.2846083,
							51.4779752
						],
						[
							-0.2844172,
							51.4783365
						],
						[
							-0.284292,
							51.4785708
						],
						[
							-0.2841369,
							51.4788184
						],
						[
							-0.283959,
							51.4791027
						],
						[
							-0.2837888,
							51.4793645
						],
						[
							-0.2831039,
							51.4802539
						],
						[
							-0.2829692,
							51.4804026
						],
						[
							-0.2817876,
							51.4816325
						],
						[
							-0.2800896,
							51.4832787
						],
						[
							-0.2793381,
							51.4840281
						],
						[
							-0.2782288,
							51.4851224
						],
						[
							-0.2778834,
							51.4855237
						],
						[
							-0.277391,
							51.4863203
						],
						[
							-0.2772716,
							51.4865364
						],
						[
							-0.2769713,
							51.4873669
						],
						[
							-0.2767911,
							51.4884205
						],
						[
							-0.276771,
							51.488974
						],
						[
							-0.2767289,
							51.4893147
						],
						[
							-0.2767098,
							51.4894412
						],
						[
							-0.2765481,
							51.4899868
						],
						[
							-0.2764241,
							51.4902743
						],
						[
							-0.2763871,
							51.4903698
						],
						[
							-0.2763214,
							51.4905006
						],
						[
							-0.2762292,
							51.4906551
						],
						[
							-0.2760547,
							51.4908983
						],
						[
							-0.2759183,
							51.4910694
						],
						[
							-0.2758167,
							51.4911907
						],
						[
							-0.2757024,
							51.4913158
						],
						[
							-0.2755663,
							51.4914523
						],
						[
							-0.2754703,
							51.4915407
						],
						[
							-0.275395206533,
							51.49160177048808
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.275364468564974,
							51.49162690460992
						],
						[
							-0.2753247,
							51.4916596
						],
						[
							-0.2751225,
							51.4918146
						],
						[
							-0.2749839,
							51.4919089
						],
						[
							-0.274801,
							51.4920018
						],
						[
							-0.274233200901324,
							51.492298520331666
						],
						[
							-0.273822101976058,
							51.49256587362246
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.273822101976061,
							51.49256587362245
						],
						[
							-0.273514,
							51.4927039
						],
						[
							-0.2730072,
							51.492936
						],
						[
							-0.2723598,
							51.493202
						],
						[
							-0.271673,
							51.4934395
						],
						[
							-0.2706401,
							51.4936924
						],
						[
							-0.2698558,
							51.4938145
						],
						[
							-0.2689764,
							51.4939245
						],
						[
							-0.2680631,
							51.4940124
						],
						[
							-0.267382,
							51.4940999
						],
						[
							-0.2671783,
							51.49413
						],
						[
							-0.2669062,
							51.4941886
						],
						[
							-0.2656482,
							51.4944963
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.195502576761315,
							51.487043057652016
						],
						[
							-0.195899488638874,
							51.48739392273085
						],
						[
							-0.1960753,
							51.4876774
						],
						[
							-0.1961944,
							51.4878682
						],
						[
							-0.1962909,
							51.4880493
						],
						[
							-0.1963557,
							51.4882362
						],
						[
							-0.1964134,
							51.4884739
						],
						[
							-0.196436,
							51.4887317
						],
						[
							-0.1964472,
							51.4889475
						],
						[
							-0.1964205,
							51.4891922
						],
						[
							-0.1963416,
							51.4895062
						],
						[
							-0.1962289,
							51.4897421
						],
						[
							-0.1960716,
							51.4900075
						],
						[
							-0.1951902,
							51.4908025
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.206148454168726,
							51.42164806256628
						],
						[
							-0.2060745,
							51.4217052
						],
						[
							-0.2048596,
							51.42251
						],
						[
							-0.2042106,
							51.4229153
						],
						[
							-0.2039318,
							51.4230875
						],
						[
							-0.2036852,
							51.423217
						],
						[
							-0.2032336,
							51.4234793
						],
						[
							-0.2026552,
							51.4238506
						],
						[
							-0.202374,
							51.4241072
						],
						[
							-0.2020089,
							51.4244108
						],
						[
							-0.2019663,
							51.4244462
						],
						[
							-0.201625,
							51.4247564
						],
						[
							-0.2013962,
							51.4249941
						],
						[
							-0.201127,
							51.4252855
						],
						[
							-0.200601,
							51.4259649
						],
						[
							-0.2004108,
							51.4262299
						],
						[
							-0.1993544,
							51.4276161
						],
						[
							-0.1988047,
							51.4283404
						],
						[
							-0.1984074,
							51.4289775
						],
						[
							-0.1982293,
							51.4293292
						],
						[
							-0.1981563,
							51.429524
						],
						[
							-0.1979627,
							51.4301593
						],
						[
							-0.1978774,
							51.4308723
						],
						[
							-0.1979069,
							51.4314795
						],
						[
							-0.1980656,
							51.4322299
						],
						[
							-0.1982391,
							51.4326487
						],
						[
							-0.1984815,
							51.4330825
						],
						[
							-0.1988373,
							51.4336204
						],
						[
							-0.199618406579167,
							51.434444557375905
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.199646187204934,
							51.434472648840014
						],
						[
							-0.2006903,
							51.4354845
						],
						[
							-0.201533,
							51.4361394
						],
						[
							-0.2022881,
							51.4366848
						],
						[
							-0.202965,
							51.4372319
						],
						[
							-0.2037805,
							51.4380208
						],
						[
							-0.2044198,
							51.438746
						],
						[
							-0.2045667,
							51.4389467
						],
						[
							-0.2053248,
							51.4400785
						],
						[
							-0.2056641,
							51.4407578
						],
						[
							-0.2060052,
							51.4415527
						],
						[
							-0.2062054,
							51.4422229
						],
						[
							-0.2063615,
							51.4429741
						],
						[
							-0.2064878,
							51.4437883
						],
						[
							-0.2065872,
							51.4444377
						],
						[
							-0.2066236,
							51.4448663
						],
						[
							-0.2066343,
							51.4449579
						],
						[
							-0.206703154101386,
							51.44550568262939
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.206708083800646,
							51.44554490184173
						],
						[
							-0.206772,
							51.4460534
						],
						[
							-0.2067661,
							51.4465154
						],
						[
							-0.2068299,
							51.4470937
						],
						[
							-0.2070379,
							51.4480251
						],
						[
							-0.207581,
							51.4495774
						],
						[
							-0.207633,
							51.4497457
						],
						[
							-0.2077514,
							51.4499998
						],
						[
							-0.2080929,
							51.450628
						],
						[
							-0.2093637,
							51.4522682
						],
						[
							-0.2107581,
							51.4537034
						],
						[
							-0.2116228,
							51.4544754
						],
						[
							-0.211862,
							51.45473
						],
						[
							-0.2121667,
							51.455009
						],
						[
							-0.2123918,
							51.4552696
						],
						[
							-0.2125383,
							51.4555317
						],
						[
							-0.2126536,
							51.4557704
						],
						[
							-0.2127252,
							51.4560505
						],
						[
							-0.2127419,
							51.4563875
						],
						[
							-0.2126684,
							51.4567282
						],
						[
							-0.2125826,
							51.4569926
						],
						[
							-0.2124626,
							51.4572203
						],
						[
							-0.2121701,
							51.4576009
						],
						[
							-0.2112641,
							51.4587137
						],
						[
							-0.211071576047112,
							51.45905221097401
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.211053757098074,
							51.45908747210756
						],
						[
							-0.2109877,
							51.4592359
						],
						[
							-0.21089,
							51.459529
						],
						[
							-0.2108067,
							51.4600463
						],
						[
							-0.2107237,
							51.4607198
						],
						[
							-0.2105029,
							51.4612842
						],
						[
							-0.2103013,
							51.4617269
						],
						[
							-0.2101804,
							51.4622593
						],
						[
							-0.2101937,
							51.4627529
						],
						[
							-0.210423,
							51.4636699
						],
						[
							-0.2104298,
							51.4640356
						],
						[
							-0.2103827,
							51.4643472
						],
						[
							-0.2103206,
							51.4645807
						],
						[
							-0.2102296,
							51.4648031
						],
						[
							-0.2100463,
							51.4651544
						],
						[
							-0.2097535,
							51.4657002
						],
						[
							-0.2096963,
							51.4658094
						],
						[
							-0.2093605,
							51.4664615
						],
						[
							-0.2090563,
							51.4670635
						],
						[
							-0.2089654,
							51.4675003
						],
						[
							-0.2089369,
							51.4676632
						],
						[
							-0.2089107,
							51.4678129
						],
						[
							-0.208820908541723,
							51.46832553985997
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.208814438028625,
							51.468364515031375
						],
						[
							-0.2087567,
							51.4687325
						],
						[
							-0.2086776,
							51.4690321
						],
						[
							-0.208601,
							51.469322
						],
						[
							-0.2084603,
							51.4695554
						],
						[
							-0.2083798,
							51.4696413
						],
						[
							-0.2082035,
							51.4698051
						],
						[
							-0.2077575,
							51.4701292
						],
						[
							-0.2070863,
							51.4704779
						],
						[
							-0.2069119,
							51.4705676
						],
						[
							-0.2067687,
							51.4706342
						],
						[
							-0.2063521,
							51.4709512
						],
						[
							-0.2060801,
							51.4712853
						],
						[
							-0.2059707,
							51.4715243
						],
						[
							-0.2058473,
							51.4720166
						],
						[
							-0.2057513,
							51.472402
						],
						[
							-0.2055971,
							51.4727206
						],
						[
							-0.2052685,
							51.4731031
						],
						[
							-0.2050757,
							51.4732715
						],
						[
							-0.2048532,
							51.4734413
						],
						[
							-0.2030162,
							51.4743599
						],
						[
							-0.2026454,
							51.4745411
						],
						[
							-0.201104950693745,
							51.47529382628788
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.201069421572497,
							51.47531111928145
						],
						[
							-0.2001289,
							51.4757671
						],
						[
							-0.1994061,
							51.4761175
						],
						[
							-0.1981078,
							51.4767692
						],
						[
							-0.1974886,
							51.4771326
						],
						[
							-0.196971,
							51.4775605
						],
						[
							-0.1965046,
							51.4780591
						],
						[
							-0.1962133,
							51.4785894
						],
						[
							-0.1959986,
							51.479149
						],
						[
							-0.1958991,
							51.4793493
						],
						[
							-0.195755,
							51.4796249
						],
						[
							-0.195293716410794,
							51.48016908036583
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.195265851452426,
							51.48019708749734
						],
						[
							-0.1947844,
							51.4806094
						],
						[
							-0.1943738,
							51.4809007
						],
						[
							-0.1939998,
							51.4811632
						],
						[
							-0.1929489,
							51.4818742
						],
						[
							-0.1921569,
							51.4824214
						],
						[
							-0.1918789,
							51.4826427
						],
						[
							-0.1916509,
							51.4829733
						],
						[
							-0.191583,
							51.4833175
						],
						[
							-0.1916412,
							51.4835618
						],
						[
							-0.19173974243461,
							51.48387323520169
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.18835498901684,
							51.49713766425822
						],
						[
							-0.189274229263027,
							51.4980913
						],
						[
							-0.189790258526055,
							51.4984603
						],
						[
							-0.190237501936477,
							51.4987422
						],
						[
							-0.190473158526055,
							51.498907807008486
						],
						[
							-0.190610041473945,
							51.499004352900634
						],
						[
							-0.190807327326551,
							51.49915435111334
						],
						[
							-0.1909675,
							51.4992622
						],
						[
							-0.1917412,
							51.5000091
						],
						[
							-0.1921995,
							51.5004558
						],
						[
							-0.1923042,
							51.5005537
						],
						[
							-0.192646625881619,
							51.500926538073465
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.192672473624642,
							51.50095656242031
						],
						[
							-0.193223,
							51.501639
						],
						[
							-0.1934199,
							51.5020038
						],
						[
							-0.1936652,
							51.5024602
						],
						[
							-0.1939675,
							51.5031803
						],
						[
							-0.1942948,
							51.5039943
						],
						[
							-0.1943242,
							51.5040691
						],
						[
							-0.195196,
							51.5063828
						],
						[
							-0.1953392,
							51.5067578
						],
						[
							-0.1957236,
							51.5078135
						],
						[
							-0.1958171,
							51.5080901
						],
						[
							-0.1959239,
							51.5084386
						],
						[
							-0.1959923,
							51.5086212
						],
						[
							-0.1960391,
							51.508721
						],
						[
							-0.1960681,
							51.5088132
						],
						[
							-0.1960842,
							51.5089394
						],
						[
							-0.1960883,
							51.5090662
						],
						[
							-0.196093735982895,
							51.50917992881233
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.196091341614896,
							51.50921924904004
						],
						[
							-0.1960493,
							51.5094641
						],
						[
							-0.19602,
							51.5096069
						],
						[
							-0.1959655,
							51.5098067
						],
						[
							-0.1959124,
							51.5099589
						],
						[
							-0.1958279,
							51.510126
						],
						[
							-0.1957318,
							51.5102711
						],
						[
							-0.1955657,
							51.5104928
						],
						[
							-0.195336,
							51.5107612
						],
						[
							-0.1950511,
							51.5109764
						],
						[
							-0.1948462,
							51.5111084
						],
						[
							-0.1945569,
							51.5112628
						],
						[
							-0.1941338,
							51.5114329
						],
						[
							-0.1938118,
							51.5115395
						],
						[
							-0.19344,
							51.5116278
						],
						[
							-0.1929337,
							51.5117397
						],
						[
							-0.1924714,
							51.5118324
						],
						[
							-0.1918411,
							51.5119414
						],
						[
							-0.1917287,
							51.5119563
						],
						[
							-0.190748,
							51.5120671
						],
						[
							-0.1902489,
							51.5121481
						],
						[
							-0.1898121,
							51.5121905
						],
						[
							-0.1896406,
							51.5121991
						],
						[
							-0.1891258,
							51.512239
						],
						[
							-0.188251,
							51.5123038
						],
						[
							-0.187856679099524,
							51.51233160528928
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.187817041966806,
							51.51233389634313
						],
						[
							-0.186532,
							51.5123919
						],
						[
							-0.1853708,
							51.5124683
						],
						[
							-0.184267,
							51.5125559
						],
						[
							-0.1838675,
							51.5125905
						],
						[
							-0.1836237,
							51.5126111
						],
						[
							-0.1830302,
							51.5126658
						],
						[
							-0.1825852,
							51.5127172
						],
						[
							-0.1821442,
							51.512778
						],
						[
							-0.1817416,
							51.5128419
						],
						[
							-0.1814491,
							51.5128949
						],
						[
							-0.1812915,
							51.5129275
						],
						[
							-0.1808662,
							51.5130155
						],
						[
							-0.1803038,
							51.5132021
						],
						[
							-0.1795998,
							51.5134381
						],
						[
							-0.1789017,
							51.5137738
						],
						[
							-0.1770466,
							51.5147751
						],
						[
							-0.176664,
							51.5149099
						],
						[
							-0.1760753,
							51.5150999
						],
						[
							-0.1759511,
							51.5151698
						],
						[
							-0.1754622,
							51.5154494
						],
						[
							-0.1749144,
							51.5157429
						],
						[
							-0.174508368787198,
							51.51594592539991
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.174473309178402,
							51.515964139863556
						],
						[
							-0.173853,
							51.5162987
						],
						[
							-0.1722437,
							51.5172
						],
						[
							-0.1719557,
							51.5173817
						],
						[
							-0.1717823,
							51.5174999
						],
						[
							-0.171697,
							51.5176056
						],
						[
							-0.1713672,
							51.5179298
						],
						[
							-0.1712567,
							51.5180278
						],
						[
							-0.1709116,
							51.5182099
						],
						[
							-0.170775297662297,
							51.518284431098486
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.170775297662297,
							51.518284431098486
						],
						[
							-0.1707503,
							51.5182981
						],
						[
							-0.1701444,
							51.5186345
						],
						[
							-0.1690089,
							51.5192757
						],
						[
							-0.168785,
							51.5194021
						],
						[
							-0.168382,
							51.5196233
						],
						[
							-0.1681064713195,
							51.51972463311771
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.1903034,
							51.494007
						],
						[
							-0.1900882,
							51.4942266
						],
						[
							-0.1896438,
							51.4945899
						],
						[
							-0.1892028,
							51.494959
						],
						[
							-0.1886457,
							51.4955426
						],
						[
							-0.188419,
							51.4958123
						],
						[
							-0.1883315,
							51.4959189
						],
						[
							-0.188276,
							51.4960307
						],
						[
							-0.1881726,
							51.4963673
						],
						[
							-0.1881904,
							51.4967852
						],
						[
							-0.18835498901684,
							51.49713766425822
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.172170102799241,
							51.4940132
						],
						[
							-0.170652986171434,
							51.49403306705485
						],
						[
							-0.1692604,
							51.4939813
						],
						[
							-0.1676428,
							51.4939075
						],
						[
							-0.1674282,
							51.4938956
						],
						[
							-0.1638142,
							51.4937393
						],
						[
							-0.163225,
							51.493707
						],
						[
							-0.1613555,
							51.4936338
						],
						[
							-0.1596217,
							51.4933666
						],
						[
							-0.1576303,
							51.4927208
						],
						[
							-0.1571649,
							51.4925696
						],
						[
							-0.1568969,
							51.4924768
						],
						[
							-0.156545044410935,
							51.4923549271712
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.156507157650075,
							51.492342353635365
						],
						[
							-0.1560371,
							51.4921931
						],
						[
							-0.1553069,
							51.4920522
						],
						[
							-0.1550163,
							51.4920105
						],
						[
							-0.1544085,
							51.4919066
						],
						[
							-0.1540012,
							51.4918819
						],
						[
							-0.1532246,
							51.491912
						],
						[
							-0.1526144,
							51.4919426
						],
						[
							-0.1522668,
							51.4919588
						],
						[
							-0.1519472,
							51.4919863
						],
						[
							-0.1516595,
							51.4920231
						],
						[
							-0.1513991,
							51.4920759
						],
						[
							-0.151146,
							51.4921388
						],
						[
							-0.1509295,
							51.4922025
						],
						[
							-0.1503327,
							51.4924039
						],
						[
							-0.1500887,
							51.4924887
						],
						[
							-0.1493608,
							51.4927423
						],
						[
							-0.1491276,
							51.4928324
						],
						[
							-0.1489055,
							51.4929447
						],
						[
							-0.1486996,
							51.4930695
						],
						[
							-0.1485518,
							51.4931829
						],
						[
							-0.1483886,
							51.493346
						],
						[
							-0.1472706,
							51.494756
						],
						[
							-0.1464929,
							51.4956708
						],
						[
							-0.1463378,
							51.4958146
						],
						[
							-0.1461558,
							51.495933
						],
						[
							-0.1460038,
							51.4960144
						],
						[
							-0.1457988,
							51.4961037
						],
						[
							-0.1456016,
							51.4961726
						],
						[
							-0.1454032,
							51.4962296
						],
						[
							-0.1451669,
							51.4962849
						],
						[
							-0.1449187,
							51.4963241
						],
						[
							-0.1447271,
							51.4963397
						],
						[
							-0.1446109,
							51.4963445
						],
						[
							-0.1444669,
							51.4963463
						],
						[
							-0.1442658,
							51.4963449
						],
						[
							-0.1440897,
							51.4963394
						],
						[
							-0.1438828,
							51.4963337
						],
						[
							-0.1437442,
							51.4963336
						],
						[
							-0.1436084,
							51.4963355
						],
						[
							-0.143453,
							51.4963432
						],
						[
							-0.1433095,
							51.4963527
						],
						[
							-0.143249272949739,
							51.496357066461144
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.143209881432058,
							51.496360908267306
						],
						[
							-0.1429957,
							51.4963872
						],
						[
							-0.1423022,
							51.4965199
						],
						[
							-0.1415213,
							51.4966727
						],
						[
							-0.1409544,
							51.4968194
						],
						[
							-0.1402203,
							51.4970904
						],
						[
							-0.1399551,
							51.497176
						],
						[
							-0.1397045,
							51.4972477
						],
						[
							-0.1393311,
							51.4973681
						],
						[
							-0.1391757,
							51.4974021
						],
						[
							-0.1390309,
							51.4974362
						],
						[
							-0.138868,
							51.4974753
						],
						[
							-0.1387089,
							51.4975156
						],
						[
							-0.1385791,
							51.4975538
						],
						[
							-0.1384535,
							51.4975892
						],
						[
							-0.1382924,
							51.4976367
						],
						[
							-0.138133,
							51.497692
						],
						[
							-0.1379823,
							51.4977477
						],
						[
							-0.13785,
							51.4978013
						],
						[
							-0.1377414,
							51.4978484
						],
						[
							-0.137312,
							51.4980494
						],
						[
							-0.1366094,
							51.4984014
						],
						[
							-0.1360515,
							51.4986953
						],
						[
							-0.1353464,
							51.4990403
						],
						[
							-0.1343383,
							51.4994128
						],
						[
							-0.133554943537178,
							51.499607223881625
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.13351632965304,
							51.49961597547276
						],
						[
							-0.1333676,
							51.4996465
						],
						[
							-0.1327429,
							51.4997337
						],
						[
							-0.1321161,
							51.4997694
						],
						[
							-0.1315488,
							51.4997593
						],
						[
							-0.130311,
							51.4997487
						],
						[
							-0.1296895,
							51.4997663
						],
						[
							-0.1290316,
							51.4998235
						],
						[
							-0.1284442,
							51.499921
						],
						[
							-0.1281299,
							51.4999985
						],
						[
							-0.1279096,
							51.5000636
						],
						[
							-0.1276435,
							51.500167
						],
						[
							-0.125974637191878,
							51.50086984113243
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.125938401082978,
							51.5008857465846
						],
						[
							-0.1252269,
							51.5012109
						],
						[
							-0.1247357,
							51.5015014
						],
						[
							-0.1244316,
							51.501734
						],
						[
							-0.1242202,
							51.501947
						],
						[
							-0.1240757,
							51.5021861
						],
						[
							-0.1239444,
							51.5025487
						],
						[
							-0.1234937,
							51.5041583
						],
						[
							-0.1233162,
							51.5048563
						],
						[
							-0.1231603,
							51.5053746
						],
						[
							-0.1229762,
							51.5059697
						],
						[
							-0.1228544,
							51.5063936
						],
						[
							-0.122584683082093,
							51.50699594836526
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.122564435351548,
							51.5070297713965
						],
						[
							-0.1221088,
							51.5076205
						],
						[
							-0.1218702,
							51.5078355
						],
						[
							-0.1216534,
							51.5080602
						],
						[
							-0.1214973,
							51.5082517
						],
						[
							-0.121308,
							51.5083976
						],
						[
							-0.1208785,
							51.5086376
						],
						[
							-0.1204941,
							51.5088948
						],
						[
							-0.1200543,
							51.5091826
						],
						[
							-0.1195715,
							51.509446
						],
						[
							-0.1189976,
							51.5097277
						],
						[
							-0.1185383,
							51.5098931
						],
						[
							-0.1163904,
							51.5105492
						],
						[
							-0.1156501,
							51.5107496
						],
						[
							-0.1152692,
							51.5108297
						],
						[
							-0.1148135,
							51.510911
						],
						[
							-0.113769474772607,
							51.51105848384477
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.113730272131473,
							51.51106351817051
						],
						[
							-0.1132938,
							51.5111112
						],
						[
							-0.1129557,
							51.5111368
						],
						[
							-0.1124117,
							51.5111646
						],
						[
							-0.1118538,
							51.511187
						],
						[
							-0.1109627,
							51.5112024
						],
						[
							-0.1084796,
							51.5112084
						],
						[
							-0.1069587,
							51.511209
						],
						[
							-0.1056896,
							51.5112168
						],
						[
							-0.1054053,
							51.5112308
						],
						[
							-0.1050995,
							51.5112676
						],
						[
							-0.1047991,
							51.5113311
						],
						[
							-0.1045952,
							51.5113796
						],
						[
							-0.1043267,
							51.5114395
						],
						[
							-0.103159102999755,
							51.51176843656334
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.10312084105735,
							51.51177941022711
						],
						[
							-0.1025498,
							51.5119464
						],
						[
							-0.1022681,
							51.5120056
						],
						[
							-0.1020422,
							51.5120498
						],
						[
							-0.1019203,
							51.5120679
						],
						[
							-0.1016948,
							51.5120786
						],
						[
							-0.1014622,
							51.5120745
						],
						[
							-0.100916,
							51.5120414
						],
						[
							-0.0989414,
							51.5119219
						],
						[
							-0.0983237,
							51.511882
						],
						[
							-0.0973121,
							51.5118414
						],
						[
							-0.0970253,
							51.5118403
						],
						[
							-0.0962666,
							51.511856
						],
						[
							-0.0945759,
							51.511974
						],
						[
							-0.093773858193849,
							51.51191202670395
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.093734552431181,
							51.511907643461946
						],
						[
							-0.0927379,
							51.511762
						],
						[
							-0.0921216,
							51.5116643
						],
						[
							-0.0910277,
							51.511462
						],
						[
							-0.0907398,
							51.5114197
						],
						[
							-0.0905353,
							51.5114008
						],
						[
							-0.090343059118033,
							51.51139218949373
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.090303220756978,
							51.5113914310544
						],
						[
							-0.0900183,
							51.5113933
						],
						[
							-0.0898321,
							51.5114038
						],
						[
							-0.0896359,
							51.5114091
						],
						[
							-0.0894695,
							51.5114007
						],
						[
							-0.0892096,
							51.5113758
						],
						[
							-0.0883782,
							51.5112141
						],
						[
							-0.0874619,
							51.5110386
						],
						[
							-0.0872105,
							51.51099
						],
						[
							-0.0869856,
							51.51092
						],
						[
							-0.0868333,
							51.5108502
						],
						[
							-0.086677,
							51.5107765
						],
						[
							-0.086495731299294,
							51.51073075338525
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.086457119669111,
							51.51072198756322
						],
						[
							-0.0861035,
							51.5106506
						],
						[
							-0.0857332,
							51.5105997
						],
						[
							-0.0854708,
							51.5105831
						],
						[
							-0.0851716,
							51.5105807
						],
						[
							-0.0843205,
							51.5106333
						],
						[
							-0.0840808,
							51.5106334
						],
						[
							-0.0838356,
							51.5106302
						],
						[
							-0.0836248,
							51.5106159
						],
						[
							-0.0831535,
							51.5105579
						],
						[
							-0.0826885,
							51.5104458
						],
						[
							-0.0815989,
							51.5100769
						],
						[
							-0.0804517,
							51.5096775
						],
						[
							-0.0804008,
							51.509664
						],
						[
							-0.0803466,
							51.5096572
						],
						[
							-0.0796337,
							51.5096459
						],
						[
							-0.0790122,
							51.5096115
						],
						[
							-0.078665,
							51.5095869
						],
						[
							-0.0784592,
							51.5095854
						],
						[
							-0.0783151,
							51.5096142
						],
						[
							-0.0781999,
							51.5096699
						],
						[
							-0.077918,
							51.509777
						],
						[
							-0.077769867046615,
							51.509807897815065
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.077730682231053,
							51.50981304468704
						],
						[
							-0.0766367,
							51.5098716
						],
						[
							-0.0765111,
							51.5098931
						],
						[
							-0.0763933,
							51.5099267
						],
						[
							-0.0762319,
							51.5099944
						],
						[
							-0.0761323,
							51.5100441
						],
						[
							-0.0760139,
							51.5101343
						],
						[
							-0.0758734,
							51.5102604
						],
						[
							-0.0757731,
							51.51036
						],
						[
							-0.0754608,
							51.510697
						],
						[
							-0.0753046,
							51.5111095
						],
						[
							-0.0753659,
							51.511398
						],
						[
							-0.0755825,
							51.5123736
						],
						[
							-0.075579,
							51.5128571
						],
						[
							-0.0754317,
							51.5133864
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.073643834865545,
							51.51454657376696
						],
						[
							-0.071895497641689,
							51.51514077730002
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.071858374856913,
							51.515154942552805
						],
						[
							-0.0678046,
							51.5168771
						],
						[
							-0.0650373,
							51.5179188
						],
						[
							-0.0633722,
							51.5184283
						],
						[
							-0.0631195,
							51.518545
						],
						[
							-0.0628378,
							51.5188134
						],
						[
							-0.0625894,
							51.5189809
						],
						[
							-0.0622551,
							51.5191657
						],
						[
							-0.0620466,
							51.5192773
						],
						[
							-0.0619028,
							51.5193375
						],
						[
							-0.0617838,
							51.5193876
						],
						[
							-0.0616364,
							51.5194428
						],
						[
							-0.0613856,
							51.5195146
						],
						[
							-0.061,
							51.5196
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.061,
							51.5196
						],
						[
							-0.060529270547408,
							51.51972204850047
						],
						[
							-0.060283028595149,
							51.519761105295366
						],
						[
							-0.0600281,
							51.5197885
						],
						[
							-0.0597672,
							51.5198054
						],
						[
							-0.0595227,
							51.5198063
						],
						[
							-0.0593181,
							51.5197925
						],
						[
							-0.0590847,
							51.5197673
						],
						[
							-0.0588746,
							51.519744
						],
						[
							-0.0586472,
							51.5197045
						],
						[
							-0.0583709,
							51.5196712
						],
						[
							-0.0583565,
							51.5196745
						],
						[
							-0.0581935,
							51.5196524
						],
						[
							-0.057864,
							51.5196586
						],
						[
							-0.0566344,
							51.519924
						],
						[
							-0.0513925,
							51.5208443
						],
						[
							-0.04674762591462,
							51.521710975367476
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.046708865325785,
							51.5217188007016
						],
						[
							-0.0454445,
							51.5219935
						],
						[
							-0.0440455,
							51.5221751
						],
						[
							-0.0422945,
							51.5223353
						],
						[
							-0.0409556,
							51.5225916
						],
						[
							-0.0387539,
							51.5233109
						],
						[
							-0.037618,
							51.5239798
						],
						[
							-0.0374122,
							51.5241009
						],
						[
							-0.0353384,
							51.524877
						],
						[
							-0.033464129073488,
							51.52535143256148
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.033425539847342,
							51.525360339837036
						],
						[
							-0.0265272,
							51.5267998
						],
						[
							-0.026039,
							51.5268736
						],
						[
							-0.0257121,
							51.5269077
						],
						[
							-0.0254295,
							51.5269147
						],
						[
							-0.0251305,
							51.5269124
						],
						[
							-0.02486117292534,
							51.52687529644316
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.024822141368725,
							51.52686918193227
						],
						[
							-0.0246099,
							51.5268319
						],
						[
							-0.0243152,
							51.5267555
						],
						[
							-0.0239914,
							51.5266492
						],
						[
							-0.0235822,
							51.5264558
						],
						[
							-0.0218763,
							51.525568
						],
						[
							-0.0212434,
							51.525229
						],
						[
							-0.0207648,
							51.5249674
						],
						[
							-0.0206234,
							51.5248914
						],
						[
							-0.0203972,
							51.5247996
						],
						[
							-0.0202218,
							51.5247341
						],
						[
							-0.0200331,
							51.5246856
						],
						[
							-0.0197387,
							51.5246328
						],
						[
							-0.019272,
							51.5245995
						],
						[
							-0.0184861,
							51.5245777
						],
						[
							-0.0179745,
							51.5245591
						],
						[
							-0.0161955,
							51.5245078
						],
						[
							-0.0149456,
							51.5244736
						],
						[
							-0.0146557,
							51.5244702
						],
						[
							-0.0142303,
							51.5244793
						],
						[
							-0.0137109,
							51.524518
						],
						[
							-0.0137102,
							51.524518
						],
						[
							-0.0133658,
							51.5245496
						],
						[
							-0.0124243,
							51.5246408
						],
						[
							-0.011604744810281,
							51.52473435742037
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.011565519693677,
							51.52473924984159
						],
						[
							-0.0109947,
							51.5248165
						],
						[
							-0.0106232,
							51.5248635
						],
						[
							-0.0101843,
							51.5249241
						],
						[
							-0.0097502,
							51.5249877
						],
						[
							-0.0093524,
							51.5250677
						],
						[
							-0.0088181,
							51.5251776
						],
						[
							-0.0084199,
							51.5252649
						],
						[
							-0.0081008,
							51.5253407
						],
						[
							-0.0064147,
							51.5257764
						],
						[
							-0.0061444,
							51.5258416
						],
						[
							-0.0047993,
							51.5261357
						],
						[
							-0.0034446,
							51.5264546
						],
						[
							-0.0007441,
							51.5270809
						],
						[
							9.83e-05,
							51.5272755
						],
						[
							0.0010875,
							51.527501
						],
						[
							0.0018706,
							51.5276874
						],
						[
							0.0026478,
							51.527882
						],
						[
							0.0032143,
							51.528018
						],
						[
							0.0035768,
							51.528111
						],
						[
							0.0040786,
							51.5282492
						],
						[
							0.004379898832705,
							51.52833066676116
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.004418269798922,
							51.52834095207567
						],
						[
							0.0049299,
							51.5284769
						],
						[
							0.0062045,
							51.5287752
						],
						[
							0.007501,
							51.5290766
						],
						[
							0.0100914,
							51.5296594
						],
						[
							0.0107491,
							51.5298133
						],
						[
							0.0113738,
							51.5299534
						],
						[
							0.0115755,
							51.5299986
						],
						[
							0.0126243,
							51.5302266
						],
						[
							0.0135611,
							51.5304312
						],
						[
							0.0138022,
							51.5304839
						],
						[
							0.0139032,
							51.5305073
						],
						[
							0.015138,
							51.5307938
						],
						[
							0.015661,
							51.5309302
						],
						[
							0.01655216047163,
							51.531152598571126
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.016590736025331,
							51.5311615921711
						],
						[
							0.0176341,
							51.5313878
						],
						[
							0.0185284,
							51.5315507
						],
						[
							0.019354,
							51.5317429
						],
						[
							0.0209768,
							51.5321117
						],
						[
							0.0240752,
							51.5328379
						],
						[
							0.0248908,
							51.5330273
						],
						[
							0.0257895,
							51.5332278
						],
						[
							0.0276108,
							51.5336391
						],
						[
							0.0284731,
							51.5338382
						],
						[
							0.0294771,
							51.5340696
						],
						[
							0.0311225,
							51.5344441
						],
						[
							0.0318736,
							51.5346306
						],
						[
							0.0325875,
							51.5348043
						],
						[
							0.034394802608714,
							51.5352197639031
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.034433399469593,
							51.535228622974856
						],
						[
							0.0350852,
							51.535378
						],
						[
							0.0356797,
							51.5355063
						],
						[
							0.0367013,
							51.5357183
						],
						[
							0.0377329,
							51.5359594
						],
						[
							0.0392241,
							51.5363038
						],
						[
							0.0400767,
							51.5365028
						],
						[
							0.040826,
							51.5366754
						],
						[
							0.0419764,
							51.5369385
						],
						[
							0.0431243,
							51.5371997
						],
						[
							0.0442692,
							51.5374678
						],
						[
							0.0452291,
							51.5376892
						],
						[
							0.0462418,
							51.5379161
						],
						[
							0.0471814,
							51.5381264
						],
						[
							0.0481313,
							51.5383353
						],
						[
							0.0487791,
							51.5384766
						],
						[
							0.0495945,
							51.5386561
						],
						[
							0.050159,
							51.5387808
						],
						[
							0.0507203,
							51.538912
						],
						[
							0.0515563,
							51.5391222
						],
						[
							0.051992206255451,
							51.539237809503476
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.052030520280677,
							51.53924845432926
						],
						[
							0.0524627,
							51.539374
						],
						[
							0.0529046,
							51.539506
						],
						[
							0.0533232,
							51.5396362
						],
						[
							0.0539079,
							51.5398259
						],
						[
							0.0545055,
							51.5400185
						],
						[
							0.0545591,
							51.5400371
						],
						[
							0.0551107,
							51.5402277
						],
						[
							0.0555237,
							51.540375
						],
						[
							0.0559404,
							51.5405403
						],
						[
							0.0563194,
							51.5406895
						],
						[
							0.0566839,
							51.5408423
						],
						[
							0.0573124,
							51.5411316
						],
						[
							0.0579685,
							51.5414345
						],
						[
							0.0585113,
							51.5417064
						],
						[
							0.0587426,
							51.5418445
						],
						[
							0.0589664,
							51.5419858
						],
						[
							0.059157,
							51.5421257
						],
						[
							0.0593268,
							51.5422675
						],
						[
							0.0594888,
							51.5424137
						],
						[
							0.059639,
							51.5425614
						],
						[
							0.0600537,
							51.5429769
						],
						[
							0.0602573,
							51.5431659
						],
						[
							0.0605833,
							51.5434308
						],
						[
							0.0609278,
							51.5436839
						],
						[
							0.0614443,
							51.5439485
						],
						[
							0.061834,
							51.5441184
						],
						[
							0.0622295,
							51.5442618
						],
						[
							0.0626882,
							51.5444012
						],
						[
							0.0631504,
							51.5445054
						],
						[
							0.0635694,
							51.5445827
						],
						[
							0.0640027,
							51.5446403
						],
						[
							0.0643578,
							51.5446711
						],
						[
							0.0646828,
							51.5446869
						],
						[
							0.0650206,
							51.5446925
						],
						[
							0.065122189956493,
							51.5446881344765
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.0754317,
							51.5133864
						],
						[
							-0.0754045,
							51.5134742
						],
						[
							-0.0753485,
							51.5135956
						],
						[
							-0.0753178,
							51.5136628
						],
						[
							-0.0752421,
							51.5137749
						],
						[
							-0.075143,
							51.5139039
						],
						[
							-0.0750206,
							51.5140218
						],
						[
							-0.0749103,
							51.5141024
						],
						[
							-0.0747552,
							51.5141776
						],
						[
							-0.0745353,
							51.5142643
						],
						[
							-0.0740048,
							51.5144336
						],
						[
							-0.0736467,
							51.5145456
						],
						[
							-0.073643834865545,
							51.51454657376696
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.081218161905219,
							51.53950585576959
						],
						[
							0.0819577,
							51.539125
						],
						[
							0.0824881,
							51.5388626
						],
						[
							0.0827557,
							51.5387334
						],
						[
							0.0830218,
							51.5386259
						],
						[
							0.0832458,
							51.5385381
						],
						[
							0.0835495,
							51.5384331
						],
						[
							0.0840251,
							51.5382818
						],
						[
							0.0844111,
							51.5381715
						],
						[
							0.0846503,
							51.5381117
						],
						[
							0.0849777,
							51.5380352
						],
						[
							0.0854558,
							51.537936
						],
						[
							0.085872,
							51.5378632
						],
						[
							0.0863744,
							51.5377752
						],
						[
							0.0868872,
							51.537714
						],
						[
							0.0872316,
							51.5376822
						],
						[
							0.0875663,
							51.5376705
						],
						[
							0.0879452,
							51.537671
						],
						[
							0.0883393,
							51.5376818
						],
						[
							0.0889296,
							51.5377278
						],
						[
							0.0894894,
							51.5377779
						],
						[
							0.0904281,
							51.5378434
						],
						[
							0.0915542,
							51.5379375
						],
						[
							0.0921864,
							51.5379731
						],
						[
							0.0931,
							51.5379702
						],
						[
							0.0941325,
							51.5379375
						],
						[
							0.0947046,
							51.5379192
						],
						[
							0.0953045,
							51.5379163
						],
						[
							0.0957862,
							51.5379119
						],
						[
							0.0962976,
							51.537939
						],
						[
							0.0968257,
							51.5379831
						],
						[
							0.098614,
							51.5381712
						],
						[
							0.099707967713368,
							51.53826490972421
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.099747494034784,
							51.53826790041309
						],
						[
							0.100883,
							51.5383425
						],
						[
							0.101244,
							51.5383662
						],
						[
							0.1026676,
							51.5384706
						],
						[
							0.1045854,
							51.5385897
						],
						[
							0.1056247,
							51.5386647
						],
						[
							0.1065871,
							51.5387417
						],
						[
							0.1097763,
							51.5389905
						],
						[
							0.1122099,
							51.539184
						],
						[
							0.1129477,
							51.5392447
						],
						[
							0.1183281,
							51.5396705
						],
						[
							0.1227881,
							51.5399692
						],
						[
							0.1249802,
							51.5401591
						],
						[
							0.1261147,
							51.5402454
						],
						[
							0.127249112071743,
							51.54032216103171
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.127288718895927,
							51.54032464344612
						],
						[
							0.1288479,
							51.5404146
						],
						[
							0.1304624,
							51.5404928
						],
						[
							0.1336811,
							51.5406848
						],
						[
							0.1368967,
							51.5408997
						],
						[
							0.1385158,
							51.5410092
						],
						[
							0.1400776,
							51.5411096
						],
						[
							0.1417272,
							51.5412248
						],
						[
							0.1436163,
							51.5413868
						],
						[
							0.1451757,
							51.5415201
						],
						[
							0.1468731,
							51.5416276
						],
						[
							0.147680069426825,
							51.54167113028113
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.147719779201433,
							51.54167296269213
						],
						[
							0.1488311,
							51.5417156
						],
						[
							0.1493105,
							51.5417405
						],
						[
							0.1500297,
							51.5417764
						],
						[
							0.1507394,
							51.5418137
						],
						[
							0.1518059,
							51.5419001
						],
						[
							0.1530176,
							51.5420224
						],
						[
							0.1541713,
							51.5421595
						],
						[
							0.1549195,
							51.5422593
						],
						[
							0.1555179,
							51.5423489
						],
						[
							0.1563416,
							51.5424924
						],
						[
							0.1571804,
							51.5426358
						],
						[
							0.158114,
							51.5428045
						],
						[
							0.1595353,
							51.5431102
						],
						[
							0.1607833,
							51.5433318
						],
						[
							0.161146,
							51.5433962
						],
						[
							0.1620695,
							51.5435588
						],
						[
							0.1629975,
							51.5437104
						],
						[
							0.1643245,
							51.5439331
						],
						[
							0.165621979330258,
							51.54415760500294
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.16566094182708,
							51.5441641555402
						],
						[
							0.1665647,
							51.5443116
						],
						[
							0.1690107,
							51.5446868
						],
						[
							0.1718622,
							51.5451776
						],
						[
							0.1777182,
							51.546179
						],
						[
							0.1847834,
							51.5473911
						],
						[
							0.1870561,
							51.5477921
						],
						[
							0.1873578,
							51.5478412
						],
						[
							0.1912927,
							51.5485063
						],
						[
							0.1934068,
							51.5488749
						],
						[
							0.1944335,
							51.5490634
						],
						[
							0.1955047,
							51.549284
						],
						[
							0.1961545,
							51.5494109
						],
						[
							0.1967907,
							51.5495245
						],
						[
							0.197444141367008,
							51.5496392819432
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.197483075830235,
							51.54964600947762
						],
						[
							0.1980974,
							51.5497504
						],
						[
							0.1998099,
							51.5500445
						],
						[
							0.200639,
							51.5501529
						],
						[
							0.2010885,
							51.550209
						],
						[
							0.201775,
							51.5503239
						],
						[
							0.2025434,
							51.5504779
						],
						[
							0.2034654,
							51.5506793
						],
						[
							0.2044046,
							51.5508867
						],
						[
							0.2077734,
							51.5516371
						],
						[
							0.2111491,
							51.5523852
						],
						[
							0.2150043,
							51.5532414
						],
						[
							0.216894,
							51.5536461
						],
						[
							0.218522267190941,
							51.55400088752218
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.218561002564958,
							51.5540088720564
						],
						[
							0.2200014,
							51.5542889
						],
						[
							0.2215814,
							51.5545946
						],
						[
							0.2242031,
							51.5551554
						],
						[
							0.2255081,
							51.5554419
						],
						[
							0.226182,
							51.5555975
						],
						[
							0.2267862,
							51.5557444
						],
						[
							0.2280403,
							51.5560788
						],
						[
							0.2292609,
							51.5564468
						],
						[
							0.2303099,
							51.55678
						],
						[
							0.2310909,
							51.5570527
						],
						[
							0.2318572,
							51.5573414
						],
						[
							0.2333677,
							51.557915
						],
						[
							0.2336744,
							51.5580156
						],
						[
							0.2342064,
							51.5581921
						],
						[
							0.234674682767021,
							51.55832862642838
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.234712937840339,
							51.55833964346186
						],
						[
							0.2348724,
							51.558385
						],
						[
							0.2351879,
							51.5584644
						],
						[
							0.2355486,
							51.5585505
						],
						[
							0.2359657,
							51.5586284
						],
						[
							0.2363791,
							51.5587028
						],
						[
							0.236776,
							51.5587698
						],
						[
							0.2373651,
							51.5588762
						],
						[
							0.237774,
							51.5589401
						],
						[
							0.2382316,
							51.5590052
						],
						[
							0.2387163,
							51.5590546
						],
						[
							0.239358,
							51.5591113
						],
						[
							0.23999,
							51.5591679
						],
						[
							0.2406957,
							51.5592161
						],
						[
							0.2413216,
							51.5592469
						],
						[
							0.241783,
							51.559259
						],
						[
							0.2428979,
							51.5592546
						],
						[
							0.2431898,
							51.5592432
						],
						[
							0.2438233,
							51.5592245
						],
						[
							0.2446977,
							51.5591993
						],
						[
							0.2460392,
							51.5591566
						],
						[
							0.2467953,
							51.559133
						],
						[
							0.2476257,
							51.5591222
						],
						[
							0.2476672,
							51.5591217
						],
						[
							0.2482217,
							51.5591191
						],
						[
							0.2493461,
							51.5591053
						],
						[
							0.2495651,
							51.5591007
						],
						[
							0.2502091,
							51.5590872
						],
						[
							0.2507328,
							51.5590719
						],
						[
							0.2528484,
							51.5590096
						],
						[
							0.253003866598747,
							51.55901287951206
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.30154338885404,
							51.5152
						],
						[
							-0.296195090588112,
							51.51503768722618
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.065122189956493,
							51.5446881344765
						],
						[
							0.0653813,
							51.544677
						],
						[
							0.0657372,
							51.5446506
						],
						[
							0.0660495,
							51.5446124
						],
						[
							0.0663386,
							51.5445669
						],
						[
							0.0666419,
							51.5445067
						],
						[
							0.0669902,
							51.5444285
						],
						[
							0.0672572,
							51.5443566
						],
						[
							0.0675764,
							51.54427
						],
						[
							0.0681877,
							51.5441091
						],
						[
							0.0684659,
							51.5440498
						],
						[
							0.0689249,
							51.5439471
						],
						[
							0.069734,
							51.5437372
						],
						[
							0.0699873,
							51.543678
						],
						[
							0.0703484,
							51.5436011
						],
						[
							0.0706635,
							51.543544
						],
						[
							0.0719269,
							51.5433251
						],
						[
							0.0722917,
							51.5432561
						],
						[
							0.0725566,
							51.5432052
						],
						[
							0.0728556,
							51.5431357
						],
						[
							0.073255,
							51.5430334
						],
						[
							0.0736034,
							51.5429361
						],
						[
							0.0740168,
							51.5427999
						],
						[
							0.0743871,
							51.5426735
						],
						[
							0.0747253,
							51.5425471
						],
						[
							0.075137,
							51.5423817
						],
						[
							0.0755594,
							51.5422054
						],
						[
							0.0759818,
							51.542025
						],
						[
							0.0764818,
							51.5418031
						],
						[
							0.0768628,
							51.541621
						],
						[
							0.0771315,
							51.5414994
						],
						[
							0.0777878,
							51.5411712
						],
						[
							0.0783158,
							51.5409326
						],
						[
							0.0789387,
							51.5406572
						],
						[
							0.0794244,
							51.5404174
						],
						[
							0.0797226,
							51.5402751
						],
						[
							0.0805642,
							51.5398351
						],
						[
							0.081182953704177,
							51.539523778604526
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.191739742434613,
							51.483873235201706
						],
						[
							-0.1918083,
							51.4839433
						],
						[
							-0.1919961,
							51.4841454
						],
						[
							-0.1921254,
							51.4842794
						],
						[
							-0.1923903,
							51.484569
						],
						[
							-0.192588,
							51.4847696
						],
						[
							-0.1928069,
							51.4849864
						],
						[
							-0.1930485,
							51.4852618
						],
						[
							-0.1932674,
							51.4854631
						],
						[
							-0.1934639,
							51.4856412
						],
						[
							-0.1936567,
							51.4857992
						],
						[
							-0.1938755,
							51.4859703
						],
						[
							-0.1940136,
							51.4860764
						],
						[
							-0.1941827,
							51.4862034
						],
						[
							-0.1945073,
							51.486428
						],
						[
							-0.1947436,
							51.4865899
						],
						[
							-0.1950035,
							51.4867463
						],
						[
							-0.1953306,
							51.486943
						],
						[
							-0.19547045408908,
							51.48702038075908
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.200831327417439,
							51.490526591014856
						],
						[
							-0.201177,
							51.4909192
						],
						[
							-0.201317,
							51.4910743
						],
						[
							-0.2014587,
							51.4912211
						],
						[
							-0.2015938,
							51.4913761
						],
						[
							-0.2017969,
							51.4916056
						],
						[
							-0.2020663,
							51.4918957
						],
						[
							-0.202226,
							51.4920716
						],
						[
							-0.2024221,
							51.4922916
						],
						[
							-0.2025548,
							51.4924494
						],
						[
							-0.2026969,
							51.492606
						],
						[
							-0.2028287,
							51.4927487
						],
						[
							-0.2029229,
							51.4928599
						],
						[
							-0.203013,
							51.4929558
						],
						[
							-0.2031113,
							51.4930644
						],
						[
							-0.203181,
							51.493141
						],
						[
							-0.2032653,
							51.4932258
						],
						[
							-0.2033926,
							51.4933484
						],
						[
							-0.2034808,
							51.4934313
						],
						[
							-0.2035971,
							51.4935359
						],
						[
							-0.2037114,
							51.4936259
						],
						[
							-0.2038588,
							51.4937416
						],
						[
							-0.2039734,
							51.4938296
						],
						[
							-0.2041253,
							51.4939415
						],
						[
							-0.2042314,
							51.494018
						],
						[
							-0.2043194,
							51.4940786
						],
						[
							-0.2044597,
							51.4941698
						],
						[
							-0.2046407,
							51.4942846
						],
						[
							-0.2048434,
							51.4944022
						],
						[
							-0.2052244,
							51.4946098
						],
						[
							-0.2055065,
							51.494753
						],
						[
							-0.2057367,
							51.4948652
						],
						[
							-0.2060339,
							51.4950034
						],
						[
							-0.2063755,
							51.4951689
						],
						[
							-0.2065869,
							51.4952735
						],
						[
							-0.2067904,
							51.4953802
						],
						[
							-0.2069054,
							51.4954505
						],
						[
							-0.2069899,
							51.4954992
						],
						[
							-0.2072838,
							51.4956956
						],
						[
							-0.2073964,
							51.4957741
						],
						[
							-0.2075053,
							51.4958539
						],
						[
							-0.2075958,
							51.4959171
						],
						[
							-0.2078666,
							51.496145
						],
						[
							-0.2080761,
							51.4963128
						],
						[
							-0.2082959,
							51.4964914
						],
						[
							-0.208458,
							51.496623
						],
						[
							-0.2086006,
							51.4967361
						],
						[
							-0.2088052,
							51.4968962
						],
						[
							-0.2089571,
							51.4970267
						],
						[
							-0.2091277,
							51.4971604
						],
						[
							-0.2092864,
							51.4972872
						],
						[
							-0.2095215,
							51.4974763
						],
						[
							-0.209577243603558,
							51.49752130878745
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.265651715521404,
							51.49449610418332
						],
						[
							-0.2661078,
							51.4944707
						],
						[
							-0.2663654,
							51.4944623
						],
						[
							-0.2665843,
							51.4944613
						],
						[
							-0.2667681,
							51.494464
						],
						[
							-0.2671513,
							51.4944763
						],
						[
							-0.2673507,
							51.4944906
						],
						[
							-0.2676086,
							51.4945157
						],
						[
							-0.26810188582407,
							51.49457696400272
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.195188837122854,
							51.49080340565126
						],
						[
							-0.1951902,
							51.4908025
						],
						[
							-0.195892,
							51.4903721
						],
						[
							-0.1963949,
							51.4901511
						],
						[
							-0.1968078,
							51.4900405
						],
						[
							-0.1972888,
							51.4899539
						],
						[
							-0.1978192,
							51.4899065
						],
						[
							-0.1983329,
							51.4898959
						],
						[
							-0.1988874,
							51.489922
						],
						[
							-0.1995307,
							51.4900253
						],
						[
							-0.200090313988162,
							51.49018516289642
						],
						[
							-0.200831327417429,
							51.490526591014834
						],
						[
							-0.200835347021121,
							51.49052861203458
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.200835347021121,
							51.49052861203458
						],
						[
							-0.2015561,
							51.490891
						],
						[
							-0.2018883,
							51.4910349
						],
						[
							-0.2021946,
							51.4911317
						],
						[
							-0.2025274,
							51.4912211
						],
						[
							-0.2028842,
							51.4912783
						],
						[
							-0.2032499,
							51.4912963
						],
						[
							-0.2035495,
							51.4912835
						],
						[
							-0.2038509,
							51.4912553
						],
						[
							-0.2041615,
							51.4912152
						],
						[
							-0.2044462,
							51.491153
						],
						[
							-0.205141,
							51.4909498
						],
						[
							-0.205960765659018,
							51.49070242499719
						]
					]
				}
			}
		]
	},
	{
		name: "Circle",
		lineColor: "#fdc40a",
		features: [
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.17257892682836,
							51.49402315763969
						],
						[
							-0.172880240952617,
							51.494036241746215
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.172919981948695,
							51.49403787703262
						],
						[
							-0.17421781897401,
							51.49408833090708
						],
						[
							-0.174655205598494,
							51.49408461162325
						],
						[
							-0.174917230170998,
							51.4940709923595
						],
						[
							-0.175129445256497,
							51.494061605816746
						],
						[
							-0.175420060341997,
							51.49404260582991
						],
						[
							-0.176014487713748,
							51.49399305965017
						],
						[
							-0.176594,
							51.493937
						],
						[
							-0.1792016,
							51.493723432842216
						],
						[
							-0.17970254245725,
							51.493739185103514
						],
						[
							-0.1802151,
							51.49379035817749
						],
						[
							-0.182791312286239,
							51.494305480526364
						],
						[
							-0.183452400063918,
							51.49443458575443
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.183491161152979,
							51.494442407930244
						],
						[
							-0.184239872628249,
							51.49459838746867
						],
						[
							-0.184845278226743,
							51.49472670620243
						],
						[
							-0.185816587713748,
							51.494938929753665
						],
						[
							-0.18582568760043,
							51.494940382355814
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.18835498901684,
							51.49713766425822
						],
						[
							-0.189274229263027,
							51.4980913
						],
						[
							-0.189790258526055,
							51.4984603
						],
						[
							-0.190237501936477,
							51.4987422
						],
						[
							-0.190473158526055,
							51.498907807008486
						],
						[
							-0.190610041473945,
							51.499004352900634
						],
						[
							-0.190807327326551,
							51.49915435111334
						],
						[
							-0.1909675,
							51.4992622
						],
						[
							-0.1917412,
							51.5000091
						],
						[
							-0.1921995,
							51.5004558
						],
						[
							-0.1923042,
							51.5005537
						],
						[
							-0.192646625881619,
							51.500926538073465
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.192672473624642,
							51.50095656242031
						],
						[
							-0.193223,
							51.501639
						],
						[
							-0.1934199,
							51.5020038
						],
						[
							-0.1936652,
							51.5024602
						],
						[
							-0.1939675,
							51.5031803
						],
						[
							-0.1942948,
							51.5039943
						],
						[
							-0.1943242,
							51.5040691
						],
						[
							-0.195196,
							51.5063828
						],
						[
							-0.1953392,
							51.5067578
						],
						[
							-0.1957236,
							51.5078135
						],
						[
							-0.1958171,
							51.5080901
						],
						[
							-0.1959239,
							51.5084386
						],
						[
							-0.1959923,
							51.5086212
						],
						[
							-0.1960391,
							51.508721
						],
						[
							-0.1960681,
							51.5088132
						],
						[
							-0.1960842,
							51.5089394
						],
						[
							-0.1960883,
							51.5090662
						],
						[
							-0.196093735982895,
							51.50917992881233
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.196091341614896,
							51.50921924904004
						],
						[
							-0.1960493,
							51.5094641
						],
						[
							-0.19602,
							51.5096069
						],
						[
							-0.1959655,
							51.5098067
						],
						[
							-0.1959124,
							51.5099589
						],
						[
							-0.1958279,
							51.510126
						],
						[
							-0.1957318,
							51.5102711
						],
						[
							-0.1955657,
							51.5104928
						],
						[
							-0.195336,
							51.5107612
						],
						[
							-0.1950511,
							51.5109764
						],
						[
							-0.1948462,
							51.5111084
						],
						[
							-0.1945569,
							51.5112628
						],
						[
							-0.1941338,
							51.5114329
						],
						[
							-0.1938118,
							51.5115395
						],
						[
							-0.19344,
							51.5116278
						],
						[
							-0.1929337,
							51.5117397
						],
						[
							-0.1924714,
							51.5118324
						],
						[
							-0.1918411,
							51.5119414
						],
						[
							-0.1917287,
							51.5119563
						],
						[
							-0.190748,
							51.5120671
						],
						[
							-0.1902489,
							51.5121481
						],
						[
							-0.1898121,
							51.5121905
						],
						[
							-0.1896406,
							51.5121991
						],
						[
							-0.1891258,
							51.512239
						],
						[
							-0.188251,
							51.5123038
						],
						[
							-0.187856679099524,
							51.51233160528928
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.187817041966806,
							51.51233389634313
						],
						[
							-0.186532,
							51.5123919
						],
						[
							-0.1853708,
							51.5124683
						],
						[
							-0.184267,
							51.5125559
						],
						[
							-0.1838675,
							51.5125905
						],
						[
							-0.1836237,
							51.5126111
						],
						[
							-0.1830302,
							51.5126658
						],
						[
							-0.1825852,
							51.5127172
						],
						[
							-0.1821442,
							51.512778
						],
						[
							-0.1817416,
							51.5128419
						],
						[
							-0.1814491,
							51.5128949
						],
						[
							-0.1812915,
							51.5129275
						],
						[
							-0.1808662,
							51.5130155
						],
						[
							-0.1803038,
							51.5132021
						],
						[
							-0.1795998,
							51.5134381
						],
						[
							-0.1789017,
							51.5137738
						],
						[
							-0.1770466,
							51.5147751
						],
						[
							-0.176664,
							51.5149099
						],
						[
							-0.1760753,
							51.5150999
						],
						[
							-0.1759511,
							51.5151698
						],
						[
							-0.1754622,
							51.5154494
						],
						[
							-0.1749144,
							51.5157429
						],
						[
							-0.174508368787198,
							51.51594592539991
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.174473309178402,
							51.515964139863556
						],
						[
							-0.173853,
							51.5162987
						],
						[
							-0.1722437,
							51.5172
						],
						[
							-0.1719557,
							51.5173817
						],
						[
							-0.1717823,
							51.5174999
						],
						[
							-0.171697,
							51.5176056
						],
						[
							-0.1713672,
							51.5179298
						],
						[
							-0.1712567,
							51.5180278
						],
						[
							-0.1709116,
							51.5182099
						],
						[
							-0.170775297662297,
							51.518284431098486
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.170775297662297,
							51.518284431098486
						],
						[
							-0.1707503,
							51.5182981
						],
						[
							-0.1701444,
							51.5186345
						],
						[
							-0.1690089,
							51.5192757
						],
						[
							-0.168785,
							51.5194021
						],
						[
							-0.168382,
							51.5196233
						],
						[
							-0.1681064713195,
							51.51972463311771
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.185816587713736,
							51.49493892975367
						],
						[
							-0.186238781011414,
							51.49507819333258
						],
						[
							-0.1864715,
							51.4951955
						],
						[
							-0.186973,
							51.4955033
						],
						[
							-0.1873742,
							51.4958794
						],
						[
							-0.1875452,
							51.4960541
						],
						[
							-0.1875921,
							51.496102
						],
						[
							-0.1879601,
							51.4965729
						],
						[
							-0.188354989016827,
							51.49713766425821
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.172170102799241,
							51.4940132
						],
						[
							-0.170652986171434,
							51.49403306705485
						],
						[
							-0.1692604,
							51.4939813
						],
						[
							-0.1676428,
							51.4939075
						],
						[
							-0.1674282,
							51.4938956
						],
						[
							-0.1638142,
							51.4937393
						],
						[
							-0.163225,
							51.493707
						],
						[
							-0.1613555,
							51.4936338
						],
						[
							-0.1596217,
							51.4933666
						],
						[
							-0.1576303,
							51.4927208
						],
						[
							-0.1571649,
							51.4925696
						],
						[
							-0.1568969,
							51.4924768
						],
						[
							-0.156545044410935,
							51.4923549271712
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.156507157650075,
							51.492342353635365
						],
						[
							-0.1560371,
							51.4921931
						],
						[
							-0.1553069,
							51.4920522
						],
						[
							-0.1550163,
							51.4920105
						],
						[
							-0.1544085,
							51.4919066
						],
						[
							-0.1540012,
							51.4918819
						],
						[
							-0.1532246,
							51.491912
						],
						[
							-0.1526144,
							51.4919426
						],
						[
							-0.1522668,
							51.4919588
						],
						[
							-0.1519472,
							51.4919863
						],
						[
							-0.1516595,
							51.4920231
						],
						[
							-0.1513991,
							51.4920759
						],
						[
							-0.151146,
							51.4921388
						],
						[
							-0.1509295,
							51.4922025
						],
						[
							-0.1503327,
							51.4924039
						],
						[
							-0.1500887,
							51.4924887
						],
						[
							-0.1493608,
							51.4927423
						],
						[
							-0.1491276,
							51.4928324
						],
						[
							-0.1489055,
							51.4929447
						],
						[
							-0.1486996,
							51.4930695
						],
						[
							-0.1485518,
							51.4931829
						],
						[
							-0.1483886,
							51.493346
						],
						[
							-0.1472706,
							51.494756
						],
						[
							-0.1464929,
							51.4956708
						],
						[
							-0.1463378,
							51.4958146
						],
						[
							-0.1461558,
							51.495933
						],
						[
							-0.1460038,
							51.4960144
						],
						[
							-0.1457988,
							51.4961037
						],
						[
							-0.1456016,
							51.4961726
						],
						[
							-0.1454032,
							51.4962296
						],
						[
							-0.1451669,
							51.4962849
						],
						[
							-0.1449187,
							51.4963241
						],
						[
							-0.1447271,
							51.4963397
						],
						[
							-0.1446109,
							51.4963445
						],
						[
							-0.1444669,
							51.4963463
						],
						[
							-0.1442658,
							51.4963449
						],
						[
							-0.1440897,
							51.4963394
						],
						[
							-0.1438828,
							51.4963337
						],
						[
							-0.1437442,
							51.4963336
						],
						[
							-0.1436084,
							51.4963355
						],
						[
							-0.143453,
							51.4963432
						],
						[
							-0.1433095,
							51.4963527
						],
						[
							-0.143249272949739,
							51.496357066461144
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.143209881432058,
							51.496360908267306
						],
						[
							-0.1429957,
							51.4963872
						],
						[
							-0.1423022,
							51.4965199
						],
						[
							-0.1415213,
							51.4966727
						],
						[
							-0.1409544,
							51.4968194
						],
						[
							-0.1402203,
							51.4970904
						],
						[
							-0.1399551,
							51.497176
						],
						[
							-0.1397045,
							51.4972477
						],
						[
							-0.1393311,
							51.4973681
						],
						[
							-0.1391757,
							51.4974021
						],
						[
							-0.1390309,
							51.4974362
						],
						[
							-0.138868,
							51.4974753
						],
						[
							-0.1387089,
							51.4975156
						],
						[
							-0.1385791,
							51.4975538
						],
						[
							-0.1384535,
							51.4975892
						],
						[
							-0.1382924,
							51.4976367
						],
						[
							-0.138133,
							51.497692
						],
						[
							-0.1379823,
							51.4977477
						],
						[
							-0.13785,
							51.4978013
						],
						[
							-0.1377414,
							51.4978484
						],
						[
							-0.137312,
							51.4980494
						],
						[
							-0.1366094,
							51.4984014
						],
						[
							-0.1360515,
							51.4986953
						],
						[
							-0.1353464,
							51.4990403
						],
						[
							-0.1343383,
							51.4994128
						],
						[
							-0.133554943537178,
							51.499607223881625
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.13351632965304,
							51.49961597547276
						],
						[
							-0.1333676,
							51.4996465
						],
						[
							-0.1327429,
							51.4997337
						],
						[
							-0.1321161,
							51.4997694
						],
						[
							-0.1315488,
							51.4997593
						],
						[
							-0.130311,
							51.4997487
						],
						[
							-0.1296895,
							51.4997663
						],
						[
							-0.1290316,
							51.4998235
						],
						[
							-0.1284442,
							51.499921
						],
						[
							-0.1281299,
							51.4999985
						],
						[
							-0.1279096,
							51.5000636
						],
						[
							-0.1276435,
							51.500167
						],
						[
							-0.125974637191878,
							51.50086984113243
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.125938401082978,
							51.5008857465846
						],
						[
							-0.1252269,
							51.5012109
						],
						[
							-0.1247357,
							51.5015014
						],
						[
							-0.1244316,
							51.501734
						],
						[
							-0.1242202,
							51.501947
						],
						[
							-0.1240757,
							51.5021861
						],
						[
							-0.1239444,
							51.5025487
						],
						[
							-0.1234937,
							51.5041583
						],
						[
							-0.1233162,
							51.5048563
						],
						[
							-0.1231603,
							51.5053746
						],
						[
							-0.1229762,
							51.5059697
						],
						[
							-0.1228544,
							51.5063936
						],
						[
							-0.122584683082093,
							51.50699594836526
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.122564435351548,
							51.5070297713965
						],
						[
							-0.1221088,
							51.5076205
						],
						[
							-0.1218702,
							51.5078355
						],
						[
							-0.1216534,
							51.5080602
						],
						[
							-0.1214973,
							51.5082517
						],
						[
							-0.121308,
							51.5083976
						],
						[
							-0.1208785,
							51.5086376
						],
						[
							-0.1204941,
							51.5088948
						],
						[
							-0.1200543,
							51.5091826
						],
						[
							-0.1195715,
							51.509446
						],
						[
							-0.1189976,
							51.5097277
						],
						[
							-0.1185383,
							51.5098931
						],
						[
							-0.1163904,
							51.5105492
						],
						[
							-0.1156501,
							51.5107496
						],
						[
							-0.1152692,
							51.5108297
						],
						[
							-0.1148135,
							51.510911
						],
						[
							-0.113769474772607,
							51.51105848384477
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.113730272131473,
							51.51106351817051
						],
						[
							-0.1132938,
							51.5111112
						],
						[
							-0.1129557,
							51.5111368
						],
						[
							-0.1124117,
							51.5111646
						],
						[
							-0.1118538,
							51.511187
						],
						[
							-0.1109627,
							51.5112024
						],
						[
							-0.1084796,
							51.5112084
						],
						[
							-0.1069587,
							51.511209
						],
						[
							-0.1056896,
							51.5112168
						],
						[
							-0.1054053,
							51.5112308
						],
						[
							-0.1050995,
							51.5112676
						],
						[
							-0.1047991,
							51.5113311
						],
						[
							-0.1045952,
							51.5113796
						],
						[
							-0.1043267,
							51.5114395
						],
						[
							-0.103159102999755,
							51.51176843656334
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.10312084105735,
							51.51177941022711
						],
						[
							-0.1025498,
							51.5119464
						],
						[
							-0.1022681,
							51.5120056
						],
						[
							-0.1020422,
							51.5120498
						],
						[
							-0.1019203,
							51.5120679
						],
						[
							-0.1016948,
							51.5120786
						],
						[
							-0.1014622,
							51.5120745
						],
						[
							-0.100916,
							51.5120414
						],
						[
							-0.0989414,
							51.5119219
						],
						[
							-0.0983237,
							51.511882
						],
						[
							-0.0973121,
							51.5118414
						],
						[
							-0.0970253,
							51.5118403
						],
						[
							-0.0962666,
							51.511856
						],
						[
							-0.0945759,
							51.511974
						],
						[
							-0.093773858193849,
							51.51191202670395
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.093734552431181,
							51.511907643461946
						],
						[
							-0.0927379,
							51.511762
						],
						[
							-0.0921216,
							51.5116643
						],
						[
							-0.0910277,
							51.511462
						],
						[
							-0.0907398,
							51.5114197
						],
						[
							-0.0905353,
							51.5114008
						],
						[
							-0.090343059118033,
							51.51139218949373
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.090303220756978,
							51.5113914310544
						],
						[
							-0.0900183,
							51.5113933
						],
						[
							-0.0898321,
							51.5114038
						],
						[
							-0.0896359,
							51.5114091
						],
						[
							-0.0894695,
							51.5114007
						],
						[
							-0.0892096,
							51.5113758
						],
						[
							-0.0883782,
							51.5112141
						],
						[
							-0.0874619,
							51.5110386
						],
						[
							-0.0872105,
							51.51099
						],
						[
							-0.0869856,
							51.51092
						],
						[
							-0.0868333,
							51.5108502
						],
						[
							-0.086677,
							51.5107765
						],
						[
							-0.086495731299294,
							51.51073075338525
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.086457119669111,
							51.51072198756322
						],
						[
							-0.0861035,
							51.5106506
						],
						[
							-0.0857332,
							51.5105997
						],
						[
							-0.0854708,
							51.5105831
						],
						[
							-0.0851716,
							51.5105807
						],
						[
							-0.0843205,
							51.5106333
						],
						[
							-0.0840808,
							51.5106334
						],
						[
							-0.0838356,
							51.5106302
						],
						[
							-0.0836248,
							51.5106159
						],
						[
							-0.0831535,
							51.5105579
						],
						[
							-0.0826885,
							51.5104458
						],
						[
							-0.0815989,
							51.5100769
						],
						[
							-0.0804517,
							51.5096775
						],
						[
							-0.0804008,
							51.509664
						],
						[
							-0.0803466,
							51.5096572
						],
						[
							-0.0796337,
							51.5096459
						],
						[
							-0.0790122,
							51.5096115
						],
						[
							-0.078665,
							51.5095869
						],
						[
							-0.0784592,
							51.5095854
						],
						[
							-0.0783151,
							51.5096142
						],
						[
							-0.0781999,
							51.5096699
						],
						[
							-0.077918,
							51.509777
						],
						[
							-0.077769867046615,
							51.509807897815065
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.077730682231053,
							51.50981304468704
						],
						[
							-0.0766367,
							51.5098716
						],
						[
							-0.0765111,
							51.5098931
						],
						[
							-0.0763933,
							51.5099267
						],
						[
							-0.0762319,
							51.5099944
						],
						[
							-0.0761323,
							51.5100441
						],
						[
							-0.0760139,
							51.5101343
						],
						[
							-0.0758734,
							51.5102604
						],
						[
							-0.0757731,
							51.51036
						],
						[
							-0.0754608,
							51.510697
						],
						[
							-0.0753046,
							51.5111095
						],
						[
							-0.0753659,
							51.511398
						],
						[
							-0.0755825,
							51.5123736
						],
						[
							-0.075579,
							51.5128571
						],
						[
							-0.0754317,
							51.5133864
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.0754317,
							51.5133864
						],
						[
							-0.0754098,
							51.513505
						],
						[
							-0.0754053,
							51.5135718
						],
						[
							-0.0754068,
							51.5136273
						],
						[
							-0.0754174,
							51.513675
						],
						[
							-0.0754407,
							51.5137275
						],
						[
							-0.075489,
							51.513807
						],
						[
							-0.0755528,
							51.5139
						],
						[
							-0.0756325,
							51.5139997
						],
						[
							-0.075702688513158,
							51.514081375441904
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.0764812,
							51.5149196
						],
						[
							-0.0762738,
							51.5147561
						],
						[
							-0.0761757,
							51.5146854
						],
						[
							-0.0761233,
							51.514642
						],
						[
							-0.0760636,
							51.5145588
						],
						[
							-0.0760377,
							51.5145165
						],
						[
							-0.0760241,
							51.5144491
						],
						[
							-0.0759997,
							51.5144032
						],
						[
							-0.0759637,
							51.5143622
						],
						[
							-0.075729086928874,
							51.51411084911606
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.155492525346621,
							51.522504116908024
						],
						[
							-0.1533537,
							51.5228684
						],
						[
							-0.1523934,
							51.5230468
						],
						[
							-0.1511804,
							51.5231119
						],
						[
							-0.150532,
							51.5231427
						],
						[
							-0.1489664,
							51.5233223
						],
						[
							-0.1459327,
							51.523848
						],
						[
							-0.1455211,
							51.5239011
						],
						[
							-0.1449549,
							51.5239103
						],
						[
							-0.1444584,
							51.5238667
						],
						[
							-0.1441956,
							51.5238502
						],
						[
							-0.143940745527829,
							51.523837675298964
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.143901016817776,
							51.52383743755832
						],
						[
							-0.143406,
							51.5238558
						],
						[
							-0.1429079,
							51.5239112
						],
						[
							-0.1425187,
							51.5239807
						],
						[
							-0.1418167,
							51.5241334
						],
						[
							-0.1393864,
							51.5246434
						],
						[
							-0.1367951,
							51.5253289
						],
						[
							-0.1361146,
							51.5255435
						],
						[
							-0.135553161865739,
							51.52567933960369
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.135515045005948,
							51.525690506400686
						],
						[
							-0.1312815,
							51.5271514
						],
						[
							-0.1297425,
							51.5277042
						],
						[
							-0.1273714,
							51.5286265
						],
						[
							-0.1265845,
							51.5289391
						],
						[
							-0.1263801,
							51.5290327
						],
						[
							-0.1261333,
							51.5291794
						],
						[
							-0.1258254,
							51.5293319
						],
						[
							-0.1253003,
							51.5295341
						],
						[
							-0.1246817,
							51.5297729
						],
						[
							-0.124271529667222,
							51.529942123067485
						],
						[
							-0.123952866715,
							51.53006660779597
						],
						[
							-0.123700948191111,
							51.53017822300185
						],
						[
							-0.123580648191111,
							51.53026673066885
						],
						[
							-0.123444651953889,
							51.53037303804771
						],
						[
							-0.123110192764445,
							51.53054120675818
						],
						[
							-0.122962160202241,
							51.53059771503784
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.12292379472707,
							51.53060602945845
						],
						[
							-0.122353407380556,
							51.53064143758187
						],
						[
							-0.122095474095556,
							51.53064970729707
						],
						[
							-0.121954477858333,
							51.53065289218352
						],
						[
							-0.1217474,
							51.5306533
						],
						[
							-0.1216103,
							51.5306604
						],
						[
							-0.1214735,
							51.5306593
						],
						[
							-0.1213795,
							51.530655
						],
						[
							-0.1212348,
							51.5306403
						],
						[
							-0.1210148,
							51.5306141
						],
						[
							-0.1207905,
							51.5305706
						],
						[
							-0.1205329,
							51.5305173
						],
						[
							-0.1200043,
							51.5303915
						],
						[
							-0.1197513,
							51.5303313
						],
						[
							-0.1196105,
							51.5302924
						],
						[
							-0.1194049,
							51.5302272
						],
						[
							-0.1191815,
							51.5301543
						],
						[
							-0.1190099,
							51.5300977
						],
						[
							-0.1188235,
							51.5300139
						],
						[
							-0.1186771,
							51.5299356
						],
						[
							-0.1185085,
							51.5298479
						],
						[
							-0.1177164,
							51.5293958
						],
						[
							-0.1170423,
							51.5289971
						],
						[
							-0.1161271,
							51.528482
						],
						[
							-0.1156745,
							51.528204
						],
						[
							-0.1150026,
							51.527804
						],
						[
							-0.1145395,
							51.5275097
						],
						[
							-0.1134928,
							51.5268723
						],
						[
							-0.1126765,
							51.5262604
						],
						[
							-0.1114689,
							51.5255569
						],
						[
							-0.1106337,
							51.5250381
						],
						[
							-0.108462,
							51.5235977
						],
						[
							-0.1078712,
							51.5232567
						],
						[
							-0.1074259,
							51.5229345
						],
						[
							-0.107084,
							51.5226981
						],
						[
							-0.1067882,
							51.5225357
						],
						[
							-0.1066583,
							51.5224465
						],
						[
							-0.1065368,
							51.5223312
						],
						[
							-0.1063974,
							51.5221629
						],
						[
							-0.1063,
							51.5220185
						],
						[
							-0.1059965,
							51.5216826
						],
						[
							-0.1055977,
							51.5212173
						],
						[
							-0.1054429,
							51.5210751
						],
						[
							-0.1053096,
							51.5209386
						],
						[
							-0.1052104,
							51.5208087
						],
						[
							-0.1050621,
							51.5205945
						],
						[
							-0.1049786,
							51.52049
						],
						[
							-0.1049105,
							51.5204203
						],
						[
							-0.1047893,
							51.5203103
						],
						[
							-0.104680917695698,
							51.52021971834924
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.104649478524547,
							51.52019532502805
						],
						[
							-0.1045501,
							51.5201238
						],
						[
							-0.1044427,
							51.5200569
						],
						[
							-0.1043486,
							51.5200065
						],
						[
							-0.1041686,
							51.5199284
						],
						[
							-0.1039902,
							51.5198458
						],
						[
							-0.1037526,
							51.5197716
						],
						[
							-0.1034739,
							51.5197064
						],
						[
							-0.1032029,
							51.5196526
						],
						[
							-0.102968,
							51.5196223
						],
						[
							-0.1028153,
							51.5196094
						],
						[
							-0.1026523,
							51.519611
						],
						[
							-0.1023661,
							51.5196126
						],
						[
							-0.1019871,
							51.5196558
						],
						[
							-0.1015112,
							51.5197588
						],
						[
							-0.1007599,
							51.5199783
						],
						[
							-0.1001275,
							51.5200953
						],
						[
							-0.0995453,
							51.5201443
						],
						[
							-0.097812273048081,
							51.520214198457126
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.097772459123226,
							51.52021537328936
						],
						[
							-0.0976268,
							51.5202181
						],
						[
							-0.0972497,
							51.5201997
						],
						[
							-0.0967731,
							51.5201377
						],
						[
							-0.0964925,
							51.5200721
						],
						[
							-0.0905652,
							51.5186174
						],
						[
							-0.0891116,
							51.5182848
						],
						[
							-0.0886996,
							51.5181926
						],
						[
							-0.088309574207228,
							51.518123238006986
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.088270496098424,
							51.51811741741505
						],
						[
							-0.088149,
							51.5181028
						],
						[
							-0.0843534,
							51.5175
						],
						[
							-0.0840764,
							51.5174568
						],
						[
							-0.0838724,
							51.5174272
						],
						[
							-0.083232432479493,
							51.517303777976274
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.083193497910662,
							51.51729705110862
						],
						[
							-0.0818196,
							51.5170871
						],
						[
							-0.0809042,
							51.5169054
						],
						[
							-0.0792088,
							51.5163741
						],
						[
							-0.0786992,
							51.5161805
						],
						[
							-0.0771818,
							51.5153382
						],
						[
							-0.076881,
							51.515143
						],
						[
							-0.0764812,
							51.5149196
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.16806899706272,
							51.51973810856785
						],
						[
							-0.1661795,
							51.5204022
						],
						[
							-0.1656381,
							51.5205881
						],
						[
							-0.1656027,
							51.5206009
						],
						[
							-0.1653598,
							51.5206599
						],
						[
							-0.1650035,
							51.5207522
						],
						[
							-0.1636888,
							51.5209755
						],
						[
							-0.1605919,
							51.5215638
						],
						[
							-0.1574923,
							51.5221531
						],
						[
							-0.1566779,
							51.5222984
						],
						[
							-0.1558488,
							51.5224417
						],
						[
							-0.155531459180359,
							51.52249738539918
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.225086952607429,
							51.49411566076714
						],
						[
							-0.2252555,
							51.4949163
						],
						[
							-0.225488,
							51.4961213
						],
						[
							-0.2255775,
							51.496585
						],
						[
							-0.2256142,
							51.4967693
						],
						[
							-0.2257437,
							51.4974192
						],
						[
							-0.2259091,
							51.498243
						],
						[
							-0.2259529,
							51.4984725
						],
						[
							-0.2259631,
							51.4985226
						],
						[
							-0.2259994,
							51.4987
						],
						[
							-0.2260808,
							51.4990807
						],
						[
							-0.2262667,
							51.4999085
						],
						[
							-0.2266027,
							51.5015751
						],
						[
							-0.226670275720096,
							51.501927289867986
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.226677809730109,
							51.501966096598025
						],
						[
							-0.2267386,
							51.5022756
						],
						[
							-0.2267751,
							51.5024753
						],
						[
							-0.2267939,
							51.5026173
						],
						[
							-0.2268074,
							51.5027839
						],
						[
							-0.2268264,
							51.5030925
						],
						[
							-0.2268113,
							51.5035308
						],
						[
							-0.226761,
							51.5039619
						],
						[
							-0.2267299,
							51.5041492
						],
						[
							-0.2266921,
							51.5043223
						],
						[
							-0.2266118,
							51.5046886
						],
						[
							-0.2265487,
							51.5049974
						],
						[
							-0.2264743,
							51.5053051
						],
						[
							-0.2264276,
							51.5055468
						],
						[
							-0.226386566432561,
							51.50577704902898
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.226378818502893,
							51.505815821877476
						],
						[
							-0.2262511,
							51.5063922
						],
						[
							-0.2261149,
							51.5069966
						],
						[
							-0.2260466,
							51.5072947
						],
						[
							-0.2259522,
							51.5075905
						],
						[
							-0.2257881,
							51.5079564
						],
						[
							-0.2257361,
							51.5080593
						],
						[
							-0.2256473,
							51.5082105
						],
						[
							-0.225569,
							51.5083388
						],
						[
							-0.2254406,
							51.5085282
						],
						[
							-0.2253031,
							51.5087066
						],
						[
							-0.2251704,
							51.5088704
						],
						[
							-0.2250134,
							51.5090292
						],
						[
							-0.224869,
							51.5091775
						],
						[
							-0.2247139,
							51.5093156
						],
						[
							-0.224554,
							51.5094556
						],
						[
							-0.2244008,
							51.5095876
						],
						[
							-0.2242419,
							51.5097246
						],
						[
							-0.2239966,
							51.509891
						],
						[
							-0.223728405664838,
							51.51006317530327
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.223694651462026,
							51.51008395177041
						],
						[
							-0.2233964,
							51.5102598
						],
						[
							-0.2230638,
							51.5104407
						],
						[
							-0.2226427,
							51.5106437
						],
						[
							-0.2223745,
							51.5107624
						],
						[
							-0.2221861,
							51.5108362
						],
						[
							-0.2217748,
							51.5109987
						],
						[
							-0.2214456,
							51.511127
						],
						[
							-0.2211046,
							51.5112773
						],
						[
							-0.2207242,
							51.5114628
						],
						[
							-0.2205035,
							51.5115703
						],
						[
							-0.2203423,
							51.5116594
						],
						[
							-0.2200498,
							51.5118305
						],
						[
							-0.2198147,
							51.5119789
						],
						[
							-0.2195988,
							51.5121333
						],
						[
							-0.2193983,
							51.5122882
						],
						[
							-0.218638,
							51.5128541
						],
						[
							-0.2182117,
							51.5131685
						],
						[
							-0.217925416803874,
							51.51338468075895
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.217893496688208,
							51.51340863273316
						],
						[
							-0.2176858,
							51.5135635
						],
						[
							-0.2174181,
							51.5137622
						],
						[
							-0.2171551,
							51.5139578
						],
						[
							-0.2166121,
							51.5143585
						],
						[
							-0.2163619,
							51.5145598
						],
						[
							-0.2161522,
							51.5147154
						],
						[
							-0.2159299,
							51.5148829
						],
						[
							-0.2153832,
							51.515284
						],
						[
							-0.2151132,
							51.5154861
						],
						[
							-0.2149049,
							51.5156409
						],
						[
							-0.2147079,
							51.5157867
						],
						[
							-0.214508,
							51.5159148
						],
						[
							-0.2143175,
							51.5160363
						],
						[
							-0.2141846,
							51.5161143
						],
						[
							-0.2140697,
							51.5161786
						],
						[
							-0.213859,
							51.5162926
						],
						[
							-0.213589,
							51.5164321
						],
						[
							-0.2132831,
							51.5165745
						],
						[
							-0.212857,
							51.516752
						],
						[
							-0.2126021,
							51.5168471
						],
						[
							-0.2123595,
							51.5169272
						],
						[
							-0.2121146,
							51.5169952
						],
						[
							-0.2119428,
							51.5170441
						],
						[
							-0.2117426,
							51.517094
						],
						[
							-0.2113369,
							51.5171823
						],
						[
							-0.210961534325825,
							51.51726389709867
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.210922916952829,
							51.51727262666201
						],
						[
							-0.2104357,
							51.517387
						],
						[
							-0.2102325,
							51.5174337
						],
						[
							-0.2098942,
							51.5175043
						],
						[
							-0.2093275,
							51.5176378
						],
						[
							-0.2088998,
							51.5177546
						],
						[
							-0.2085226,
							51.5178613
						],
						[
							-0.2083001,
							51.5179342
						],
						[
							-0.2081377,
							51.5179879
						],
						[
							-0.208001,
							51.5180396
						],
						[
							-0.2077436,
							51.5181465
						],
						[
							-0.2075092,
							51.5182514
						],
						[
							-0.2073667,
							51.5183227
						],
						[
							-0.2072214,
							51.5183957
						],
						[
							-0.2070198,
							51.5185016
						],
						[
							-0.2068347,
							51.5186071
						],
						[
							-0.2066527,
							51.5187154
						],
						[
							-0.2060856,
							51.5191172
						],
						[
							-0.2058217,
							51.5193291
						],
						[
							-0.2055497,
							51.5195429
						],
						[
							-0.2051377,
							51.5198784
						],
						[
							-0.204915,
							51.5200311
						],
						[
							-0.2046982,
							51.5201812
						],
						[
							-0.2045154,
							51.5203014
						],
						[
							-0.2043133,
							51.5204074
						],
						[
							-0.2039199,
							51.520594
						],
						[
							-0.2036759,
							51.5206754
						],
						[
							-0.2034007,
							51.5207568
						],
						[
							-0.2032078,
							51.5208119
						],
						[
							-0.2029384,
							51.5208703
						],
						[
							-0.2027239,
							51.5209049
						],
						[
							-0.2025246,
							51.5209244
						],
						[
							-0.2022819,
							51.5209475
						],
						[
							-0.2021084,
							51.5209544
						],
						[
							-0.2018707,
							51.5209594
						],
						[
							-0.2016877,
							51.5209548
						],
						[
							-0.201528392749563,
							51.520949377152746
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.201488762280151,
							51.52094704402844
						],
						[
							-0.2013464,
							51.5209351
						],
						[
							-0.2011771,
							51.5209168
						],
						[
							-0.2009628,
							51.5208909
						],
						[
							-0.2003173,
							51.5207579
						],
						[
							-0.1994297,
							51.5205454
						],
						[
							-0.1985761,
							51.5203248
						],
						[
							-0.197529,
							51.5200939
						],
						[
							-0.1963203,
							51.5198461
						],
						[
							-0.1958676,
							51.519755
						],
						[
							-0.195609,
							51.5197149
						],
						[
							-0.1952063,
							51.5196762
						],
						[
							-0.1942156,
							51.5196352
						],
						[
							-0.1935242,
							51.5195957
						],
						[
							-0.1930607,
							51.5195423
						],
						[
							-0.1902474,
							51.5192633
						],
						[
							-0.1886404,
							51.5191257
						],
						[
							-0.188008728045329,
							51.51906465712523
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.187969331047705,
							51.51906084991806
						],
						[
							-0.1868771,
							51.5189553
						],
						[
							-0.1867571,
							51.5189521
						],
						[
							-0.1863694,
							51.5189301
						],
						[
							-0.1859669,
							51.5189013
						],
						[
							-0.1856584,
							51.5188816
						],
						[
							-0.1854267,
							51.5188763
						],
						[
							-0.1851121,
							51.5188778
						],
						[
							-0.1847158,
							51.5188823
						],
						[
							-0.1843963,
							51.518877
						],
						[
							-0.1838195,
							51.5188581
						],
						[
							-0.1835097,
							51.5188345
						],
						[
							-0.1830268,
							51.5187882
						],
						[
							-0.181123942398223,
							51.51866030526168
						],
						[
							-0.180217263744531,
							51.51865827604148
						],
						[
							-0.17920755522877,
							51.51863457475454
						],
						[
							-0.178399437735794,
							51.51846295985478
						],
						[
							-0.177903849867763,
							51.5182520444855
						],
						[
							-0.177042499416766,
							51.51771090710539
						],
						[
							-0.1767,
							51.5175
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.1767,
							51.5175
						],
						[
							-0.176383568251966,
							51.51734704326364
						],
						[
							-0.176075034706559,
							51.51727863732075
						],
						[
							-0.175726873929971,
							51.51730376474049
						],
						[
							-0.174969903966407,
							51.517389733224086
						],
						[
							-0.174555167528679,
							51.51743886049032
						],
						[
							-0.173892190675023,
							51.51759473872599
						],
						[
							-0.1712322,
							51.5181784
						],
						[
							-0.1707503,
							51.5182981
						]
					]
				}
			}
		]
	},
	{
		name: "Metropolitan",
		lineColor: "#75003d",
		features: [
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.478392812086747,
							51.54644142683732
						],
						[
							-0.4772954,
							51.5468704
						],
						[
							-0.476757,
							51.5470562
						],
						[
							-0.4762277,
							51.5472222
						],
						[
							-0.4753784,
							51.5474929
						],
						[
							-0.474746,
							51.5476853
						],
						[
							-0.4742031,
							51.5478173
						],
						[
							-0.4735676,
							51.5479314
						],
						[
							-0.4731142,
							51.5479965
						],
						[
							-0.473093,
							51.5479987
						],
						[
							-0.4726122,
							51.5480493
						],
						[
							-0.4718721,
							51.5480672
						],
						[
							-0.470947,
							51.5480644
						],
						[
							-0.4700021,
							51.5480719
						],
						[
							-0.4694774,
							51.5480974
						],
						[
							-0.4689708,
							51.5481502
						],
						[
							-0.4684931,
							51.548221
						],
						[
							-0.4683883,
							51.54824
						],
						[
							-0.4676878,
							51.5483671
						],
						[
							-0.4670144,
							51.5485114
						],
						[
							-0.466332,
							51.5486736
						],
						[
							-0.4657147,
							51.5488698
						],
						[
							-0.4654326,
							51.5489471
						],
						[
							-0.4649685,
							51.5490877
						],
						[
							-0.4644362,
							51.5492565
						],
						[
							-0.463546,
							51.5495337
						],
						[
							-0.4627649,
							51.5497714
						],
						[
							-0.4617715,
							51.5500854
						],
						[
							-0.4610709,
							51.5503052
						],
						[
							-0.4607903,
							51.5503938
						],
						[
							-0.4601761,
							51.5505853
						],
						[
							-0.4597181,
							51.5507352
						],
						[
							-0.4595998,
							51.5507691
						],
						[
							-0.4593101,
							51.5508644
						],
						[
							-0.4586125,
							51.5510879
						],
						[
							-0.4577253,
							51.5513576
						],
						[
							-0.4571247,
							51.5515528
						],
						[
							-0.4564362,
							51.5517612
						],
						[
							-0.4558447,
							51.5519451
						],
						[
							-0.4550773,
							51.5521894
						],
						[
							-0.4543175,
							51.5524336
						],
						[
							-0.4533893,
							51.5527203
						],
						[
							-0.4524278,
							51.5530239
						],
						[
							-0.4517014,
							51.5532521
						],
						[
							-0.4509628,
							51.5534794
						],
						[
							-0.450217429637587,
							51.55371937337283
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.450179371256143,
							51.55373163226993
						],
						[
							-0.4493643,
							51.5539943
						],
						[
							-0.448606,
							51.55428
						],
						[
							-0.4481328,
							51.5544912
						],
						[
							-0.4477021,
							51.5546789
						],
						[
							-0.4473336,
							51.5548722
						],
						[
							-0.4469286,
							51.5550957
						],
						[
							-0.4466829,
							51.5552569
						],
						[
							-0.4463523,
							51.5554747
						],
						[
							-0.4459535,
							51.5557614
						],
						[
							-0.4456532,
							51.556
						],
						[
							-0.4453317,
							51.5562923
						],
						[
							-0.4450602,
							51.5565459
						],
						[
							-0.4449009,
							51.5566949
						],
						[
							-0.4446825,
							51.5569372
						],
						[
							-0.4445324,
							51.5571418
						],
						[
							-0.4442837,
							51.5574992
						],
						[
							-0.4440577,
							51.5578651
						],
						[
							-0.4437059,
							51.5584911
						],
						[
							-0.4435299,
							51.5589249
						],
						[
							-0.4434526,
							51.5592011
						],
						[
							-0.4434025,
							51.5594595
						],
						[
							-0.4432933,
							51.5598206
						],
						[
							-0.443169,
							51.5601874
						],
						[
							-0.4430719,
							51.5604485
						],
						[
							-0.4429218,
							51.5607634
						],
						[
							-0.4427546,
							51.5609799
						],
						[
							-0.4426305,
							51.5611969
						],
						[
							-0.4424569,
							51.5614509
						],
						[
							-0.4422944,
							51.5616776
						],
						[
							-0.44206858763742,
							51.561956951592485
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.4420444053484,
							51.56198861144818
						],
						[
							-0.4418223,
							51.5622965
						],
						[
							-0.4415265,
							51.5625831
						],
						[
							-0.4413278,
							51.5627651
						],
						[
							-0.4410351,
							51.5630196
						],
						[
							-0.4407819,
							51.5632167
						],
						[
							-0.4404952,
							51.5634316
						],
						[
							-0.4400615,
							51.5637248
						],
						[
							-0.4396714,
							51.5639545
						],
						[
							-0.4392093,
							51.5642119
						],
						[
							-0.4385923,
							51.5645077
						],
						[
							-0.4379067,
							51.5647992
						],
						[
							-0.4372074,
							51.5650677
						],
						[
							-0.4364834,
							51.5653583
						],
						[
							-0.4359075,
							51.5655944
						],
						[
							-0.4352521,
							51.5658689
						],
						[
							-0.4345432,
							51.5661672
						],
						[
							-0.4342344,
							51.566297
						],
						[
							-0.4334422,
							51.56663
						],
						[
							-0.4326414,
							51.5669914
						],
						[
							-0.4317378,
							51.5673886
						],
						[
							-0.430981,
							51.5676997
						],
						[
							-0.4301596,
							51.5680474
						],
						[
							-0.429363,
							51.5684028
						],
						[
							-0.4286102,
							51.5687284
						],
						[
							-0.427985,
							51.5690079
						],
						[
							-0.4274036,
							51.5692602
						],
						[
							-0.4267386,
							51.5695474
						],
						[
							-0.4259639,
							51.5698798
						],
						[
							-0.4254497,
							51.570092
						],
						[
							-0.4250562,
							51.5702599
						],
						[
							-0.4243761,
							51.5705104
						],
						[
							-0.4238647,
							51.5706971
						],
						[
							-0.4233985,
							51.5708684
						],
						[
							-0.422994,
							51.5710116
						],
						[
							-0.4226101,
							51.5711377
						],
						[
							-0.421978,
							51.5712954
						],
						[
							-0.421647379633623,
							51.57136847957721
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.421608732624377,
							51.57137702202463
						],
						[
							-0.420918,
							51.5715297
						],
						[
							-0.4202887,
							51.571672
						],
						[
							-0.419865,
							51.5717539
						],
						[
							-0.4194235,
							51.571834
						],
						[
							-0.4188311,
							51.5719226
						],
						[
							-0.4177987,
							51.5721024
						],
						[
							-0.4164686,
							51.5723947
						],
						[
							-0.4150605,
							51.5727092
						],
						[
							-0.413895,
							51.5729691
						],
						[
							-0.4131587,
							51.5731361
						],
						[
							-0.412879,
							51.5732001
						],
						[
							-0.412407430780487,
							51.573303174714304
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.41236875515192,
							51.57331153646644
						],
						[
							-0.4109374,
							51.5736176
						],
						[
							-0.4092564,
							51.5740003
						],
						[
							-0.4076507,
							51.5743633
						],
						[
							-0.4063221,
							51.5746777
						],
						[
							-0.4050455,
							51.5749504
						],
						[
							-0.4034399,
							51.5753117
						],
						[
							-0.4023211,
							51.5755555
						],
						[
							-0.4008635,
							51.5758793
						],
						[
							-0.3999407,
							51.5760812
						],
						[
							-0.3993333,
							51.5762108
						],
						[
							-0.3986066,
							51.5763471
						],
						[
							-0.3978346,
							51.5764809
						],
						[
							-0.397094484442436,
							51.57660454489765
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.397055498718836,
							51.57661094878681
						],
						[
							-0.3965046,
							51.5766999
						],
						[
							-0.3958163,
							51.5767945
						],
						[
							-0.3952006,
							51.5768686
						],
						[
							-0.3943683,
							51.5769589
						],
						[
							-0.3936718,
							51.5770254
						],
						[
							-0.3930671,
							51.5770757
						],
						[
							-0.3924652,
							51.5771157
						],
						[
							-0.3919866,
							51.5771413
						],
						[
							-0.3912544,
							51.5771873
						],
						[
							-0.3907361,
							51.5772043
						],
						[
							-0.3903029,
							51.5772231
						],
						[
							-0.3894802,
							51.5772393
						],
						[
							-0.3889934,
							51.5772469
						],
						[
							-0.3881241,
							51.5772418
						],
						[
							-0.3874221,
							51.5772325
						],
						[
							-0.3868462,
							51.5772163
						],
						[
							-0.3863018,
							51.5772035
						],
						[
							-0.3856834,
							51.5771694
						],
						[
							-0.3851583,
							51.5771404
						],
						[
							-0.3846468,
							51.5771063
						],
						[
							-0.3838198,
							51.5770419
						],
						[
							-0.3833981,
							51.5770036
						],
						[
							-0.3825295,
							51.576916
						],
						[
							-0.3818024,
							51.5768268
						],
						[
							-0.3810752,
							51.576731
						],
						[
							-0.3803955,
							51.5766358
						],
						[
							-0.3795482,
							51.5765249
						],
						[
							-0.3787143,
							51.5764074
						],
						[
							-0.3780653,
							51.5763074
						],
						[
							-0.3774594,
							51.5762107
						],
						[
							-0.3767661,
							51.5760971
						],
						[
							-0.3761181,
							51.5760063
						],
						[
							-0.3756477,
							51.5759383
						],
						[
							-0.3750284,
							51.5758425
						],
						[
							-0.3744412,
							51.5757462
						],
						[
							-0.3738487,
							51.5756504
						],
						[
							-0.3733929,
							51.5755671
						],
						[
							-0.3729895,
							51.5754884
						],
						[
							-0.3728404,
							51.5754593
						],
						[
							-0.3719208,
							51.5753004
						],
						[
							-0.37130085917451,
							51.57519161463775
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.371261972812131,
							51.57518458340654
						],
						[
							-0.3708565,
							51.5751091
						],
						[
							-0.3702738,
							51.5750268
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.0764812,
							51.5149196
						],
						[
							-0.0762738,
							51.5147561
						],
						[
							-0.0761757,
							51.5146854
						],
						[
							-0.0761233,
							51.514642
						],
						[
							-0.0760636,
							51.5145588
						],
						[
							-0.0760377,
							51.5145165
						],
						[
							-0.0760241,
							51.5144491
						],
						[
							-0.0759997,
							51.5144032
						],
						[
							-0.0759637,
							51.5143622
						],
						[
							-0.075729086928874,
							51.51411084911606
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.155492525346621,
							51.522504116908024
						],
						[
							-0.1533537,
							51.5228684
						],
						[
							-0.1523934,
							51.5230468
						],
						[
							-0.1511804,
							51.5231119
						],
						[
							-0.150532,
							51.5231427
						],
						[
							-0.1489664,
							51.5233223
						],
						[
							-0.1459327,
							51.523848
						],
						[
							-0.1455211,
							51.5239011
						],
						[
							-0.1449549,
							51.5239103
						],
						[
							-0.1444584,
							51.5238667
						],
						[
							-0.1441956,
							51.5238502
						],
						[
							-0.143940745527829,
							51.523837675298964
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.143901016817776,
							51.52383743755832
						],
						[
							-0.143406,
							51.5238558
						],
						[
							-0.1429079,
							51.5239112
						],
						[
							-0.1425187,
							51.5239807
						],
						[
							-0.1418167,
							51.5241334
						],
						[
							-0.1393864,
							51.5246434
						],
						[
							-0.1367951,
							51.5253289
						],
						[
							-0.1361146,
							51.5255435
						],
						[
							-0.135553161865739,
							51.52567933960369
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.135515045005948,
							51.525690506400686
						],
						[
							-0.1312815,
							51.5271514
						],
						[
							-0.1297425,
							51.5277042
						],
						[
							-0.1273714,
							51.5286265
						],
						[
							-0.1265845,
							51.5289391
						],
						[
							-0.1263801,
							51.5290327
						],
						[
							-0.1261333,
							51.5291794
						],
						[
							-0.1258254,
							51.5293319
						],
						[
							-0.1253003,
							51.5295341
						],
						[
							-0.1246817,
							51.5297729
						],
						[
							-0.124271529667222,
							51.529942123067485
						],
						[
							-0.123952866715,
							51.53006660779597
						],
						[
							-0.123700948191111,
							51.53017822300185
						],
						[
							-0.123580648191111,
							51.53026673066885
						],
						[
							-0.123444651953889,
							51.53037303804771
						],
						[
							-0.123110192764445,
							51.53054120675818
						],
						[
							-0.122962160202241,
							51.53059771503784
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.12292379472707,
							51.53060602945845
						],
						[
							-0.122353407380556,
							51.53064143758187
						],
						[
							-0.122095474095556,
							51.53064970729707
						],
						[
							-0.121954477858333,
							51.53065289218352
						],
						[
							-0.1217474,
							51.5306533
						],
						[
							-0.1216103,
							51.5306604
						],
						[
							-0.1214735,
							51.5306593
						],
						[
							-0.1213795,
							51.530655
						],
						[
							-0.1212348,
							51.5306403
						],
						[
							-0.1210148,
							51.5306141
						],
						[
							-0.1207905,
							51.5305706
						],
						[
							-0.1205329,
							51.5305173
						],
						[
							-0.1200043,
							51.5303915
						],
						[
							-0.1197513,
							51.5303313
						],
						[
							-0.1196105,
							51.5302924
						],
						[
							-0.1194049,
							51.5302272
						],
						[
							-0.1191815,
							51.5301543
						],
						[
							-0.1190099,
							51.5300977
						],
						[
							-0.1188235,
							51.5300139
						],
						[
							-0.1186771,
							51.5299356
						],
						[
							-0.1185085,
							51.5298479
						],
						[
							-0.1177164,
							51.5293958
						],
						[
							-0.1170423,
							51.5289971
						],
						[
							-0.1161271,
							51.528482
						],
						[
							-0.1156745,
							51.528204
						],
						[
							-0.1150026,
							51.527804
						],
						[
							-0.1145395,
							51.5275097
						],
						[
							-0.1134928,
							51.5268723
						],
						[
							-0.1126765,
							51.5262604
						],
						[
							-0.1114689,
							51.5255569
						],
						[
							-0.1106337,
							51.5250381
						],
						[
							-0.108462,
							51.5235977
						],
						[
							-0.1078712,
							51.5232567
						],
						[
							-0.1074259,
							51.5229345
						],
						[
							-0.107084,
							51.5226981
						],
						[
							-0.1067882,
							51.5225357
						],
						[
							-0.1066583,
							51.5224465
						],
						[
							-0.1065368,
							51.5223312
						],
						[
							-0.1063974,
							51.5221629
						],
						[
							-0.1063,
							51.5220185
						],
						[
							-0.1059965,
							51.5216826
						],
						[
							-0.1055977,
							51.5212173
						],
						[
							-0.1054429,
							51.5210751
						],
						[
							-0.1053096,
							51.5209386
						],
						[
							-0.1052104,
							51.5208087
						],
						[
							-0.1050621,
							51.5205945
						],
						[
							-0.1049786,
							51.52049
						],
						[
							-0.1049105,
							51.5204203
						],
						[
							-0.1047893,
							51.5203103
						],
						[
							-0.104680917695698,
							51.52021971834924
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.104649478524547,
							51.52019532502805
						],
						[
							-0.1045501,
							51.5201238
						],
						[
							-0.1044427,
							51.5200569
						],
						[
							-0.1043486,
							51.5200065
						],
						[
							-0.1041686,
							51.5199284
						],
						[
							-0.1039902,
							51.5198458
						],
						[
							-0.1037526,
							51.5197716
						],
						[
							-0.1034739,
							51.5197064
						],
						[
							-0.1032029,
							51.5196526
						],
						[
							-0.102968,
							51.5196223
						],
						[
							-0.1028153,
							51.5196094
						],
						[
							-0.1026523,
							51.519611
						],
						[
							-0.1023661,
							51.5196126
						],
						[
							-0.1019871,
							51.5196558
						],
						[
							-0.1015112,
							51.5197588
						],
						[
							-0.1007599,
							51.5199783
						],
						[
							-0.1001275,
							51.5200953
						],
						[
							-0.0995453,
							51.5201443
						],
						[
							-0.097812273048081,
							51.520214198457126
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.097772459123226,
							51.52021537328936
						],
						[
							-0.0976268,
							51.5202181
						],
						[
							-0.0972497,
							51.5201997
						],
						[
							-0.0967731,
							51.5201377
						],
						[
							-0.0964925,
							51.5200721
						],
						[
							-0.0905652,
							51.5186174
						],
						[
							-0.0891116,
							51.5182848
						],
						[
							-0.0886996,
							51.5181926
						],
						[
							-0.088309574207228,
							51.518123238006986
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.088270496098424,
							51.51811741741505
						],
						[
							-0.088149,
							51.5181028
						],
						[
							-0.0843534,
							51.5175
						],
						[
							-0.0840764,
							51.5174568
						],
						[
							-0.0838724,
							51.5174272
						],
						[
							-0.083232432479493,
							51.517303777976274
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.083193497910662,
							51.51729705110862
						],
						[
							-0.0818196,
							51.5170871
						],
						[
							-0.0809042,
							51.5169054
						],
						[
							-0.0792088,
							51.5163741
						],
						[
							-0.0786992,
							51.5161805
						],
						[
							-0.0771818,
							51.5153382
						],
						[
							-0.076881,
							51.515143
						],
						[
							-0.0764812,
							51.5149196
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.3702738,
							51.5750268
						],
						[
							-0.3698095,
							51.5749843
						],
						[
							-0.3694074,
							51.5749438
						],
						[
							-0.3689441,
							51.574904
						],
						[
							-0.3683986,
							51.5748823
						],
						[
							-0.3677843,
							51.574874
						],
						[
							-0.3673775,
							51.5748811
						],
						[
							-0.3670557,
							51.5748963
						],
						[
							-0.3665442,
							51.5749312
						],
						[
							-0.3659399,
							51.5749865
						],
						[
							-0.3654376,
							51.5750488
						],
						[
							-0.3649692,
							51.5751217
						],
						[
							-0.3644912,
							51.5752052
						],
						[
							-0.364001,
							51.5753037
						],
						[
							-0.3634679,
							51.5754379
						],
						[
							-0.3629324,
							51.5755852
						],
						[
							-0.3624002,
							51.5757562
						],
						[
							-0.3619925,
							51.5759009
						],
						[
							-0.3616002,
							51.5760477
						],
						[
							-0.3613583,
							51.5761513
						],
						[
							-0.360928,
							51.5763418
						],
						[
							-0.3605211,
							51.5765263
						],
						[
							-0.360107,
							51.5767118
						],
						[
							-0.3595974,
							51.5769345
						],
						[
							-0.3594299,
							51.5770099
						],
						[
							-0.3589252,
							51.5772396
						],
						[
							-0.3584358,
							51.5774568
						],
						[
							-0.3579456,
							51.5776715
						],
						[
							-0.3574789,
							51.5778836
						],
						[
							-0.3569992,
							51.5780952
						],
						[
							-0.3563116,
							51.5784049
						],
						[
							-0.3557996,
							51.5786391
						],
						[
							-0.3554356,
							51.5787985
						],
						[
							-0.3542601,
							51.5792185
						],
						[
							-0.3540983,
							51.5792784
						],
						[
							-0.353784117620198,
							51.579384416510024
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.353746132917914,
							51.57939669021182
						],
						[
							-0.3530825,
							51.5796017
						],
						[
							-0.3527909,
							51.5796853
						],
						[
							-0.3525934,
							51.5797346
						],
						[
							-0.3522792,
							51.5798159
						],
						[
							-0.3518725,
							51.5799034
						],
						[
							-0.3516213,
							51.5799593
						],
						[
							-0.3513713,
							51.5800077
						],
						[
							-0.3510027,
							51.5800686
						],
						[
							-0.3506385,
							51.5801225
						],
						[
							-0.3502956,
							51.5801683
						],
						[
							-0.349939,
							51.5802019
						],
						[
							-0.3495927,
							51.5802304
						],
						[
							-0.3492662,
							51.5802512
						],
						[
							-0.3489643,
							51.5802613
						],
						[
							-0.3485838,
							51.5802665
						],
						[
							-0.3481818,
							51.5802606
						],
						[
							-0.3474813,
							51.5802224
						],
						[
							-0.347060629619701,
							51.58019134117057
						],
						[
							-0.346457164809834,
							51.5801317
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.608449236854013,
							51.67420331043714
						],
						[
							-0.6082149,
							51.6741625
						],
						[
							-0.6076709,
							51.6740827
						],
						[
							-0.6067243,
							51.6739438
						],
						[
							-0.6064287,
							51.6739005
						],
						[
							-0.6059997,
							51.6738376
						],
						[
							-0.6031621,
							51.6734213
						],
						[
							-0.5994714,
							51.673142
						],
						[
							-0.5950114,
							51.6730423
						],
						[
							-0.592264,
							51.6729335
						],
						[
							-0.5915237,
							51.6729069
						],
						[
							-0.5890765,
							51.6728339
						],
						[
							-0.5865689,
							51.6726687
						],
						[
							-0.5813939,
							51.6718965
						],
						[
							-0.577867091647719,
							51.671218428752226
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.560801077401899,
							51.668038254427785
						],
						[
							-0.5597443,
							51.6678399
						],
						[
							-0.559284,
							51.6677528
						],
						[
							-0.5560546,
							51.6671834
						],
						[
							-0.554612,
							51.6669786
						],
						[
							-0.55228038172933,
							51.66686417202882
						],
						[
							-0.5488636,
							51.6670019
						],
						[
							-0.5486162,
							51.6670219
						],
						[
							-0.5458039,
							51.667167
						],
						[
							-0.542149387443866,
							51.66690398203703
						],
						[
							-0.5399564,
							51.6664726
						],
						[
							-0.5375609,
							51.6657391
						],
						[
							-0.5356976,
							51.6650717
						],
						[
							-0.5338275,
							51.6641205
						],
						[
							-0.5313718,
							51.6625162
						],
						[
							-0.5277765,
							51.660186
						],
						[
							-0.5236823,
							51.6575025
						],
						[
							-0.5201461,
							51.6553087
						],
						[
							-0.518372583178327,
							51.654191472568016
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.518338311201544,
							51.65417171228951
						],
						[
							-0.5168054,
							51.6533664
						],
						[
							-0.5155444,
							51.6527856
						],
						[
							-0.5133431,
							51.6519685
						],
						[
							-0.5116749,
							51.6515074
						],
						[
							-0.5114998,
							51.6514224
						],
						[
							-0.5102065,
							51.6510823
						],
						[
							-0.5072657,
							51.6505571
						],
						[
							-0.5061958,
							51.6503853
						],
						[
							-0.5052155,
							51.6502204
						],
						[
							-0.5041251,
							51.6500358
						],
						[
							-0.5037708,
							51.6499764
						],
						[
							-0.5027638,
							51.6498096
						],
						[
							-0.5018824,
							51.6496652
						],
						[
							-0.5009999,
							51.6495074
						],
						[
							-0.5001525,
							51.6493822
						],
						[
							-0.4991197,
							51.649227
						],
						[
							-0.4981112,
							51.6490671
						],
						[
							-0.4974934,
							51.6489771
						],
						[
							-0.4970341,
							51.6489068
						],
						[
							-0.4960219,
							51.6487739
						],
						[
							-0.4950035,
							51.648664
						],
						[
							-0.4942446,
							51.648563
						],
						[
							-0.4939038,
							51.6485247
						],
						[
							-0.4934435,
							51.6484576
						],
						[
							-0.493044,
							51.6483957
						],
						[
							-0.4925096,
							51.6483088
						],
						[
							-0.4919227,
							51.6482014
						],
						[
							-0.4913223,
							51.6480813
						],
						[
							-0.490721,
							51.6479522
						],
						[
							-0.4902906,
							51.6478628
						],
						[
							-0.4897129,
							51.647719
						],
						[
							-0.4891178,
							51.6475676
						],
						[
							-0.4884752,
							51.6473957
						],
						[
							-0.487878,
							51.6472085
						],
						[
							-0.4874826,
							51.6470961
						],
						[
							-0.4868164,
							51.6468693
						],
						[
							-0.4861553,
							51.6466258
						],
						[
							-0.4855673,
							51.6463952
						],
						[
							-0.4849104,
							51.6461057
						],
						[
							-0.4841917,
							51.6457588
						],
						[
							-0.4835625,
							51.6454201
						],
						[
							-0.4828644,
							51.6450195
						],
						[
							-0.4822362,
							51.6446272
						],
						[
							-0.4817317,
							51.6443096
						],
						[
							-0.4811736,
							51.6439543
						],
						[
							-0.4806464,
							51.6436176
						],
						[
							-0.48008,
							51.6432521
						],
						[
							-0.4795889,
							51.6429345
						],
						[
							-0.4794077,
							51.642822
						],
						[
							-0.4792617,
							51.6427301
						],
						[
							-0.4787611,
							51.6424049
						],
						[
							-0.4785095,
							51.6422501
						],
						[
							-0.4780332,
							51.6419588
						],
						[
							-0.4775878,
							51.6416885
						],
						[
							-0.4772338,
							51.6414689
						],
						[
							-0.4768551,
							51.6412373
						],
						[
							-0.4765908,
							51.6410885
						],
						[
							-0.4763369,
							51.640962
						],
						[
							-0.4760079,
							51.6408053
						],
						[
							-0.4757498,
							51.6407028
						],
						[
							-0.4754679,
							51.640602
						],
						[
							-0.4750573,
							51.6404689
						],
						[
							-0.4748211,
							51.640403
						],
						[
							-0.4745412,
							51.640338
						],
						[
							-0.4742389,
							51.6402717
						],
						[
							-0.4739035,
							51.6402158
						],
						[
							-0.473602167154165,
							51.64018360150588
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.473562745982826,
							51.64017994692554
						],
						[
							-0.4732925,
							51.6401587
						],
						[
							-0.4729775,
							51.6401486
						],
						[
							-0.4726625,
							51.6401433
						],
						[
							-0.4724166,
							51.6401476
						],
						[
							-0.4721713,
							51.6401604
						],
						[
							-0.4720151,
							51.6401693
						],
						[
							-0.4715982,
							51.6402093
						],
						[
							-0.4712899,
							51.6402429
						],
						[
							-0.4709685,
							51.6403053
						],
						[
							-0.4705028,
							51.6403958
						],
						[
							-0.4699488,
							51.640508
						],
						[
							-0.4692801,
							51.6406405
						],
						[
							-0.4679767,
							51.6408869
						],
						[
							-0.466086,
							51.6412326
						],
						[
							-0.4659071,
							51.6412605
						],
						[
							-0.4651766,
							51.6413581
						],
						[
							-0.4645972,
							51.6414225
						],
						[
							-0.4636427,
							51.641468
						],
						[
							-0.4628822,
							51.641459
						],
						[
							-0.4608732,
							51.6412954
						],
						[
							-0.4603933,
							51.6412284
						],
						[
							-0.459683,
							51.6411343
						],
						[
							-0.4584588,
							51.6409491
						],
						[
							-0.4564906,
							51.6406645
						],
						[
							-0.4559832,
							51.6405912
						],
						[
							-0.454243,
							51.6403504
						],
						[
							-0.4526079,
							51.6401058
						],
						[
							-0.451498,
							51.6399716
						],
						[
							-0.4508657,
							51.6398701
						],
						[
							-0.4504591,
							51.6397907
						],
						[
							-0.4498322,
							51.6396399
						],
						[
							-0.4493525,
							51.6395091
						],
						[
							-0.448803803896635,
							51.639361332724086
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.432223510789514,
							51.62975747815908
						],
						[
							-0.432,
							51.6294163
						],
						[
							-0.4318085,
							51.6291132
						],
						[
							-0.4315655,
							51.6287507
						],
						[
							-0.431299,
							51.6283598
						],
						[
							-0.4311256,
							51.6281275
						],
						[
							-0.4309097,
							51.6278306
						],
						[
							-0.43068,
							51.6275242
						],
						[
							-0.430435,
							51.6271757
						],
						[
							-0.4300531,
							51.6266168
						],
						[
							-0.4297263,
							51.626128
						],
						[
							-0.4294726,
							51.6257326
						],
						[
							-0.429177,
							51.6252939
						],
						[
							-0.4289313,
							51.6249307
						],
						[
							-0.4287246,
							51.624622
						],
						[
							-0.4284984,
							51.6242704
						],
						[
							-0.4282644,
							51.623903
						],
						[
							-0.4280011,
							51.6234986
						],
						[
							-0.4279229,
							51.6233802
						],
						[
							-0.427698,
							51.6230483
						],
						[
							-0.4274708,
							51.6226894
						],
						[
							-0.4272632,
							51.6223758
						],
						[
							-0.4270292,
							51.6220254
						],
						[
							-0.4268186,
							51.6217124
						],
						[
							-0.4266343,
							51.6214407
						],
						[
							-0.4264783,
							51.6211834
						],
						[
							-0.4262872,
							51.6208626
						],
						[
							-0.4261449,
							51.6206162
						],
						[
							-0.4259938,
							51.6203311
						],
						[
							-0.4258524,
							51.620063
						],
						[
							-0.4257052,
							51.6197276
						],
						[
							-0.4255989,
							51.6194837
						],
						[
							-0.425477,
							51.6191659
						],
						[
							-0.4253649,
							51.6188662
						],
						[
							-0.4252538,
							51.6185339
						],
						[
							-0.4251582,
							51.6181967
						],
						[
							-0.4250656,
							51.6178117
						],
						[
							-0.4249847,
							51.6173922
						],
						[
							-0.4249418,
							51.6170362
						],
						[
							-0.4249077,
							51.6166906
						],
						[
							-0.4248901,
							51.6163491
						],
						[
							-0.4248833,
							51.6157292
						],
						[
							-0.4248745,
							51.6151965
						],
						[
							-0.424857,
							51.6147733
						],
						[
							-0.4248443,
							51.614493
						],
						[
							-0.4248258,
							51.6143066
						],
						[
							-0.4247868,
							51.6139839
						],
						[
							-0.4247458,
							51.6137351
						],
						[
							-0.4246815,
							51.6134251
						],
						[
							-0.4245879,
							51.6130685
						],
						[
							-0.4244563,
							51.6126387
						],
						[
							-0.4243129,
							51.6122772
						],
						[
							-0.424135,
							51.6118317
						],
						[
							-0.4240053,
							51.6114666
						],
						[
							-0.4238805,
							51.6111457
						],
						[
							-0.423737062385244,
							51.610803765990504
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.423721337909274,
							51.610767437669615
						],
						[
							-0.4235705,
							51.6104295
						],
						[
							-0.4234018,
							51.6101019
						],
						[
							-0.4231776,
							51.6096751
						],
						[
							-0.4229777,
							51.6093408
						],
						[
							-0.4227379,
							51.6089667
						],
						[
							-0.4225185,
							51.6086451
						],
						[
							-0.4222855,
							51.6083273
						],
						[
							-0.4220398,
							51.6080112
						],
						[
							-0.4217766,
							51.6077006
						],
						[
							-0.4214831,
							51.6073742
						],
						[
							-0.4211419,
							51.6070394
						],
						[
							-0.4208367,
							51.6067494
						],
						[
							-0.4205364,
							51.606489
						],
						[
							-0.4201923,
							51.6062165
						],
						[
							-0.4198783,
							51.6059737
						],
						[
							-0.4196258,
							51.605789
						],
						[
							-0.4192095,
							51.6054929
						],
						[
							-0.4187989,
							51.6052124
						],
						[
							-0.4182786,
							51.6048728
						],
						[
							-0.4177471,
							51.6045283
						],
						[
							-0.4172654,
							51.6042322
						],
						[
							-0.417104,
							51.6041395
						],
						[
							-0.4168078,
							51.6039799
						],
						[
							-0.4162672,
							51.6036956
						],
						[
							-0.4158979,
							51.6035046
						],
						[
							-0.4152422,
							51.6031924
						],
						[
							-0.4145794,
							51.6028802
						],
						[
							-0.4137594,
							51.6025174
						],
						[
							-0.4131514,
							51.6022496
						],
						[
							-0.4125939,
							51.6019984
						],
						[
							-0.4118526,
							51.6016391
						],
						[
							-0.4108177,
							51.6011795
						],
						[
							-0.4099191,
							51.6007879
						],
						[
							-0.408980892511386,
							51.600372902857174
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.408944799569797,
							51.60035671642333
						],
						[
							-0.4082299,
							51.6000317
						],
						[
							-0.4076022,
							51.5997544
						],
						[
							-0.4067345,
							51.5993689
						],
						[
							-0.4062093,
							51.5991499
						],
						[
							-0.4057712,
							51.5989607
						],
						[
							-0.4052517,
							51.5987575
						],
						[
							-0.4046774,
							51.5985429
						],
						[
							-0.4040371,
							51.5983405
						],
						[
							-0.4034389,
							51.5981469
						],
						[
							-0.4028913,
							51.5979855
						],
						[
							-0.4020657,
							51.5977535
						],
						[
							-0.4014366,
							51.5975939
						],
						[
							-0.4008511,
							51.597457
						],
						[
							-0.4002305,
							51.5973244
						],
						[
							-0.3997517,
							51.5972372
						],
						[
							-0.3986073,
							51.597054
						],
						[
							-0.3975401,
							51.5968926
						],
						[
							-0.3963073,
							51.5966912
						],
						[
							-0.3952261,
							51.5965307
						],
						[
							-0.3942151,
							51.5963833
						],
						[
							-0.3932631,
							51.5962419
						],
						[
							-0.3922016,
							51.5960884
						],
						[
							-0.3913886,
							51.5959698
						],
						[
							-0.3907638,
							51.5958739
						],
						[
							-0.3902161,
							51.5957901
						],
						[
							-0.3897177,
							51.595702
						],
						[
							-0.3893063,
							51.5956314
						],
						[
							-0.3887909,
							51.595525
						],
						[
							-0.3883599,
							51.5954351
						],
						[
							-0.387853,
							51.5953156
						],
						[
							-0.3873236,
							51.5951935
						],
						[
							-0.3867676,
							51.5950365
						],
						[
							-0.3863674,
							51.5949274
						],
						[
							-0.3859883,
							51.5948088
						],
						[
							-0.3855937,
							51.5946832
						],
						[
							-0.3851219,
							51.5945122
						],
						[
							-0.384799,
							51.594391
						],
						[
							-0.3842233,
							51.594172
						],
						[
							-0.3836925,
							51.5939418
						],
						[
							-0.3834369,
							51.5938353
						],
						[
							-0.3827728,
							51.5935518
						],
						[
							-0.3823529,
							51.5933791
						],
						[
							-0.3820061,
							51.5932273
						],
						[
							-0.3813209,
							51.592936
						],
						[
							-0.380827946763776,
							51.592721496380875
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.380791640478854,
							51.592705728656895
						],
						[
							-0.3805641,
							51.5926071
						],
						[
							-0.3801498,
							51.5924257
						],
						[
							-0.3795208,
							51.592157
						],
						[
							-0.3787654,
							51.5918299
						],
						[
							-0.3779004,
							51.5914574
						],
						[
							-0.3772096,
							51.5911573
						],
						[
							-0.3765159,
							51.5908529
						],
						[
							-0.375936,
							51.5906095
						],
						[
							-0.3752438,
							51.5903044
						],
						[
							-0.3744813,
							51.5899825
						],
						[
							-0.3737989,
							51.5896824
						],
						[
							-0.3728778,
							51.5892784
						],
						[
							-0.3720339,
							51.5889112
						],
						[
							-0.3711058,
							51.5885134
						],
						[
							-0.3701945,
							51.5881234
						],
						[
							-0.3692018,
							51.5877029
						],
						[
							-0.3682666,
							51.5872946
						],
						[
							-0.3671714,
							51.5868209
						],
						[
							-0.3660137,
							51.5863236
						],
						[
							-0.3650567,
							51.5859109
						],
						[
							-0.3643456,
							51.5856077
						],
						[
							-0.3637593,
							51.5853743
						],
						[
							-0.362815998234908,
							51.585006806579976
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.36277902012984,
							51.584992357377274
						],
						[
							-0.3622604,
							51.5847891
						],
						[
							-0.3619676,
							51.584686
						],
						[
							-0.3608226,
							51.5842908
						],
						[
							-0.359799,
							51.5839348
						],
						[
							-0.3569455,
							51.5829496
						],
						[
							-0.3559417,
							51.5826022
						],
						[
							-0.3545436,
							51.5820878
						],
						[
							-0.3541293,
							51.5819447
						],
						[
							-0.3536941,
							51.5817929
						],
						[
							-0.3533215,
							51.5816635
						],
						[
							-0.3529477,
							51.5815326
						],
						[
							-0.3526387,
							51.5814305
						],
						[
							-0.3522453,
							51.5813068
						],
						[
							-0.3518576,
							51.5811925
						],
						[
							-0.3513831,
							51.5810645
						],
						[
							-0.3509098,
							51.5809501
						],
						[
							-0.3505459,
							51.5808667
						],
						[
							-0.3499284,
							51.580725
						],
						[
							-0.3493463,
							51.5806085
						],
						[
							-0.3488753,
							51.5805136
						],
						[
							-0.3484564,
							51.580423
						],
						[
							-0.347944729852811,
							51.580330634190545
						],
						[
							-0.346464700494387,
							51.580132707757855
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.336285585098305,
							51.579232111709295
						],
						[
							-0.3358248,
							51.5790974
						],
						[
							-0.3351555,
							51.5789075
						],
						[
							-0.3347628,
							51.5788046
						],
						[
							-0.3345106,
							51.5787379
						],
						[
							-0.3341782,
							51.5786639
						],
						[
							-0.3337676,
							51.5785914
						],
						[
							-0.3336473,
							51.5785767
						],
						[
							-0.3335016,
							51.5785627
						],
						[
							-0.3331637,
							51.5785304
						],
						[
							-0.3326867,
							51.5785071
						],
						[
							-0.3321261,
							51.5785019
						],
						[
							-0.3314415,
							51.57851
						],
						[
							-0.3305917,
							51.5785329
						],
						[
							-0.3297923,
							51.5785502
						],
						[
							-0.3285025,
							51.5785798
						],
						[
							-0.3277964,
							51.5785945
						],
						[
							-0.326821,
							51.5786214
						],
						[
							-0.3263194,
							51.5786275
						],
						[
							-0.3252728,
							51.5786494
						],
						[
							-0.3237532,
							51.5786667
						],
						[
							-0.3222251,
							51.5786568
						],
						[
							-0.3216993,
							51.5786489
						],
						[
							-0.321196,
							51.5786407
						],
						[
							-0.320185,
							51.5786165
						],
						[
							-0.3196641,
							51.5785985
						],
						[
							-0.3191969,
							51.5785726
						],
						[
							-0.3187059,
							51.5785533
						],
						[
							-0.3182222,
							51.5785212
						],
						[
							-0.317796867871321,
							51.57848966560268
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.317757491103187,
							51.578485730671545
						],
						[
							-0.3175349,
							51.5784577
						],
						[
							-0.3173091,
							51.5784256
						],
						[
							-0.3164176,
							51.5782861
						],
						[
							-0.3161266,
							51.5782375
						],
						[
							-0.3158435,
							51.5781927
						],
						[
							-0.3156043,
							51.5781501
						],
						[
							-0.3151807,
							51.5780747
						],
						[
							-0.3147921,
							51.5780082
						],
						[
							-0.3143542,
							51.5779313
						],
						[
							-0.3138976,
							51.5778464
						],
						[
							-0.3134679,
							51.5777681
						],
						[
							-0.313041,
							51.5776779
						],
						[
							-0.3125126,
							51.5775644
						],
						[
							-0.3118534,
							51.5774106
						],
						[
							-0.3114361,
							51.5773129
						],
						[
							-0.3106853,
							51.5771164
						],
						[
							-0.3104239,
							51.57705
						],
						[
							-0.3101769,
							51.5769824
						],
						[
							-0.3097643,
							51.5768609
						],
						[
							-0.3092749,
							51.5767243
						],
						[
							-0.3087234,
							51.5765537
						],
						[
							-0.3082063,
							51.5763828
						],
						[
							-0.3076801,
							51.5762084
						],
						[
							-0.3071838,
							51.5760355
						],
						[
							-0.3066597,
							51.5758575
						],
						[
							-0.3059502,
							51.5756185
						],
						[
							-0.3048504,
							51.5752452
						],
						[
							-0.3026353,
							51.574495
						],
						[
							-0.302177,
							51.5743435
						],
						[
							-0.3016328,
							51.5741589
						],
						[
							-0.3008129,
							51.5738807
						],
						[
							-0.3003866,
							51.5737429
						],
						[
							-0.2999774,
							51.5736004
						],
						[
							-0.2991378,
							51.573307
						],
						[
							-0.2987246,
							51.5731682
						],
						[
							-0.2982893,
							51.573043
						],
						[
							-0.2978613,
							51.5729088
						],
						[
							-0.2974235,
							51.5727806
						],
						[
							-0.296552,
							51.5725228
						],
						[
							-0.2961322,
							51.5724002
						],
						[
							-0.2956949,
							51.5722558
						],
						[
							-0.295163479461816,
							51.57208046211895
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.295125690552477,
							51.572067604209224
						],
						[
							-0.2946021,
							51.571884
						],
						[
							-0.294147,
							51.5717243
						],
						[
							-0.2935554,
							51.5715035
						],
						[
							-0.2927532,
							51.5711941
						],
						[
							-0.2923287,
							51.5710234
						],
						[
							-0.2918729,
							51.5708276
						],
						[
							-0.291499,
							51.5706684
						],
						[
							-0.2911363,
							51.5705107
						],
						[
							-0.2909802,
							51.5704359
						],
						[
							-0.2908501,
							51.5703764
						],
						[
							-0.2905931,
							51.5702569
						],
						[
							-0.290337,
							51.5701359
						],
						[
							-0.2900903,
							51.5700172
						],
						[
							-0.2895025,
							51.5697239
						],
						[
							-0.2888691,
							51.5693986
						],
						[
							-0.288368601134592,
							51.5691467
						],
						[
							-0.287919363007785,
							51.56887974265775
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.4487918,
							51.6393581
						],
						[
							-0.4491586,
							51.6394737
						],
						[
							-0.449346,
							51.6395493
						],
						[
							-0.4495605,
							51.6396377
						],
						[
							-0.4498647,
							51.639783
						],
						[
							-0.4500545,
							51.6398995
						],
						[
							-0.4502854,
							51.6400566
						],
						[
							-0.4504553,
							51.6401978
						],
						[
							-0.4506207,
							51.6403659
						],
						[
							-0.4507761,
							51.6405492
						],
						[
							-0.4509804,
							51.6408241
						],
						[
							-0.4510859,
							51.6410101
						],
						[
							-0.4511858,
							51.6412251
						],
						[
							-0.4512824,
							51.6415324
						],
						[
							-0.4513235,
							51.6418204
						],
						[
							-0.4512791,
							51.6421208
						],
						[
							-0.4512136,
							51.6423894
						],
						[
							-0.451064609971823,
							51.64276881743017
						],
						[
							-0.4509538,
							51.6429385
						],
						[
							-0.4507728,
							51.6431308
						],
						[
							-0.4505363,
							51.6433588
						],
						[
							-0.4503465,
							51.6435462
						],
						[
							-0.4500811,
							51.6437405
						],
						[
							-0.4498469,
							51.6438886
						],
						[
							-0.4495494,
							51.6440636
						],
						[
							-0.4492662,
							51.6441917
						],
						[
							-0.4488566,
							51.6443591
						],
						[
							-0.4484247,
							51.644499
						],
						[
							-0.4478019,
							51.6446891
						],
						[
							-0.446977,
							51.6449351
						],
						[
							-0.4462642,
							51.6451714
						],
						[
							-0.4455137,
							51.6454029
						],
						[
							-0.4449742,
							51.6455744
						],
						[
							-0.4444169,
							51.6457611
						],
						[
							-0.4439161,
							51.6459402
						],
						[
							-0.4435331,
							51.6460876
						],
						[
							-0.443169,
							51.6462571
						],
						[
							-0.4428137,
							51.6464452
						],
						[
							-0.4424773,
							51.6466374
						],
						[
							-0.442081,
							51.6468764
						],
						[
							-0.441683498611325,
							51.64713826519882
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.441651088774592,
							51.647161324819486
						],
						[
							-0.4411728,
							51.6475281
						],
						[
							-0.4408664,
							51.6477782
						],
						[
							-0.4404778,
							51.6480751
						],
						[
							-0.4401126,
							51.6483658
						],
						[
							-0.4398705,
							51.6485628
						],
						[
							-0.4395797,
							51.648817
						],
						[
							-0.4392433,
							51.6490684
						],
						[
							-0.4388503,
							51.649344
						],
						[
							-0.4384928,
							51.6495617
						],
						[
							-0.4381675,
							51.6497532
						],
						[
							-0.4377734,
							51.6499626
						],
						[
							-0.4374037,
							51.6501451
						],
						[
							-0.4369374,
							51.6503531
						],
						[
							-0.4365366,
							51.6505212
						],
						[
							-0.4360847,
							51.6506858
						],
						[
							-0.4353298,
							51.6509414
						],
						[
							-0.434617,
							51.6511735
						],
						[
							-0.4338177,
							51.6514332
						],
						[
							-0.433156,
							51.6516426
						],
						[
							-0.4327293,
							51.6517784
						],
						[
							-0.4320526,
							51.6519927
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.4320526,
							51.6519927
						],
						[
							-0.4316362,
							51.6521265
						],
						[
							-0.4309651,
							51.6523413
						],
						[
							-0.4303333,
							51.6525434
						],
						[
							-0.4296669,
							51.6527594
						],
						[
							-0.429081,
							51.6529458
						],
						[
							-0.4284175,
							51.6531589
						],
						[
							-0.4277745,
							51.6533692
						],
						[
							-0.4274488,
							51.653469
						],
						[
							-0.4268226,
							51.6536775
						],
						[
							-0.4258408,
							51.6539969
						],
						[
							-0.425429,
							51.6541327
						],
						[
							-0.4250705,
							51.6542564
						],
						[
							-0.4243395,
							51.6545015
						],
						[
							-0.4242497,
							51.6545317
						],
						[
							-0.4238819,
							51.654653
						],
						[
							-0.4233849,
							51.6548301
						],
						[
							-0.4228664,
							51.6550374
						],
						[
							-0.4224096,
							51.6552412
						],
						[
							-0.42190411827067,
							51.655450461926236
						],
						[
							-0.421777904323325,
							51.655506323926595
						],
						[
							-0.421583390375979,
							51.655582823967144
						],
						[
							-0.421309704323325,
							51.65569697631745
						],
						[
							-0.4207773,
							51.6558987
						],
						[
							-0.4202639,
							51.6561197
						],
						[
							-0.4190168,
							51.6566562
						],
						[
							-0.417390844064354,
							51.65736429832122
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.610941122012224,
							51.70537309677744
						],
						[
							-0.6110004,
							51.7052133
						],
						[
							-0.6110585,
							51.7050575
						],
						[
							-0.611145,
							51.7048211
						],
						[
							-0.61119,
							51.704656
						],
						[
							-0.6112014,
							51.7045503
						],
						[
							-0.6112113,
							51.7044163
						],
						[
							-0.6112195,
							51.7043123
						],
						[
							-0.6112178,
							51.7042079
						],
						[
							-0.6112136,
							51.703953
						],
						[
							-0.6112021,
							51.7038179
						],
						[
							-0.6111779,
							51.7036651
						],
						[
							-0.6111434,
							51.7035389
						],
						[
							-0.6110841,
							51.7033183
						],
						[
							-0.6110417,
							51.7032246
						],
						[
							-0.6109828,
							51.7030789
						],
						[
							-0.6108859,
							51.7029493
						],
						[
							-0.610631,
							51.7026637
						],
						[
							-0.6104975,
							51.7025435
						],
						[
							-0.6102991,
							51.7024105
						],
						[
							-0.6097689,
							51.7020755
						],
						[
							-0.6093889,
							51.7017941
						],
						[
							-0.6091246,
							51.701518
						],
						[
							-0.6090047,
							51.7013439
						],
						[
							-0.6088814,
							51.7011403
						],
						[
							-0.6088225,
							51.7009764
						],
						[
							-0.6088071,
							51.7009074
						],
						[
							-0.6087632,
							51.7006501
						],
						[
							-0.6087537,
							51.7005406
						],
						[
							-0.6087474,
							51.7003037
						],
						[
							-0.6087731,
							51.7000773
						],
						[
							-0.6088627,
							51.6994331
						],
						[
							-0.6088654,
							51.6993892
						],
						[
							-0.6088834,
							51.6992499
						],
						[
							-0.6088979,
							51.699173
						],
						[
							-0.608965,
							51.6982586
						],
						[
							-0.609036,
							51.6972493
						],
						[
							-0.6090267,
							51.6971055
						],
						[
							-0.6090066,
							51.6968717
						],
						[
							-0.608987,
							51.6967479
						],
						[
							-0.6089584,
							51.6966238
						],
						[
							-0.608919,
							51.6964971
						],
						[
							-0.6087665,
							51.6962086
						],
						[
							-0.6085906,
							51.6959503
						],
						[
							-0.6084513,
							51.6957914
						],
						[
							-0.6082675,
							51.6956266
						],
						[
							-0.6081122,
							51.6954943
						],
						[
							-0.6079902,
							51.6953994
						],
						[
							-0.6075456,
							51.695099
						],
						[
							-0.606868,
							51.6946469
						],
						[
							-0.6065116,
							51.694408
						],
						[
							-0.6044331,
							51.6930087
						],
						[
							-0.6034217,
							51.6923105
						],
						[
							-0.6032307,
							51.6921734
						],
						[
							-0.6028919,
							51.6919732
						],
						[
							-0.6026132,
							51.6918492
						],
						[
							-0.6021722,
							51.6916639
						],
						[
							-0.6019495,
							51.6915904
						],
						[
							-0.6015614,
							51.6914751
						],
						[
							-0.6011418,
							51.6913683
						],
						[
							-0.6008726,
							51.6913064
						],
						[
							-0.600639,
							51.6912515
						],
						[
							-0.6004248,
							51.6912181
						],
						[
							-0.5999294,
							51.6911325
						],
						[
							-0.599366,
							51.6910395
						],
						[
							-0.5987436,
							51.6909445
						],
						[
							-0.5983951,
							51.6908841
						],
						[
							-0.5979257,
							51.6908133
						],
						[
							-0.5972019,
							51.6906902
						],
						[
							-0.596822,
							51.6906173
						],
						[
							-0.596707,
							51.6905962
						],
						[
							-0.5965863,
							51.6905737
						],
						[
							-0.5964603,
							51.6905455
						],
						[
							-0.5962993,
							51.6905058
						],
						[
							-0.5961805,
							51.6904741
						],
						[
							-0.5960508,
							51.6904398
						],
						[
							-0.5958715,
							51.6903825
						],
						[
							-0.5955859,
							51.6902848
						],
						[
							-0.5954469,
							51.6902306
						],
						[
							-0.5952256,
							51.6901306
						],
						[
							-0.5950003,
							51.690018
						],
						[
							-0.5948811,
							51.6899474
						],
						[
							-0.594627,
							51.6898064
						],
						[
							-0.5944733,
							51.6897104
						],
						[
							-0.5940012,
							51.6893422
						],
						[
							-0.5935436,
							51.6889948
						],
						[
							-0.5931657,
							51.6886799
						],
						[
							-0.5929412,
							51.6884978
						],
						[
							-0.5925603,
							51.6881994
						],
						[
							-0.5914512,
							51.6872718
						],
						[
							-0.5913592,
							51.687164
						],
						[
							-0.5913091,
							51.6870885
						],
						[
							-0.5910878,
							51.6867802
						],
						[
							-0.5909843,
							51.6865747
						],
						[
							-0.590873,
							51.6863102
						],
						[
							-0.5906877,
							51.6858561
						],
						[
							-0.5905733,
							51.6855463
						],
						[
							-0.5904762,
							51.6853539
						],
						[
							-0.5903925,
							51.6852122
						],
						[
							-0.5903265,
							51.6851123
						],
						[
							-0.5901831,
							51.6849427
						],
						[
							-0.5899645,
							51.6847175
						],
						[
							-0.5897285,
							51.6845161
						],
						[
							-0.5894409,
							51.6842806
						],
						[
							-0.5893295,
							51.6842125
						],
						[
							-0.5892022,
							51.6841471
						],
						[
							-0.5890378,
							51.684058
						],
						[
							-0.5889198,
							51.6839797
						],
						[
							-0.5887213,
							51.6838809
						],
						[
							-0.5885027,
							51.6837927
						],
						[
							-0.5881783,
							51.6836795
						],
						[
							-0.5879689,
							51.6836132
						],
						[
							-0.5877679,
							51.6835571
						],
						[
							-0.5874078,
							51.68347
						],
						[
							-0.5869054,
							51.6833755
						],
						[
							-0.5861102,
							51.6832319
						],
						[
							-0.5857883,
							51.6831643
						],
						[
							-0.5855981,
							51.683118
						],
						[
							-0.5854136,
							51.683065
						],
						[
							-0.5851578,
							51.6829909
						],
						[
							-0.5848114,
							51.6828588
						],
						[
							-0.5845116,
							51.6827029
						],
						[
							-0.5839235,
							51.6823793
						],
						[
							-0.5836847,
							51.682224
						],
						[
							-0.5836048,
							51.682164
						],
						[
							-0.5833913,
							51.6820061
						],
						[
							-0.5831538,
							51.6817803
						],
						[
							-0.5825959,
							51.6809933
						],
						[
							-0.5824884,
							51.6805729
						],
						[
							-0.5824792,
							51.6802234
						],
						[
							-0.5825022,
							51.6798092
						],
						[
							-0.5825275,
							51.6795673
						],
						[
							-0.5826185,
							51.6793218
						],
						[
							-0.5829878,
							51.6788654
						],
						[
							-0.5835281,
							51.6782594
						],
						[
							-0.5844855,
							51.677377
						],
						[
							-0.5847752,
							51.677083
						],
						[
							-0.5849657,
							51.676824
						],
						[
							-0.5851113,
							51.6765732
						],
						[
							-0.5851978,
							51.6763517
						],
						[
							-0.5853229,
							51.6758193
						],
						[
							-0.5853178,
							51.6754988
						],
						[
							-0.5852508,
							51.6751659
						],
						[
							-0.5851676,
							51.6749495
						],
						[
							-0.5849308,
							51.6744906
						],
						[
							-0.5847888,
							51.6742766
						],
						[
							-0.5844963,
							51.6739807
						],
						[
							-0.583835,
							51.6733698
						],
						[
							-0.5833483,
							51.6729953
						],
						[
							-0.5831042,
							51.6728349
						],
						[
							-0.582785,
							51.6726519
						],
						[
							-0.5822684,
							51.6724049
						],
						[
							-0.581609138520963,
							51.67213723933265
						],
						[
							-0.580888668906101,
							51.67192334582934
						],
						[
							-0.5778581,
							51.6712167
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.395949581307782,
							51.66344691956569
						],
						[
							-0.3950502,
							51.6627992
						],
						[
							-0.3942323,
							51.6621624
						],
						[
							-0.3936595,
							51.6617562
						],
						[
							-0.3935512,
							51.6616716
						],
						[
							-0.393187,
							51.6613997
						],
						[
							-0.3929172,
							51.6611587
						],
						[
							-0.3927202,
							51.6609994
						],
						[
							-0.3924992,
							51.6608056
						],
						[
							-0.3922661,
							51.6605804
						],
						[
							-0.3921625,
							51.6604712
						],
						[
							-0.3920164,
							51.6602949
						],
						[
							-0.3918677,
							51.6601013
						],
						[
							-0.3916788,
							51.6598342
						],
						[
							-0.3915919,
							51.6596809
						],
						[
							-0.3914772,
							51.6594835
						],
						[
							-0.3913452,
							51.6592084
						],
						[
							-0.3912029,
							51.6588832
						],
						[
							-0.3911185,
							51.6586594
						],
						[
							-0.3907968,
							51.6578892
						],
						[
							-0.3906201,
							51.6574767
						],
						[
							-0.390443,
							51.6570425
						],
						[
							-0.3901965,
							51.6564364
						],
						[
							-0.3900322,
							51.6560046
						],
						[
							-0.3899459,
							51.6557186
						],
						[
							-0.3899053,
							51.6555088
						],
						[
							-0.3898815,
							51.6552369
						],
						[
							-0.3899053,
							51.6548733
						],
						[
							-0.3899627,
							51.6546046
						],
						[
							-0.3900703,
							51.6542733
						],
						[
							-0.3901595,
							51.6540718
						],
						[
							-0.390299,
							51.6538422
						],
						[
							-0.3904027,
							51.6536943
						],
						[
							-0.3906155,
							51.6534262
						],
						[
							-0.3908756,
							51.6531681
						],
						[
							-0.3911272,
							51.6529518
						],
						[
							-0.3912998,
							51.6528287
						],
						[
							-0.3915607,
							51.6525925
						],
						[
							-0.39214156596283,
							51.65231400825372
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.392177201071932,
							51.65229692327302
						],
						[
							-0.393397,
							51.6517121
						],
						[
							-0.3937032,
							51.651554
						],
						[
							-0.393982,
							51.6513876
						],
						[
							-0.3941938,
							51.6512444
						],
						[
							-0.3944054,
							51.6510763
						],
						[
							-0.3946765,
							51.6508035
						],
						[
							-0.3948423,
							51.6506118
						],
						[
							-0.3949576,
							51.6504455
						],
						[
							-0.3950544,
							51.6502779
						],
						[
							-0.3951029,
							51.6501827
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.175882590374857,
							51.54461391813201
						],
						[
							-0.1757964,
							51.5445419
						],
						[
							-0.1756651,
							51.5444026
						],
						[
							-0.17559,
							51.5443027
						],
						[
							-0.1755079,
							51.5441889
						],
						[
							-0.1754274,
							51.5440598
						],
						[
							-0.1753679,
							51.5438867
						],
						[
							-0.1753137,
							51.5436751
						],
						[
							-0.1752502,
							51.5434355
						],
						[
							-0.175175,
							51.5428133
						],
						[
							-0.1752589,
							51.5411584
						],
						[
							-0.1756182,
							51.5390084
						],
						[
							-0.1758728,
							51.5379444
						],
						[
							-0.1759597,
							51.5369502
						],
						[
							-0.1759369,
							51.5366433
						],
						[
							-0.1758761,
							51.5363387
						],
						[
							-0.1758001,
							51.5360459
						],
						[
							-0.1757153,
							51.53582
						],
						[
							-0.1756063,
							51.5356148
						],
						[
							-0.1752422,
							51.5351714
						],
						[
							-0.1733719,
							51.5336019
						],
						[
							-0.1706073,
							51.531338
						],
						[
							-0.1679991,
							51.529202
						],
						[
							-0.1679173,
							51.5291369
						],
						[
							-0.1675726,
							51.5288925
						],
						[
							-0.1672374,
							51.5286839
						],
						[
							-0.1669642,
							51.5285164
						],
						[
							-0.1666486,
							51.5283318
						],
						[
							-0.1663066,
							51.5281558
						],
						[
							-0.1660384,
							51.5280265
						],
						[
							-0.1656307,
							51.5278179
						],
						[
							-0.1654522,
							51.5277325
						],
						[
							-0.164724,
							51.5274156
						],
						[
							-0.1635454,
							51.5267934
						],
						[
							-0.1626291,
							51.5263322
						],
						[
							-0.1585598,
							51.5241948
						],
						[
							-0.1580891,
							51.5239281
						],
						[
							-0.157996,
							51.5238685
						],
						[
							-0.1577929,
							51.5237028
						],
						[
							-0.157574,
							51.5235099
						],
						[
							-0.1573565,
							51.5233078
						],
						[
							-0.1569841,
							51.5229657
						],
						[
							-0.1568032,
							51.5228345
						],
						[
							-0.1566651,
							51.5227597
						],
						[
							-0.1565193,
							51.5226978
						],
						[
							-0.1563484,
							51.5226424
						],
						[
							-0.1560706,
							51.5225691
						],
						[
							-0.1558066,
							51.5225146
						],
						[
							-0.155531852708184,
							51.52250172996393
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.287919363007793,
							51.56887974265776
						],
						[
							-0.287831771805478,
							51.56882553334039
						],
						[
							-0.2876643,
							51.5687128
						],
						[
							-0.287417,
							51.5685441
						],
						[
							-0.2871685,
							51.5683672
						],
						[
							-0.2867779,
							51.5680473
						],
						[
							-0.2863956,
							51.5677
						],
						[
							-0.2852848,
							51.5666679
						],
						[
							-0.2848683,
							51.5662958
						],
						[
							-0.2845062,
							51.5660086
						],
						[
							-0.2842198,
							51.5657969
						],
						[
							-0.2839999,
							51.5656451
						],
						[
							-0.283672,
							51.5654402
						],
						[
							-0.2832903,
							51.5652069
						],
						[
							-0.2830427,
							51.5650646
						],
						[
							-0.2826012,
							51.5648286
						],
						[
							-0.2822388,
							51.5646463
						],
						[
							-0.2818882,
							51.5644849
						],
						[
							-0.2816169,
							51.5643649
						],
						[
							-0.2811954,
							51.5641954
						],
						[
							-0.2806338,
							51.5639747
						],
						[
							-0.280058834458927,
							51.56377484670313
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.280021094514943,
							51.56376189269349
						],
						[
							-0.2790781,
							51.5634423
						],
						[
							-0.2782097,
							51.5631496
						],
						[
							-0.2776909,
							51.5630028
						],
						[
							-0.2770777,
							51.5628401
						],
						[
							-0.2764881,
							51.5627178
						],
						[
							-0.2759562,
							51.5626076
						],
						[
							-0.2759509,
							51.5626065
						],
						[
							-0.2750469,
							51.5624339
						],
						[
							-0.2740786,
							51.5622427
						],
						[
							-0.2736641,
							51.5621396
						],
						[
							-0.2732724,
							51.5620359
						],
						[
							-0.2728376,
							51.5619067
						],
						[
							-0.2724054,
							51.5617759
						],
						[
							-0.2717909,
							51.5615538
						],
						[
							-0.2713845,
							51.5614078
						],
						[
							-0.2710041,
							51.5612613
						],
						[
							-0.2700237,
							51.5608897
						],
						[
							-0.2691105,
							51.5605358
						],
						[
							-0.2679887,
							51.5600762
						],
						[
							-0.2674131,
							51.5598392
						],
						[
							-0.2668634,
							51.5596169
						],
						[
							-0.2647003,
							51.558715
						],
						[
							-0.2638488,
							51.5583585
						],
						[
							-0.2634219,
							51.5581707
						],
						[
							-0.2629792,
							51.55799
						],
						[
							-0.2620993,
							51.5576463
						],
						[
							-0.2616543,
							51.5574776
						],
						[
							-0.2612379,
							51.5573092
						],
						[
							-0.2598844,
							51.5568452
						],
						[
							-0.2591453,
							51.5566016
						],
						[
							-0.258513,
							51.5564075
						],
						[
							-0.2579386,
							51.5562332
						],
						[
							-0.257028,
							51.5559657
						],
						[
							-0.2561198,
							51.555708
						],
						[
							-0.2551094,
							51.555437
						],
						[
							-0.2545804,
							51.5552923
						],
						[
							-0.2541174,
							51.5551662
						],
						[
							-0.2536762,
							51.5550539
						],
						[
							-0.253251,
							51.5549457
						],
						[
							-0.2523732,
							51.5547403
						],
						[
							-0.2520862,
							51.5546732
						],
						[
							-0.2510633,
							51.5544325
						],
						[
							-0.250373,
							51.5542758
						],
						[
							-0.24998230166395,
							51.554173240495345
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.249943932220747,
							51.5541629455649
						],
						[
							-0.2491197,
							51.553937
						],
						[
							-0.2483442,
							51.5537085
						],
						[
							-0.2472624,
							51.5534076
						],
						[
							-0.2464961,
							51.553224
						],
						[
							-0.2461479,
							51.5531464
						],
						[
							-0.2455038,
							51.5530087
						],
						[
							-0.2448431,
							51.5528817
						],
						[
							-0.2441847,
							51.5527751
						],
						[
							-0.2435079,
							51.5526756
						],
						[
							-0.2414691,
							51.5523932
						],
						[
							-0.2403622,
							51.5522379
						],
						[
							-0.2397786,
							51.5521574
						],
						[
							-0.239248661896402,
							51.552082466077074
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.239209628979158,
							51.55207636015685
						],
						[
							-0.2374638,
							51.5517769
						],
						[
							-0.236416,
							51.5515884
						],
						[
							-0.2354998,
							51.551431
						],
						[
							-0.2349567,
							51.5513404
						],
						[
							-0.2339842,
							51.5511882
						],
						[
							-0.2332398,
							51.55107
						],
						[
							-0.2330018,
							51.5510297
						],
						[
							-0.2312084,
							51.5507484
						],
						[
							-0.229434,
							51.5504681
						],
						[
							-0.2276777,
							51.5501956
						],
						[
							-0.2267127,
							51.5500721
						],
						[
							-0.225863,
							51.549951
						],
						[
							-0.2237701,
							51.549621
						],
						[
							-0.2225224,
							51.5494318
						],
						[
							-0.221874425577687,
							51.549332395384496
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.221835415411901,
							51.54932614581723
						],
						[
							-0.2212777,
							51.549233
						],
						[
							-0.2198681,
							51.5490082
						],
						[
							-0.2186239,
							51.5487865
						],
						[
							-0.2180459,
							51.5486942
						],
						[
							-0.214619,
							51.5481745
						],
						[
							-0.2131897,
							51.5479464
						],
						[
							-0.2121555,
							51.5477841
						],
						[
							-0.2112041,
							51.5476251
						],
						[
							-0.2101097,
							51.5474692
						],
						[
							-0.2094229,
							51.5473612
						],
						[
							-0.2089578,
							51.5472941
						],
						[
							-0.2084654,
							51.5472238
						],
						[
							-0.2079986,
							51.5471891
						],
						[
							-0.2077433,
							51.5471657
						],
						[
							-0.2071148,
							51.5471136
						],
						[
							-0.2063636,
							51.5470859
						],
						[
							-0.205306021668862,
							51.5470258259385
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.205266343082357,
							51.54702379661356
						],
						[
							-0.2043828,
							51.5469836
						],
						[
							-0.2035509,
							51.5469032
						],
						[
							-0.2028563,
							51.5468537
						],
						[
							-0.2016011,
							51.546706
						],
						[
							-0.2005084,
							51.5466491
						],
						[
							-0.1986623,
							51.546518
						],
						[
							-0.1977397,
							51.5464722
						],
						[
							-0.1971013,
							51.5464471
						],
						[
							-0.1966842,
							51.5464306
						],
						[
							-0.19632,
							51.5464241
						],
						[
							-0.1959611,
							51.5464322
						],
						[
							-0.195486,
							51.5464567
						],
						[
							-0.1950287,
							51.5464856
						],
						[
							-0.1944766,
							51.5465404
						],
						[
							-0.1912044,
							51.546824
						],
						[
							-0.190819723995531,
							51.54685485737634
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.190780162118744,
							51.54685762357744
						],
						[
							-0.1889002,
							51.5469655
						],
						[
							-0.1870591,
							51.5470852
						],
						[
							-0.1862871,
							51.5471515
						],
						[
							-0.1855964,
							51.5472283
						],
						[
							-0.183957,
							51.547431
						],
						[
							-0.1831866,
							51.5475152
						],
						[
							-0.1827214,
							51.5475427
						],
						[
							-0.1826142,
							51.5475447
						],
						[
							-0.1821859,
							51.5475441
						],
						[
							-0.1818009,
							51.5475215
						],
						[
							-0.1815902,
							51.5475002
						],
						[
							-0.180903264087089,
							51.54738078362103
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.180864707706112,
							51.54737166896924
						],
						[
							-0.1802555,
							51.5471888
						],
						[
							-0.1800085,
							51.5470848
						],
						[
							-0.1797983,
							51.5469445
						],
						[
							-0.1796394,
							51.5468276
						],
						[
							-0.1795612,
							51.5467683
						],
						[
							-0.1794054,
							51.5466627
						],
						[
							-0.1792438,
							51.5465331
						],
						[
							-0.1791003,
							51.5463947
						],
						[
							-0.1789376,
							51.5462229
						],
						[
							-0.1787585,
							51.5460296
						],
						[
							-0.1786375,
							51.5459246
						],
						[
							-0.1784135,
							51.5457405
						],
						[
							-0.1783119,
							51.5456765
						],
						[
							-0.1781869,
							51.5456069
						],
						[
							-0.1778918,
							51.5454885
						],
						[
							-0.1764122,
							51.544939
						],
						[
							-0.1762024,
							51.5448326
						],
						[
							-0.1760492,
							51.5447361
						],
						[
							-0.1759156,
							51.5446415
						],
						[
							-0.175882590374857,
							51.54461391813201
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.448803803896635,
							51.639361332724086
						],
						[
							-0.4487918,
							51.6393581
						],
						[
							-0.44849,
							51.6392768
						],
						[
							-0.4481512,
							51.6391914
						],
						[
							-0.4473844,
							51.6390065
						],
						[
							-0.44678,
							51.6388616
						],
						[
							-0.4465165,
							51.6387949
						],
						[
							-0.4457207,
							51.6385893
						],
						[
							-0.4449064,
							51.6383633
						],
						[
							-0.4447155,
							51.638312
						],
						[
							-0.4440957,
							51.6381241
						],
						[
							-0.4434084,
							51.6379059
						],
						[
							-0.4427191,
							51.6376836
						],
						[
							-0.4422534,
							51.6375221
						],
						[
							-0.4416167,
							51.6372791
						],
						[
							-0.4409822,
							51.6370195
						],
						[
							-0.4403986,
							51.6367684
						],
						[
							-0.4401813,
							51.6366656
						],
						[
							-0.4397788,
							51.6364715
						],
						[
							-0.4395077,
							51.6363433
						],
						[
							-0.439013,
							51.636079
						],
						[
							-0.4385592,
							51.6358207
						],
						[
							-0.4382753,
							51.6356565
						],
						[
							-0.4377881,
							51.6353661
						],
						[
							-0.4373579,
							51.6350924
						],
						[
							-0.4371364,
							51.6349496
						],
						[
							-0.4367621,
							51.6346926
						],
						[
							-0.4363857,
							51.6344202
						],
						[
							-0.4360286,
							51.6341452
						],
						[
							-0.4357146,
							51.6339002
						],
						[
							-0.4355167,
							51.6337407
						],
						[
							-0.4351941,
							51.633463
						],
						[
							-0.4348585,
							51.6331586
						],
						[
							-0.4346714,
							51.6329877
						],
						[
							-0.4343789,
							51.6326953
						],
						[
							-0.4339894,
							51.6322858
						],
						[
							-0.4338862,
							51.632171
						],
						[
							-0.4337981,
							51.6320632
						],
						[
							-0.4334895,
							51.6316713
						],
						[
							-0.4331464,
							51.6311927
						],
						[
							-0.4327549,
							51.6305912
						],
						[
							-0.4324495,
							51.6301139
						],
						[
							-0.432245011515693,
							51.62979086333348
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.577867091647719,
							51.671218428752226
						],
						[
							-0.5778581,
							51.6712167
						],
						[
							-0.5742027,
							51.6705045
						],
						[
							-0.5726341,
							51.6701989
						],
						[
							-0.5677647,
							51.6693407
						],
						[
							-0.5632057,
							51.6684911
						],
						[
							-0.560839920714476,
							51.66804555746476
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.346464700494387,
							51.580132707757855
						],
						[
							-0.346457164809852,
							51.5801317
						],
						[
							-0.345890329619701,
							51.5800707
						],
						[
							-0.344951,
							51.5799817
						],
						[
							-0.3444129,
							51.5799466
						],
						[
							-0.3438496,
							51.5799147
						],
						[
							-0.3433761,
							51.5798988
						],
						[
							-0.3430112,
							51.5798816
						],
						[
							-0.3427746,
							51.5798713
						],
						[
							-0.342525,
							51.5798602
						],
						[
							-0.342066,
							51.5798413
						],
						[
							-0.3413826,
							51.5798163
						],
						[
							-0.3410571,
							51.5798043
						],
						[
							-0.3406961,
							51.579794
						],
						[
							-0.3403402,
							51.579794
						],
						[
							-0.3398848,
							51.5798077
						],
						[
							-0.3396692,
							51.5798141
						],
						[
							-0.3394441,
							51.5798205
						],
						[
							-0.3392036,
							51.5798174
						],
						[
							-0.3388218,
							51.5798017
						],
						[
							-0.3385015,
							51.5797727
						],
						[
							-0.3383517,
							51.5797529
						],
						[
							-0.3381933,
							51.5797303
						],
						[
							-0.3378763,
							51.5796644
						],
						[
							-0.3375581,
							51.5795946
						],
						[
							-0.3372458,
							51.5795044
						],
						[
							-0.337189,
							51.579488
						],
						[
							-0.3369198,
							51.5794064
						],
						[
							-0.336323867384081,
							51.57924295693008
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.3951029,
							51.6501827
						],
						[
							-0.3954347,
							51.6498585
						],
						[
							-0.3962358,
							51.6488025
						],
						[
							-0.3976666,
							51.6475195
						],
						[
							-0.3982779,
							51.6470894
						],
						[
							-0.3990074,
							51.6466433
						],
						[
							-0.4001554,
							51.6460508
						],
						[
							-0.4007562,
							51.6457579
						],
						[
							-0.4016575,
							51.6455182
						],
						[
							-0.4023227,
							51.6454184
						],
						[
							-0.4028698,
							51.6453851
						],
						[
							-0.4034706,
							51.6454051
						],
						[
							-0.4041144,
							51.6454783
						],
						[
							-0.4045757,
							51.6455648
						],
						[
							-0.4050126,
							51.6457135
						],
						[
							-0.4060351,
							51.6460017
						],
						[
							-0.408661365509033,
							51.64679197190352
						],
						[
							-0.4135128,
							51.6482477
						],
						[
							-0.4166296,
							51.6492177
						],
						[
							-0.4175616,
							51.6494769
						],
						[
							-0.4182319,
							51.6496597
						],
						[
							-0.4189715,
							51.6498252
						],
						[
							-0.4198207,
							51.6500036
						],
						[
							-0.4204064,
							51.6501163
						],
						[
							-0.4209268,
							51.6502018
						],
						[
							-0.4214905,
							51.6502884
						],
						[
							-0.4221017,
							51.6503714
						],
						[
							-0.4227538,
							51.6504539
						],
						[
							-0.4234609,
							51.6505258
						],
						[
							-0.4240383,
							51.6505855
						],
						[
							-0.424276,
							51.650607
						],
						[
							-0.424368381500244,
							51.6506264282379
						],
						[
							-0.4245426,
							51.650637
						],
						[
							-0.4248581,
							51.6506846
						],
						[
							-0.4256131,
							51.6508131
						],
						[
							-0.4261603,
							51.6509596
						],
						[
							-0.4269113,
							51.6512192
						],
						[
							-0.4278554,
							51.6514721
						],
						[
							-0.4286494,
							51.6516852
						],
						[
							-0.429497,
							51.6518649
						],
						[
							-0.4302855,
							51.6519341
						],
						[
							-0.4310634,
							51.651978
						],
						[
							-0.4317924,
							51.651978
						],
						[
							-0.4320526,
							51.6519927
						]
					]
				}
			}
		]
	},
	{
		name: "Hammersmith & City",
		lineColor: "#e3719d",
		features: [
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.170775297662297,
							51.518284431098486
						],
						[
							-0.1707503,
							51.5182981
						],
						[
							-0.1701444,
							51.5186345
						],
						[
							-0.1690089,
							51.5192757
						],
						[
							-0.168785,
							51.5194021
						],
						[
							-0.168382,
							51.5196233
						],
						[
							-0.1681064713195,
							51.51972463311771
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.073643834865545,
							51.51454657376696
						],
						[
							-0.071895497641689,
							51.51514077730002
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.071858374856913,
							51.515154942552805
						],
						[
							-0.0678046,
							51.5168771
						],
						[
							-0.0650373,
							51.5179188
						],
						[
							-0.0633722,
							51.5184283
						],
						[
							-0.0631195,
							51.518545
						],
						[
							-0.0628378,
							51.5188134
						],
						[
							-0.0625894,
							51.5189809
						],
						[
							-0.0622551,
							51.5191657
						],
						[
							-0.0620466,
							51.5192773
						],
						[
							-0.0619028,
							51.5193375
						],
						[
							-0.0617838,
							51.5193876
						],
						[
							-0.0616364,
							51.5194428
						],
						[
							-0.0613856,
							51.5195146
						],
						[
							-0.061,
							51.5196
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.061,
							51.5196
						],
						[
							-0.060529270547408,
							51.51972204850047
						],
						[
							-0.060283028595149,
							51.519761105295366
						],
						[
							-0.0600281,
							51.5197885
						],
						[
							-0.0597672,
							51.5198054
						],
						[
							-0.0595227,
							51.5198063
						],
						[
							-0.0593181,
							51.5197925
						],
						[
							-0.0590847,
							51.5197673
						],
						[
							-0.0588746,
							51.519744
						],
						[
							-0.0586472,
							51.5197045
						],
						[
							-0.0583709,
							51.5196712
						],
						[
							-0.0583565,
							51.5196745
						],
						[
							-0.0581935,
							51.5196524
						],
						[
							-0.057864,
							51.5196586
						],
						[
							-0.0566344,
							51.519924
						],
						[
							-0.0513925,
							51.5208443
						],
						[
							-0.04674762591462,
							51.521710975367476
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.046708865325785,
							51.5217188007016
						],
						[
							-0.0454445,
							51.5219935
						],
						[
							-0.0440455,
							51.5221751
						],
						[
							-0.0422945,
							51.5223353
						],
						[
							-0.0409556,
							51.5225916
						],
						[
							-0.0387539,
							51.5233109
						],
						[
							-0.037618,
							51.5239798
						],
						[
							-0.0374122,
							51.5241009
						],
						[
							-0.0353384,
							51.524877
						],
						[
							-0.033464129073488,
							51.52535143256148
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.033425539847342,
							51.525360339837036
						],
						[
							-0.0265272,
							51.5267998
						],
						[
							-0.026039,
							51.5268736
						],
						[
							-0.0257121,
							51.5269077
						],
						[
							-0.0254295,
							51.5269147
						],
						[
							-0.0251305,
							51.5269124
						],
						[
							-0.02486117292534,
							51.52687529644316
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.024822141368725,
							51.52686918193227
						],
						[
							-0.0246099,
							51.5268319
						],
						[
							-0.0243152,
							51.5267555
						],
						[
							-0.0239914,
							51.5266492
						],
						[
							-0.0235822,
							51.5264558
						],
						[
							-0.0218763,
							51.525568
						],
						[
							-0.0212434,
							51.525229
						],
						[
							-0.0207648,
							51.5249674
						],
						[
							-0.0206234,
							51.5248914
						],
						[
							-0.0203972,
							51.5247996
						],
						[
							-0.0202218,
							51.5247341
						],
						[
							-0.0200331,
							51.5246856
						],
						[
							-0.0197387,
							51.5246328
						],
						[
							-0.019272,
							51.5245995
						],
						[
							-0.0184861,
							51.5245777
						],
						[
							-0.0179745,
							51.5245591
						],
						[
							-0.0161955,
							51.5245078
						],
						[
							-0.0149456,
							51.5244736
						],
						[
							-0.0146557,
							51.5244702
						],
						[
							-0.0142303,
							51.5244793
						],
						[
							-0.0137109,
							51.524518
						],
						[
							-0.0137102,
							51.524518
						],
						[
							-0.0133658,
							51.5245496
						],
						[
							-0.0124243,
							51.5246408
						],
						[
							-0.011604744810281,
							51.52473435742037
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.011565519693677,
							51.52473924984159
						],
						[
							-0.0109947,
							51.5248165
						],
						[
							-0.0106232,
							51.5248635
						],
						[
							-0.0101843,
							51.5249241
						],
						[
							-0.0097502,
							51.5249877
						],
						[
							-0.0093524,
							51.5250677
						],
						[
							-0.0088181,
							51.5251776
						],
						[
							-0.0084199,
							51.5252649
						],
						[
							-0.0081008,
							51.5253407
						],
						[
							-0.0064147,
							51.5257764
						],
						[
							-0.0061444,
							51.5258416
						],
						[
							-0.0047993,
							51.5261357
						],
						[
							-0.0034446,
							51.5264546
						],
						[
							-0.0007441,
							51.5270809
						],
						[
							9.83e-05,
							51.5272755
						],
						[
							0.0010875,
							51.527501
						],
						[
							0.0018706,
							51.5276874
						],
						[
							0.0026478,
							51.527882
						],
						[
							0.0032143,
							51.528018
						],
						[
							0.0035768,
							51.528111
						],
						[
							0.0040786,
							51.5282492
						],
						[
							0.004379898832705,
							51.52833066676116
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.004418269798922,
							51.52834095207567
						],
						[
							0.0049299,
							51.5284769
						],
						[
							0.0062045,
							51.5287752
						],
						[
							0.007501,
							51.5290766
						],
						[
							0.0100914,
							51.5296594
						],
						[
							0.0107491,
							51.5298133
						],
						[
							0.0113738,
							51.5299534
						],
						[
							0.0115755,
							51.5299986
						],
						[
							0.0126243,
							51.5302266
						],
						[
							0.0135611,
							51.5304312
						],
						[
							0.0138022,
							51.5304839
						],
						[
							0.0139032,
							51.5305073
						],
						[
							0.015138,
							51.5307938
						],
						[
							0.015661,
							51.5309302
						],
						[
							0.01655216047163,
							51.531152598571126
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.016590736025331,
							51.5311615921711
						],
						[
							0.0176341,
							51.5313878
						],
						[
							0.0185284,
							51.5315507
						],
						[
							0.019354,
							51.5317429
						],
						[
							0.0209768,
							51.5321117
						],
						[
							0.0240752,
							51.5328379
						],
						[
							0.0248908,
							51.5330273
						],
						[
							0.0257895,
							51.5332278
						],
						[
							0.0276108,
							51.5336391
						],
						[
							0.0284731,
							51.5338382
						],
						[
							0.0294771,
							51.5340696
						],
						[
							0.0311225,
							51.5344441
						],
						[
							0.0318736,
							51.5346306
						],
						[
							0.0325875,
							51.5348043
						],
						[
							0.034394802608714,
							51.5352197639031
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.034433399469593,
							51.535228622974856
						],
						[
							0.0350852,
							51.535378
						],
						[
							0.0356797,
							51.5355063
						],
						[
							0.0367013,
							51.5357183
						],
						[
							0.0377329,
							51.5359594
						],
						[
							0.0392241,
							51.5363038
						],
						[
							0.0400767,
							51.5365028
						],
						[
							0.040826,
							51.5366754
						],
						[
							0.0419764,
							51.5369385
						],
						[
							0.0431243,
							51.5371997
						],
						[
							0.0442692,
							51.5374678
						],
						[
							0.0452291,
							51.5376892
						],
						[
							0.0462418,
							51.5379161
						],
						[
							0.0471814,
							51.5381264
						],
						[
							0.0481313,
							51.5383353
						],
						[
							0.0487791,
							51.5384766
						],
						[
							0.0495945,
							51.5386561
						],
						[
							0.050159,
							51.5387808
						],
						[
							0.0507203,
							51.538912
						],
						[
							0.0515563,
							51.5391222
						],
						[
							0.051992206255451,
							51.539237809503476
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.052030520280677,
							51.53924845432926
						],
						[
							0.0524627,
							51.539374
						],
						[
							0.0529046,
							51.539506
						],
						[
							0.0533232,
							51.5396362
						],
						[
							0.0539079,
							51.5398259
						],
						[
							0.0545055,
							51.5400185
						],
						[
							0.0545591,
							51.5400371
						],
						[
							0.0551107,
							51.5402277
						],
						[
							0.0555237,
							51.540375
						],
						[
							0.0559404,
							51.5405403
						],
						[
							0.0563194,
							51.5406895
						],
						[
							0.0566839,
							51.5408423
						],
						[
							0.0573124,
							51.5411316
						],
						[
							0.0579685,
							51.5414345
						],
						[
							0.0585113,
							51.5417064
						],
						[
							0.0587426,
							51.5418445
						],
						[
							0.0589664,
							51.5419858
						],
						[
							0.059157,
							51.5421257
						],
						[
							0.0593268,
							51.5422675
						],
						[
							0.0594888,
							51.5424137
						],
						[
							0.059639,
							51.5425614
						],
						[
							0.0600537,
							51.5429769
						],
						[
							0.0602573,
							51.5431659
						],
						[
							0.0605833,
							51.5434308
						],
						[
							0.0609278,
							51.5436839
						],
						[
							0.0614443,
							51.5439485
						],
						[
							0.061834,
							51.5441184
						],
						[
							0.0622295,
							51.5442618
						],
						[
							0.0626882,
							51.5444012
						],
						[
							0.0631504,
							51.5445054
						],
						[
							0.0635694,
							51.5445827
						],
						[
							0.0640027,
							51.5446403
						],
						[
							0.0643578,
							51.5446711
						],
						[
							0.0646828,
							51.5446869
						],
						[
							0.0650206,
							51.5446925
						],
						[
							0.065122189956493,
							51.5446881344765
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.0764812,
							51.5149196
						],
						[
							-0.0762221,
							51.5147657
						],
						[
							-0.0760294,
							51.5146774
						],
						[
							-0.0758693,
							51.5146129
						],
						[
							-0.0756553,
							51.5145585
						],
						[
							-0.0755185,
							51.5145387
						],
						[
							-0.0753312,
							51.5145246
						],
						[
							-0.0750571,
							51.5145092
						],
						[
							-0.0744925,
							51.5144988
						],
						[
							-0.0744295,
							51.5144995
						],
						[
							-0.0740914,
							51.5145002
						],
						[
							-0.0739176,
							51.5145097
						],
						[
							-0.0737451,
							51.5145268
						],
						[
							-0.0736467,
							51.5145456
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.155492525346621,
							51.522504116908024
						],
						[
							-0.1533537,
							51.5228684
						],
						[
							-0.1523934,
							51.5230468
						],
						[
							-0.1511804,
							51.5231119
						],
						[
							-0.150532,
							51.5231427
						],
						[
							-0.1489664,
							51.5233223
						],
						[
							-0.1459327,
							51.523848
						],
						[
							-0.1455211,
							51.5239011
						],
						[
							-0.1449549,
							51.5239103
						],
						[
							-0.1444584,
							51.5238667
						],
						[
							-0.1441956,
							51.5238502
						],
						[
							-0.143940745527829,
							51.523837675298964
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.143901016817776,
							51.52383743755832
						],
						[
							-0.143406,
							51.5238558
						],
						[
							-0.1429079,
							51.5239112
						],
						[
							-0.1425187,
							51.5239807
						],
						[
							-0.1418167,
							51.5241334
						],
						[
							-0.1393864,
							51.5246434
						],
						[
							-0.1367951,
							51.5253289
						],
						[
							-0.1361146,
							51.5255435
						],
						[
							-0.135553161865739,
							51.52567933960369
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.135515045005948,
							51.525690506400686
						],
						[
							-0.1312815,
							51.5271514
						],
						[
							-0.1297425,
							51.5277042
						],
						[
							-0.1273714,
							51.5286265
						],
						[
							-0.1265845,
							51.5289391
						],
						[
							-0.1263801,
							51.5290327
						],
						[
							-0.1261333,
							51.5291794
						],
						[
							-0.1258254,
							51.5293319
						],
						[
							-0.1253003,
							51.5295341
						],
						[
							-0.1246817,
							51.5297729
						],
						[
							-0.124271529667222,
							51.529942123067485
						],
						[
							-0.123952866715,
							51.53006660779597
						],
						[
							-0.123700948191111,
							51.53017822300185
						],
						[
							-0.123580648191111,
							51.53026673066885
						],
						[
							-0.123444651953889,
							51.53037303804771
						],
						[
							-0.123110192764445,
							51.53054120675818
						],
						[
							-0.122962160202241,
							51.53059771503784
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.12292379472707,
							51.53060602945845
						],
						[
							-0.122353407380556,
							51.53064143758187
						],
						[
							-0.122095474095556,
							51.53064970729707
						],
						[
							-0.121954477858333,
							51.53065289218352
						],
						[
							-0.1217474,
							51.5306533
						],
						[
							-0.1216103,
							51.5306604
						],
						[
							-0.1214735,
							51.5306593
						],
						[
							-0.1213795,
							51.530655
						],
						[
							-0.1212348,
							51.5306403
						],
						[
							-0.1210148,
							51.5306141
						],
						[
							-0.1207905,
							51.5305706
						],
						[
							-0.1205329,
							51.5305173
						],
						[
							-0.1200043,
							51.5303915
						],
						[
							-0.1197513,
							51.5303313
						],
						[
							-0.1196105,
							51.5302924
						],
						[
							-0.1194049,
							51.5302272
						],
						[
							-0.1191815,
							51.5301543
						],
						[
							-0.1190099,
							51.5300977
						],
						[
							-0.1188235,
							51.5300139
						],
						[
							-0.1186771,
							51.5299356
						],
						[
							-0.1185085,
							51.5298479
						],
						[
							-0.1177164,
							51.5293958
						],
						[
							-0.1170423,
							51.5289971
						],
						[
							-0.1161271,
							51.528482
						],
						[
							-0.1156745,
							51.528204
						],
						[
							-0.1150026,
							51.527804
						],
						[
							-0.1145395,
							51.5275097
						],
						[
							-0.1134928,
							51.5268723
						],
						[
							-0.1126765,
							51.5262604
						],
						[
							-0.1114689,
							51.5255569
						],
						[
							-0.1106337,
							51.5250381
						],
						[
							-0.108462,
							51.5235977
						],
						[
							-0.1078712,
							51.5232567
						],
						[
							-0.1074259,
							51.5229345
						],
						[
							-0.107084,
							51.5226981
						],
						[
							-0.1067882,
							51.5225357
						],
						[
							-0.1066583,
							51.5224465
						],
						[
							-0.1065368,
							51.5223312
						],
						[
							-0.1063974,
							51.5221629
						],
						[
							-0.1063,
							51.5220185
						],
						[
							-0.1059965,
							51.5216826
						],
						[
							-0.1055977,
							51.5212173
						],
						[
							-0.1054429,
							51.5210751
						],
						[
							-0.1053096,
							51.5209386
						],
						[
							-0.1052104,
							51.5208087
						],
						[
							-0.1050621,
							51.5205945
						],
						[
							-0.1049786,
							51.52049
						],
						[
							-0.1049105,
							51.5204203
						],
						[
							-0.1047893,
							51.5203103
						],
						[
							-0.104680917695698,
							51.52021971834924
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.104649478524547,
							51.52019532502805
						],
						[
							-0.1045501,
							51.5201238
						],
						[
							-0.1044427,
							51.5200569
						],
						[
							-0.1043486,
							51.5200065
						],
						[
							-0.1041686,
							51.5199284
						],
						[
							-0.1039902,
							51.5198458
						],
						[
							-0.1037526,
							51.5197716
						],
						[
							-0.1034739,
							51.5197064
						],
						[
							-0.1032029,
							51.5196526
						],
						[
							-0.102968,
							51.5196223
						],
						[
							-0.1028153,
							51.5196094
						],
						[
							-0.1026523,
							51.519611
						],
						[
							-0.1023661,
							51.5196126
						],
						[
							-0.1019871,
							51.5196558
						],
						[
							-0.1015112,
							51.5197588
						],
						[
							-0.1007599,
							51.5199783
						],
						[
							-0.1001275,
							51.5200953
						],
						[
							-0.0995453,
							51.5201443
						],
						[
							-0.097812273048081,
							51.520214198457126
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.097772459123226,
							51.52021537328936
						],
						[
							-0.0976268,
							51.5202181
						],
						[
							-0.0972497,
							51.5201997
						],
						[
							-0.0967731,
							51.5201377
						],
						[
							-0.0964925,
							51.5200721
						],
						[
							-0.0905652,
							51.5186174
						],
						[
							-0.0891116,
							51.5182848
						],
						[
							-0.0886996,
							51.5181926
						],
						[
							-0.088309574207228,
							51.518123238006986
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.088270496098424,
							51.51811741741505
						],
						[
							-0.088149,
							51.5181028
						],
						[
							-0.0843534,
							51.5175
						],
						[
							-0.0840764,
							51.5174568
						],
						[
							-0.0838724,
							51.5174272
						],
						[
							-0.083232432479493,
							51.517303777976274
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.083193497910662,
							51.51729705110862
						],
						[
							-0.0818196,
							51.5170871
						],
						[
							-0.0809042,
							51.5169054
						],
						[
							-0.0792088,
							51.5163741
						],
						[
							-0.0786992,
							51.5161805
						],
						[
							-0.0771818,
							51.5153382
						],
						[
							-0.076881,
							51.515143
						],
						[
							-0.0764812,
							51.5149196
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.16806899706272,
							51.51973810856785
						],
						[
							-0.1661795,
							51.5204022
						],
						[
							-0.1656381,
							51.5205881
						],
						[
							-0.1656027,
							51.5206009
						],
						[
							-0.1653598,
							51.5206599
						],
						[
							-0.1650035,
							51.5207522
						],
						[
							-0.1636888,
							51.5209755
						],
						[
							-0.1605919,
							51.5215638
						],
						[
							-0.1574923,
							51.5221531
						],
						[
							-0.1566779,
							51.5222984
						],
						[
							-0.1558488,
							51.5224417
						],
						[
							-0.155531459180359,
							51.52249738539918
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.225086952607429,
							51.49411566076714
						],
						[
							-0.2252555,
							51.4949163
						],
						[
							-0.225488,
							51.4961213
						],
						[
							-0.2255775,
							51.496585
						],
						[
							-0.2256142,
							51.4967693
						],
						[
							-0.2257437,
							51.4974192
						],
						[
							-0.2259091,
							51.498243
						],
						[
							-0.2259529,
							51.4984725
						],
						[
							-0.2259631,
							51.4985226
						],
						[
							-0.2259994,
							51.4987
						],
						[
							-0.2260808,
							51.4990807
						],
						[
							-0.2262667,
							51.4999085
						],
						[
							-0.2266027,
							51.5015751
						],
						[
							-0.226670275720096,
							51.501927289867986
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.226677809730109,
							51.501966096598025
						],
						[
							-0.2267386,
							51.5022756
						],
						[
							-0.2267751,
							51.5024753
						],
						[
							-0.2267939,
							51.5026173
						],
						[
							-0.2268074,
							51.5027839
						],
						[
							-0.2268264,
							51.5030925
						],
						[
							-0.2268113,
							51.5035308
						],
						[
							-0.226761,
							51.5039619
						],
						[
							-0.2267299,
							51.5041492
						],
						[
							-0.2266921,
							51.5043223
						],
						[
							-0.2266118,
							51.5046886
						],
						[
							-0.2265487,
							51.5049974
						],
						[
							-0.2264743,
							51.5053051
						],
						[
							-0.2264276,
							51.5055468
						],
						[
							-0.226386566432561,
							51.50577704902898
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.226378818502893,
							51.505815821877476
						],
						[
							-0.2262511,
							51.5063922
						],
						[
							-0.2261149,
							51.5069966
						],
						[
							-0.2260466,
							51.5072947
						],
						[
							-0.2259522,
							51.5075905
						],
						[
							-0.2257881,
							51.5079564
						],
						[
							-0.2257361,
							51.5080593
						],
						[
							-0.2256473,
							51.5082105
						],
						[
							-0.225569,
							51.5083388
						],
						[
							-0.2254406,
							51.5085282
						],
						[
							-0.2253031,
							51.5087066
						],
						[
							-0.2251704,
							51.5088704
						],
						[
							-0.2250134,
							51.5090292
						],
						[
							-0.224869,
							51.5091775
						],
						[
							-0.2247139,
							51.5093156
						],
						[
							-0.224554,
							51.5094556
						],
						[
							-0.2244008,
							51.5095876
						],
						[
							-0.2242419,
							51.5097246
						],
						[
							-0.2239966,
							51.509891
						],
						[
							-0.223728405664838,
							51.51006317530327
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.223694651462026,
							51.51008395177041
						],
						[
							-0.2233964,
							51.5102598
						],
						[
							-0.2230638,
							51.5104407
						],
						[
							-0.2226427,
							51.5106437
						],
						[
							-0.2223745,
							51.5107624
						],
						[
							-0.2221861,
							51.5108362
						],
						[
							-0.2217748,
							51.5109987
						],
						[
							-0.2214456,
							51.511127
						],
						[
							-0.2211046,
							51.5112773
						],
						[
							-0.2207242,
							51.5114628
						],
						[
							-0.2205035,
							51.5115703
						],
						[
							-0.2203423,
							51.5116594
						],
						[
							-0.2200498,
							51.5118305
						],
						[
							-0.2198147,
							51.5119789
						],
						[
							-0.2195988,
							51.5121333
						],
						[
							-0.2193983,
							51.5122882
						],
						[
							-0.218638,
							51.5128541
						],
						[
							-0.2182117,
							51.5131685
						],
						[
							-0.217925416803874,
							51.51338468075895
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.217893496688208,
							51.51340863273316
						],
						[
							-0.2176858,
							51.5135635
						],
						[
							-0.2174181,
							51.5137622
						],
						[
							-0.2171551,
							51.5139578
						],
						[
							-0.2166121,
							51.5143585
						],
						[
							-0.2163619,
							51.5145598
						],
						[
							-0.2161522,
							51.5147154
						],
						[
							-0.2159299,
							51.5148829
						],
						[
							-0.2153832,
							51.515284
						],
						[
							-0.2151132,
							51.5154861
						],
						[
							-0.2149049,
							51.5156409
						],
						[
							-0.2147079,
							51.5157867
						],
						[
							-0.214508,
							51.5159148
						],
						[
							-0.2143175,
							51.5160363
						],
						[
							-0.2141846,
							51.5161143
						],
						[
							-0.2140697,
							51.5161786
						],
						[
							-0.213859,
							51.5162926
						],
						[
							-0.213589,
							51.5164321
						],
						[
							-0.2132831,
							51.5165745
						],
						[
							-0.212857,
							51.516752
						],
						[
							-0.2126021,
							51.5168471
						],
						[
							-0.2123595,
							51.5169272
						],
						[
							-0.2121146,
							51.5169952
						],
						[
							-0.2119428,
							51.5170441
						],
						[
							-0.2117426,
							51.517094
						],
						[
							-0.2113369,
							51.5171823
						],
						[
							-0.210961534325825,
							51.51726389709867
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.210922916952829,
							51.51727262666201
						],
						[
							-0.2104357,
							51.517387
						],
						[
							-0.2102325,
							51.5174337
						],
						[
							-0.2098942,
							51.5175043
						],
						[
							-0.2093275,
							51.5176378
						],
						[
							-0.2088998,
							51.5177546
						],
						[
							-0.2085226,
							51.5178613
						],
						[
							-0.2083001,
							51.5179342
						],
						[
							-0.2081377,
							51.5179879
						],
						[
							-0.208001,
							51.5180396
						],
						[
							-0.2077436,
							51.5181465
						],
						[
							-0.2075092,
							51.5182514
						],
						[
							-0.2073667,
							51.5183227
						],
						[
							-0.2072214,
							51.5183957
						],
						[
							-0.2070198,
							51.5185016
						],
						[
							-0.2068347,
							51.5186071
						],
						[
							-0.2066527,
							51.5187154
						],
						[
							-0.2060856,
							51.5191172
						],
						[
							-0.2058217,
							51.5193291
						],
						[
							-0.2055497,
							51.5195429
						],
						[
							-0.2051377,
							51.5198784
						],
						[
							-0.204915,
							51.5200311
						],
						[
							-0.2046982,
							51.5201812
						],
						[
							-0.2045154,
							51.5203014
						],
						[
							-0.2043133,
							51.5204074
						],
						[
							-0.2039199,
							51.520594
						],
						[
							-0.2036759,
							51.5206754
						],
						[
							-0.2034007,
							51.5207568
						],
						[
							-0.2032078,
							51.5208119
						],
						[
							-0.2029384,
							51.5208703
						],
						[
							-0.2027239,
							51.5209049
						],
						[
							-0.2025246,
							51.5209244
						],
						[
							-0.2022819,
							51.5209475
						],
						[
							-0.2021084,
							51.5209544
						],
						[
							-0.2018707,
							51.5209594
						],
						[
							-0.2016877,
							51.5209548
						],
						[
							-0.201528392749563,
							51.520949377152746
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.201488762280151,
							51.52094704402844
						],
						[
							-0.2013464,
							51.5209351
						],
						[
							-0.2011771,
							51.5209168
						],
						[
							-0.2009628,
							51.5208909
						],
						[
							-0.2003173,
							51.5207579
						],
						[
							-0.1994297,
							51.5205454
						],
						[
							-0.1985761,
							51.5203248
						],
						[
							-0.197529,
							51.5200939
						],
						[
							-0.1963203,
							51.5198461
						],
						[
							-0.1958676,
							51.519755
						],
						[
							-0.195609,
							51.5197149
						],
						[
							-0.1952063,
							51.5196762
						],
						[
							-0.1942156,
							51.5196352
						],
						[
							-0.1935242,
							51.5195957
						],
						[
							-0.1930607,
							51.5195423
						],
						[
							-0.1902474,
							51.5192633
						],
						[
							-0.1886404,
							51.5191257
						],
						[
							-0.188008728045329,
							51.51906465712523
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.187969331047705,
							51.51906084991806
						],
						[
							-0.1868771,
							51.5189553
						],
						[
							-0.1867571,
							51.5189521
						],
						[
							-0.1863694,
							51.5189301
						],
						[
							-0.1859669,
							51.5189013
						],
						[
							-0.1856584,
							51.5188816
						],
						[
							-0.1854267,
							51.5188763
						],
						[
							-0.1851121,
							51.5188778
						],
						[
							-0.1847158,
							51.5188823
						],
						[
							-0.1843963,
							51.518877
						],
						[
							-0.1838195,
							51.5188581
						],
						[
							-0.1835097,
							51.5188345
						],
						[
							-0.1830268,
							51.5187882
						],
						[
							-0.181123942398223,
							51.51866030526168
						],
						[
							-0.180217263744531,
							51.51865827604148
						],
						[
							-0.17920755522877,
							51.51863457475454
						],
						[
							-0.178399437735794,
							51.51846295985478
						],
						[
							-0.177903849867763,
							51.5182520444855
						],
						[
							-0.177042499416766,
							51.51771090710539
						],
						[
							-0.1767,
							51.5175
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.1767,
							51.5175
						],
						[
							-0.176383568251966,
							51.51734704326364
						],
						[
							-0.176075034706559,
							51.51727863732075
						],
						[
							-0.175726873929971,
							51.51730376474049
						],
						[
							-0.174969903966407,
							51.517389733224086
						],
						[
							-0.174555167528679,
							51.51743886049032
						],
						[
							-0.173892190675023,
							51.51759473872599
						],
						[
							-0.1712322,
							51.5181784
						],
						[
							-0.1707503,
							51.5182981
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.065122189956493,
							51.5446881344765
						],
						[
							0.0653813,
							51.544677
						],
						[
							0.0657372,
							51.5446506
						],
						[
							0.0660495,
							51.5446124
						],
						[
							0.0663386,
							51.5445669
						],
						[
							0.0666419,
							51.5445067
						],
						[
							0.0669902,
							51.5444285
						],
						[
							0.0672572,
							51.5443566
						],
						[
							0.0675764,
							51.54427
						],
						[
							0.0681877,
							51.5441091
						],
						[
							0.0684659,
							51.5440498
						],
						[
							0.0689249,
							51.5439471
						],
						[
							0.069734,
							51.5437372
						],
						[
							0.0699873,
							51.543678
						],
						[
							0.0703484,
							51.5436011
						],
						[
							0.0706635,
							51.543544
						],
						[
							0.0719269,
							51.5433251
						],
						[
							0.0722917,
							51.5432561
						],
						[
							0.0725566,
							51.5432052
						],
						[
							0.0728556,
							51.5431357
						],
						[
							0.073255,
							51.5430334
						],
						[
							0.0736034,
							51.5429361
						],
						[
							0.0740168,
							51.5427999
						],
						[
							0.0743871,
							51.5426735
						],
						[
							0.0747253,
							51.5425471
						],
						[
							0.075137,
							51.5423817
						],
						[
							0.0755594,
							51.5422054
						],
						[
							0.0759818,
							51.542025
						],
						[
							0.0764818,
							51.5418031
						],
						[
							0.0768628,
							51.541621
						],
						[
							0.0771315,
							51.5414994
						],
						[
							0.0777878,
							51.5411712
						],
						[
							0.0783158,
							51.5409326
						],
						[
							0.0789387,
							51.5406572
						],
						[
							0.0794244,
							51.5404174
						],
						[
							0.0797226,
							51.5402751
						],
						[
							0.0805642,
							51.5398351
						],
						[
							0.081182953704177,
							51.539523778604526
						]
					]
				}
			}
		]
	},
	{
		name: "Central",
		lineColor: "#da0015",
		features: [
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.296195090588112,
							51.51503768722618
						],
						[
							-0.2958968,
							51.5150472
						],
						[
							-0.2950954,
							51.5150677
						],
						[
							-0.2942945,
							51.5150734
						],
						[
							-0.2921522,
							51.5150588
						],
						[
							-0.2915188,
							51.5150496
						],
						[
							-0.289848,
							51.5150176
						],
						[
							-0.2877187,
							51.5150245
						],
						[
							-0.2870898,
							51.5150522
						],
						[
							-0.2864031,
							51.5151174
						],
						[
							-0.2858531,
							51.5152204
						],
						[
							-0.2853144,
							51.5153541
						],
						[
							-0.284814,
							51.5155111
						],
						[
							-0.2842364,
							51.5157554
						],
						[
							-0.2837236,
							51.5160217
						],
						[
							-0.2830204,
							51.5164781
						],
						[
							-0.282499,
							51.5168581
						],
						[
							-0.2817151,
							51.5174006
						],
						[
							-0.2812223,
							51.5177569
						],
						[
							-0.280621367788235,
							51.51818481592202
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.280588785875316,
							51.51820789313762
						],
						[
							-0.2801953,
							51.5184851
						],
						[
							-0.2790263,
							51.5193164
						],
						[
							-0.2781409,
							51.5199799
						],
						[
							-0.2767399,
							51.5209957
						],
						[
							-0.2752672,
							51.5220245
						],
						[
							-0.2739485,
							51.5230026
						],
						[
							-0.2726752,
							51.5238817
						],
						[
							-0.2716218,
							51.5245068
						],
						[
							-0.2705936,
							51.5248516
						],
						[
							-0.2697666,
							51.5250525
						],
						[
							-0.2692777,
							51.5251252
						],
						[
							-0.2686328,
							51.525176
						],
						[
							-0.2678808,
							51.5251892
						],
						[
							-0.2673339,
							51.5251529
						],
						[
							-0.2667619,
							51.5250843
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.30154338885404,
							51.5152
						],
						[
							-0.296195090588112,
							51.51503768722618
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.437642838805582,
							51.56962560202927
						],
						[
							-0.43552,
							51.5689205
						],
						[
							-0.4347922,
							51.5686788
						],
						[
							-0.4329417,
							51.5680946
						],
						[
							-0.431913,
							51.5677698
						],
						[
							-0.4313587,
							51.567593
						],
						[
							-0.4311575,
							51.5675234
						],
						[
							-0.4301866,
							51.5672058
						],
						[
							-0.4293114,
							51.5669354
						],
						[
							-0.4275861,
							51.5663696
						],
						[
							-0.4267703,
							51.5660943
						],
						[
							-0.4259683,
							51.5658332
						],
						[
							-0.4251952,
							51.5655495
						],
						[
							-0.424407,
							51.5652704
						],
						[
							-0.4211801,
							51.5642084
						],
						[
							-0.4200512,
							51.5638358
						],
						[
							-0.4189099,
							51.563465
						],
						[
							-0.4177774,
							51.5630934
						],
						[
							-0.4166466,
							51.5627436
						],
						[
							-0.4158417,
							51.5624928
						],
						[
							-0.4150198,
							51.5622306
						],
						[
							-0.414381,
							51.5620197
						],
						[
							-0.4134422,
							51.5617108
						],
						[
							-0.4129795,
							51.5615517
						],
						[
							-0.4127309,
							51.5614786
						],
						[
							-0.4121224,
							51.5612603
						],
						[
							-0.4113496,
							51.5609907
						],
						[
							-0.410613571856145,
							51.56074717704591
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.410575642629366,
							51.56073459452467
						],
						[
							-0.4099927,
							51.5605407
						],
						[
							-0.4091011,
							51.5602624
						],
						[
							-0.4073891,
							51.5597259
						],
						[
							-0.4071422,
							51.5596448
						],
						[
							-0.406019,
							51.5592643
						],
						[
							-0.4048907,
							51.5588792
						],
						[
							-0.4035606,
							51.558402
						],
						[
							-0.4027899,
							51.5581419
						],
						[
							-0.4018851,
							51.5578518
						],
						[
							-0.4005864,
							51.5574418
						],
						[
							-0.399307453514388,
							51.55704067587497
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.39926952814085,
							51.55702822669894
						],
						[
							-0.3982829,
							51.5566898
						],
						[
							-0.3980948,
							51.5566214
						],
						[
							-0.3972286,
							51.5562833
						],
						[
							-0.3959033,
							51.5557372
						],
						[
							-0.3945829,
							51.5552097
						],
						[
							-0.3909443,
							51.5537536
						],
						[
							-0.3904266,
							51.5535419
						],
						[
							-0.3899359,
							51.5533602
						],
						[
							-0.3894022,
							51.5531834
						],
						[
							-0.3887417,
							51.5530004
						],
						[
							-0.3881701,
							51.5528646
						],
						[
							-0.3874823,
							51.5527289
						],
						[
							-0.3867212,
							51.5526057
						],
						[
							-0.3857633,
							51.552478
						],
						[
							-0.3850755,
							51.5523735
						],
						[
							-0.3845241,
							51.5522762
						],
						[
							-0.3840545,
							51.5521762
						],
						[
							-0.3835203,
							51.5520521
						],
						[
							-0.3828726,
							51.5518815
						],
						[
							-0.381988,
							51.5516574
						],
						[
							-0.3809081,
							51.5513877
						],
						[
							-0.3800279,
							51.5511609
						],
						[
							-0.3789652,
							51.5508913
						],
						[
							-0.3778824,
							51.5506207
						],
						[
							-0.3767997,
							51.5503466
						],
						[
							-0.3757399,
							51.550076
						],
						[
							-0.3746241,
							51.5497956
						],
						[
							-0.3735744,
							51.5495348
						],
						[
							-0.372796,
							51.5493429
						],
						[
							-0.3718712,
							51.5490991
						],
						[
							-0.3707066,
							51.5488008
						],
						[
							-0.3699426,
							51.5486088
						],
						[
							-0.369090238240509,
							51.548395709560126
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.369051758327206,
							51.54838611211044
						],
						[
							-0.3677685,
							51.5480668
						],
						[
							-0.3669083,
							51.5478471
						],
						[
							-0.3663009,
							51.5476944
						],
						[
							-0.3652511,
							51.5474301
						],
						[
							-0.3639587,
							51.5470979
						],
						[
							-0.3629506,
							51.5468353
						],
						[
							-0.3620431,
							51.5465942
						],
						[
							-0.3608627,
							51.5463066
						],
						[
							-0.3598324,
							51.5460406
						],
						[
							-0.3567438,
							51.5452318
						],
						[
							-0.3561559,
							51.5450779
						],
						[
							-0.3538812,
							51.5444681
						],
						[
							-0.3525312,
							51.5440908
						],
						[
							-0.3522176,
							51.5440032
						],
						[
							-0.3509496,
							51.5436746
						],
						[
							-0.3493809,
							51.5433013
						],
						[
							-0.3476034,
							51.5428578
						],
						[
							-0.3468053,
							51.5426668
						],
						[
							-0.3462173,
							51.5425289
						],
						[
							-0.344835557192349,
							51.542192389902944
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.344797093698952,
							51.54218268878205
						],
						[
							-0.3434856,
							51.5418405
						],
						[
							-0.3431005,
							51.5417421
						],
						[
							-0.3422341,
							51.5415031
						],
						[
							-0.3419405,
							51.5414173
						],
						[
							-0.340863,
							51.5411098
						],
						[
							-0.339838,
							51.5408388
						],
						[
							-0.3394197,
							51.5407246
						],
						[
							-0.3384852,
							51.5404339
						],
						[
							-0.3375486,
							51.5401859
						],
						[
							-0.3364115,
							51.5398879
						],
						[
							-0.3352528,
							51.5395786
						],
						[
							-0.334748,
							51.5394614
						],
						[
							-0.3339167,
							51.5392858
						],
						[
							-0.3332644,
							51.5391445
						],
						[
							-0.3326932,
							51.5390182
						],
						[
							-0.3315621,
							51.5387414
						],
						[
							-0.330746,
							51.5385478
						],
						[
							-0.3296898,
							51.5382966
						],
						[
							-0.3286354,
							51.5380391
						],
						[
							-0.327614,
							51.5377797
						],
						[
							-0.3265843,
							51.5375171
						],
						[
							-0.3250631,
							51.5371238
						],
						[
							-0.3242983,
							51.5369189
						],
						[
							-0.3240616,
							51.5368571
						],
						[
							-0.323599056812795,
							51.53674439229934
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.323560586736383,
							51.53673473274197
						],
						[
							-0.3228151,
							51.536542
						],
						[
							-0.321984,
							51.536332
						],
						[
							-0.3210785,
							51.5361085
						],
						[
							-0.3202211,
							51.535883
						],
						[
							-0.3196528,
							51.5357374
						],
						[
							-0.3194758,
							51.535693
						],
						[
							-0.3189285,
							51.535557
						],
						[
							-0.3175554,
							51.5352111
						],
						[
							-0.3164025,
							51.5349155
						],
						[
							-0.3154695,
							51.5346803
						],
						[
							-0.3146455,
							51.5344721
						],
						[
							-0.3137652,
							51.5342478
						],
						[
							-0.3126357,
							51.5339573
						],
						[
							-0.3114523,
							51.5336536
						],
						[
							-0.3100757,
							51.5333012
						],
						[
							-0.3097808,
							51.5332272
						],
						[
							-0.3095286,
							51.5331641
						],
						[
							-0.308657,
							51.5329452
						],
						[
							-0.308099,
							51.5328152
						],
						[
							-0.3077466,
							51.5327249
						],
						[
							-0.307483,
							51.5326444
						],
						[
							-0.3063862,
							51.5323655
						],
						[
							-0.3052572,
							51.5320949
						],
						[
							-0.3042283,
							51.5318889
						],
						[
							-0.3037017,
							51.5317905
						],
						[
							-0.3031127,
							51.5316892
						],
						[
							-0.3027781,
							51.53163
						],
						[
							-0.3022637,
							51.5315471
						],
						[
							-0.301379,
							51.5314083
						],
						[
							-0.3004726,
							51.5312846
						],
						[
							-0.2995581,
							51.5311827
						],
						[
							-0.2985755,
							51.5310686
						],
						[
							-0.2983804,
							51.5310405
						],
						[
							-0.2979794,
							51.5309849
						],
						[
							-0.2975733,
							51.5309302
						],
						[
							-0.2954399,
							51.5306376
						],
						[
							-0.2945825,
							51.5305089
						],
						[
							-0.293306396621289,
							51.5303008782081
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.293267487089949,
							51.530293993259946
						],
						[
							-0.2914366,
							51.5299444
						],
						[
							-0.2895809,
							51.5295669
						],
						[
							-0.2875548,
							51.5291545
						],
						[
							-0.2862523,
							51.5288816
						],
						[
							-0.2849433,
							51.5286233
						],
						[
							-0.2823155,
							51.5281195
						],
						[
							-0.2783216,
							51.5273472
						],
						[
							-0.2745433,
							51.5265911
						],
						[
							-0.2717934,
							51.5260475
						],
						[
							-0.2708802,
							51.5258237
						],
						[
							-0.2701516,
							51.5255809
						],
						[
							-0.2695455,
							51.5254388
						],
						[
							-0.26881,
							51.5253062
						],
						[
							-0.2676986,
							51.5252297
						],
						[
							-0.2672281,
							51.525175
						],
						[
							-0.2667619,
							51.5250843
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.2667619,
							51.5250843
						],
						[
							-0.2651096,
							51.5247426
						],
						[
							-0.2627674,
							51.5242479
						],
						[
							-0.259732980754316,
							51.52360882165962
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.259694271975088,
							51.52360066921252
						],
						[
							-0.2584789,
							51.5233447
						],
						[
							-0.2578499,
							51.523197
						],
						[
							-0.2570835,
							51.5230035
						],
						[
							-0.2554205,
							51.5226601
						],
						[
							-0.2544878,
							51.5224247
						],
						[
							-0.2538098,
							51.5221697
						],
						[
							-0.2532446,
							51.5219275
						],
						[
							-0.2524848,
							51.5214885
						],
						[
							-0.2518391,
							51.5209613
						],
						[
							-0.2505909,
							51.519788
						],
						[
							-0.250399,
							51.5196076
						],
						[
							-0.2493358,
							51.5185301
						],
						[
							-0.2479259,
							51.5171653
						],
						[
							-0.247587406185112,
							51.51688192985981
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.247555591237137,
							51.51685828512237
						],
						[
							-0.2470524,
							51.5165276
						],
						[
							-0.2465719,
							51.5162468
						],
						[
							-0.2460477,
							51.5160097
						],
						[
							-0.2454222,
							51.5157624
						],
						[
							-0.2447168,
							51.5155462
						],
						[
							-0.2439543,
							51.5153973
						],
						[
							-0.2432558,
							51.5153029
						],
						[
							-0.2421761,
							51.5152416
						],
						[
							-0.241813,
							51.5152495
						],
						[
							-0.2406005,
							51.5153291
						],
						[
							-0.2397353,
							51.5153859
						],
						[
							-0.2384265,
							51.5155034
						],
						[
							-0.2377678,
							51.5155552
						],
						[
							-0.2365554,
							51.5156583
						],
						[
							-0.2362652,
							51.5156832
						],
						[
							-0.2359773,
							51.5157028
						],
						[
							-0.2356859,
							51.5157154
						],
						[
							-0.2350613,
							51.5157261
						],
						[
							-0.2348273,
							51.5157313
						],
						[
							-0.234589,
							51.5157416
						],
						[
							-0.2343604,
							51.5157579
						],
						[
							-0.2341392,
							51.5157814
						],
						[
							-0.2338673,
							51.5158189
						],
						[
							-0.2332958,
							51.5159133
						],
						[
							-0.2328921,
							51.5159641
						],
						[
							-0.2325179,
							51.5160049
						],
						[
							-0.2320661,
							51.5160437
						],
						[
							-0.2316731,
							51.5160598
						],
						[
							-0.231279,
							51.5160647
						],
						[
							-0.2308094,
							51.5160598
						],
						[
							-0.2303024,
							51.5160368
						],
						[
							-0.2298395,
							51.5159971
						],
						[
							-0.2292247,
							51.515933
						],
						[
							-0.2286209,
							51.5158466
						],
						[
							-0.2280622,
							51.5157387
						],
						[
							-0.2276541,
							51.5156552
						],
						[
							-0.2271922,
							51.5155397
						],
						[
							-0.2266991,
							51.5153976
						],
						[
							-0.226291,
							51.5152887
						],
						[
							-0.2260999,
							51.5152274
						],
						[
							-0.2259304,
							51.51516
						],
						[
							-0.225745,
							51.5150724
						],
						[
							-0.2255904,
							51.5149822
						],
						[
							-0.2255046,
							51.51492
						],
						[
							-0.2252617,
							51.5147398
						],
						[
							-0.2250673,
							51.5144962
						],
						[
							-0.2249867,
							51.5143372
						],
						[
							-0.2244827,
							51.5130462
						],
						[
							-0.2244091,
							51.512704
						],
						[
							-0.2243854,
							51.5124563
						],
						[
							-0.224385779191775,
							51.512091896704305
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.224385514478536,
							51.51205194522216
						],
						[
							-0.224384,
							51.5119461
						],
						[
							-0.2243816,
							51.5117729
						],
						[
							-0.2243737,
							51.5106754
						],
						[
							-0.2243698,
							51.5104507
						],
						[
							-0.2243644,
							51.5101443
						],
						[
							-0.2243636,
							51.5101173
						],
						[
							-0.2243546,
							51.5098265
						],
						[
							-0.2243536,
							51.5097789
						],
						[
							-0.224329,
							51.5076173
						],
						[
							-0.2243445,
							51.507432
						],
						[
							-0.2243821,
							51.5071918
						],
						[
							-0.2244389,
							51.5069646
						],
						[
							-0.224502,
							51.5067355
						],
						[
							-0.2245629,
							51.5065424
						],
						[
							-0.2245902,
							51.5063527
						],
						[
							-0.2246315,
							51.5061285
						],
						[
							-0.2246346,
							51.5059296
						],
						[
							-0.2246274,
							51.5057505
						],
						[
							-0.2245791,
							51.5055731
						],
						[
							-0.2245235,
							51.5054377
						],
						[
							-0.2244259,
							51.5053197
						],
						[
							-0.2242903,
							51.5052183
						],
						[
							-0.2241097,
							51.5051327
						],
						[
							-0.2237877,
							51.5050418
						],
						[
							-0.2234188,
							51.5049673
						],
						[
							-0.2212821,
							51.5046532
						],
						[
							-0.2210392,
							51.5046199
						],
						[
							-0.2200915,
							51.5044851
						],
						[
							-0.220003,
							51.5044739
						],
						[
							-0.2199011,
							51.5044678
						],
						[
							-0.2196757,
							51.5044698
						],
						[
							-0.2195517,
							51.504464
						],
						[
							-0.2193712,
							51.5044448
						],
						[
							-0.2191554,
							51.5044279
						],
						[
							-0.2189462,
							51.5044162
						],
						[
							-0.2187694,
							51.5044119
						],
						[
							-0.21845,
							51.504418
						],
						[
							-0.2180955,
							51.5044394
						],
						[
							-0.2178596,
							51.5044621
						],
						[
							-0.217394,
							51.5045405
						],
						[
							-0.217147597735436,
							51.504593405085316
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.217108904719017,
							51.504601657054884
						],
						[
							-0.209138,
							51.5062901
						],
						[
							-0.205661244359302,
							51.50727119769717
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.205622941417582,
							51.507281912501455
						],
						[
							-0.2020246,
							51.5082797
						],
						[
							-0.2005688,
							51.5085951
						],
						[
							-0.1995859,
							51.508723
						],
						[
							-0.1987638,
							51.5088257
						],
						[
							-0.1968439,
							51.5090595
						],
						[
							-0.196114108738382,
							51.509196141712174
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.196075341455427,
							51.50920392478128
						],
						[
							-0.1954895,
							51.5093295
						],
						[
							-0.1919423,
							51.5100934
						],
						[
							-0.1909807,
							51.5102287
						],
						[
							-0.1894488,
							51.5103693
						],
						[
							-0.18724961458584,
							51.51049922934105
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.18721013392884,
							51.5105025083437
						],
						[
							-0.1851749,
							51.5107207
						],
						[
							-0.1809814,
							51.5111702
						],
						[
							-0.175164526590945,
							51.51186894238792
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.17512520367381,
							51.51187321732089
						],
						[
							-0.1641468,
							51.5129419
						],
						[
							-0.1598789,
							51.5133126
						],
						[
							-0.1590396,
							51.5133843
						],
						[
							-0.157855199085059,
							51.513537268722814
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.157815958747135,
							51.5135420650404
						],
						[
							-0.1561542,
							51.5137337
						],
						[
							-0.153801,
							51.5138685
						],
						[
							-0.149936,
							51.51424267554729
						],
						[
							-0.149005827887115,
							51.51432368194687
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.148966516022117,
							51.51432802666028
						],
						[
							-0.145925524905984,
							51.5147358932665
						],
						[
							-0.142099761665753,
							51.5153049538495
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.142060598742695,
							51.51531023894531
						],
						[
							-0.1408111,
							51.5154617
						],
						[
							-0.1402345,
							51.5155284
						],
						[
							-0.1395854,
							51.5155892
						],
						[
							-0.1379139,
							51.5157211
						],
						[
							-0.13455,
							51.5161423
						],
						[
							-0.1326197,
							51.5163309
						],
						[
							-0.1318285,
							51.5163914
						],
						[
							-0.13054353187832,
							51.516466315068044
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.130503860454433,
							51.51646838961597
						],
						[
							-0.1300846,
							51.5164878
						],
						[
							-0.129849,
							51.5165065
						],
						[
							-0.1290331,
							51.5166419
						],
						[
							-0.1279161,
							51.516821
						],
						[
							-0.1264186,
							51.5170063
						],
						[
							-0.1252581,
							51.5171424
						],
						[
							-0.1246073,
							51.5172042
						],
						[
							-0.1232101,
							51.5173286
						],
						[
							-0.1222742,
							51.5174016
						],
						[
							-0.120196812302135,
							51.51761303501533
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.120157446882681,
							51.51761704159921
						],
						[
							-0.1197226,
							51.5176613
						],
						[
							-0.1179113,
							51.5178154
						],
						[
							-0.116170921434225,
							51.51798613724775
						],
						[
							-0.113601130942959,
							51.518115326862905
						],
						[
							-0.112141364854374,
							51.51811137809931
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.112101620854927,
							51.5181102055768
						],
						[
							-0.109512337513222,
							51.51798520942201
						],
						[
							-0.108227937792003,
							51.51792023056901
						],
						[
							-0.1065157,
							51.5177164
						],
						[
							-0.1056818,
							51.5175168
						],
						[
							-0.1051585,
							51.5173743
						],
						[
							-0.1019417,
							51.5163025
						],
						[
							-0.1002826,
							51.5157626
						],
						[
							-0.0994849,
							51.5155271
						],
						[
							-0.0987438,
							51.5153095
						],
						[
							-0.0981268,
							51.5151265
						],
						[
							-0.097501032567439,
							51.51493079199657
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.097462920434456,
							51.51491887247333
						],
						[
							-0.096318,
							51.5145608
						],
						[
							-0.0959093,
							51.514454
						],
						[
							-0.0953155,
							51.5143426
						],
						[
							-0.0934612,
							51.5140505
						],
						[
							-0.0918403,
							51.5137644
						],
						[
							-0.0903034,
							51.5135019
						],
						[
							-0.0898254,
							51.5134275
						],
						[
							-0.0896242,
							51.5134068
						],
						[
							-0.0894499,
							51.5134018
						],
						[
							-0.0893184,
							51.5134055
						],
						[
							-0.0892146,
							51.5134124
						],
						[
							-0.0890632,
							51.5134322
						],
						[
							-0.089008914591683,
							51.51344503037395
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.088970476979794,
							51.51345489490425
						],
						[
							-0.088302036260836,
							51.51364008681373
						],
						[
							-0.0878456,
							51.5137696
						],
						[
							-0.0865177,
							51.5140532
						],
						[
							-0.0863616,
							51.5141041
						],
						[
							-0.0861943,
							51.5141729
						],
						[
							-0.0855875,
							51.5147061
						],
						[
							-0.085448,
							51.514796
						],
						[
							-0.0853075,
							51.5148573
						],
						[
							-0.0851449,
							51.5149115
						],
						[
							-0.0849597,
							51.5149876
						],
						[
							-0.0847602,
							51.5151022
						],
						[
							-0.0845563,
							51.5152455
						],
						[
							-0.0844386,
							51.5153465
						],
						[
							-0.0842641,
							51.5155126
						],
						[
							-0.0842387,
							51.5155371
						],
						[
							-0.0838276,
							51.5160212
						],
						[
							-0.0836788,
							51.5162131
						],
						[
							-0.0835422,
							51.5164613
						],
						[
							-0.0833782,
							51.5168889
						],
						[
							-0.083220418744778,
							51.517281632154884
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.083204832137748,
							51.5173180307235
						],
						[
							-0.0830984,
							51.5175518
						],
						[
							-0.0830271,
							51.5176643
						],
						[
							-0.0829514,
							51.5177483
						],
						[
							-0.082806,
							51.5179223
						],
						[
							-0.0825803,
							51.5181725
						],
						[
							-0.0822959,
							51.5184621
						],
						[
							-0.0820741,
							51.5186693
						],
						[
							-0.0818524,
							51.5188379
						],
						[
							-0.077747213094296,
							51.52181111538404
						],
						[
							-0.07687661726426,
							51.52233024964901
						],
						[
							-0.075964499415597,
							51.52278413333463
						],
						[
							-0.075224607755526,
							51.52316878343354
						],
						[
							-0.0719266,
							51.5248124
						],
						[
							-0.0701479,
							51.5256395
						],
						[
							-0.0679664,
							51.5264624
						],
						[
							-0.0673711,
							51.52665
						],
						[
							-0.0667598,
							51.5267946
						],
						[
							-0.0659965,
							51.5269249
						],
						[
							-0.0616983,
							51.5273102
						],
						[
							-0.0595008,
							51.5275251
						],
						[
							-0.0587034,
							51.5276036
						],
						[
							-0.0578754,
							51.5276548
						],
						[
							-0.0572141,
							51.527662
						],
						[
							-0.0566276,
							51.5276364
						],
						[
							-0.055508056232001,
							51.52747600184099
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.055469286329293,
							51.52746823531235
						],
						[
							-0.0543805,
							51.5271869
						],
						[
							-0.0473217,
							51.5247785
						],
						[
							-0.0461343,
							51.5243987
						],
						[
							-0.0454628,
							51.5242417
						],
						[
							-0.044725,
							51.524138
						],
						[
							-0.0441255,
							51.5240845
						],
						[
							-0.043435,
							51.524023
						],
						[
							-0.042103,
							51.5239567
						],
						[
							-0.0406266,
							51.5239398
						],
						[
							-0.0392571,
							51.5239967
						],
						[
							-0.0387437,
							51.5240529
						],
						[
							-0.0382518,
							51.5241334
						],
						[
							-0.0378381,
							51.524222
						],
						[
							-0.0372098,
							51.5243797
						],
						[
							-0.0355682,
							51.5249331
						],
						[
							-0.034718542941876,
							51.525158172506785
						],
						[
							-0.034457945359205,
							51.52520565677501
						],
						[
							-0.033970148272863,
							51.52527134041716
						],
						[
							-0.033464400420946,
							51.5253531457819
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.033425803828339,
							51.525362006547454
						],
						[
							-0.032892001122614,
							51.52552152388439
						],
						[
							-0.03261570674925,
							51.52563327576337
						],
						[
							-0.0320493,
							51.5257837
						],
						[
							-0.0281585,
							51.5266433
						],
						[
							-0.0261805,
							51.5271851
						],
						[
							-0.0250478,
							51.5275865
						],
						[
							-0.0233805,
							51.5282873
						],
						[
							-0.0219924,
							51.5289802
						],
						[
							-0.0182192,
							51.5317719
						],
						[
							-0.0167155,
							51.532792
						],
						[
							-0.015015,
							51.5338041
						],
						[
							-0.0116293,
							51.5356867
						],
						[
							-0.0093138,
							51.5370958
						],
						[
							-0.0076256,
							51.538539
						],
						[
							-0.0073113,
							51.5388232
						],
						[
							-0.0069306,
							51.5391868
						],
						[
							-0.0062307,
							51.5398657
						],
						[
							-0.0060868,
							51.5399823
						],
						[
							-0.0056432,
							51.5403561
						],
						[
							-0.0046985,
							51.540876
						],
						[
							-0.003900375069031,
							51.541481984466316
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.00386781511438,
							51.5415048151164
						],
						[
							-0.002526,
							51.5423715
						],
						[
							-0.0017776,
							51.542871
						],
						[
							-0.0013824,
							51.5431486
						],
						[
							-0.0006836,
							51.5436581
						],
						[
							-0.000369,
							51.5438925
						],
						[
							-5.86e-05,
							51.5441455
						],
						[
							0.0002495,
							51.5444453
						],
						[
							0.0005434,
							51.5447718
						],
						[
							0.0007671,
							51.5451339
						],
						[
							0.0008571,
							51.5454095
						],
						[
							0.000873,
							51.5457775
						],
						[
							0.0008333,
							51.546062
						],
						[
							0.0007336,
							51.5463874
						],
						[
							0.0005869,
							51.5467401
						],
						[
							0.0003831,
							51.5471021
						],
						[
							5.43e-05,
							51.5475376
						],
						[
							-0.0002141,
							51.5478488
						],
						[
							-0.0005629,
							51.548152
						],
						[
							-0.0010464,
							51.5484733
						],
						[
							-0.0016415,
							51.548779
						],
						[
							-0.0021744,
							51.549037
						],
						[
							-0.0029947,
							51.5493609
						],
						[
							-0.0043802,
							51.5498503
						],
						[
							-0.0049962,
							51.5501095
						],
						[
							-0.0055185,
							51.5503907
						],
						[
							-0.0060406,
							51.5507043
						],
						[
							-0.0067122,
							51.5511586
						],
						[
							-0.0070745,
							51.5514854
						],
						[
							-0.0072672,
							51.5517016
						],
						[
							-0.0074467,
							51.5519811
						],
						[
							-0.0077303,
							51.5524668
						],
						[
							-0.0079207,
							51.5530391
						],
						[
							-0.0079569,
							51.5534246
						],
						[
							-0.0079324,
							51.5537986
						],
						[
							-0.0077159,
							51.5544828
						],
						[
							-0.0072782,
							51.5551174
						],
						[
							-0.0065168,
							51.5558624
						],
						[
							-0.0059792,
							51.5562765
						],
						[
							-0.005424046016003,
							51.5566208507579
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.00539045354304,
							51.55664194463775
						],
						[
							-0.0044587,
							51.5572342
						],
						[
							-0.0014061,
							51.5591741
						],
						[
							0.0001809,
							51.5601845
						],
						[
							0.0017944,
							51.5612426
						],
						[
							0.0046343,
							51.5636357
						],
						[
							0.0053722,
							51.5644411
						],
						[
							0.0060803,
							51.5653096
						],
						[
							0.0075957,
							51.5675235
						],
						[
							0.0079447,
							51.5680347
						],
						[
							0.008389866658395,
							51.56856619729667
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.008415516665938,
							51.568596419379
						],
						[
							0.0086004,
							51.5688114
						],
						[
							0.0089103,
							51.569121
						],
						[
							0.0093704,
							51.5695774
						],
						[
							0.010224,
							51.5702578
						],
						[
							0.0111714,
							51.5709249
						],
						[
							0.011172066042476,
							51.57092532322987
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.021526768220566,
							51.58074476782437
						],
						[
							0.0216201,
							51.5808707
						],
						[
							0.0216906,
							51.5809681
						],
						[
							0.0218507,
							51.5811893
						],
						[
							0.0222591,
							51.581815
						],
						[
							0.0228586,
							51.5828555
						],
						[
							0.0239999,
							51.5846861
						],
						[
							0.0241001,
							51.584826
						],
						[
							0.0250065,
							51.5861896
						],
						[
							0.0255063,
							51.5870048
						],
						[
							0.026066,
							51.5880345
						],
						[
							0.0265691,
							51.589128
						],
						[
							0.026941,
							51.5901436
						],
						[
							0.027221,
							51.5911394
						],
						[
							0.027386653954079,
							51.59191225666915
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.027394778293726,
							51.59195096990016
						],
						[
							0.0279499,
							51.5946538
						],
						[
							0.0282478,
							51.5957186
						],
						[
							0.028534,
							51.5965351
						],
						[
							0.0288049,
							51.5972147
						],
						[
							0.0296675,
							51.5990371
						],
						[
							0.0317008,
							51.6027287
						],
						[
							0.0330022,
							51.6050873
						],
						[
							0.0333278,
							51.6056841
						],
						[
							0.033850006008036,
							51.60663746725974
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.033869662964,
							51.606671791881986
						],
						[
							0.0343524,
							51.6074789
						],
						[
							0.034878,
							51.6082928
						],
						[
							0.0357688,
							51.6095242
						],
						[
							0.0383739,
							51.6127933
						],
						[
							0.0397172,
							51.6144687
						],
						[
							0.04080712488864,
							51.61583660299351
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.046801794918615,
							51.62596500888261
						],
						[
							0.04701,
							51.6267657
						],
						[
							0.0473528,
							51.6279257
						],
						[
							0.0475166,
							51.6283929
						],
						[
							0.047716,
							51.6289072
						],
						[
							0.0480021,
							51.6296101
						],
						[
							0.0482974,
							51.6302098
						],
						[
							0.0485945,
							51.6308458
						],
						[
							0.0489484,
							51.6314432
						],
						[
							0.0493153,
							51.6320447
						],
						[
							0.0499379,
							51.6329645
						],
						[
							0.0508968,
							51.6342213
						],
						[
							0.0519425,
							51.6356273
						],
						[
							0.052504,
							51.636553
						],
						[
							0.0528392,
							51.6372309
						],
						[
							0.0531932,
							51.6380518
						],
						[
							0.0534008,
							51.6387449
						],
						[
							0.0536248,
							51.6394635
						],
						[
							0.0538182,
							51.6399303
						],
						[
							0.0540624,
							51.6403766
						],
						[
							0.054469,
							51.6408247
						],
						[
							0.0546763,
							51.6409996
						],
						[
							0.054945,
							51.6411845
						],
						[
							0.055237707646873,
							51.64135083048278
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.055272578665479,
							51.641369415075516
						],
						[
							0.0556193,
							51.6415423
						],
						[
							0.0559836,
							51.6416976
						],
						[
							0.0563697,
							51.6418166
						],
						[
							0.0567852,
							51.641911
						],
						[
							0.0572043,
							51.6419924
						],
						[
							0.0573531,
							51.642024
						],
						[
							0.0576563,
							51.6420784
						],
						[
							0.0583642,
							51.642172
						],
						[
							0.0590464,
							51.6422165
						],
						[
							0.0595014,
							51.6422165
						],
						[
							0.06035,
							51.6421794
						],
						[
							0.0627458,
							51.6420828
						],
						[
							0.063895,
							51.6420443
						],
						[
							0.065176,
							51.6420111
						],
						[
							0.0663144,
							51.6420328
						],
						[
							0.0675248,
							51.6420689
						],
						[
							0.0692566,
							51.6421559
						],
						[
							0.0694967,
							51.6421738
						],
						[
							0.070794,
							51.6422811
						],
						[
							0.0726396,
							51.642504
						],
						[
							0.0741765,
							51.6427335
						],
						[
							0.0765125,
							51.6431716
						],
						[
							0.0779108,
							51.6434907
						],
						[
							0.079095,
							51.6437828
						],
						[
							0.080268,
							51.6441109
						],
						[
							0.0813931,
							51.6444606
						],
						[
							0.0822554,
							51.6447594
						],
						[
							0.0825886,
							51.6448886
						],
						[
							0.083554965452009,
							51.64528537216639
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.083591315241052,
							51.64530105450873
						],
						[
							0.0843578,
							51.645648
						],
						[
							0.0851168,
							51.6460414
						],
						[
							0.0856397,
							51.6463562
						],
						[
							0.0861444,
							51.646694
						],
						[
							0.0866294,
							51.6470006
						],
						[
							0.088426,
							51.648129
						],
						[
							0.0898684,
							51.6491769
						],
						[
							0.0910064,
							51.6501762
						],
						[
							0.0919938,
							51.6511547
						],
						[
							0.0935863,
							51.6529724
						],
						[
							0.0944803,
							51.6542834
						],
						[
							0.0952929,
							51.6557031
						],
						[
							0.0963331,
							51.6575132
						],
						[
							0.0971812,
							51.658734
						],
						[
							0.0987628,
							51.6606765
						],
						[
							0.099413,
							51.6615192
						],
						[
							0.0999359,
							51.6623528
						],
						[
							0.1004989,
							51.6634773
						],
						[
							0.1008381,
							51.6643584
						],
						[
							0.1010633,
							51.6652209
						],
						[
							0.1012684,
							51.6664034
						],
						[
							0.1013511,
							51.6669863
						],
						[
							0.1014766,
							51.6675348
						],
						[
							0.1017367,
							51.6683557
						],
						[
							0.101911,
							51.6687453
						],
						[
							0.1024286,
							51.6699469
						],
						[
							0.103202384743632,
							51.67171996285377
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.103218237125386,
							51.6717562260034
						],
						[
							0.1037348,
							51.6729359
						],
						[
							0.1053248,
							51.6765819
						],
						[
							0.1066994,
							51.679723
						],
						[
							0.1076046,
							51.6816336
						],
						[
							0.1092697,
							51.6853694
						],
						[
							0.110283,
							51.6876908
						],
						[
							0.1103864,
							51.6878935
						],
						[
							0.1110836,
							51.6894721
						],
						[
							0.1113899,
							51.6901423
						],
						[
							0.1116107,
							51.6905976
						],
						[
							0.1119352,
							51.691136
						],
						[
							0.112094,
							51.6914146
						],
						[
							0.1121447,
							51.6914917
						],
						[
							0.1122732,
							51.6916869
						],
						[
							0.1126908,
							51.6922701
						],
						[
							0.1131431,
							51.6928057
						],
						[
							0.1135429,
							51.6932816
						],
						[
							0.1142226,
							51.6939345
						],
						[
							0.114512327835471,
							51.694164536119615
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.0111714,
							51.5709249
						],
						[
							0.0115199,
							51.5712604
						],
						[
							0.0118588,
							51.5714543
						],
						[
							0.0122883,
							51.5716368
						],
						[
							0.0129906,
							51.571814
						],
						[
							0.0137235,
							51.5719423
						],
						[
							0.0153503,
							51.5720573
						],
						[
							0.0165862,
							51.5721964
						],
						[
							0.018197,
							51.5724714
						],
						[
							0.0189832,
							51.5726367
						],
						[
							0.0199703,
							51.5729322
						],
						[
							0.0211483,
							51.5733237
						],
						[
							0.028601945904478,
							51.57565992779416
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.028640280618581,
							51.57567044199552
						],
						[
							0.0305185,
							51.5761129
						],
						[
							0.0320864,
							51.576343
						],
						[
							0.0340974,
							51.5765737
						],
						[
							0.0351548,
							51.5766506
						],
						[
							0.0361163,
							51.5766941
						],
						[
							0.0371315,
							51.576718
						],
						[
							0.0382414,
							51.5766941
						],
						[
							0.0394316,
							51.5766363
						],
						[
							0.0413945,
							51.5765251
						],
						[
							0.0435979,
							51.5764259
						],
						[
							0.045391109638426,
							51.57636349222978
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.045430957131597,
							51.576362529339555
						],
						[
							0.047247,
							51.5763379
						],
						[
							0.0555933,
							51.5763965
						],
						[
							0.0599355,
							51.5765489
						],
						[
							0.0620901,
							51.5766128
						],
						[
							0.0641185,
							51.5766378
						],
						[
							0.066330640788219,
							51.57660925752668
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.066370510095718,
							51.5766084323667
						],
						[
							0.066768,
							51.5765971
						],
						[
							0.0674201,
							51.5765395
						],
						[
							0.0681886,
							51.5764152
						],
						[
							0.0689899,
							51.5762194
						],
						[
							0.0696875,
							51.5759901
						],
						[
							0.0706528,
							51.5756518
						],
						[
							0.0755807,
							51.5732115
						],
						[
							0.0774219,
							51.5723013
						],
						[
							0.0791053,
							51.571455
						],
						[
							0.080804,
							51.5706235
						],
						[
							0.0814104,
							51.5703856
						],
						[
							0.0818465,
							51.5702452
						],
						[
							0.0823244,
							51.5701224
						],
						[
							0.0827543,
							51.5700477
						],
						[
							0.0834475,
							51.5699373
						],
						[
							0.0840554,
							51.5698845
						],
						[
							0.0847747,
							51.5698421
						],
						[
							0.0854401,
							51.56985
						],
						[
							0.0859406,
							51.5698747
						],
						[
							0.0863197,
							51.5699145
						],
						[
							0.0867323,
							51.5699762
						],
						[
							0.0870802,
							51.5700588
						],
						[
							0.0873683,
							51.5701498
						],
						[
							0.0876439,
							51.5702592
						],
						[
							0.0878683,
							51.5703671
						],
						[
							0.088151,
							51.5705352
						],
						[
							0.0883377,
							51.5706713
						],
						[
							0.0884665,
							51.5707905
						],
						[
							0.0887927,
							51.5711305
						],
						[
							0.0891289,
							51.5717113
						],
						[
							0.0894214,
							51.5724209
						],
						[
							0.0896507,
							51.573133
						],
						[
							0.0897251,
							51.5734644
						],
						[
							0.0897911,
							51.5738613
						],
						[
							0.0899123,
							51.5745317
						],
						[
							0.0899598,
							51.575123
						],
						[
							0.0899485,
							51.5754803
						],
						[
							0.089947503579502,
							51.575578032243826
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.089945395644042,
							51.575617698379645
						],
						[
							0.0898871,
							51.5762207
						],
						[
							0.0898465,
							51.5764352
						],
						[
							0.0897848,
							51.5766874
						],
						[
							0.089522,
							51.5776231
						],
						[
							0.0893252,
							51.5781383
						],
						[
							0.089069,
							51.5787859
						],
						[
							0.0888862,
							51.5794581
						],
						[
							0.0886694,
							51.580144
						],
						[
							0.0884373,
							51.5808824
						],
						[
							0.0882617,
							51.5816786
						],
						[
							0.0881959,
							51.5824677
						],
						[
							0.0881924,
							51.5831755
						],
						[
							0.0883614,
							51.5844116
						],
						[
							0.08857369845648,
							51.58563074062601
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.088579968252666,
							51.58566969497171
						],
						[
							0.0888284,
							51.5873432
						],
						[
							0.0893229,
							51.5900339
						],
						[
							0.0896852,
							51.5912112
						],
						[
							0.0902182,
							51.5929648
						],
						[
							0.0905688,
							51.5941626
						],
						[
							0.0909093,
							51.5952909
						],
						[
							0.090982,
							51.5955278
						],
						[
							0.091107265765601,
							51.59593013989043
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.091119286135958,
							51.5959682360508
						],
						[
							0.0916767,
							51.5977117
						],
						[
							0.0922099,
							51.599474
						],
						[
							0.092569,
							51.6007972
						],
						[
							0.0926137,
							51.600921
						],
						[
							0.0929054,
							51.6018953
						],
						[
							0.093465850135634,
							51.60369044236594
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.093476647103159,
							51.603728732294286
						],
						[
							0.093537,
							51.6039682
						],
						[
							0.0935953,
							51.604122
						],
						[
							0.0937978,
							51.6048184
						],
						[
							0.0938268,
							51.6049035
						],
						[
							0.0938899,
							51.6051025
						],
						[
							0.0939226,
							51.6052032
						],
						[
							0.0942655,
							51.6062896
						],
						[
							0.0949293,
							51.6084607
						],
						[
							0.0950522,
							51.6089577
						],
						[
							0.0950829,
							51.6094268
						],
						[
							0.0949884,
							51.6099497
						],
						[
							0.0948582,
							51.6103284
						],
						[
							0.0945917,
							51.6107604
						],
						[
							0.0941706,
							51.611338
						],
						[
							0.0938845,
							51.6117293
						],
						[
							0.0938622,
							51.6117598
						],
						[
							0.0933901,
							51.6123594
						],
						[
							0.0929559,
							51.6127865
						],
						[
							0.092531967586932,
							51.613108931542
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.092498663699851,
							51.613130290744245
						],
						[
							0.0915447,
							51.6136386
						],
						[
							0.088946462972365,
							51.61444660575002
						],
						[
							0.086329799088937,
							51.61515935120379
						],
						[
							0.084016,
							51.6158639
						],
						[
							0.0822106,
							51.6163167
						],
						[
							0.074767579009458,
							51.617822579220025
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.074728699412306,
							51.6178296531652
						],
						[
							0.0726962,
							51.6181583
						],
						[
							0.0681993,
							51.6188299
						],
						[
							0.0667252,
							51.6190489
						],
						[
							0.0654088,
							51.6192162
						],
						[
							0.0644848,
							51.6193197
						],
						[
							0.063418,
							51.6193837
						],
						[
							0.0626104,
							51.6194223
						],
						[
							0.061513,
							51.6194489
						],
						[
							0.0612324,
							51.6194459
						],
						[
							0.0599791,
							51.619427
						],
						[
							0.0586044,
							51.6193547
						],
						[
							0.0563894,
							51.6191178
						],
						[
							0.0543795,
							51.6188059
						],
						[
							0.0537093,
							51.6186997
						],
						[
							0.0530475,
							51.6185917
						],
						[
							0.0482328,
							51.6178132
						],
						[
							0.0463093,
							51.617504
						],
						[
							0.0460848,
							51.6174643
						],
						[
							0.043899297773951,
							51.617108870930494
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.04386043607855,
							51.617101683958104
						],
						[
							0.0433986,
							51.6170059
						],
						[
							0.0429261,
							51.6168804
						],
						[
							0.042492,
							51.6167526
						],
						[
							0.0420463,
							51.6165799
						],
						[
							0.0413682,
							51.6162265
						],
						[
							0.0408127,
							51.6158436
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.04080712488864,
							51.61583660299351
						],
						[
							0.0408127,
							51.6158436
						],
						[
							0.0418861,
							51.6172102
						],
						[
							0.0430077,
							51.6186294
						],
						[
							0.0439033,
							51.6198692
						],
						[
							0.0442889,
							51.6204348
						],
						[
							0.0445661,
							51.6208794
						],
						[
							0.0449326,
							51.6215538
						],
						[
							0.0454408,
							51.6224676
						],
						[
							0.0460529,
							51.6238407
						],
						[
							0.0466027,
							51.6253275
						],
						[
							0.046790818880921,
							51.62592674731619
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.011172066042476,
							51.57092532322987
						],
						[
							0.0117126,
							51.5712688
						],
						[
							0.0122911,
							51.5716026
						],
						[
							0.0135068,
							51.5722231
						],
						[
							0.0147821,
							51.5728943
						],
						[
							0.0153361,
							51.57318
						],
						[
							0.0157166,
							51.5734084
						],
						[
							0.0161484,
							51.5737242
						],
						[
							0.016844,
							51.5744288
						],
						[
							0.0177558,
							51.5756596
						],
						[
							0.0187532,
							51.577004
						],
						[
							0.020613,
							51.5795124
						],
						[
							0.021503283372343,
							51.58071308641356
						]
					]
				}
			}
		]
	},
	{
		name: "Jubilee",
		lineColor: "#66727a",
		features: [
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.2879193630078,
							51.568879742657764
						],
						[
							-0.288381929133143,
							51.56923677032462
						],
						[
							-0.288786,
							51.5696681
						],
						[
							-0.2890975,
							51.5700648
						],
						[
							-0.2895367,
							51.5706513
						],
						[
							-0.2897846,
							51.5710169
						],
						[
							-0.2899506,
							51.5712688
						],
						[
							-0.290188,
							51.5716459
						],
						[
							-0.290315,
							51.5719071
						],
						[
							-0.2904016,
							51.5721311
						],
						[
							-0.2904537,
							51.5723622
						],
						[
							-0.290475,
							51.5726422
						],
						[
							-0.2904688,
							51.5728001
						],
						[
							-0.290442,
							51.5729874
						],
						[
							-0.2903879,
							51.5732027
						],
						[
							-0.2902813,
							51.57347
						],
						[
							-0.2901788,
							51.5736508
						],
						[
							-0.2900405,
							51.57385
						],
						[
							-0.2898965,
							51.5740145
						],
						[
							-0.289741,
							51.5741769
						],
						[
							-0.2895793,
							51.5743172
						],
						[
							-0.2893976,
							51.5744466
						],
						[
							-0.2891395,
							51.5746126
						],
						[
							-0.2888676,
							51.5747672
						],
						[
							-0.2885708,
							51.5749215
						],
						[
							-0.2863342,
							51.576049
						],
						[
							-0.2855988,
							51.5764152
						],
						[
							-0.2848689,
							51.5767902
						],
						[
							-0.2844473,
							51.5770015
						],
						[
							-0.2839207,
							51.5772678
						],
						[
							-0.2833506,
							51.5775539
						],
						[
							-0.282891,
							51.5777912
						],
						[
							-0.2826402,
							51.5779205
						],
						[
							-0.2823602,
							51.5780805
						],
						[
							-0.2820926,
							51.5782496
						],
						[
							-0.2817598,
							51.5784795
						],
						[
							-0.2814932,
							51.578683
						],
						[
							-0.2811874,
							51.5789379
						],
						[
							-0.2808984,
							51.5792049
						],
						[
							-0.2806319,
							51.5794984
						],
						[
							-0.2804235,
							51.5797528
						],
						[
							-0.2801529,
							51.5801363
						],
						[
							-0.2798954,
							51.5806017
						],
						[
							-0.2797796,
							51.5808722
						],
						[
							-0.279693,
							51.5811074
						],
						[
							-0.2794793,
							51.581819
						],
						[
							-0.2793665,
							51.5822219
						],
						[
							-0.2792511,
							51.5826016
						],
						[
							-0.2791251,
							51.5830208
						],
						[
							-0.2790217,
							51.5833527
						],
						[
							-0.2789324,
							51.5836595
						],
						[
							-0.2787889,
							51.5841478
						],
						[
							-0.2786881,
							51.5844899
						],
						[
							-0.27862803609812,
							51.5846973768318
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.278616453185337,
							51.58473554227864
						],
						[
							-0.2783952,
							51.5854323
						],
						[
							-0.2783548,
							51.5857045
						],
						[
							-0.2782996,
							51.586237
						],
						[
							-0.278445,
							51.5869938
						],
						[
							-0.2785595,
							51.5873792
						],
						[
							-0.278678,
							51.5877391
						],
						[
							-0.2788362,
							51.5881597
						],
						[
							-0.2790531,
							51.5885697
						],
						[
							-0.2792322,
							51.5888267
						],
						[
							-0.2794441,
							51.5890968
						],
						[
							-0.279572,
							51.5892602
						],
						[
							-0.2798096,
							51.5895246
						],
						[
							-0.2801641,
							51.5898908
						],
						[
							-0.2805313,
							51.590282
						],
						[
							-0.28122,
							51.59102
						],
						[
							-0.2815333,
							51.591354
						],
						[
							-0.2819014,
							51.5917376
						],
						[
							-0.2821344,
							51.5919566
						],
						[
							-0.2825908,
							51.592341
						],
						[
							-0.2828516,
							51.5925326
						],
						[
							-0.2831364,
							51.5927201
						],
						[
							-0.2833875,
							51.5928774
						],
						[
							-0.2836499,
							51.5930266
						],
						[
							-0.2844005,
							51.593401
						],
						[
							-0.2847742,
							51.593568
						],
						[
							-0.2854947,
							51.5938767
						],
						[
							-0.2857306,
							51.5939778
						],
						[
							-0.286167302431943,
							51.59416490332817
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.286203713392254,
							51.59418046561514
						],
						[
							-0.2873657,
							51.5946759
						],
						[
							-0.2881331,
							51.5950115
						],
						[
							-0.2885524,
							51.5952096
						],
						[
							-0.2888492,
							51.5953722
						],
						[
							-0.2892698,
							51.5956281
						],
						[
							-0.2896694,
							51.5959001
						],
						[
							-0.2898944,
							51.5960724
						],
						[
							-0.2901075,
							51.5962436
						],
						[
							-0.2903104,
							51.5964251
						],
						[
							-0.2905102,
							51.5966347
						],
						[
							-0.2908302,
							51.5969746
						],
						[
							-0.290971,
							51.5971509
						],
						[
							-0.2911015,
							51.5973268
						],
						[
							-0.2912342,
							51.597507
						],
						[
							-0.2913576,
							51.5977126
						],
						[
							-0.2914788,
							51.5979296
						],
						[
							-0.2915956,
							51.5981779
						],
						[
							-0.2916911,
							51.5984124
						],
						[
							-0.291838,
							51.5988073
						],
						[
							-0.2919636,
							51.5992014
						],
						[
							-0.2921045,
							51.5996117
						],
						[
							-0.292182,
							51.5998769
						],
						[
							-0.2922588,
							51.6001189
						],
						[
							-0.2927553,
							51.6016838
						],
						[
							-0.2929889,
							51.6024258
						],
						[
							-0.2931254,
							51.6028368
						],
						[
							-0.2932553,
							51.603246
						],
						[
							-0.2935447,
							51.6041186
						],
						[
							-0.2936812,
							51.6045588
						],
						[
							-0.2938461,
							51.6050626
						],
						[
							-0.294006,
							51.6055479
						],
						[
							-0.2943157,
							51.6064705
						],
						[
							-0.2944543,
							51.6068754
						],
						[
							-0.2946181,
							51.6073542
						],
						[
							-0.2946946,
							51.6075983
						],
						[
							-0.294738314633679,
							51.607737447988896
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.294750497885086,
							51.60777551219061
						],
						[
							-0.2948835,
							51.6081835
						],
						[
							-0.2949872,
							51.6085226
						],
						[
							-0.2951073,
							51.6088191
						],
						[
							-0.2952973,
							51.6092523
						],
						[
							-0.2955932,
							51.6097799
						],
						[
							-0.2957756,
							51.6100613
						],
						[
							-0.2959677,
							51.6103285
						],
						[
							-0.2973077,
							51.612055
						],
						[
							-0.2980742,
							51.6130557
						],
						[
							-0.2985809,
							51.6137045
						],
						[
							-0.2989399,
							51.6141696
						],
						[
							-0.2990554,
							51.6143224
						],
						[
							-0.2997893,
							51.615293
						],
						[
							-0.3002479,
							51.6158942
						],
						[
							-0.3007125,
							51.6164776
						],
						[
							-0.301441,
							51.6174227
						],
						[
							-0.301685,
							51.6177392
						],
						[
							-0.3018585,
							51.6179643
						],
						[
							-0.3024747,
							51.6186825
						],
						[
							-0.3027496,
							51.6190382
						],
						[
							-0.3028747,
							51.6192104
						],
						[
							-0.303004193593681,
							51.61937727036137
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.003870818959284,
							51.541479781720284
						],
						[
							-0.0031541,
							51.5406202
						],
						[
							-0.0029809,
							51.5404082
						],
						[
							-0.002805,
							51.5402026
						],
						[
							-0.0026451,
							51.5400192
						],
						[
							-0.0024718,
							51.5398184
						],
						[
							-0.0023538,
							51.5396741
						],
						[
							-0.0022478,
							51.5395382
						],
						[
							-0.002193,
							51.5394681
						],
						[
							-0.0019852,
							51.5392256
						],
						[
							-0.0019315,
							51.5391684
						],
						[
							-0.0018604,
							51.5390867
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.0018604,
							51.5390867
						],
						[
							-0.0016808,
							51.5388707
						],
						[
							-0.0015785,
							51.5387321
						],
						[
							-0.0014876,
							51.5386061
						],
						[
							-0.001127,
							51.5382684
						],
						[
							-0.0008276,
							51.5380407
						],
						[
							-0.0006888,
							51.5379508
						],
						[
							-0.0005818,
							51.5378723
						],
						[
							-0.000354169733075,
							51.53771028026102
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.000322645723586,
							51.53768601823146
						],
						[
							-0.0001185,
							51.5375163
						],
						[
							9.96e-05,
							51.5373303
						],
						[
							0.0002761,
							51.5371725
						],
						[
							0.0004683,
							51.5369844
						],
						[
							0.000608,
							51.5368116
						],
						[
							0.0007464,
							51.536631
						],
						[
							0.0008644,
							51.5364543
						],
						[
							0.0010754,
							51.5361376
						],
						[
							0.0011424,
							51.5360432
						],
						[
							0.001319,
							51.5358032
						],
						[
							0.0014901,
							51.5355752
						],
						[
							0.0016443,
							51.5353346
						],
						[
							0.0017539,
							51.5351677
						],
						[
							0.0019059,
							51.5349048
						],
						[
							0.0020569,
							51.5345835
						],
						[
							0.0021888,
							51.5343116
						],
						[
							0.0023218,
							51.5340307
						],
						[
							0.0023945,
							51.5338561
						],
						[
							0.0024985,
							51.5336795
						],
						[
							0.0026147,
							51.5334987
						],
						[
							0.002731,
							51.5333443
						],
						[
							0.0029747,
							51.5330466
						],
						[
							0.0030577,
							51.5329506
						],
						[
							0.0033313,
							51.5326342
						],
						[
							0.003517653792215,
							51.53240888983715
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.00354251479482,
							51.532377878749884
						],
						[
							0.0036254,
							51.5322712
						],
						[
							0.0037282,
							51.5321154
						],
						[
							0.0038948,
							51.5318372
						],
						[
							0.0039727,
							51.5317243
						],
						[
							0.0040398,
							51.5315998
						],
						[
							0.0041338,
							51.5313921
						],
						[
							0.0042018,
							51.5312346
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.0042018,
							51.5312346
						],
						[
							0.004245,
							51.5310882
						],
						[
							0.0042588,
							51.5309852
						],
						[
							0.0042736,
							51.5308912
						],
						[
							0.0042794,
							51.5307655
						],
						[
							0.0042771,
							51.5306021
						],
						[
							0.0042573,
							51.5304949
						],
						[
							0.004232,
							51.5303591
						],
						[
							0.0041103,
							51.529946
						],
						[
							0.004055,
							51.5296756
						],
						[
							0.0040648,
							51.5295562
						],
						[
							0.0040758,
							51.5294156
						],
						[
							0.0040916,
							51.529299
						],
						[
							0.0041091,
							51.5292072
						],
						[
							0.0041357,
							51.5291112
						],
						[
							0.0041736,
							51.5290003
						],
						[
							0.0042232,
							51.5288802
						],
						[
							0.0042759,
							51.5287389
						],
						[
							0.0043318,
							51.528598
						],
						[
							0.0043677,
							51.528478
						],
						[
							0.004394811864803,
							51.528355176780764
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.0045196,
							51.5255301
						],
						[
							0.0044713,
							51.5249715
						],
						[
							0.0044544,
							51.5246075
						],
						[
							0.0042868,
							51.5228518
						],
						[
							0.0041432,
							51.5219515
						],
						[
							0.0041208,
							51.5217682
						],
						[
							0.0041008,
							51.521554
						],
						[
							0.0040977,
							51.5213619
						],
						[
							0.0041124,
							51.5210953
						],
						[
							0.0041503,
							51.5208032
						],
						[
							0.004172913696271,
							51.52057908540038
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.004401389804399,
							51.52831621862405
						],
						[
							0.0044257,
							51.5281098
						],
						[
							0.0044282,
							51.5280203
						],
						[
							0.0044234,
							51.5278988
						],
						[
							0.0044626,
							51.5271352
						],
						[
							0.0044927,
							51.5263699
						],
						[
							0.0045196,
							51.5255301
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.004177263369469,
							51.52053977432093
						],
						[
							0.0042479,
							51.5199532
						],
						[
							0.0042948,
							51.5197356
						],
						[
							0.004333,
							51.519525
						],
						[
							0.0044175,
							51.5191778
						],
						[
							0.0045241,
							51.518869
						],
						[
							0.0046499,
							51.5184716
						],
						[
							0.0047529,
							51.518191
						],
						[
							0.0048938,
							51.5179184
						],
						[
							0.0050372,
							51.5176171
						],
						[
							0.005158,
							51.5173503
						],
						[
							0.0053041,
							51.517115
						],
						[
							0.0054456,
							51.5168956
						],
						[
							0.0056281,
							51.5166294
						],
						[
							0.0059917,
							51.5161509
						],
						[
							0.0062961,
							51.5157567
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.0062961,
							51.5157567
						],
						[
							0.006569,
							51.5154698
						],
						[
							0.0070332,
							51.5150349
						],
						[
							0.0071486,
							51.5149376
						],
						[
							0.0072513,
							51.5148559
						],
						[
							0.0073321,
							51.5147847
						],
						[
							0.0074798,
							51.5146558
						],
						[
							0.0076455,
							51.5145077
						],
						[
							0.0077902,
							51.5143856
						],
						[
							0.0078946,
							51.5142996
						],
						[
							0.0081025,
							51.5141086
						],
						[
							0.008156803294941,
							51.51405373933986
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.008185089312757,
							51.51402615326781
						],
						[
							0.0083466,
							51.5138741
						],
						[
							0.0084913,
							51.5137323
						],
						[
							0.008565422600203,
							51.513653589540596
						],
						[
							0.008565422600203,
							51.513653589540596
						],
						[
							0.0085947,
							51.5136225
						],
						[
							0.0087157,
							51.5135094
						],
						[
							0.0088903,
							51.5133278
						],
						[
							0.0089495,
							51.5132625
						],
						[
							0.009005,
							51.5132055
						],
						[
							0.0090723,
							51.5131258
						],
						[
							0.0091374,
							51.5130403
						],
						[
							0.0092382,
							51.5128915
						],
						[
							0.0093262,
							51.5127605
						],
						[
							0.0094322,
							51.5126153
						],
						[
							0.0095,
							51.5125045
						],
						[
							0.0095888,
							51.5123415
						],
						[
							0.0096943,
							51.5121354
						],
						[
							0.0098048,
							51.5119098
						],
						[
							0.009953,
							51.5116234
						],
						[
							0.010129,
							51.5113137
						],
						[
							0.0102739,
							51.5111037
						],
						[
							0.0105525,
							51.5106457
						],
						[
							0.0110463,
							51.5099086
						],
						[
							0.0112818,
							51.509508
						],
						[
							0.0115345,
							51.5089706
						],
						[
							0.0116273,
							51.5086684
						],
						[
							0.0116472,
							51.5081943
						],
						[
							0.0116257,
							51.5074097
						],
						[
							0.0114165,
							51.5066579
						],
						[
							0.0109954,
							51.5057045
						],
						[
							0.0105582,
							51.5049081
						],
						[
							0.009592,
							51.5036012
						],
						[
							0.0084872,
							51.5025268
						],
						[
							0.0075807,
							51.5019097
						],
						[
							0.0069318,
							51.5015606
						],
						[
							0.003902602866308,
							51.50010217131765
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							0.003866173333284,
							51.50008664548169
						],
						[
							0.0034876,
							51.4999453
						],
						[
							0.002883,
							51.499744
						],
						[
							0.0024955,
							51.4996291
						],
						[
							0.0020776,
							51.4995588
						],
						[
							0.0015674,
							51.4995207
						],
						[
							0.0009771,
							51.4994985
						],
						[
							0.0004141,
							51.4995146
						],
						[
							-0.0009281,
							51.4996154
						],
						[
							-0.0025028,
							51.4998759
						],
						[
							-0.0049332,
							51.5003892
						],
						[
							-0.006454,
							51.500852
						],
						[
							-0.0079882,
							51.5014137
						],
						[
							-0.0093686,
							51.501993
						],
						[
							-0.0103205,
							51.5023005
						],
						[
							-0.0111603,
							51.5025244
						],
						[
							-0.0117625,
							51.5026348
						],
						[
							-0.012378,
							51.5027236
						],
						[
							-0.018592395492692,
							51.50353806235366
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.018631668144006,
							51.503542654652534
						],
						[
							-0.0243841,
							51.5041344
						],
						[
							-0.0260289,
							51.5043291
						],
						[
							-0.0276256,
							51.5043549
						],
						[
							-0.029033,
							51.5041498
						],
						[
							-0.0304597,
							51.5037032
						],
						[
							-0.0316824,
							51.5031205
						],
						[
							-0.0325182,
							51.5024239
						],
						[
							-0.0330132,
							51.5022033
						],
						[
							-0.0381619,
							51.5006587
						],
						[
							-0.044646,
							51.4984555
						],
						[
							-0.047400279924789,
							51.49798742520956
						],
						[
							-0.050188086661826,
							51.498002200577844
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.050228019307519,
							51.4980019871415
						],
						[
							-0.0551498,
							51.4979232
						],
						[
							-0.0633131,
							51.4977563
						],
						[
							-0.06371839626824,
							51.49773093918905
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.063757764767331,
							51.49772695204923
						],
						[
							-0.0652006,
							51.4975243
						],
						[
							-0.0657079,
							51.4974589
						],
						[
							-0.0668309,
							51.4974048
						],
						[
							-0.0698249,
							51.4978754
						],
						[
							-0.0722759,
							51.49817
						],
						[
							-0.0734086,
							51.4985691
						],
						[
							-0.0755434,
							51.4997033
						],
						[
							-0.0774958,
							51.5008338
						],
						[
							-0.0797569,
							51.5022034
						],
						[
							-0.0814816,
							51.503096
						],
						[
							-0.0833366,
							51.5041158
						],
						[
							-0.0842684,
							51.5044897
						],
						[
							-0.0856401,
							51.5048867
						],
						[
							-0.0861913,
							51.5049748
						],
						[
							-0.0866529,
							51.5049989
						],
						[
							-0.0879677,
							51.5050121
						],
						[
							-0.089615008184348,
							51.50502013060277
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.089654667422442,
							51.50501817396571
						],
						[
							-0.0900797,
							51.5049738
						],
						[
							-0.0906338,
							51.5048735
						],
						[
							-0.092822,
							51.5042362
						],
						[
							-0.0938412,
							51.5040679
						],
						[
							-0.0983815,
							51.5038872
						],
						[
							-0.1023216,
							51.5040013
						],
						[
							-0.105129187892809,
							51.50407954506665
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.105169004252449,
							51.50408070452625
						],
						[
							-0.1058109,
							51.5041002
						],
						[
							-0.1072274,
							51.5040747
						],
						[
							-0.1092828,
							51.5039253
						],
						[
							-0.1112909,
							51.5036537
						],
						[
							-0.1127913,
							51.5033756
						],
						[
							-0.1142285,
							51.5030877
						],
						[
							-0.114337415850903,
							51.50306169534497
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.114375933356144,
							51.50305233524144
						],
						[
							-0.1155481,
							51.5027625
						],
						[
							-0.116739,
							51.502462
						],
						[
							-0.1184245,
							51.5020168
						],
						[
							-0.1199537,
							51.5016285
						],
						[
							-0.1208268,
							51.5014339
						],
						[
							-0.1219662,
							51.5012188
						],
						[
							-0.1228326,
							51.5011011
						],
						[
							-0.1231468,
							51.5010569
						],
						[
							-0.1236516,
							51.5009866
						],
						[
							-0.1246011,
							51.5009009
						],
						[
							-0.1252475,
							51.5008719
						],
						[
							-0.125936409045387,
							51.50087736989145
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.125976358806094,
							51.50087768708986
						],
						[
							-0.1264062,
							51.5008811
						],
						[
							-0.1271551,
							51.50095
						],
						[
							-0.1280338,
							51.5010916
						],
						[
							-0.1293347,
							51.5013924
						],
						[
							-0.1302895,
							51.5017377
						],
						[
							-0.1313737,
							51.5022255
						],
						[
							-0.1322019,
							51.5027097
						],
						[
							-0.1328061,
							51.5032162
						],
						[
							-0.1338767,
							51.5043112
						],
						[
							-0.1345795,
							51.5048845
						],
						[
							-0.1352677,
							51.5052882
						],
						[
							-0.1359976,
							51.5055818
						],
						[
							-0.1364716,
							51.5057292
						],
						[
							-0.1369265,
							51.5058227
						],
						[
							-0.1373645,
							51.505903
						],
						[
							-0.138376,
							51.5060469
						],
						[
							-0.1386731,
							51.5060763
						],
						[
							-0.139121574095556,
							51.50612223283937
						],
						[
							-0.140805955571667,
							51.50629855220064
						],
						[
							-0.142519396237222,
							51.50647637094145
						],
						[
							-0.143099027348022,
							51.50655477870861
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.143138599190822,
							51.506557481986775
						],
						[
							-0.144381210998333,
							51.50656087512502
						],
						[
							-0.145159981476111,
							51.50653505626135
						],
						[
							-0.1456677,
							51.506546696856
						],
						[
							-0.1461476,
							51.5066249
						],
						[
							-0.1464765,
							51.5067438
						],
						[
							-0.1468541,
							51.5069146
						],
						[
							-0.1474175,
							51.5073056
						],
						[
							-0.1477672,
							51.5076122
						],
						[
							-0.1479929,
							51.5079025
						],
						[
							-0.1483535,
							51.5085126
						],
						[
							-0.1485535,
							51.5089463
						],
						[
							-0.1488378,
							51.5096503
						],
						[
							-0.1490432,
							51.5106613
						],
						[
							-0.149084,
							51.5113878
						],
						[
							-0.1490095,
							51.5140922
						],
						[
							-0.148988075465428,
							51.514305712883
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.148984778703266,
							51.51434519072717
						],
						[
							-0.1489631,
							51.5146699
						],
						[
							-0.148829,
							51.515357
						],
						[
							-0.1484132,
							51.5162593
						],
						[
							-0.1477139,
							51.5171841
						],
						[
							-0.1471898,
							51.517803
						],
						[
							-0.1468246,
							51.5184853
						],
						[
							-0.1465991,
							51.5191633
						],
						[
							-0.146487,
							51.5199395
						],
						[
							-0.1464255,
							51.5204825
						],
						[
							-0.1464212,
							51.5208509
						],
						[
							-0.1464435,
							51.5211559
						],
						[
							-0.1464918,
							51.5215
						],
						[
							-0.146568,
							51.5218238
						],
						[
							-0.1466527,
							51.5221766
						],
						[
							-0.1467707,
							51.5224563
						],
						[
							-0.146936,
							51.522712
						],
						[
							-0.1471562,
							51.5229566
						],
						[
							-0.1475518,
							51.5233076
						],
						[
							-0.1480534,
							51.5236577
						],
						[
							-0.1483852,
							51.523834
						],
						[
							-0.1487979,
							51.52401536257941
						],
						[
							-0.1487979,
							51.52401536257942
						],
						[
							-0.149172625904444,
							51.524080099367346
						],
						[
							-0.149836441150093,
							51.5240964024206
						],
						[
							-0.150706872935554,
							51.52394697077507
						],
						[
							-0.151399487696665,
							51.52381766976907
						],
						[
							-0.152380687116664,
							51.52356451894229
						],
						[
							-0.152788653396663,
							51.523415480291696
						],
						[
							-0.153323568157774,
							51.5232526802917
						],
						[
							-0.153866416971949,
							51.523060431544415
						],
						[
							-0.15441267597333,
							51.52285394442663
						],
						[
							-0.155492968314432,
							51.522506913696716
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.155531893796674,
							51.52250012945859
						],
						[
							-0.156879877133332,
							51.52245469425792
						],
						[
							-0.15830276686,
							51.522654867593815
						],
						[
							-0.159374570477778,
							51.52279475351455
						],
						[
							-0.160327185238889,
							51.52280813476914
						],
						[
							-0.1611427,
							51.522747
						],
						[
							-0.1615729,
							51.5226839
						],
						[
							-0.1620953,
							51.5226397
						],
						[
							-0.1624617,
							51.522638
						],
						[
							-0.1628858,
							51.5226575
						],
						[
							-0.1632602,
							51.5226914
						],
						[
							-0.1636848,
							51.5227652
						],
						[
							-0.1640735,
							51.5228683
						],
						[
							-0.1643873,
							51.5229841
						],
						[
							-0.1645761,
							51.5230643
						],
						[
							-0.1648567,
							51.5232337
						],
						[
							-0.1651749,
							51.5234879
						],
						[
							-0.1655854,
							51.5238683
						],
						[
							-0.1658462,
							51.5241268
						],
						[
							-0.166222,
							51.5245432
						],
						[
							-0.1668106,
							51.5253585
						],
						[
							-0.1672808,
							51.5261693
						],
						[
							-0.1675875,
							51.5268853
						],
						[
							-0.1677311,
							51.5272818
						],
						[
							-0.168007,
							51.5282381
						],
						[
							-0.1681239,
							51.5285879
						],
						[
							-0.1684129,
							51.5291461
						],
						[
							-0.1686764,
							51.5295592
						],
						[
							-0.1689293,
							51.5298215
						],
						[
							-0.169294,
							51.5301233
						],
						[
							-0.1708673,
							51.5313392
						],
						[
							-0.1710569,
							51.5315006
						],
						[
							-0.1728095,
							51.532903
						],
						[
							-0.1733926,
							51.533381
						],
						[
							-0.1737067,
							51.5337087
						],
						[
							-0.1738556,
							51.5339453
						],
						[
							-0.1739459,
							51.5341454
						],
						[
							-0.1740031,
							51.5343436
						],
						[
							-0.174050216869438,
							51.53481131409702
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.174053128234273,
							51.53485085298213
						],
						[
							-0.1740803,
							51.535432
						],
						[
							-0.1741018,
							51.537431
						],
						[
							-0.1740964,
							51.5377302
						],
						[
							-0.1740849,
							51.5381421
						],
						[
							-0.1740556,
							51.5384117
						],
						[
							-0.173973,
							51.5389028
						],
						[
							-0.1738228,
							51.53983
						],
						[
							-0.1735761,
							51.5411523
						],
						[
							-0.1735439,
							51.5414934
						],
						[
							-0.1735627,
							51.54172
						],
						[
							-0.1736345,
							51.5420159
						],
						[
							-0.1737424,
							51.5422485
						],
						[
							-0.1738647,
							51.5424386
						],
						[
							-0.1740347,
							51.542647
						],
						[
							-0.1741822,
							51.5428128
						],
						[
							-0.1743727,
							51.5430221
						],
						[
							-0.174649870364502,
							51.54332149359056
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.174675640680045,
							51.54335159536489
						],
						[
							-0.1748999,
							51.543635
						],
						[
							-0.1750654,
							51.5438264
						],
						[
							-0.1753008,
							51.5440933
						],
						[
							-0.175652443385324,
							51.54445634791063
						],
						[
							-0.175882590374862,
							51.54461391813201
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.287919363007793,
							51.56887974265776
						],
						[
							-0.287831771805478,
							51.56882553334039
						],
						[
							-0.2876643,
							51.5687128
						],
						[
							-0.287417,
							51.5685441
						],
						[
							-0.2871685,
							51.5683672
						],
						[
							-0.2867779,
							51.5680473
						],
						[
							-0.2863956,
							51.5677
						],
						[
							-0.2852848,
							51.5666679
						],
						[
							-0.2848683,
							51.5662958
						],
						[
							-0.2845062,
							51.5660086
						],
						[
							-0.2842198,
							51.5657969
						],
						[
							-0.2839999,
							51.5656451
						],
						[
							-0.283672,
							51.5654402
						],
						[
							-0.2832903,
							51.5652069
						],
						[
							-0.2830427,
							51.5650646
						],
						[
							-0.2826012,
							51.5648286
						],
						[
							-0.2822388,
							51.5646463
						],
						[
							-0.2818882,
							51.5644849
						],
						[
							-0.2816169,
							51.5643649
						],
						[
							-0.2811954,
							51.5641954
						],
						[
							-0.2806338,
							51.5639747
						],
						[
							-0.280058834458927,
							51.56377484670313
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.280021094514943,
							51.56376189269349
						],
						[
							-0.2790781,
							51.5634423
						],
						[
							-0.2782097,
							51.5631496
						],
						[
							-0.2776909,
							51.5630028
						],
						[
							-0.2770777,
							51.5628401
						],
						[
							-0.2764881,
							51.5627178
						],
						[
							-0.2759562,
							51.5626076
						],
						[
							-0.2759509,
							51.5626065
						],
						[
							-0.2750469,
							51.5624339
						],
						[
							-0.2740786,
							51.5622427
						],
						[
							-0.2736641,
							51.5621396
						],
						[
							-0.2732724,
							51.5620359
						],
						[
							-0.2728376,
							51.5619067
						],
						[
							-0.2724054,
							51.5617759
						],
						[
							-0.2717909,
							51.5615538
						],
						[
							-0.2713845,
							51.5614078
						],
						[
							-0.2710041,
							51.5612613
						],
						[
							-0.2700237,
							51.5608897
						],
						[
							-0.2691105,
							51.5605358
						],
						[
							-0.2679887,
							51.5600762
						],
						[
							-0.2674131,
							51.5598392
						],
						[
							-0.2668634,
							51.5596169
						],
						[
							-0.2647003,
							51.558715
						],
						[
							-0.2638488,
							51.5583585
						],
						[
							-0.2634219,
							51.5581707
						],
						[
							-0.2629792,
							51.55799
						],
						[
							-0.2620993,
							51.5576463
						],
						[
							-0.2616543,
							51.5574776
						],
						[
							-0.2612379,
							51.5573092
						],
						[
							-0.2598844,
							51.5568452
						],
						[
							-0.2591453,
							51.5566016
						],
						[
							-0.258513,
							51.5564075
						],
						[
							-0.2579386,
							51.5562332
						],
						[
							-0.257028,
							51.5559657
						],
						[
							-0.2561198,
							51.555708
						],
						[
							-0.2551094,
							51.555437
						],
						[
							-0.2545804,
							51.5552923
						],
						[
							-0.2541174,
							51.5551662
						],
						[
							-0.2536762,
							51.5550539
						],
						[
							-0.253251,
							51.5549457
						],
						[
							-0.2523732,
							51.5547403
						],
						[
							-0.2520862,
							51.5546732
						],
						[
							-0.2510633,
							51.5544325
						],
						[
							-0.250373,
							51.5542758
						],
						[
							-0.24998230166395,
							51.554173240495345
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.249943932220747,
							51.5541629455649
						],
						[
							-0.2491197,
							51.553937
						],
						[
							-0.2483442,
							51.5537085
						],
						[
							-0.2472624,
							51.5534076
						],
						[
							-0.2464961,
							51.553224
						],
						[
							-0.2461479,
							51.5531464
						],
						[
							-0.2455038,
							51.5530087
						],
						[
							-0.2448431,
							51.5528817
						],
						[
							-0.2441847,
							51.5527751
						],
						[
							-0.2435079,
							51.5526756
						],
						[
							-0.2414691,
							51.5523932
						],
						[
							-0.2403622,
							51.5522379
						],
						[
							-0.2397786,
							51.5521574
						],
						[
							-0.239248661896402,
							51.552082466077074
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.239209628979158,
							51.55207636015685
						],
						[
							-0.2374638,
							51.5517769
						],
						[
							-0.236416,
							51.5515884
						],
						[
							-0.2354998,
							51.551431
						],
						[
							-0.2349567,
							51.5513404
						],
						[
							-0.2339842,
							51.5511882
						],
						[
							-0.2332398,
							51.55107
						],
						[
							-0.2330018,
							51.5510297
						],
						[
							-0.2312084,
							51.5507484
						],
						[
							-0.229434,
							51.5504681
						],
						[
							-0.2276777,
							51.5501956
						],
						[
							-0.2267127,
							51.5500721
						],
						[
							-0.225863,
							51.549951
						],
						[
							-0.2237701,
							51.549621
						],
						[
							-0.2225224,
							51.5494318
						],
						[
							-0.221874425577687,
							51.549332395384496
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.221835415411901,
							51.54932614581723
						],
						[
							-0.2212777,
							51.549233
						],
						[
							-0.2198681,
							51.5490082
						],
						[
							-0.2186239,
							51.5487865
						],
						[
							-0.2180459,
							51.5486942
						],
						[
							-0.214619,
							51.5481745
						],
						[
							-0.2131897,
							51.5479464
						],
						[
							-0.2121555,
							51.5477841
						],
						[
							-0.2112041,
							51.5476251
						],
						[
							-0.2101097,
							51.5474692
						],
						[
							-0.2094229,
							51.5473612
						],
						[
							-0.2089578,
							51.5472941
						],
						[
							-0.2084654,
							51.5472238
						],
						[
							-0.2079986,
							51.5471891
						],
						[
							-0.2077433,
							51.5471657
						],
						[
							-0.2071148,
							51.5471136
						],
						[
							-0.2063636,
							51.5470859
						],
						[
							-0.205306021668862,
							51.5470258259385
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.205266343082357,
							51.54702379661356
						],
						[
							-0.2043828,
							51.5469836
						],
						[
							-0.2035509,
							51.5469032
						],
						[
							-0.2028563,
							51.5468537
						],
						[
							-0.2016011,
							51.546706
						],
						[
							-0.2005084,
							51.5466491
						],
						[
							-0.1986623,
							51.546518
						],
						[
							-0.1977397,
							51.5464722
						],
						[
							-0.1971013,
							51.5464471
						],
						[
							-0.1966842,
							51.5464306
						],
						[
							-0.19632,
							51.5464241
						],
						[
							-0.1959611,
							51.5464322
						],
						[
							-0.195486,
							51.5464567
						],
						[
							-0.1950287,
							51.5464856
						],
						[
							-0.1944766,
							51.5465404
						],
						[
							-0.1912044,
							51.546824
						],
						[
							-0.190819723995531,
							51.54685485737634
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.190780162118744,
							51.54685762357744
						],
						[
							-0.1889002,
							51.5469655
						],
						[
							-0.1870591,
							51.5470852
						],
						[
							-0.1862871,
							51.5471515
						],
						[
							-0.1855964,
							51.5472283
						],
						[
							-0.183957,
							51.547431
						],
						[
							-0.1831866,
							51.5475152
						],
						[
							-0.1827214,
							51.5475427
						],
						[
							-0.1826142,
							51.5475447
						],
						[
							-0.1821859,
							51.5475441
						],
						[
							-0.1818009,
							51.5475215
						],
						[
							-0.1815902,
							51.5475002
						],
						[
							-0.180903264087089,
							51.54738078362103
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.180864707706112,
							51.54737166896924
						],
						[
							-0.1802555,
							51.5471888
						],
						[
							-0.1800085,
							51.5470848
						],
						[
							-0.1797983,
							51.5469445
						],
						[
							-0.1796394,
							51.5468276
						],
						[
							-0.1795612,
							51.5467683
						],
						[
							-0.1794054,
							51.5466627
						],
						[
							-0.1792438,
							51.5465331
						],
						[
							-0.1791003,
							51.5463947
						],
						[
							-0.1789376,
							51.5462229
						],
						[
							-0.1787585,
							51.5460296
						],
						[
							-0.1786375,
							51.5459246
						],
						[
							-0.1784135,
							51.5457405
						],
						[
							-0.1783119,
							51.5456765
						],
						[
							-0.1781869,
							51.5456069
						],
						[
							-0.1778918,
							51.5454885
						],
						[
							-0.1764122,
							51.544939
						],
						[
							-0.1762024,
							51.5448326
						],
						[
							-0.1760492,
							51.5447361
						],
						[
							-0.1759156,
							51.5446415
						],
						[
							-0.175882590374857,
							51.54461391813201
						]
					]
				}
			}
		]
	},
	{
		name: "Bakerloo",
		lineColor: "#914721",
		features: [
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.335381297201951,
							51.592312139765234
						],
						[
							-0.334945,
							51.5920838
						],
						[
							-0.3343746,
							51.5918209
						],
						[
							-0.3313053,
							51.5904059
						],
						[
							-0.3304026,
							51.5899897
						],
						[
							-0.3281179,
							51.5889001
						],
						[
							-0.325124,
							51.5874168
						],
						[
							-0.3243327,
							51.5869915
						],
						[
							-0.3235763,
							51.5865569
						],
						[
							-0.3224547,
							51.5858583
						],
						[
							-0.3217047,
							51.5853918
						],
						[
							-0.3209849,
							51.5849143
						],
						[
							-0.3202709,
							51.5844332
						],
						[
							-0.3195845,
							51.5839371
						],
						[
							-0.3189863,
							51.5835035
						],
						[
							-0.3183175,
							51.5830212
						],
						[
							-0.3171903,
							51.5820763
						],
						[
							-0.316636508547741,
							51.581541611560866
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.316607744767775,
							51.58151481918729
						],
						[
							-0.3159444,
							51.580912
						],
						[
							-0.3152153,
							51.5802333
						],
						[
							-0.3147242,
							51.5797303
						],
						[
							-0.3142092,
							51.5792333
						],
						[
							-0.3137521,
							51.5787213
						],
						[
							-0.3133213,
							51.5782041
						],
						[
							-0.3129559,
							51.5777303
						],
						[
							-0.3125042,
							51.5771126
						],
						[
							-0.3122882,
							51.5767874
						],
						[
							-0.3120463,
							51.5764077
						],
						[
							-0.3116344,
							51.5757103
						],
						[
							-0.3114121,
							51.5753476
						],
						[
							-0.3112721,
							51.5751088
						],
						[
							-0.3110522,
							51.5747305
						],
						[
							-0.3106177,
							51.5739932
						],
						[
							-0.3101519,
							51.5732028
						],
						[
							-0.3096415,
							51.5723361
						],
						[
							-0.308664,
							51.5706768
						],
						[
							-0.308410309930751,
							51.570270915093595
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.308389780482425,
							51.570237035028406
						],
						[
							-0.3082044,
							51.5699213
						],
						[
							-0.3079415,
							51.5694669
						],
						[
							-0.3076476,
							51.5689684
						],
						[
							-0.3070617,
							51.5679664
						],
						[
							-0.3068572,
							51.5676269
						],
						[
							-0.3064938,
							51.5670118
						],
						[
							-0.3059429,
							51.5660666
						],
						[
							-0.3053921,
							51.5651192
						],
						[
							-0.3048606,
							51.5641671
						],
						[
							-0.304479,
							51.5634818
						],
						[
							-0.3040934,
							51.5627916
						],
						[
							-0.303884549692751,
							51.56242310245423
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.303864849632426,
							51.562388799794746
						],
						[
							-0.3036872,
							51.5620835
						],
						[
							-0.303368,
							51.5615653
						],
						[
							-0.3030359,
							51.5610423
						],
						[
							-0.3024121,
							51.5600645
						],
						[
							-0.3012399,
							51.5580899
						],
						[
							-0.3010234,
							51.5577241
						],
						[
							-0.3007075,
							51.5572111
						],
						[
							-0.3001891,
							51.5564146
						],
						[
							-0.2990646,
							51.554831
						],
						[
							-0.2988316,
							51.5545309
						],
						[
							-0.2985353,
							51.5541646
						],
						[
							-0.2979343,
							51.5535076
						],
						[
							-0.2974128,
							51.5529335
						],
						[
							-0.2971164,
							51.5525979
						],
						[
							-0.2968105,
							51.5522809
						],
						[
							-0.295896892251237,
							51.55140874379367
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.295868420853606,
							51.55138134310143
						],
						[
							-0.2954427,
							51.5509683
						],
						[
							-0.2950304,
							51.5505741
						],
						[
							-0.2946614,
							51.5502134
						],
						[
							-0.2942338,
							51.5497824
						],
						[
							-0.2937953,
							51.5493381
						],
						[
							-0.2933712,
							51.5488989
						],
						[
							-0.2931352,
							51.5486569
						],
						[
							-0.2929333,
							51.5484527
						],
						[
							-0.2927397,
							51.5482811
						],
						[
							-0.2925212,
							51.5481105
						],
						[
							-0.2920697,
							51.5478287
						],
						[
							-0.2918884,
							51.5477364
						],
						[
							-0.2916883,
							51.5476502
						],
						[
							-0.2914839,
							51.5475768
						],
						[
							-0.2912559,
							51.5475072
						],
						[
							-0.2910379,
							51.5474519
						],
						[
							-0.2908356,
							51.5474082
						],
						[
							-0.2905058,
							51.5473476
						],
						[
							-0.2901125,
							51.5473149
						],
						[
							-0.288295,
							51.5471813
						],
						[
							-0.2873457,
							51.5471151
						],
						[
							-0.2867469,
							51.547066
						],
						[
							-0.2863201,
							51.5470311
						],
						[
							-0.2859875,
							51.547004
						],
						[
							-0.2855077,
							51.5469523
						],
						[
							-0.2851821,
							51.5469108
						],
						[
							-0.2848997,
							51.5468695
						],
						[
							-0.2844767,
							51.5468034
						],
						[
							-0.2839453,
							51.5466993
						],
						[
							-0.2835061,
							51.546599
						],
						[
							-0.2833168,
							51.546552
						],
						[
							-0.2828642,
							51.5464287
						],
						[
							-0.2824088,
							51.5462925
						],
						[
							-0.2820092,
							51.5461581
						],
						[
							-0.281562,
							51.5459948
						],
						[
							-0.2810569,
							51.5458138
						],
						[
							-0.2806147,
							51.5456548
						],
						[
							-0.2803449,
							51.5455592
						],
						[
							-0.2796736,
							51.5453149
						],
						[
							-0.2789617,
							51.5450602
						],
						[
							-0.2784659,
							51.5448846
						],
						[
							-0.2782013,
							51.544789
						],
						[
							-0.2779212,
							51.54469
						],
						[
							-0.2776135,
							51.5445789
						],
						[
							-0.2771315,
							51.5444049
						],
						[
							-0.2768286,
							51.5442972
						],
						[
							-0.2763989,
							51.5441459
						],
						[
							-0.2759357,
							51.5439658
						],
						[
							-0.275719137541341,
							51.543887936914565
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.275681709073997,
							51.543874371597596
						],
						[
							-0.2750733,
							51.5436521
						],
						[
							-0.2746968,
							51.5435099
						],
						[
							-0.2744831,
							51.5434271
						],
						[
							-0.274057,
							51.5432696
						],
						[
							-0.2735186,
							51.5430699
						],
						[
							-0.2730947,
							51.5429123
						],
						[
							-0.2727501,
							51.5427734
						],
						[
							-0.2722563,
							51.542592
						],
						[
							-0.2711687,
							51.5421764
						],
						[
							-0.2709336,
							51.5420861
						],
						[
							-0.2705035,
							51.5419355
						],
						[
							-0.2701579,
							51.5417955
						],
						[
							-0.2695545,
							51.5415525
						],
						[
							-0.2689713,
							51.5413036
						],
						[
							-0.2684191,
							51.5410696
						],
						[
							-0.2678656,
							51.5408279
						],
						[
							-0.2667656,
							51.5403652
						],
						[
							-0.2665205,
							51.5402609
						],
						[
							-0.2662821,
							51.5401563
						],
						[
							-0.265893,
							51.5399866
						],
						[
							-0.2651368,
							51.5396573
						],
						[
							-0.2636084,
							51.5389937
						],
						[
							-0.2632133,
							51.5388168
						],
						[
							-0.2628181,
							51.5386568
						],
						[
							-0.2620643,
							51.5383084
						],
						[
							-0.2605865,
							51.5376103
						],
						[
							-0.2600345,
							51.5373495
						],
						[
							-0.2594772,
							51.537086
						],
						[
							-0.2583761,
							51.536566
						],
						[
							-0.2581581,
							51.5364657
						],
						[
							-0.257970467044661,
							51.53638060423209
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.257934558534257,
							51.53636457942803
						],
						[
							-0.2575418,
							51.5361918
						],
						[
							-0.2561922,
							51.5356561
						],
						[
							-0.2559046,
							51.535549
						],
						[
							-0.2556817,
							51.5354589
						],
						[
							-0.2553599,
							51.5353295
						],
						[
							-0.2550431,
							51.5352149
						],
						[
							-0.2543998,
							51.5349586
						],
						[
							-0.2538855,
							51.534762
						],
						[
							-0.2534923,
							51.5346048
						],
						[
							-0.2531141,
							51.5344619
						],
						[
							-0.252023,
							51.5339792
						],
						[
							-0.2514237,
							51.5337938
						],
						[
							-0.2508099,
							51.5335767
						],
						[
							-0.2501955,
							51.5333789
						],
						[
							-0.2492849,
							51.5331097
						],
						[
							-0.2483188,
							51.5328513
						],
						[
							-0.2478275,
							51.532741
						],
						[
							-0.2473532,
							51.5326522
						],
						[
							-0.2469383,
							51.5325957
						],
						[
							-0.2464198,
							51.5325355
						],
						[
							-0.2461707,
							51.5325133
						],
						[
							-0.2457484,
							51.5324924
						],
						[
							-0.2453969,
							51.5324903
						],
						[
							-0.2449912,
							51.532491
						],
						[
							-0.2447061,
							51.5324826
						],
						[
							-0.2443692,
							51.5324559
						],
						[
							-0.2438328,
							51.5323795
						],
						[
							-0.2435477,
							51.5323213
						],
						[
							-0.243317422490598,
							51.532277202510926
						],
						[
							-0.243198965680161,
							51.53225270493785
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.243160127130767,
							51.532245371827315
						],
						[
							-0.242990044981197,
							51.53221630252304
						],
						[
							-0.2424512,
							51.5321513
						],
						[
							-0.2420069,
							51.5321
						],
						[
							-0.2411569,
							51.5320165
						],
						[
							-0.2407434,
							51.5319752
						],
						[
							-0.2403507,
							51.5319364
						],
						[
							-0.2400996,
							51.5319053
						],
						[
							-0.2398324,
							51.53186
						],
						[
							-0.2395607,
							51.5318004
						],
						[
							-0.2393514,
							51.5317493
						],
						[
							-0.2391122,
							51.5316725
						],
						[
							-0.2388886,
							51.5315897
						],
						[
							-0.2386833,
							51.5314942
						],
						[
							-0.2384852,
							51.5313982
						],
						[
							-0.2382794,
							51.5312858
						],
						[
							-0.2380785,
							51.5311654
						],
						[
							-0.2376719,
							51.5309328
						],
						[
							-0.2373507,
							51.5307701
						],
						[
							-0.2371365,
							51.5306577
						],
						[
							-0.2367684,
							51.5305331
						],
						[
							-0.2363428,
							51.5304193
						],
						[
							-0.2361189,
							51.5303747
						],
						[
							-0.2358773,
							51.5303331
						],
						[
							-0.2356127,
							51.530307
						],
						[
							-0.2353169,
							51.5302799
						],
						[
							-0.2350162,
							51.5302537
						],
						[
							-0.2346347,
							51.5302254
						],
						[
							-0.234002,
							51.5301849
						],
						[
							-0.2336533,
							51.5301661
						],
						[
							-0.2331003,
							51.530153
						],
						[
							-0.2325518,
							51.5301466
						],
						[
							-0.2320619,
							51.5301571
						],
						[
							-0.2316716,
							51.5301648
						],
						[
							-0.2311463,
							51.5301877
						],
						[
							-0.2305955,
							51.5302184
						],
						[
							-0.2295085,
							51.5302747
						],
						[
							-0.2292363,
							51.5302925
						],
						[
							-0.2250611,
							51.5305453
						],
						[
							-0.224615713144458,
							51.53057182024054
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.224576059138283,
							51.53057400475957
						],
						[
							-0.223964,
							51.530605
						],
						[
							-0.2225442,
							51.5307107
						],
						[
							-0.2219999,
							51.5307733
						],
						[
							-0.2214891,
							51.5308476
						],
						[
							-0.2206297,
							51.5309829
						],
						[
							-0.2197892,
							51.5311416
						],
						[
							-0.2184131,
							51.5314413
						],
						[
							-0.2174249,
							51.5316726
						],
						[
							-0.2169806,
							51.5317723
						],
						[
							-0.2158984,
							51.5320264
						],
						[
							-0.2151279,
							51.5321874
						],
						[
							-0.2145594,
							51.5323057
						],
						[
							-0.2132672,
							51.5325861
						],
						[
							-0.2125309,
							51.5327444
						],
						[
							-0.211911,
							51.5328751
						],
						[
							-0.2109663,
							51.5330692
						],
						[
							-0.2103333,
							51.5331921
						],
						[
							-0.2099247,
							51.533272
						],
						[
							-0.2088513,
							51.5334276
						],
						[
							-0.207666,
							51.5336432
						],
						[
							-0.2071018,
							51.5337427
						],
						[
							-0.2064186,
							51.5338294
						],
						[
							-0.205525048704483,
							51.53398931925705
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.205486120857368,
							51.53399608856396
						],
						[
							-0.2046793,
							51.5341323
						],
						[
							-0.2042207,
							51.5342123
						],
						[
							-0.2038322,
							51.5343203
						],
						[
							-0.2033475,
							51.5344131
						],
						[
							-0.2022142,
							51.5346474
						],
						[
							-0.2010387,
							51.5348452
						],
						[
							-0.200185436585027,
							51.53499094073359
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.200185436585027,
							51.53499094073359
						],
						[
							-0.1998943,
							51.535023
						],
						[
							-0.1992374,
							51.535085
						],
						[
							-0.1982235,
							51.5351542
						],
						[
							-0.1966872,
							51.5352991
						],
						[
							-0.1958734,
							51.535288
						],
						[
							-0.1954117,
							51.5352903
						],
						[
							-0.1947213,
							51.535258
						],
						[
							-0.1944423,
							51.5352146
						],
						[
							-0.19415330117403,
							51.53515828083839
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.194114631640773,
							51.53514988060198
						],
						[
							-0.1938415,
							51.5350844
						],
						[
							-0.1932648,
							51.5349193
						],
						[
							-0.1928439,
							51.5347092
						],
						[
							-0.1922622,
							51.5345157
						],
						[
							-0.1912212,
							51.5340688
						],
						[
							-0.1902211,
							51.5334979
						],
						[
							-0.1888895,
							51.5325824
						],
						[
							-0.1874951,
							51.5313932
						],
						[
							-0.185789115572998,
							51.52981592047194
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.185759085742142,
							51.529790078212926
						],
						[
							-0.184848,
							51.5290606
						],
						[
							-0.1840184,
							51.5283768
						],
						[
							-0.183402,
							51.5275971
						],
						[
							-0.1832933,
							51.5273331
						],
						[
							-0.183323,
							51.5270322
						],
						[
							-0.1835088,
							51.5266448
						],
						[
							-0.1838331,
							51.5260692
						],
						[
							-0.1839654,
							51.5257178
						],
						[
							-0.1840984,
							51.5252648
						],
						[
							-0.1841388,
							51.5245912
						],
						[
							-0.1841089,
							51.5240561
						],
						[
							-0.184012369368083,
							51.52331999305207
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.183998688467688,
							51.52328389143771
						],
						[
							-0.1838768,
							51.5231028
						],
						[
							-0.1835476,
							51.5227847
						],
						[
							-0.182253,
							51.5219247
						],
						[
							-0.1809589,
							51.5211237
						],
						[
							-0.1792998,
							51.5201714
						],
						[
							-0.1786637,
							51.5197295
						],
						[
							-0.1783609,
							51.5194434
						],
						[
							-0.1780615,
							51.5190616
						],
						[
							-0.1776431,
							51.5184093
						],
						[
							-0.1774071,
							51.5179431
						],
						[
							-0.1766132,
							51.5170407
						],
						[
							-0.1763878,
							51.5168369
						],
						[
							-0.1761134,
							51.5165938
						],
						[
							-0.1758811,
							51.5163808
						],
						[
							-0.1755319,
							51.5161505
						],
						[
							-0.1753726,
							51.5160675
						],
						[
							-0.1752277,
							51.5160131
						],
						[
							-0.1750657,
							51.5159749
						],
						[
							-0.1748941,
							51.515957
						],
						[
							-0.174510682572048,
							51.515954871098366
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.174471129814409,
							51.51595747297314
						],
						[
							-0.1742675,
							51.5159857
						],
						[
							-0.1739864,
							51.5160648
						],
						[
							-0.1737604,
							51.5161525
						],
						[
							-0.1735037,
							51.5162622
						],
						[
							-0.1732475,
							51.5163962
						],
						[
							-0.1730016,
							51.5165691
						],
						[
							-0.1726338,
							51.5168863
						],
						[
							-0.1720172,
							51.5175731
						],
						[
							-0.1717238,
							51.5179398
						],
						[
							-0.171543,
							51.5182171
						],
						[
							-0.1713828,
							51.5185754
						],
						[
							-0.1712262,
							51.518999
						],
						[
							-0.1709745,
							51.519708
						],
						[
							-0.1708472,
							51.5199828
						],
						[
							-0.1706573,
							51.5202478
						],
						[
							-0.170518,
							51.5203725
						],
						[
							-0.170355970406067,
							51.52047239862611
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.170320824454604,
							51.52049044363273
						],
						[
							-0.1700749,
							51.5205933
						],
						[
							-0.1691992,
							51.5208965
						],
						[
							-0.1683908,
							51.5211943
						],
						[
							-0.1652279,
							51.5221602
						],
						[
							-0.1648717,
							51.5222166
						],
						[
							-0.1645876,
							51.5222295
						],
						[
							-0.164229,
							51.5222208
						],
						[
							-0.1638262,
							51.5222253
						],
						[
							-0.1635969,
							51.5222403
						],
						[
							-0.163241303149465,
							51.52227412575956
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.163201841484445,
							51.52227752467285
						],
						[
							-0.1626501,
							51.5223201
						],
						[
							-0.1621435,
							51.5223579
						],
						[
							-0.1616064,
							51.52236405983431
						],
						[
							-0.15973913314,
							51.52230962216629
						],
						[
							-0.158086451808889,
							51.52225625569283
						],
						[
							-0.15732213314,
							51.522280378705275
						],
						[
							-0.155531621566804,
							51.522498410666834
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.155493267298064,
							51.52250754641248
						],
						[
							-0.155099039795554,
							51.52264952386707
						],
						[
							-0.154688662082221,
							51.522880903569906
						],
						[
							-0.154316443703332,
							51.52323122585771
						],
						[
							-0.153997810563332,
							51.523453142514064
						],
						[
							-0.152968580461109,
							51.52386901462402
						],
						[
							-0.149218648191111,
							51.5247194
						],
						[
							-0.1488339,
							51.5247635
						],
						[
							-0.148494,
							51.524786
						],
						[
							-0.1481684,
							51.5247887
						],
						[
							-0.1478991,
							51.5247625
						],
						[
							-0.1476505,
							51.5246987
						],
						[
							-0.1474496,
							51.5246063
						],
						[
							-0.1471897,
							51.5244449
						],
						[
							-0.1470376,
							51.5243157
						],
						[
							-0.1469105,
							51.5241661
						],
						[
							-0.1467715,
							51.5239551
						],
						[
							-0.146508925025873,
							51.52346461883281
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.146489926311398,
							51.52342995881977
						],
						[
							-0.1462254,
							51.5229585
						],
						[
							-0.1441236,
							51.5183591
						],
						[
							-0.1440482,
							51.5182758
						],
						[
							-0.143939,
							51.5182139
						],
						[
							-0.1437558,
							51.5181224
						],
						[
							-0.143615,
							51.5180552
						],
						[
							-0.1434171,
							51.5179552
						],
						[
							-0.1430981,
							51.5178068
						],
						[
							-0.1429244,
							51.5176167
						],
						[
							-0.1428073,
							51.5175038
						],
						[
							-0.1426761,
							51.5172892
						],
						[
							-0.142197448191111,
							51.51601376800888
						],
						[
							-0.142149459334445,
							51.5157890320108
						],
						[
							-0.142132851953889,
							51.51566979606397
						],
						[
							-0.142104426049445,
							51.51549918833854
						],
						[
							-0.142082702407808,
							51.51532746740593
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.142072048882298,
							51.51528985450344
						],
						[
							-0.141839803762778,
							51.51477820742096
						],
						[
							-0.141648,
							51.5144875
						],
						[
							-0.141065,
							51.5136367
						],
						[
							-0.1383597,
							51.5105624
						],
						[
							-0.1381629,
							51.510329
						],
						[
							-0.1380009,
							51.5101916
						],
						[
							-0.1377613,
							51.5100748
						],
						[
							-0.1375441,
							51.5099858
						],
						[
							-0.1372662,
							51.5099219
						],
						[
							-0.1369999,
							51.5098878
						],
						[
							-0.1367566,
							51.5098681
						],
						[
							-0.1364302,
							51.5098618
						],
						[
							-0.1359015,
							51.5098796
						],
						[
							-0.1344303,
							51.5099549
						],
						[
							-0.133694,
							51.509975
						],
						[
							-0.133461698494528,
							51.509960072255495
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.133422544972671,
							51.50995472780284
						],
						[
							-0.1332418,
							51.5099167
						],
						[
							-0.1330573,
							51.5098401
						],
						[
							-0.1329175,
							51.5097479
						],
						[
							-0.1323781,
							51.5092019
						],
						[
							-0.1314852,
							51.5080402
						],
						[
							-0.1312536,
							51.5078535
						],
						[
							-0.1310083,
							51.5077164
						],
						[
							-0.1308072,
							51.507648
						],
						[
							-0.130429,
							51.5076002
						],
						[
							-0.1302171,
							51.5075807
						],
						[
							-0.1298711,
							51.5075714
						],
						[
							-0.1296672,
							51.5075665
						],
						[
							-0.1294446,
							51.5075498
						],
						[
							-0.1291523,
							51.507528
						],
						[
							-0.1285434,
							51.5074869
						],
						[
							-0.1281008,
							51.5074578
						],
						[
							-0.1278648,
							51.5074313
						],
						[
							-0.127381213763479,
							51.507339101351725
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.1273422336794,
							51.50733266185603
						],
						[
							-0.1270188,
							51.5072874
						],
						[
							-0.1267531,
							51.5072482
						],
						[
							-0.1263205,
							51.507189
						],
						[
							-0.1256092,
							51.5070748
						],
						[
							-0.124648656443493,
							51.50695902625147
						],
						[
							-0.124049829603618,
							51.50695271619254
						],
						[
							-0.122980202931814,
							51.50703562782255
						],
						[
							-0.122774762253807,
							51.50703239489027
						],
						[
							-0.122596310212186,
							51.5070158296481
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.122556865929387,
							51.507012320987776
						],
						[
							-0.122472941634111,
							51.507005180549285
						],
						[
							-0.122418128976076,
							51.507001103942336
						],
						[
							-0.1221756896445,
							51.50698717097156
						],
						[
							-0.1219124498025,
							51.506972828082965
						],
						[
							-0.1216434,
							51.50695343008903
						],
						[
							-0.1213564,
							51.5069357
						],
						[
							-0.1211499,
							51.5069218
						],
						[
							-0.1209641,
							51.5069037
						],
						[
							-0.1207646,
							51.5068731
						],
						[
							-0.1203571,
							51.5067993
						],
						[
							-0.119852,
							51.5066742
						],
						[
							-0.1192931,
							51.5064816
						],
						[
							-0.1186684,
							51.5062797
						],
						[
							-0.1181657,
							51.506066
						],
						[
							-0.1175665,
							51.5057738
						],
						[
							-0.1169019,
							51.5054787
						],
						[
							-0.1163177,
							51.5051554
						],
						[
							-0.1159398,
							51.5049344
						],
						[
							-0.115505,
							51.5046375
						],
						[
							-0.1151563,
							51.5043734
						],
						[
							-0.1149417,
							51.5041505
						],
						[
							-0.1146413,
							51.503725
						],
						[
							-0.11455568936003,
							51.50356920936915
						],
						[
							-0.11436387833906,
							51.50307560020812
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.114358936651613,
							51.503037450454165
						],
						[
							-0.114389805618571,
							51.502767894757284
						],
						[
							-0.1151509,
							51.5008901
						],
						[
							-0.1151349,
							51.5006794
						],
						[
							-0.1151188,
							51.5005264
						],
						[
							-0.1150919,
							51.5003748
						],
						[
							-0.1150437,
							51.5002409
						],
						[
							-0.1149042,
							51.5000621
						],
						[
							-0.1145654,
							51.499908
						],
						[
							-0.1143601,
							51.4998147
						],
						[
							-0.1128887,
							51.4991418
						],
						[
							-0.112305725227538,
							51.49889414238709
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.112269025188874,
							51.498879147448335
						],
						[
							-0.1115801,
							51.4986087
						],
						[
							-0.1111368,
							51.498467
						],
						[
							-0.110884,
							51.4984143
						],
						[
							-0.1106117,
							51.4983716
						],
						[
							-0.1103705,
							51.4983571
						],
						[
							-0.1098775,
							51.4983435
						],
						[
							-0.1094204,
							51.4983664
						],
						[
							-0.1059791,
							51.4985939
						],
						[
							-0.1055285,
							51.4986039
						],
						[
							-0.105035,
							51.4985585
						],
						[
							-0.1045656,
							51.4984428
						],
						[
							-0.1040635,
							51.4981651
						],
						[
							-0.1013968,
							51.4961022
						],
						[
							-0.1009784,
							51.4957674
						],
						[
							-0.100559810375393,
							51.49539642566956
						]
					]
				}
			}
		]
	},
	{
		name: "Northern",
		lineColor: "#000000",
		features: [
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.275485628793175,
							51.61398125533029
						],
						[
							-0.2741314,
							51.6133784
						],
						[
							-0.2739651,
							51.6132937
						],
						[
							-0.2738086,
							51.6132197
						],
						[
							-0.2734407,
							51.6130209
						],
						[
							-0.2731302,
							51.6128233
						],
						[
							-0.2727672,
							51.6125709
						],
						[
							-0.2725052,
							51.6123617
						],
						[
							-0.2719874,
							51.6118592
						],
						[
							-0.2717611,
							51.6115472
						],
						[
							-0.2716034,
							51.6113039
						],
						[
							-0.271488,
							51.6110116
						],
						[
							-0.2713024,
							51.6104319
						],
						[
							-0.2711254,
							51.6098995
						],
						[
							-0.2709935,
							51.6095651
						],
						[
							-0.2708483,
							51.6092465
						],
						[
							-0.2706217,
							51.6088566
						],
						[
							-0.2704018,
							51.6085065
						],
						[
							-0.2698853,
							51.6077635
						],
						[
							-0.2695605,
							51.6073069
						],
						[
							-0.2691924,
							51.6068017
						],
						[
							-0.2688048,
							51.606318
						],
						[
							-0.2679151,
							51.6053875
						],
						[
							-0.2673477,
							51.6048873
						],
						[
							-0.2669203,
							51.6045418
						],
						[
							-0.2665408,
							51.6042595
						],
						[
							-0.266091,
							51.603954
						],
						[
							-0.2657202,
							51.6037262
						],
						[
							-0.2651832,
							51.6034321
						],
						[
							-0.2646699,
							51.603183
						],
						[
							-0.2641908,
							51.602956
						],
						[
							-0.26397011888356,
							51.60285324362097
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.263934400627928,
							51.602836321824526
						],
						[
							-0.2635619,
							51.6026568
						],
						[
							-0.2631285,
							51.6024454
						],
						[
							-0.2627801,
							51.6022778
						],
						[
							-0.2619536,
							51.6019266
						],
						[
							-0.2605163,
							51.6013396
						],
						[
							-0.2592999,
							51.6008553
						],
						[
							-0.2581883,
							51.6004126
						],
						[
							-0.2579739,
							51.6003317
						],
						[
							-0.2578078,
							51.6002717
						],
						[
							-0.2573604,
							51.60008
						],
						[
							-0.2567369,
							51.5998282
						],
						[
							-0.2562734,
							51.5996419
						],
						[
							-0.2557615,
							51.599434
						],
						[
							-0.2554095,
							51.599277
						],
						[
							-0.2552501,
							51.5991882
						],
						[
							-0.2551704,
							51.5991448
						],
						[
							-0.2545583,
							51.5988225
						],
						[
							-0.2542819,
							51.5986636
						],
						[
							-0.2539838,
							51.5984852
						],
						[
							-0.2535201,
							51.5981784
						],
						[
							-0.2530397,
							51.5978212
						],
						[
							-0.2524838,
							51.5974039
						],
						[
							-0.2519345,
							51.5969667
						],
						[
							-0.2513707,
							51.5965213
						],
						[
							-0.2508537,
							51.5960978
						],
						[
							-0.2506645,
							51.5959401
						],
						[
							-0.2504376,
							51.5957833
						],
						[
							-0.249799868072106,
							51.59536009473775
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.249766710411151,
							51.59533814749129
						],
						[
							-0.249479,
							51.5951482
						],
						[
							-0.2489144,
							51.5947549
						],
						[
							-0.2485018,
							51.594463
						],
						[
							-0.2480763,
							51.594177
						],
						[
							-0.2476642,
							51.5939041
						],
						[
							-0.2469682,
							51.5934632
						],
						[
							-0.2465069,
							51.5931705
						],
						[
							-0.2459933,
							51.5928388
						],
						[
							-0.2454317,
							51.5924738
						],
						[
							-0.2447951,
							51.5921103
						],
						[
							-0.2440556,
							51.591754
						],
						[
							-0.2434611,
							51.5914961
						],
						[
							-0.2430255,
							51.5913314
						],
						[
							-0.2424072,
							51.591117
						],
						[
							-0.2417903,
							51.5909273
						],
						[
							-0.2411144,
							51.5907102
						],
						[
							-0.2406464,
							51.5905647
						],
						[
							-0.2397908,
							51.5902858
						],
						[
							-0.2393214,
							51.5901239
						],
						[
							-0.2385993,
							51.5898483
						],
						[
							-0.2367606,
							51.5890852
						],
						[
							-0.2366732,
							51.5890474
						],
						[
							-0.2365723,
							51.5890018
						],
						[
							-0.2347325,
							51.5882575
						],
						[
							-0.2325097,
							51.5872452
						],
						[
							-0.2298574,
							51.5857856
						],
						[
							-0.2276656,
							51.5842887
						],
						[
							-0.2274067,
							51.5841119
						],
						[
							-0.2269463,
							51.5837668
						],
						[
							-0.2264524,
							51.5834151
						],
						[
							-0.226114238573823,
							51.58314529747111
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.226082930312489,
							51.58312073364258
						],
						[
							-0.2257906,
							51.5828952
						],
						[
							-0.2255062,
							51.5826558
						],
						[
							-0.2251224,
							51.5823647
						],
						[
							-0.2247533,
							51.5820914
						],
						[
							-0.2244342,
							51.581875
						],
						[
							-0.2240475,
							51.5816118
						],
						[
							-0.223629,
							51.5813451
						],
						[
							-0.2230882,
							51.5810203
						],
						[
							-0.222364,
							51.5805934
						],
						[
							-0.2219832,
							51.5803808
						],
						[
							-0.2215503,
							51.5801422
						],
						[
							-0.2209497,
							51.5798244
						],
						[
							-0.2205072,
							51.5796125
						],
						[
							-0.2201904,
							51.5794608
						],
						[
							-0.2195835,
							51.5791846
						],
						[
							-0.2189731,
							51.5789017
						],
						[
							-0.218536,
							51.5787166
						],
						[
							-0.218153,
							51.5785584
						],
						[
							-0.2175284,
							51.5782911
						],
						[
							-0.2163473,
							51.5778194
						],
						[
							-0.2159718,
							51.5776475
						],
						[
							-0.2146704,
							51.5771354
						],
						[
							-0.2140866,
							51.5769161
						],
						[
							-0.213571679032812,
							51.57670764052725
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.213535724001177,
							51.57669118342988
						],
						[
							-0.2131496,
							51.5764931
						],
						[
							-0.2126979,
							51.5762807
						],
						[
							-0.2122674,
							51.5760504
						],
						[
							-0.2117472,
							51.5758085
						],
						[
							-0.2112303,
							51.57557
						],
						[
							-0.2106241,
							51.5752982
						],
						[
							-0.2098594,
							51.5749544
						],
						[
							-0.2094612,
							51.574769
						],
						[
							-0.2090981,
							51.5745997
						],
						[
							-0.2084469,
							51.5743013
						],
						[
							-0.2080214,
							51.5741111
						],
						[
							-0.2072843,
							51.5737767
						],
						[
							-0.2066685,
							51.573541
						],
						[
							-0.2063424,
							51.5734248
						],
						[
							-0.2060181,
							51.573341
						],
						[
							-0.2056604,
							51.5732521
						],
						[
							-0.2053466,
							51.5731955
						],
						[
							-0.2048963,
							51.5731293
						],
						[
							-0.2045726,
							51.5730967
						],
						[
							-0.20427,
							51.5730783
						],
						[
							-0.2039862,
							51.5730683
						],
						[
							-0.2035089,
							51.5730677
						],
						[
							-0.2032403,
							51.5730821
						],
						[
							-0.2030132,
							51.5731025
						],
						[
							-0.2026072,
							51.5731493
						],
						[
							-0.2022129,
							51.5732095
						],
						[
							-0.2020062,
							51.573248
						],
						[
							-0.2017973,
							51.5732897
						],
						[
							-0.2015143,
							51.573346
						],
						[
							-0.2012733,
							51.573392
						],
						[
							-0.2010567,
							51.5734351
						],
						[
							-0.2007979,
							51.5734813
						],
						[
							-0.2006366,
							51.5735087
						],
						[
							-0.2005038,
							51.5735277
						],
						[
							-0.2002388,
							51.5735622
						],
						[
							-0.2000727,
							51.5735794
						],
						[
							-0.1999106,
							51.5735901
						],
						[
							-0.1997078,
							51.5736103
						],
						[
							-0.1995429,
							51.5736158
						],
						[
							-0.199363,
							51.5736222
						],
						[
							-0.1991388,
							51.5736165
						],
						[
							-0.1988973,
							51.5736103
						],
						[
							-0.1985026,
							51.5735907
						],
						[
							-0.1982658,
							51.5735665
						],
						[
							-0.1977836,
							51.5734957
						],
						[
							-0.197446,
							51.5734265
						],
						[
							-0.1971152,
							51.5733554
						],
						[
							-0.1967801,
							51.573263
						],
						[
							-0.1965345,
							51.5731854
						],
						[
							-0.1962523,
							51.5730901
						],
						[
							-0.1959113,
							51.5729599
						],
						[
							-0.1954149,
							51.5727534
						],
						[
							-0.1950076,
							51.5726352
						],
						[
							-0.1945721,
							51.5725287
						],
						[
							-0.19410537855797,
							51.57240248638097
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.194067056189467,
							51.57239189423257
						],
						[
							-0.1933457,
							51.5721882
						],
						[
							-0.1927156,
							51.5720428
						],
						[
							-0.19208,
							51.5719309
						],
						[
							-0.1916375,
							51.5718508
						],
						[
							-0.1912767,
							51.5717854
						],
						[
							-0.1908957,
							51.5717163
						],
						[
							-0.1908599,
							51.5717098
						],
						[
							-0.1902488,
							51.5715951
						],
						[
							-0.1896083,
							51.5714773
						],
						[
							-0.1891107,
							51.571369
						],
						[
							-0.1884834,
							51.5712396
						],
						[
							-0.1879407,
							51.5711113
						],
						[
							-0.1872563,
							51.5709585
						],
						[
							-0.1864841,
							51.570696
						],
						[
							-0.1858347,
							51.570506
						],
						[
							-0.1850024,
							51.5702277
						],
						[
							-0.18434,
							51.5699662
						],
						[
							-0.183592,
							51.5696275
						],
						[
							-0.1831208,
							51.5693901
						],
						[
							-0.1827126,
							51.5691582
						],
						[
							-0.1824109,
							51.5689474
						],
						[
							-0.1819698,
							51.5686432
						],
						[
							-0.1817277,
							51.5684236
						],
						[
							-0.1815573,
							51.5682231
						],
						[
							-0.1814176,
							51.5680077
						],
						[
							-0.1801382,
							51.5653452
						],
						[
							-0.1798914,
							51.5647331
						],
						[
							-0.1796586,
							51.5639777
						],
						[
							-0.1794979,
							51.5631941
						],
						[
							-0.1793797,
							51.5623704
						],
						[
							-0.1793299,
							51.5615252
						],
						[
							-0.1793663,
							51.5601883
						],
						[
							-0.1794524,
							51.5590985
						],
						[
							-0.1795204,
							51.5587911
						],
						[
							-0.179652,
							51.5584753
						],
						[
							-0.1797586,
							51.5581605
						],
						[
							-0.1797531,
							51.5579536
						],
						[
							-0.1796899,
							51.5577484
						],
						[
							-0.1795556,
							51.5575053
						],
						[
							-0.1794317,
							51.5573457
						],
						[
							-0.1792145,
							51.5571266
						],
						[
							-0.1788921,
							51.5568757
						],
						[
							-0.178212094508316,
							51.556405535375866
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.178177946066319,
							51.55638553264669
						],
						[
							-0.1776621,
							51.5561337
						],
						[
							-0.176928,
							51.5558747
						],
						[
							-0.1760184,
							51.5556005
						],
						[
							-0.1753655,
							51.5554279
						],
						[
							-0.1743168,
							51.5551814
						],
						[
							-0.1728711,
							51.5548194
						],
						[
							-0.17189,
							51.5544767
						],
						[
							-0.1700244,
							51.5536471
						],
						[
							-0.1687866,
							51.5530053
						],
						[
							-0.1676487,
							51.5523628
						],
						[
							-0.1666659,
							51.5516835
						],
						[
							-0.164720432213694,
							51.55028675389701
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.164687124718151,
							51.550265100717496
						],
						[
							-0.1636306,
							51.5496464
						],
						[
							-0.162183,
							51.5489153
						],
						[
							-0.1617194,
							51.5486456
						],
						[
							-0.1601104,
							51.547599
						],
						[
							-0.159279,
							51.5471863
						],
						[
							-0.158023,
							51.5466793
						],
						[
							-0.1573176,
							51.5464156
						],
						[
							-0.1567942,
							51.5461919
						],
						[
							-0.1564063,
							51.5459957
						],
						[
							-0.1558448,
							51.5456808
						],
						[
							-0.1538881,
							51.5444298
						],
						[
							-0.153487349499569,
							51.54421126109998
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.15345160156087,
							51.54419439755984
						],
						[
							-0.1522054,
							51.543693
						],
						[
							-0.1515698,
							51.5434907
						],
						[
							-0.1508888,
							51.5433108
						],
						[
							-0.1490086,
							51.5429414
						],
						[
							-0.1478757,
							51.5427018
						],
						[
							-0.1470958,
							51.5424455
						],
						[
							-0.1464383,
							51.5421614
						],
						[
							-0.1458523,
							51.5418601
						],
						[
							-0.1451749,
							51.5414126
						],
						[
							-0.1444062,
							51.5408629
						],
						[
							-0.1439643,
							51.5404939
						],
						[
							-0.1434794,
							51.5399793
						],
						[
							-0.1430747,
							51.5395463
						],
						[
							-0.1428166,
							51.5392707
						],
						[
							-0.1426229,
							51.5390591
						],
						[
							-0.142566239739672,
							51.53895765847813
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.134285615378131,
							51.52843239363036
						],
						[
							-0.135948164005466,
							51.528347281702146
						],
						[
							-0.136944705567574,
							51.528404858880535
						],
						[
							-0.137336564445406,
							51.52856124531098
						],
						[
							-0.1375885,
							51.5287846
						],
						[
							-0.1378185,
							51.5290716
						],
						[
							-0.138699,
							51.5306469
						],
						[
							-0.1389544,
							51.5313234
						],
						[
							-0.1390271,
							51.5316064
						],
						[
							-0.1392046,
							51.5322676
						],
						[
							-0.1392374,
							51.5324916
						],
						[
							-0.1392449,
							51.5327455
						],
						[
							-0.13909,
							51.5343298
						],
						[
							-0.1390584,
							51.534611
						],
						[
							-0.1390546,
							51.5347814
						],
						[
							-0.1390678,
							51.5349254
						],
						[
							-0.1390988,
							51.5350354
						],
						[
							-0.139159,
							51.5351738
						],
						[
							-0.139162766577861,
							51.535178873003524
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.13426586551384,
							51.52843377111254
						],
						[
							-0.13385124555085,
							51.52846288232081
						],
						[
							-0.1335619,
							51.5285129
						],
						[
							-0.1331854,
							51.528577
						],
						[
							-0.1324887,
							51.5287875
						],
						[
							-0.1316731,
							51.5290462
						],
						[
							-0.1309924,
							51.5293015
						],
						[
							-0.1307017,
							51.5294277
						],
						[
							-0.1299914,
							51.5297452
						],
						[
							-0.1295411,
							51.5299485
						],
						[
							-0.1291657,
							51.5300916
						],
						[
							-0.128711,
							51.5302339
						],
						[
							-0.1281968,
							51.5303886
						],
						[
							-0.1276028,
							51.5304927
						],
						[
							-0.126938481476111,
							51.530573261463495
						],
						[
							-0.125106581476111,
							51.53061477769256
						],
						[
							-0.124348918523889,
							51.530597308925195
						],
						[
							-0.123523085238889,
							51.5305865709339
						],
						[
							-0.122963500846926,
							51.530604173972236
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.122923957655648,
							51.530607058149
						],
						[
							-0.1200679,
							51.5309354
						],
						[
							-0.1190753,
							51.5310011
						],
						[
							-0.1185873,
							51.5310183
						],
						[
							-0.1136313,
							51.5314218
						],
						[
							-0.1092709,
							51.5318544
						],
						[
							-0.1079066,
							51.5318921
						],
						[
							-0.1072195,
							51.5318898
						],
						[
							-0.1065445,
							51.5318513
						],
						[
							-0.106129988772443,
							51.531821496776544
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.106129988772441,
							51.53182149677656
						],
						[
							-0.105676,
							51.5317407
						],
						[
							-0.1053216,
							51.5316541
						],
						[
							-0.1046592,
							51.5314699
						],
						[
							-0.0977626,
							51.5296972
						],
						[
							-0.0911791,
							51.5280373
						],
						[
							-0.0887248,
							51.5273709
						],
						[
							-0.0882125,
							51.5270192
						],
						[
							-0.0879558,
							51.5266971
						],
						[
							-0.087548972662104,
							51.52564691551689
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.087540328096252,
							51.52560863312664
						],
						[
							-0.0874454,
							51.5243338
						],
						[
							-0.087005,
							51.5222149
						],
						[
							-0.087005,
							51.5216926
						],
						[
							-0.0871686,
							51.5206624
						],
						[
							-0.0872571,
							51.5203041
						],
						[
							-0.0873691,
							51.5199244
						],
						[
							-0.0874107,
							51.5198009
						],
						[
							-0.0876113,
							51.5193202
						],
						[
							-0.088068,
							51.5184864
						],
						[
							-0.088279858455606,
							51.518136719231855
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.088299695280721,
							51.51810248625751
						],
						[
							-0.0886747,
							51.5174251
						],
						[
							-0.088781,
							51.5172167
						],
						[
							-0.0891509,
							51.5163785
						],
						[
							-0.089714,
							51.5148582
						],
						[
							-0.0897694,
							51.5147099
						],
						[
							-0.0897912,
							51.5146114
						],
						[
							-0.0897865,
							51.5144906
						],
						[
							-0.0897516,
							51.5143806
						],
						[
							-0.0896793,
							51.5142707
						],
						[
							-0.089002388955758,
							51.51346477016375
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.088976883565936,
							51.513434403463705
						],
						[
							-0.0883645,
							51.5127053
						],
						[
							-0.0881075,
							51.5124071
						],
						[
							-0.0877133,
							51.5119179
						],
						[
							-0.0869988,
							51.5110483
						],
						[
							-0.0868924,
							51.5109097
						],
						[
							-0.086828,
							51.5107827
						],
						[
							-0.0867958,
							51.5106567
						],
						[
							-0.0867985,
							51.5105372
						],
						[
							-0.0868199,
							51.510393
						],
						[
							-0.0868575,
							51.510259
						],
						[
							-0.0870292,
							51.5097989
						],
						[
							-0.0870748,
							51.5096394
						],
						[
							-0.0870989,
							51.5094249
						],
						[
							-0.0870855,
							51.5092656
						],
						[
							-0.0870506,
							51.5091157
						],
						[
							-0.0868629,
							51.5087304
						],
						[
							-0.0868092,
							51.5085893
						],
						[
							-0.0867781,
							51.5084434
						],
						[
							-0.0867717,
							51.5082777
						],
						[
							-0.0871106,
							51.5071421
						],
						[
							-0.0871928,
							51.5069538
						],
						[
							-0.0873242,
							51.5067643
						],
						[
							-0.0874959,
							51.5066128
						],
						[
							-0.088502277509402,
							51.5058524
						],
						[
							-0.089618888224426,
							51.50503205954277
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.089651217135176,
							51.505008558758426
						],
						[
							-0.090513177509402,
							51.5043886
						],
						[
							-0.0909577,
							51.5040714
						],
						[
							-0.091211,
							51.5038143
						],
						[
							-0.0916336,
							51.5032793
						],
						[
							-0.0917739,
							51.5030975
						],
						[
							-0.0920424,
							51.5027015
						],
						[
							-0.0924519,
							51.5019827
						],
						[
							-0.0926206,
							51.5017623
						],
						[
							-0.0931442,
							51.5012993
						],
						[
							-0.093393024470986,
							51.50110379505851
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.093425362964036,
							51.501081026919096
						],
						[
							-0.0937753,
							51.5008538
						],
						[
							-0.0953139,
							51.5000091
						],
						[
							-0.095746,
							51.499769
						],
						[
							-0.0966035,
							51.4993356
						],
						[
							-0.0969417,
							51.4991248
						],
						[
							-0.097289,
							51.4988759
						],
						[
							-0.0977201,
							51.4985282
						],
						[
							-0.0993562,
							51.4970578
						],
						[
							-0.0995547,
							51.4968424
						],
						[
							-0.0997363,
							51.4966233
						],
						[
							-0.099942,
							51.4963092
						],
						[
							-0.1004012,
							51.4956381
						],
						[
							-0.100535282339445,
							51.49540051877545
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.100551487942768,
							51.49536443560119
						],
						[
							-0.100637,
							51.4951158
						],
						[
							-0.10073,
							51.4945276
						],
						[
							-0.1006828,
							51.4936357
						],
						[
							-0.1007601,
							51.4932822
						],
						[
							-0.1009368,
							51.4929686
						],
						[
							-0.1011798,
							51.4926893
						],
						[
							-0.1018346,
							51.4922111
						],
						[
							-0.102514,
							51.491709
						],
						[
							-0.1026553,
							51.4915998
						],
						[
							-0.1030738,
							51.4912491
						],
						[
							-0.1042305,
							51.4900923
						],
						[
							-0.1044222,
							51.4899065
						],
						[
							-0.1052639,
							51.4891335
						],
						[
							-0.105537413242459,
							51.48883052494789
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.105563725155018,
							51.48880137881585
						],
						[
							-0.1056501,
							51.4887057
						],
						[
							-0.1066318,
							51.4876932
						],
						[
							-0.1071335,
							51.4872044
						],
						[
							-0.1087134,
							51.4856582
						],
						[
							-0.1117746,
							51.4826622
						],
						[
							-0.1121172,
							51.4821189
						],
						[
							-0.112355525821304,
							51.48184406186624
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.112381571844035,
							51.4818142357991
						],
						[
							-0.1130876,
							51.4810114
						],
						[
							-0.1144397,
							51.4798212
						],
						[
							-0.1152116,
							51.4790909
						],
						[
							-0.1172716,
							51.4771295
						],
						[
							-0.122487546824038,
							51.472193482869
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.122516126119495,
							51.47216619007459
						],
						[
							-0.1228023,
							51.4718904
						],
						[
							-0.1242936,
							51.470587
						],
						[
							-0.1252326,
							51.4698731
						],
						[
							-0.1265772,
							51.4689486
						],
						[
							-0.1277072,
							51.467993
						],
						[
							-0.1281565,
							51.4674224
						],
						[
							-0.1292801,
							51.4657554
						],
						[
							-0.129591,
							51.4654219
						],
						[
							-0.12988435659818,
							51.46513169264312
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.129914820350425,
							51.46510651534216
						],
						[
							-0.1308221,
							51.464483
						],
						[
							-0.1327855,
							51.4636124
						],
						[
							-0.1347759,
							51.4628782
						],
						[
							-0.1359961,
							51.4624479
						],
						[
							-0.1368034,
							51.462232
						],
						[
							-0.137569445255461,
							51.46199206810812
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.137605378738143,
							51.46197571497872
						],
						[
							-0.1379997,
							51.4617331
						],
						[
							-0.1383001,
							51.4615303
						],
						[
							-0.1393845,
							51.4609353
						],
						[
							-0.141512,
							51.4595184
						],
						[
							-0.1425388,
							51.4583413
						],
						[
							-0.1432824,
							51.457473
						],
						[
							-0.143565,
							51.4571492
						],
						[
							-0.1449946,
							51.4556076
						],
						[
							-0.1461102,
							51.4544097
						],
						[
							-0.1472161,
							51.4532556
						],
						[
							-0.147323,
							51.4531216
						],
						[
							-0.147572526308795,
							51.452779062353756
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.147593691483185,
							51.45274551502892
						],
						[
							-0.14772,
							51.4525117
						],
						[
							-0.1478488,
							51.4522693
						],
						[
							-0.1478434,
							51.451129
						],
						[
							-0.1479614,
							51.4502369
						],
						[
							-0.1481438,
							51.4487818
						],
						[
							-0.148681,
							51.4479703
						],
						[
							-0.1493151,
							51.4469161
						],
						[
							-0.1499414,
							51.4461124
						],
						[
							-0.1513893,
							51.4452118
						],
						[
							-0.1519579,
							51.4443613
						],
						[
							-0.1528484,
							51.4433579
						],
						[
							-0.152976210708112,
							51.44318061417907
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.153000558975196,
							51.443149122930244
						],
						[
							-0.1552058,
							51.4404878
						],
						[
							-0.1559649,
							51.43928
						],
						[
							-0.1566872,
							51.4378739
						],
						[
							-0.1572475,
							51.4372959
						],
						[
							-0.1583523,
							51.4363843
						],
						[
							-0.1589316,
							51.4360081
						],
						[
							-0.159633319796114,
							51.43560809336146
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.15966821767081,
							51.435589561476775
						],
						[
							-0.1612115,
							51.4348284
						],
						[
							-0.1621447,
							51.4342322
						],
						[
							-0.1634125,
							51.4330874
						],
						[
							-0.1636987,
							51.4325342
						],
						[
							-0.1640109,
							51.4318033
						],
						[
							-0.1647738,
							51.4310696
						],
						[
							-0.1668165,
							51.4290632
						],
						[
							-0.168171066821136,
							51.42775910286609
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.168200288771482,
							51.427732452726566
						],
						[
							-0.1688314,
							51.4271873
						],
						[
							-0.1696516,
							51.4262822
						],
						[
							-0.1706966,
							51.4250198
						],
						[
							-0.1738209,
							51.4225586
						],
						[
							-0.1748747,
							51.421495
						],
						[
							-0.1758027,
							51.4204536
						],
						[
							-0.1766297,
							51.419587
						],
						[
							-0.1776674,
							51.4185652
						],
						[
							-0.178076963879295,
							51.418129499924206
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.178104276425714,
							51.418100940380754
						],
						[
							-0.1783134,
							51.417886
						],
						[
							-0.1785234,
							51.4176349
						],
						[
							-0.1788387,
							51.4173148
						],
						[
							-0.1795619,
							51.4168358
						],
						[
							-0.1807743,
							51.416441
						],
						[
							-0.1814073,
							51.4162871
						],
						[
							-0.1820296,
							51.4161666
						],
						[
							-0.1834672,
							51.416073
						],
						[
							-0.1913723,
							51.4155232
						],
						[
							-0.1916686,
							51.4154655
						],
						[
							-0.1918953,
							51.4154094
						],
						[
							-0.192119144039935,
							51.415334908243715
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.192157194614508,
							51.415322883620966
						],
						[
							-0.1927584,
							51.4151429
						],
						[
							-0.1934343,
							51.4148953
						],
						[
							-0.1937776,
							51.4147347
						],
						[
							-0.1940351,
							51.4145741
						],
						[
							-0.1943141,
							51.4143667
						],
						[
							-0.1945457,
							51.4141379
						],
						[
							-0.1946681,
							51.4139786
						],
						[
							-0.1947432,
							51.4137176
						],
						[
							-0.1947647,
							51.4134232
						],
						[
							-0.194568,
							51.4084991
						],
						[
							-0.1946542,
							51.4082764
						],
						[
							-0.194704,
							51.408091
						],
						[
							-0.1947198,
							51.407875
						],
						[
							-0.1947342,
							51.4041586
						],
						[
							-0.1947357,
							51.4040818
						],
						[
							-0.1947452,
							51.4036
						],
						[
							-0.19475,
							51.4033578
						],
						[
							-0.1947427,
							51.4032025
						],
						[
							-0.1947125,
							51.4030388
						],
						[
							-0.19473309137977,
							51.40236540360403
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.210130843807269,
							51.608248460661486
						],
						[
							-0.209513,
							51.6081065
						],
						[
							-0.2088774,
							51.6079267
						],
						[
							-0.2085955,
							51.6078299
						],
						[
							-0.2082545,
							51.6076989
						],
						[
							-0.2078253,
							51.6075273
						],
						[
							-0.2073693,
							51.6073358
						],
						[
							-0.206908,
							51.6071359
						],
						[
							-0.2066666,
							51.6070309
						],
						[
							-0.2059616,
							51.6067305
						],
						[
							-0.2054251,
							51.6064889
						],
						[
							-0.204937,
							51.6062807
						],
						[
							-0.2046151,
							51.6061475
						],
						[
							-0.2042316,
							51.6059909
						],
						[
							-0.2036334,
							51.605741
						],
						[
							-0.2030876,
							51.6055029
						],
						[
							-0.2011628,
							51.6046942
						],
						[
							-0.2007983,
							51.6045366
						],
						[
							-0.1998726,
							51.6041323
						],
						[
							-0.1991269,
							51.6038075
						],
						[
							-0.1983763,
							51.6034621
						],
						[
							-0.1980705,
							51.6033071
						],
						[
							-0.1977939,
							51.6031849
						],
						[
							-0.1971691,
							51.6029739
						],
						[
							-0.1966568,
							51.602754
						],
						[
							-0.1956375,
							51.6023242
						],
						[
							-0.1944842,
							51.6018308
						],
						[
							-0.1943796,
							51.6017861
						],
						[
							-0.1941564,
							51.6016906
						],
						[
							-0.1938619,
							51.6015645
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.1273422336794,
							51.50733266185603
						],
						[
							-0.1270188,
							51.5072874
						],
						[
							-0.1267531,
							51.5072482
						],
						[
							-0.1263205,
							51.507189
						],
						[
							-0.1256092,
							51.5070748
						],
						[
							-0.124648656443493,
							51.50695902625147
						],
						[
							-0.124049829603618,
							51.50695271619254
						],
						[
							-0.122980202931814,
							51.50703562782255
						],
						[
							-0.122774762253807,
							51.50703239489027
						],
						[
							-0.122596310212186,
							51.5070158296481
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.122556865929387,
							51.507012320987776
						],
						[
							-0.122472941634111,
							51.507005180549285
						],
						[
							-0.122418128976076,
							51.507001103942336
						],
						[
							-0.1221756896445,
							51.50698717097156
						],
						[
							-0.1219124498025,
							51.506972828082965
						],
						[
							-0.1216434,
							51.50695343008903
						],
						[
							-0.1213564,
							51.5069357
						],
						[
							-0.1211499,
							51.5069218
						],
						[
							-0.1209641,
							51.5069037
						],
						[
							-0.1207646,
							51.5068731
						],
						[
							-0.1203571,
							51.5067993
						],
						[
							-0.119852,
							51.5066742
						],
						[
							-0.1192931,
							51.5064816
						],
						[
							-0.1186684,
							51.5062797
						],
						[
							-0.1181657,
							51.506066
						],
						[
							-0.1175665,
							51.5057738
						],
						[
							-0.1169019,
							51.5054787
						],
						[
							-0.1163177,
							51.5051554
						],
						[
							-0.1159398,
							51.5049344
						],
						[
							-0.115505,
							51.5046375
						],
						[
							-0.1151563,
							51.5043734
						],
						[
							-0.1149417,
							51.5041505
						],
						[
							-0.1146413,
							51.503725
						],
						[
							-0.11455568936003,
							51.50356920936915
						],
						[
							-0.11436387833906,
							51.50307560020812
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.194798817982958,
							51.65092241393606
						],
						[
							-0.194664,
							51.6508436
						],
						[
							-0.1940163,
							51.6504841
						],
						[
							-0.1934248,
							51.6501621
						],
						[
							-0.1929983,
							51.6499121
						],
						[
							-0.1926179,
							51.6496961
						],
						[
							-0.1924541,
							51.6496031
						],
						[
							-0.1919671,
							51.6493265
						],
						[
							-0.1916519,
							51.6491569
						],
						[
							-0.1912442,
							51.6489039
						],
						[
							-0.1908848,
							51.6486393
						],
						[
							-0.1907778,
							51.6485519
						],
						[
							-0.19013,
							51.647882
						],
						[
							-0.1898373,
							51.64749
						],
						[
							-0.1897439,
							51.6473649
						],
						[
							-0.1879285,
							51.6450004
						],
						[
							-0.1838368,
							51.6396706
						],
						[
							-0.1834491,
							51.6392257
						],
						[
							-0.1827698,
							51.6383656
						],
						[
							-0.1822371,
							51.6376807
						],
						[
							-0.1811859,
							51.6362769
						],
						[
							-0.1803841,
							51.6352692
						],
						[
							-0.17961,
							51.6340414
						],
						[
							-0.1793037,
							51.6333167
						],
						[
							-0.1791619,
							51.6324557
						],
						[
							-0.179152887008848,
							51.63038538621914
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.179154513158669,
							51.630345671337665
						],
						[
							-0.1791904,
							51.6299324
						],
						[
							-0.1793058,
							51.6292397
						],
						[
							-0.1794668,
							51.6287069
						],
						[
							-0.1798047,
							51.6279977
						],
						[
							-0.1802874,
							51.6271869
						],
						[
							-0.1807729,
							51.6263593
						],
						[
							-0.181261,
							51.6255318
						],
						[
							-0.1820523,
							51.624173
						],
						[
							-0.182519,
							51.6233787
						],
						[
							-0.1830742,
							51.6224146
						],
						[
							-0.1834497,
							51.6217801
						],
						[
							-0.1843134,
							51.6203213
						],
						[
							-0.1850751,
							51.6189941
						],
						[
							-0.185338,
							51.6184528
						],
						[
							-0.185616535246402,
							51.61781275123693
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.185630694214272,
							51.617776578803536
						],
						[
							-0.1858235,
							51.6172104
						],
						[
							-0.1859892,
							51.6167405
						],
						[
							-0.1861988,
							51.6160738
						],
						[
							-0.1862586,
							51.6159083
						],
						[
							-0.1868721,
							51.6138392
						],
						[
							-0.1869923,
							51.6134714
						],
						[
							-0.1880066,
							51.6103624
						],
						[
							-0.188294920559766,
							51.60950731968362
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.188310699818892,
							51.60947101927615
						],
						[
							-0.1887849,
							51.6085942
						],
						[
							-0.1889053,
							51.6084103
						],
						[
							-0.1890525,
							51.6081556
						],
						[
							-0.1893985,
							51.6076813
						],
						[
							-0.1897997,
							51.6072801
						],
						[
							-0.1901549,
							51.606953
						],
						[
							-0.1909328,
							51.6063684
						],
						[
							-0.1913887,
							51.6060655
						],
						[
							-0.1942952,
							51.6043796
						],
						[
							-0.1945912,
							51.6041419
						],
						[
							-0.1947921,
							51.603905
						],
						[
							-0.1949399,
							51.603668
						],
						[
							-0.1950555,
							51.6033348
						],
						[
							-0.1950659,
							51.602989
						],
						[
							-0.1949546,
							51.6026499
						],
						[
							-0.1947698,
							51.6023386
						],
						[
							-0.1945388,
							51.602036
						],
						[
							-0.1943287,
							51.6018628
						],
						[
							-0.194141,
							51.6017245
						],
						[
							-0.1938619,
							51.6015645
						],
						[
							-0.1932101,
							51.6012713
						],
						[
							-0.191962026996332,
							51.60069152769869
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.191926149217315,
							51.60067491898452
						],
						[
							-0.1908286,
							51.6001686
						],
						[
							-0.190375,
							51.5999751
						],
						[
							-0.1887389,
							51.5993053
						],
						[
							-0.1876285,
							51.5988605
						],
						[
							-0.1864697,
							51.598374
						],
						[
							-0.1851984,
							51.5978375
						],
						[
							-0.1840799,
							51.597341
						],
						[
							-0.1818805,
							51.5964113
						],
						[
							-0.1809766,
							51.5960147
						],
						[
							-0.1792412,
							51.5952782
						],
						[
							-0.1769881,
							51.5943218
						],
						[
							-0.1748477,
							51.593392
						],
						[
							-0.1730453,
							51.5925988
						],
						[
							-0.1714682,
							51.5917823
						],
						[
							-0.170701,
							51.5913291
						],
						[
							-0.1694887,
							51.5905392
						],
						[
							-0.1688235,
							51.5900626
						],
						[
							-0.1669781,
							51.5886428
						],
						[
							-0.1662432,
							51.5881062
						],
						[
							-0.1659106,
							51.5879062
						],
						[
							-0.165236332288812,
							51.58757500983376
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.16520144174142,
							51.58755646356844
						],
						[
							-0.1642988,
							51.587039
						],
						[
							-0.1637151,
							51.5866869
						],
						[
							-0.1634698,
							51.5865497
						],
						[
							-0.162821,
							51.5862428
						],
						[
							-0.1622628,
							51.5859431
						],
						[
							-0.1617651,
							51.5856941
						],
						[
							-0.1613133,
							51.5854164
						],
						[
							-0.1610081,
							51.5852033
						],
						[
							-0.1598402,
							51.5844575
						],
						[
							-0.1519613,
							51.5802119
						],
						[
							-0.1476489,
							51.5784179
						],
						[
							-0.1468234,
							51.5780772
						],
						[
							-0.145800297382253,
							51.57765220077546
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.14576381724529,
							51.57763677425532
						],
						[
							-0.1452411,
							51.5774118
						],
						[
							-0.1446478,
							51.5770629
						],
						[
							-0.1442158,
							51.5767051
						],
						[
							-0.1436281,
							51.5761089
						],
						[
							-0.1396898,
							51.5720882
						],
						[
							-0.137755,
							51.5699647
						],
						[
							-0.1373499,
							51.569535
						],
						[
							-0.1369771,
							51.5691164
						],
						[
							-0.1365045,
							51.5685576
						],
						[
							-0.1359259,
							51.5677868
						],
						[
							-0.135591,
							51.5673064
						],
						[
							-0.1351217,
							51.5666664
						],
						[
							-0.1349552,
							51.5663401
						],
						[
							-0.1348438,
							51.5660374
						],
						[
							-0.134753824529919,
							51.56566146441511
						],
						[
							-0.134745884242931,
							51.56539090746501
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.13474778576846,
							51.56535139370705
						],
						[
							-0.1347906,
							51.5650137
						],
						[
							-0.1349317,
							51.5646488
						],
						[
							-0.1355405,
							51.5636147
						],
						[
							-0.1362844,
							51.5622573
						],
						[
							-0.1369001,
							51.5611387
						],
						[
							-0.1371898,
							51.5605494
						],
						[
							-0.1375555,
							51.5595046
						],
						[
							-0.1377832,
							51.5585142
						],
						[
							-0.1379893,
							51.5577641
						],
						[
							-0.1382656,
							51.5571186
						],
						[
							-0.138423529962186,
							51.55682539589805
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.138443254077709,
							51.55679110549531
						],
						[
							-0.1387568,
							51.5562795
						],
						[
							-0.1395186,
							51.5552335
						],
						[
							-0.1400013,
							51.5545186
						],
						[
							-0.1405168,
							51.5538237
						],
						[
							-0.1406528,
							51.5535761
						],
						[
							-0.1408473,
							51.5531753
						],
						[
							-0.1409725,
							51.5527687
						],
						[
							-0.1410941,
							51.5520954
						],
						[
							-0.1410706,
							51.5517077
						],
						[
							-0.1410275,
							51.5514729
						],
						[
							-0.1408173,
							51.550944
						],
						[
							-0.1407567,
							51.5506794
						],
						[
							-0.1407344,
							51.5504082
						],
						[
							-0.140739016215086,
							51.550330153359624
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.140741360132504,
							51.550290524599674
						],
						[
							-0.1407613,
							51.5499534
						],
						[
							-0.1409074,
							51.5494254
						],
						[
							-0.1412016,
							51.5483599
						],
						[
							-0.1413975,
							51.5477468
						],
						[
							-0.1417219,
							51.5466554
						],
						[
							-0.1417618,
							51.5464347
						],
						[
							-0.1417219,
							51.5459379
						],
						[
							-0.1416545,
							51.5456212
						],
						[
							-0.1416561,
							51.5455144
						],
						[
							-0.1416265,
							51.5454115
						],
						[
							-0.1415968,
							51.5452564
						],
						[
							-0.1415857,
							51.5451503
						],
						[
							-0.1415587,
							51.5450107
						],
						[
							-0.1415118,
							51.5448371
						],
						[
							-0.1414942,
							51.5447498
						],
						[
							-0.1414799,
							51.5444525
						],
						[
							-0.1414719,
							51.5443796
						],
						[
							-0.141491,
							51.5441371
						],
						[
							-0.141646,
							51.5436016
						],
						[
							-0.1418092,
							51.5428567
						],
						[
							-0.1419658,
							51.5423176
						],
						[
							-0.1421324,
							51.5412732
						],
						[
							-0.1422254,
							51.5406844
						],
						[
							-0.1423407,
							51.5399763
						],
						[
							-0.1424036,
							51.5396902
						],
						[
							-0.1425014,
							51.5393143
						],
						[
							-0.1425356,
							51.5391473
						],
						[
							-0.142554602150761,
							51.53896008357179
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.142554147215635,
							51.538920788482905
						],
						[
							-0.1425338,
							51.5387581
						],
						[
							-0.1424867,
							51.5386083
						],
						[
							-0.1423569,
							51.5383903
						],
						[
							-0.1420298,
							51.5380154
						],
						[
							-0.1416842,
							51.5375986
						],
						[
							-0.1412747,
							51.5371833
						],
						[
							-0.1397863,
							51.5358371
						],
						[
							-0.1393283,
							51.5353716
						],
						[
							-0.13881,
							51.5346716
						],
						[
							-0.138552240259008,
							51.53437289578593
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.138524807258173,
							51.53434445669689
						],
						[
							-0.1343383,
							51.5304611
						],
						[
							-0.1341352,
							51.5302295
						],
						[
							-0.1339146,
							51.5299207
						],
						[
							-0.1338223,
							51.529729
						],
						[
							-0.1337992,
							51.5295171
						],
						[
							-0.1338665,
							51.5291214
						],
						[
							-0.1340017,
							51.5288297
						],
						[
							-0.1342045,
							51.5285152
						],
						[
							-0.134271819976933,
							51.52844649279183
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.134299471124739,
							51.52841827184976
						],
						[
							-0.1344617,
							51.5282527
						],
						[
							-0.1346152,
							51.5281114
						],
						[
							-0.1348313,
							51.5279423
						],
						[
							-0.1352413,
							51.5276987
						],
						[
							-0.1374833,
							51.5267976
						],
						[
							-0.1378183,
							51.5266832
						],
						[
							-0.1380141,
							51.526604
						],
						[
							-0.1381804,
							51.5265107
						],
						[
							-0.1383245,
							51.5263982
						],
						[
							-0.1384237,
							51.526255
						],
						[
							-0.1384925,
							51.5260638
						],
						[
							-0.138513,
							51.5257874
						],
						[
							-0.1384299,
							51.5253418
						],
						[
							-0.138304,
							51.5250264
						],
						[
							-0.138109023106366,
							51.524670820768236
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.138089146770527,
							51.52463660792561
						],
						[
							-0.1380159,
							51.5245174
						],
						[
							-0.1373356,
							51.5236732
						],
						[
							-0.1366273,
							51.5228698
						],
						[
							-0.134371909137214,
							51.520529226907726
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.134343912695322,
							51.520501351259746
						],
						[
							-0.1324922,
							51.5187324
						],
						[
							-0.1309526,
							51.5171398
						],
						[
							-0.1307839,
							51.5169443
						],
						[
							-0.1306668,
							51.5167828
						],
						[
							-0.1305962,
							51.5166582
						],
						[
							-0.130530773593719,
							51.51648604399231
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.130516270650061,
							51.51644909326962
						],
						[
							-0.1304562,
							51.5163008
						],
						[
							-0.1303063,
							51.5158621
						],
						[
							-0.1301332,
							51.5153324
						],
						[
							-0.1299858,
							51.5149343
						],
						[
							-0.1298383,
							51.514568
						],
						[
							-0.1295119,
							51.5137519
						],
						[
							-0.1294181,
							51.5135399
						],
						[
							-0.1292486,
							51.5132252
						],
						[
							-0.1288331,
							51.5125799
						],
						[
							-0.1286663,
							51.5123097
						],
						[
							-0.1285601,
							51.5121184
						],
						[
							-0.1285048,
							51.5120069
						],
						[
							-0.1284704,
							51.5118736
						],
						[
							-0.1284563,
							51.5117066
						],
						[
							-0.128488243101082,
							51.511242504485956
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.128490560275993,
							51.51120287149041
						],
						[
							-0.1285059,
							51.5108842
						],
						[
							-0.1284851,
							51.5103807
						],
						[
							-0.1284626,
							51.5102741
						],
						[
							-0.1284162,
							51.5101485
						],
						[
							-0.128389532147322,
							51.50989346754037
						],
						[
							-0.128143704888628,
							51.508084442902074
						],
						[
							-0.127989454517388,
							51.50769006422059
						],
						[
							-0.127786139277854,
							51.50752460988686
						],
						[
							-0.127379865745444,
							51.507343455388295
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.11434947598645,
							51.50303859823173
						],
						[
							-0.112605992619444,
							51.4985667
						],
						[
							-0.1116845,
							51.4966632
						],
						[
							-0.1114211,
							51.4961962
						],
						[
							-0.1110043,
							51.4957299
						],
						[
							-0.1067916,
							51.4927538
						],
						[
							-0.1064521,
							51.4924672
						],
						[
							-0.1059049,
							51.4919208
						],
						[
							-0.1052934,
							51.4907912
						],
						[
							-0.1051432,
							51.4902908
						],
						[
							-0.1051432,
							51.4898706
						],
						[
							-0.105210882422361,
							51.48944639372274
						],
						[
							-0.105343151876647,
							51.489162195669614
						],
						[
							-0.105542507896854,
							51.48883490842443
						]
					]
				}
			},
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.142993927001953,
							51.47943183143322
						],
						[
							-0.140869617462158,
							51.4804341011058
						],
						[
							-0.139431953430176,
							51.48095527263103
						],
						[
							-0.137951374053955,
							51.481142358392496
						],
						[
							-0.136556625366211,
							51.481262627405336
						],
						[
							-0.13507604598999,
							51.48122253776961
						],
						[
							-0.133852958679199,
							51.481142358392496
						],
						[
							-0.132737159729004,
							51.4810488156077
						],
						[
							-0.131599903106689,
							51.48087509278403
						],
						[
							-0.130441188812256,
							51.48054100857089
						],
						[
							-0.128617286682129,
							51.48015346781766
						],
						[
							-0.127243995666504,
							51.479872832802705
						],
						[
							-0.125699043273926,
							51.47968574183342
						],
						[
							-0.124411582946777,
							51.47967237816338
						],
						[
							-0.122973918914795,
							51.47988619641402
						],
						[
							-0.121901035308838,
							51.480086650113606
						],
						[
							-0.118768215179443,
							51.48068800592616
						],
						[
							-0.11584997177124,
							51.481342806571
						],
						[
							-0.114498138427734,
							51.48182387860486
						],
						[
							-0.113275051116943,
							51.48251875147262
						],
						[
							-0.109455585479736,
							51.48520460280602
						],
						[
							-0.106000900268555,
							51.48825104864771
						],
						[
							-0.105552813622776,
							51.48881798928327
						]
					]
				}
			}
		]
	},
	{
		name: "Waterloo & City",
		lineColor: "#57c4a2",
		features: [
			{
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: [
						[
							-0.114360045100013,
							51.50307656230356
						],
						[
							-0.114395255861667,
							51.50328069783045
						],
						[
							-0.114368948336111,
							51.5034784630518
						],
						[
							-0.114332251953889,
							51.50366219860013
						],
						[
							-0.114192337047778,
							51.5040659
						],
						[
							-0.114096037047778,
							51.5043086
						],
						[
							-0.1139815,
							51.5044696
						],
						[
							-0.1137946,
							51.5046516
						],
						[
							-0.1136079,
							51.504799
						],
						[
							-0.1134048,
							51.5049075
						],
						[
							-0.1131417,
							51.5050063
						],
						[
							-0.1092595,
							51.506476
						],
						[
							-0.1090701,
							51.5065723
						],
						[
							-0.1089395,
							51.506692
						],
						[
							-0.1088429,
							51.506864
						],
						[
							-0.1088102,
							51.5070007
						],
						[
							-0.1087887,
							51.5072725
						],
						[
							-0.1087619,
							51.5074523
						],
						[
							-0.1086973,
							51.5076338
						],
						[
							-0.1085873,
							51.507847
						],
						[
							-0.1084586,
							51.5080127
						],
						[
							-0.1082912,
							51.5081667
						],
						[
							-0.1080765,
							51.5083174
						],
						[
							-0.1077614,
							51.5084613
						],
						[
							-0.1072361,
							51.508749
						],
						[
							-0.1068369,
							51.5090563
						],
						[
							-0.1062381,
							51.5095205
						],
						[
							-0.1055763,
							51.5101285
						],
						[
							-0.1047478,
							51.5112599
						],
						[
							-0.1046271,
							51.5113986
						],
						[
							-0.1044674,
							51.5115487
						],
						[
							-0.1043403,
							51.5116495
						],
						[
							-0.1041524,
							51.5117767
						],
						[
							-0.1040192,
							51.5118509
						],
						[
							-0.1038361,
							51.5119321
						],
						[
							-0.1035903,
							51.5120113
						],
						[
							-0.1033497,
							51.5120596
						],
						[
							-0.1030186,
							51.5120935
						],
						[
							-0.1026719,
							51.5121143
						],
						[
							-0.102138,
							51.5121239
						],
						[
							-0.1018242,
							51.5121219
						],
						[
							-0.1010008,
							51.5120923
						],
						[
							-0.1008694,
							51.5120869
						],
						[
							-0.0982998,
							51.5118963
						],
						[
							-0.0973127,
							51.5118666
						],
						[
							-0.0970365,
							51.5118683
						],
						[
							-0.0966025,
							51.5118847
						],
						[
							-0.0963089,
							51.5118997
						],
						[
							-0.0953763,
							51.5120346
						],
						[
							-0.0948935,
							51.5121207
						],
						[
							-0.0931581,
							51.5124642
						],
						[
							-0.089008914472326,
							51.51344502962036
						]
					]
				}
			}
		]
	}
];